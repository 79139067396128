import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { EmwButton } from '../../../lib/common';
import { TdsIcon, TdsProgressCircle } from '@tds/react';
import EmwTypography from '../EmwTypography/EmwTypography';
import { StyledEmwDialog } from './styled';
import TourElement from '../Tour/TourElement/TourElement';
import { useDispatch } from 'react-redux';
import { feedbackInsideModal } from '../../features/feedback/redux/actions';
import FeedbackButton from '../../features/feedback/FeedbackButton';

export default function EmwDialog({
  isOpen,
  setIsOpen,
  icon,
  title,
  text,
  actionButton,
  callback,
  classes,
  children,
  width,
  loading,
  disabled,
}) {
  const dispatch = useDispatch()
  const handleCancel = () => {
    dispatch(feedbackInsideModal(false))
    setIsOpen(false);
  };

  const handleClick = () => {
    dispatch(feedbackInsideModal(false))
    callback();
  };

  return (
    <div>
      <StyledEmwDialog aria-labelledby="customized-dialog" open={isOpen} width={width}>
        <TourElement id="tour-orgCard-EmwDialogID">
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            <TdsIcon classes={classes} icon={icon} size="large" variant="outlined" />
            <p
              className={'text-black-100 ml-xxs text-scale-6'}
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </DialogTitle>
          <DialogContent dividers id="customized-dialog-content">
            <EmwTypography classes={'qtm-body-2'}>
              {children} {text}
            </EmwTypography>
          </DialogContent>
          <DialogActions>
            <EmwButton
              color="primary"
              classes="mr-xs qtm-ghost qtm-neutral"
              size="small"
              onClick={handleCancel}
              id="btnClose"
            >
              Cancel
            </EmwButton>
            <EmwButton
              variant="filled"
              color="primary"
              size="small"
              onClick={handleClick}
              disabled={loading || disabled}
              id="btnClose-modal"
            >
              {loading && (
                <span className="mr-xxs">
                  <TdsProgressCircle />{' '}
                </span>
              )}
              {actionButton}
            </EmwButton>
          </DialogActions>
        </TourElement>
        <FeedbackButton title="Allocate CC"/>
      </StyledEmwDialog>
    </div>
  );
}
