import React, { useState } from 'react';
import { TdsIcon } from '@tds/react';
import './styled';
import { EmwButton } from '../../../../../../../lib/common';
import EmwTypography from '../../../../../../components/EmwTypography/EmwTypography';
import EmwProgressCircle from '../../../../../../components/EmwProgressCircle/EmwProgressCircle';

export default function KPIUploadButton({
  selectedOption,
  selectedFile,
  handleUpload,
  source,
  isLoading,
}) {
  return (
    <EmwButton
      variant="filled"
      color="primary"
      size="small"
      onClick={() => handleUpload(source)}
      disabled={!selectedOption || !selectedFile || isLoading}
    >
      <>
        {isLoading && <EmwProgressCircle size="small" />}
        {!isLoading && (
          <>
            <TdsIcon icon="upload_file" size="small"></TdsIcon>
            <EmwTypography classes="font-bold">Upload</EmwTypography>
          </>
        )}
      </>
    </EmwButton>
  );
}
