import React, { useEffect, useState } from 'react';
import { createFilterOptions, TextField } from '@mui/material';
import { StyledAutocomplete } from './styled';
import { Box } from '@mui/system';
const filter = createFilterOptions();

export default function EmwCustomAutocomplete({
  options,
  handleChange,
  id,
  disabled,
  name,
  defaultValue,
  placeholder,
  classes,
  hasAutoFocus,
}) {
  const [value, setValue] = useState(defaultValue);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (defaultValue.value === '') {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }, []);

  const onChange = (event, newValue) => {
    if (typeof newValue === 'string') {
      setValue({
        label: newValue,
      });
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      setValue({
        label: newValue.inputValue,
      });
      setHasError(false);
    } else {
      setValue(newValue);
      if (!newValue) {
        setHasError(true);
      } else {
        setHasError(false);
      }
    }
    handleChange(event, newValue);
  };

  const filterOptions = (filterOptions, params) => {
    const filtered = filter(filterOptions, params);

    const { inputValue } = params;
    // Suggest the creation of a new value
    const isExisting = filterOptions.some(option => inputValue === option.label);
    if (inputValue !== '' && !isExisting) {
      filtered.push({
        inputValue,
        label: `Add "${inputValue}"`,
      });
    }

    return filtered;
  };

  const getOptionLabel = option => {
    // Value selected with enter, right from the input

    if (typeof option === 'string') {
      return option;
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option.label;
  };

  return (
    <StyledAutocomplete
      size="small"
      name={name}
      value={value || ''}
      onChange={onChange}
      filterOptions={filterOptions}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      disableClearable={true}
      id={id}
      options={options || ''}
      getOptionLabel={getOptionLabel || ''}
      className={classes}
      renderOption={(props, option) => (
        <li {...props}>
          <Box
            sx={{
              fontSize: '14px',
              '& span': {
                color: '#131319',
              },
              '& div': {
                marginLeft: '20px',
                color: '#5D607E',
              },
            }}
          >
            {' '}
            {option.indent === false ? <span>{option.label}</span> : <div>{option.label}</div>}
          </Box>
        </li>
      )}
      renderInput={params => {
        return (
          <TextField
            {...params}
            autoFocus={hasAutoFocus}
            error={hasError}
            placeholder={placeholder}
          />
        );
      }}
    />
  );
}
