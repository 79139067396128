import React, { useEffect, useState } from 'react';
import { ROLES as roles, SERVICES as services } from '../../../constants';
import {
  getUsageMonitoringRolesApi,
  getUsageMonitoringServicesCountApi,
  getUsageMonitoringServicesUserPercentageApi,
} from '../../../redux/api';
import { QtmMultiselect } from '@qtm/react';
import { useDispatch, useSelector } from 'react-redux';
import useAppSnackbar from '../../../../../hooks/useAppSnankbar';
import {
  rolesFilters,
  servicesFilters,
  setLoading,
} from '../../../../../components/EmwGeneralFilters/redux/actions';
import {
  rolesFiltersSelector,
  servicesFiltersSelector,
} from '../../../../../components/EmwGeneralFilters/redux/selectors';
import { StyledUsageMonitoringServicesRolesFilters } from './styled';

export default function ServicesRolesFilters({ stateData }) {
  const [rolesList, setRolesList] = useState([]);
  const allRoles = roles.map(item => item.value);
  const allServices = services.map(item => item.value);
  const [newStateData, setNewStateData] = useState(stateData);
  const dispatch = useDispatch();
  const snackbar = useAppSnackbar();
  const rolesValues = useSelector(rolesFiltersSelector);
  const servicesValues = useSelector(servicesFiltersSelector);

  useEffect(() => {
    (async () => {
      try {
        const response = await getUsageMonitoringRolesApi();
        if (response.data.length) {
          const rolesMap = response.data.map(item => ({ value: item.code, label: item.value }));
          rolesMap.unshift({
            value: 'all',
            label: 'Select All',
          });
          setRolesList(rolesMap);
        } else {
          setRolesList(roles);
        }
      } catch (err) {
        console.error('Error getting roles! ' + err);
      }
    })();
  }, []);

  useEffect(() => {
    dispatch(rolesFilters(newStateData.roles));
    dispatch(servicesFilters(newStateData.services));
  }, [newStateData]);

  const onRoleChange = event => {
    const selectedRoles = event.detail.selectedOptions;
    handleSelectionChange('role', selectedRoles, allRoles, 'roles');
  };

  const onServiceChange = event => {
    const selectedServices = event.detail.selectedOptions;
    handleSelectionChange('service', selectedServices, allServices, 'services');
  };

  const handleSelectionChange = (type, selectedOptions, allOptions, stateKey) => {
    let newSelection = [];

    if (selectedOptions.length === 0) {
      setNewStateData({ ...newStateData, [stateKey]: [] });
      return;
    }

    if (selectedOptions.includes('all')) {
      if (newStateData[stateKey] && !newStateData[stateKey].includes('all')) {
        newSelection = allOptions;
      } else {
        const optionsToDeselect = selectedOptions.filter(item => item.toLowerCase() !== 'all');
        newSelection = [...optionsToDeselect];
      }
    } else {
      if (
        newStateData[stateKey] &&
        newStateData[stateKey].includes('all') &&
        !selectedOptions.includes('all')
      ) {
        newSelection = [];
      } else {
        const optionsToDeselect = selectedOptions.filter(item => item.toLowerCase() !== 'all');
        newSelection = [...optionsToDeselect];
      }
    }

    setNewStateData({ ...newStateData, [stateKey]: newSelection });
  };
  return (
    <StyledUsageMonitoringServicesRolesFilters >
      <QtmMultiselect
        size="small"
        placeholder="Roles"
        options={rolesList}
        onValueChanged={onRoleChange}
        classes={'select-roles-dropdown'}
        scrollable
        value={rolesValues}
      ></QtmMultiselect>
      <QtmMultiselect
        size="small"
        placeholder="Services"
        options={services}
        onValueChanged={onServiceChange}
        classes={'select-services-dropdown ml-s'}
        scrollable
        value={servicesValues}
      ></QtmMultiselect>
    </StyledUsageMonitoringServicesRolesFilters>
  );
}