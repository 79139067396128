import React, { useState } from 'react';
import EmwTypography from '../V2/components/EmwTypography/EmwTypography';
import { Grid } from '@mui/material';
import { EmwButton } from '../lib/common';

export default function MoreOptions() {
  const [showMore, setShowMore] = useState(false);

  const handleClick = () => {
    document.getElementById('goTo').click();
  };
  const handleContactClick = () => {
    document.getElementById('mailTo').click();
  };

  if (showMore) {
    return (
      <>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item sm={10} sx={{ '*': { width: '100%' } }}>
            <EmwButton classes="mt-m" variant="outline" size="small" onClick={handleClick}>
              <span style={{ fontWeight: '700' }}>Request access with KISS</span>
            </EmwButton>
          </Grid>
        </Grid>

        <Grid container alignItems="center" justifyContent="center">
          <Grid item sm={10} sx={{ '*': { width: '100%' } }}>
            <EmwButton classes="mt-xs" variant="outline" size="small" onClick={handleContactClick}>
              <span style={{ fontWeight: '700' }}>Contact EMW</span>
            </EmwButton>
          </Grid>
        </Grid>

        <a href="#" style={{ textDecoration: 'underline' }} onClick={() => setShowMore(false)}>
          <EmwTypography fontSize={8} classes="text-bluegrey-400 mt-l">
            go back
          </EmwTypography>
        </a>
        <a
          href="https://kiss.service-now.com/esc?id=sc_cat_item&sys_id=2a88b7501bb31d54b6fccb77624bcbc0"
          className="hidden"
          id="goTo"
        >
          kiss.service-now.com
        </a>

        <a href="mailto:FR-TGS-CONTACT-ENG-EMW@thalesgroup.com" className="hidden" id="mailTo">
          FR-TGS-CONTACT-ENG-EMW@thalesgroup.com
        </a>
      </>
    );
  }

  return (
    <>
      <EmwTypography fontSize={7} classes="mt-s text-dark-primary-700" fontWeight="medium">
        Ask your EM or HoEO (preferred)
      </EmwTypography>

      <a href="#" style={{ textDecoration: 'underline' }} onClick={() => setShowMore(true)}>
        <EmwTypography fontSize={8} classes="text-bluegrey-400 mt-l">
          Or more options
        </EmwTypography>
      </a>
    </>
  );
}
