import app from './modules/app';

let timer;

export default () => next => action => {
  next(action);

  if (typeof action.loading === 'boolean') {
    const loadingAction = app.actions.requestPending(action.loading);

    clearTimeout(timer);

    if (action.loading) {
      next(loadingAction);
    } else {
      const delay = 2000;
      timer = setTimeout(() => next(loadingAction), delay);
    }
  }
};
