import React, { useState } from 'react';
import { Box, Dialog, DialogTitle, styled } from '@mui/material';
import { TdsTypography } from '@tds/react';
import EMWLogo from '../../styles/images/icon/EMW_logo-01.svg';
import TourElement from '../../V2/components/Tour/TourElement/TourElement';
import { getZoom } from '../../V2/utils/sizeUtils';

const StyledDialog = styled(Dialog)(({ theme }) => {
  const zoom = getZoom();
  return {
    zoom: `${zoom}%`,
    color: 'black',
    '.dialog-title': {
      color: theme.palette.primary[500],
      textAlign: 'center',
      fontWeight: 'bolder',
      paddingBottom: 0,
    },
    '.MuiDialogTitle-root': {
      paddingTop: '10px',
      fontWeight: 'bolder',
      fontSize: '28px',
      paddingBottom: '0px',
    },
    '.MuiDialogContent-root': {
      // width: "400px",
      paddingLeft: '48px',
      paddingRight: '48px',
      color: 'black',
      borderTop: `1px solid ${theme.palette.divider}`,
    },
    '.MuiDialogActions-root': {
      justifyContent: 'center',
    },
  };
});

export default function EmwDialog({
  open,
  handleClose,
  title,
  children,
  size,
  stretch,
  logoOff,
  blackTittle,
  logo,
}) {
  const [maxWidth] = useState(size ? size : 'sm');
  const [fullWidth] = useState(!!stretch);

  return (
    <StyledDialog open={open} onClose={handleClose} fullWidth={fullWidth} maxWidth={maxWidth}>
      <TourElement id="tour-orgCard-EmwDialogID">
        <DialogTitle className="dialog-title">
          {logoOff && <Box mx="auto" sx={{ maxWidth: '175px' }}></Box>}

          {logo && (
            <Box mx="auto" sx={{ maxWidth: '175px' }}>
              <img src={logo} alt="EMW logo" style={{ margin: '0 auto' }} />
            </Box>
          )}

          {title && (
            <TdsTypography fontWeight="bolder" component="qtm-body-2">
              {title}
            </TdsTypography>
          )}
        </DialogTitle>

        {children}
      </TourElement>
    </StyledDialog>
  );
}
