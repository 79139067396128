import React from 'react';

export default function SortValue() {
  return (
    <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.50244 3.12001H4.25244C4.43477 3.12001 4.60964 3.04757 4.73857 2.91864C4.8675 2.78971 4.93993 2.61484 4.93993 2.43251V1.05751C4.93993 0.875173 4.8675 0.700305 4.73857 0.571374C4.60964 0.442443 4.43477 0.37001 4.25244 0.37001H1.50244C1.3201 0.37001 1.14524 0.442443 1.01631 0.571374C0.887375 0.700305 0.814941 0.875173 0.814941 1.05751V2.43251C0.814941 2.61484 0.887375 2.78971 1.01631 2.91864C1.14524 3.04757 1.3201 3.12001 1.50244 3.12001ZM1.50244 8.62H7.00243C7.18477 8.62 7.35964 8.54757 7.48857 8.41863C7.6175 8.2897 7.68993 8.11484 7.68993 7.9325V6.5575C7.68993 6.37517 7.6175 6.2003 7.48857 6.07137C7.35964 5.94244 7.18477 5.87 7.00243 5.87H1.50244C1.3201 5.87 1.14524 5.94244 1.01631 6.07137C0.887375 6.2003 0.814941 6.37517 0.814941 6.5575V7.9325C0.814941 8.11484 0.887375 8.2897 1.01631 8.41863C1.14524 8.54757 1.3201 8.62 1.50244 8.62ZM12.5024 16.87H1.50244C1.3201 16.87 1.14524 16.9424 1.01631 17.0714C0.887375 17.2003 0.814941 17.3751 0.814941 17.5575V18.9325C0.814941 19.1148 0.887375 19.2897 1.01631 19.4186C1.14524 19.5476 1.3201 19.62 1.50244 19.62H12.5024C12.6848 19.62 12.8596 19.5476 12.9886 19.4186C13.1175 19.2897 13.1899 19.1148 13.1899 18.9325V17.5575C13.1899 17.3751 13.1175 17.2003 12.9886 17.0714C12.8596 16.9424 12.6848 16.87 12.5024 16.87ZM1.50244 14.12H9.75243C9.93476 14.12 10.1096 14.0476 10.2386 13.9186C10.3675 13.7897 10.4399 13.6148 10.4399 13.4325V12.0575C10.4399 11.8752 10.3675 11.7003 10.2386 11.5714C10.1096 11.4424 9.93476 11.37 9.75243 11.37H1.50244C1.3201 11.37 1.14524 11.4424 1.01631 11.5714C0.887375 11.7003 0.814941 11.8752 0.814941 12.0575V13.4325C0.814941 13.6148 0.887375 13.7897 1.01631 13.9186C1.14524 14.0476 1.3201 14.12 1.50244 14.12Z"
        fill="#041295"
      />
      <path
        d="M20.9993 12.9464H22.642C23.1287 12.9464 23.3727 13.5371 23.0298 13.8811L20.2919 17.1665C20.1893 17.2691 20.05 17.3267 19.9049 17.3267C19.7597 17.3267 19.6205 17.2691 19.5178 17.1665L16.78 13.8811C16.4353 13.5364 16.6807 12.9464 17.1663 12.9464H18.8091V2.54258C18.8091 2.39736 18.8667 2.25808 18.9694 2.15539C19.0721 2.0527 19.2114 1.99501 19.3566 1.99501H20.4518C20.597 1.99501 20.7363 2.0527 20.839 2.15539C20.9416 2.25808 20.9993 2.39736 20.9993 2.54258V12.9464Z"
        fill="#041295"
      />
    </svg>
  );
}
