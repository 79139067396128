import React, { useContext, useEffect, useRef } from 'react';
import { Box, Divider, Grid } from '@mui/material';
import Section1 from '../Section1/Section1';
import Section2 from '../Section2/Section2';
import Section3 from '../Section3/Section3';
import CountrySelector from '../CountrySelector/CountrySelector';
import { OrganizationCardContext } from '../../../../context/OrganizationCardContextProvider';
import { getZoom } from '../../../../../../../../utils/sizeUtils';

/**
 * @todo: Bogdan B refactor cardForm
 * @description Low performance an to many re renders
 * @deprecated
 *
 */
export default function CardForm({ targetField, isReseted, setCountryName }) {
  const zoom = getZoom();
  const context = useContext(OrganizationCardContext);
  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      const windowWidth = window.screen.width;
      if (windowWidth < 1400) {
        ref.current.style.zoom = `${zoom}%`;
      }
    }
  }, [ref]);

  const renderSection1 = () => {
    if (Object.keys(context.data.section1).length > 0) {
      return (
        <>
          {context.data.section1.hasOwnProperty('countries') &&
            context.data.section1.countries.visible && (
              <CountrySelector
                setCountryName={setCountryName}
                isReseted={isReseted}
                data={context.data}
                toggleIsOpen={true}
              />
            )}
          <Section1
            section1={context.data.section1}
            targetField={targetField}
            sectionCer={context.data.sectionCer}
          />
        </>
      );
    }

    return null;
  };

  const renderSection2 = () => {
    if (
      context.data.section2.visible
      // &&
      // Object.keys(context.data.section2).length > 0 &&
      // context.data.section2.deliveries
    ) {
      return <Section2 section2={context.data.section2} targetField={targetField} />;
    }
  };

  const renderSection3 = () => {
    if (
      context.data.section3.visible &&
      Object.keys(context.data.section3).length > 0 &&
      context.data.section3.disciplines
    ) {
      return <Section3 section3={context.data.section3} targetField={targetField} />;
    }
  };

  return (
    <Box style={{ display: 'flex', flexDirection: 'column', height: '100%' }} ref={ref}>
      <Grid item flexGrow={1}>
        <Grid container>
          <Grid item sm={12} md={6} className="left">
            <Grid container>
              <Grid item flexGrow={1}>
                {renderSection1()}
              </Grid>

              <Divider
                orientation="vertical"
                flexItem
                sx={{ marginLeft: 'auto', paddingLeft: '15px' }}
              />
            </Grid>
          </Grid>

          <Grid item sm={12} md={6} className="right" sx={{ paddingLeft: '15px' }}>
            <Grid container>
              <Grid item flexGrow={1}>
                {renderSection2()}
                {renderSection3()}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
