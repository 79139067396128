import React, { useEffect, useState, useRef } from 'react';
import { Box } from '@mui/material';
import { SketchPicker, CompactPicker } from 'react-color';

export default function EmwColorDropdown({ defaultColor, handleColorChange, height }) {
  const [showPicker, setShowPicker] = useState(false);
  const [background, setBackground] = useState({
    h: 250,
    s: 0,
    l: 0.2,
    a: 1,
  });
  const [colorPreview, setColorPreview] = useState(defaultColor);
  const colorBoxRef = useRef(null);

  //please leave this for now until mustafa approves
  // const presetColors = [
  //   { color: "#66ff66", title: "green" },
  //   { color: "#ff0707", title: "orange" },
  //   { color: "#bfbfbf", title: "grey" },
  //   { color: "#ffbf00", title: "red" },
  //   { color: "#fefe00", title: "yellow" },
  //   { color: "#000000", title: "black" },
  //   { color: "#FFFFFF", title: "white" },
  //   { color: "#02ff00", title: "lime-green" },
  // ];

  const colorList = [
    '#66ff66',
    '#ff0707',
    '#bfbfbf',
    '#ffbf00',
    '#fefe00',
    '#000000',
    '#FFFFFF',
    '#02ff00',
    '#194D33',
    '#0C797D',
    '#0062B1',
    '#653294',
  ];

  const handleChange = data => {
    if (data.hsl !== background) {
      setColorPreview(data.hex);
      setBackground(data.hsl);
    }
  };

  const handlePickerToggle = () => {
    setShowPicker(!showPicker);
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (colorBoxRef.current && !colorBoxRef.current.contains(event.target)) {
        setShowPicker(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [colorBoxRef]);

  return (
    <Box
      alignItems={'center'}
      display={'flex'}
      ref={colorBoxRef}
      sx={{
        width: '45px',
        height: height ? height : '28px',
        border: '1px solid #5D607E',
        borderRadius: '2px',
      }}
    >
      <div
        style={{
          width: '17px',
          height: '17px',
          padding: '4px 0 4px',
          marginLeft: '4px',
          border: '1px solid #5D607E',
          borderRadius: '1px',
          backgroundColor: colorPreview,
        }}
      />
      <div
        onClick={handlePickerToggle}
        style={{
          content: '""',
          width: 0,
          height: 0,
          borderStyle: 'solid',
          borderWidth: '5px 4px 0 4px',
          borderColor: 'grey transparent transparent transparent',
          position: 'relative',
          left: '7px',
          cursor: 'pointer',
        }}
      ></div>
      {showPicker && (
        <div style={{ position: 'absolute', top: '67px', zIndex: 1498 }}>
          {/* please leave this for now until mustafa approves */}
          {/* <SketchPicker
            color={background}
            onChange={handleChange}
            title={"BG"}
            presetColors={presetColors}
            onChangeComplete={handleColorChange}
          /> */}
          <CompactPicker
            colors={colorList}
            onChange={handleChange}
            onChangeComplete={handleColorChange}
            title={'BG'}
          />
        </div>
      )}
    </Box>
  );
}
