import * as actions from './actions';
import { getState, reduceAction } from '../../../../util/reducer';
import initialState from './initialState';

const reducerConfig = {
  [actions.UPDATE_SETTINGS]: reduceAction,
};

export default (state = initialState, action = {}) =>
  getState(state, action, initialState, reducerConfig);
