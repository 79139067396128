import React, { useEffect, useRef, useState } from 'react';
import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { useDispatch, useSelector } from 'react-redux';
import {
  alertsSelector,
  chartsSelector,
  commentsSelector,
  isDrilledSelector,
  powerBiInitializedSelector,
  reportFiltersSelector,
  selectedReportSelector,
} from '../../redux/selectors';
import { useLocation } from 'react-router-dom-v5-compat';
import usePowerBi from '../../hooks/usePowerBi';
import cls from 'classnames';
import useModals from '../../hooks/useModals';
import useUser from '../../../../auth/useUser';
import UnderMaintenancePage from '../UnderMaintenancePage/UnderMaintenancePage';
import { isHzaSelector } from '../../../footer/redux/selectors';
import useEventHandlers from './useEventHandlers';

window.iconClick = (type, chart, displayName) => {
  document.getElementById(type + chart + displayName).click();
};

const MOOD_AND_CONFIDENCE_URL = '/moodandconfidence';
const ENGINEERING_COMPETITIVENESS_URL = '/engineeringcompetitivness';
const POWER_BI_SCHEMA_BASIC_URL = 'http://powerbi.com/product/schema#basic';
const JOB_FAMILY_URL = '/jobfamily';
const SKILLS_URL = '/skills';

const RGB_GREY = 'rgb(242, 242, 242)';

// The pages on witch this component should be visible
export const pathNames = [
  MOOD_AND_CONFIDENCE_URL,
  ENGINEERING_COMPETITIVENESS_URL,
  JOB_FAMILY_URL,
  SKILLS_URL,
];
const allowedSections = [
  'cfa7700d-3296-4594-91c2-c62f11eff5d5', // Mood And Confidence to Deliver Default
  '9bb042d7-65f3-4a1e-b352-00a47c56ff5a', // Job Family Browsing Default
  '518d1e3d-6560-4951-b1c9-bdb4fb8d7476', // Competitivnes Default
  '881436c5-ddb5-4692-a961-8e1babb96981', // Landing page
  '3170ad2f-3efd-4460-9d68-48df5fd387c5',
];

export const tdpPbiPages = [];
tdpPbiPages[MOOD_AND_CONFIDENCE_URL] = 'ReportSection12cd800f5b41b0e04f27';
tdpPbiPages[ENGINEERING_COMPETITIVENESS_URL] = 'ReportSectionf445ac6e2fc5f81534b1';
tdpPbiPages[JOB_FAMILY_URL] = 'ReportSection27d68bf0023d76e62190';
tdpPbiPages[SKILLS_URL] = 'ReportSection22034a373e0e6328ac80';

const pbiTDPPagesArray = [
  {
    displayName: 'WFB Default',
    isDrilled: false,
    name: 'ReportSectionfa523654a26976a87575',
    pathName: ENGINEERING_COMPETITIVENESS_URL,
    friendlyName: 'wfb default',
    pageFilters: true,
    isCustom: false,
  },
  {
    displayName: 'Skills Focus Default',
    isDrilled: false,
    name: 'ReportSection22034a373e0e6328ac80',
    pathName: SKILLS_URL,
    friendlyName: 'skills default',
    pageFilters: true,
    isCustom: false,
  },
  {
    displayName: 'Skills Focus Custom',
    isDrilled: false,
    name: 'ReportSection558a2dd4080796e802a0',
    pathName: SKILLS_URL,
    friendlyName: 'skills custom',
    pageFilters: true,
    isCustom: true,
  },
  {
    displayName: 'Mood Confidence to deliver Default',
    isDrilled: false,
    name: 'ReportSection12cd800f5b41b0e04f27',
    pathName: MOOD_AND_CONFIDENCE_URL,
    friendlyName: 'mctd default',
    pageFilters: true,
    isCustom: false,
  },
  {
    displayName: 'Mood Confidence to deliver Custom',
    isDrilled: false,
    name: 'ReportSection2f1d7c77a5385b516560',
    pathName: MOOD_AND_CONFIDENCE_URL,
    friendlyName: 'mctd custom',
    pageFilters: true,
    isCustom: true,
  },
  {
    displayName: 'Drill Through M&C',
    isDrilled: true,
    name: 'ReportSection9d6b9aad41d6f21c2858',
    pathName: MOOD_AND_CONFIDENCE_URL,
    friendlyName: 'mctd drill through',
    pageFilters: false, // investigate raman filtrele cu My_XXXXX pe paginile de drill
    isCustom: false,
  },
  {
    displayName: 'Drill to detail MC',
    isDrilled: true,
    name: 'ReportSection6251a23cd0ba370500ea',
    pathName: MOOD_AND_CONFIDENCE_URL,
    friendlyName: 'mctd drill to details',
    pageFilters: true,
    isCustom: false,
  },
  {
    displayName: 'Drill to details Talents',
    isDrilled: true,
    name: 'ReportSection2dc3580b104b8469813d',
    pathName: SKILLS_URL,
    friendlyName: 'skills drill to details - workers',
    pageFilters: true,
    isCustom: false,
  },
  {
    displayName: 'Skill set fit 2',
    isDrilled: true,
    name: 'ReportSectioncf3b17477c168409be22',
    pathName: SKILLS_URL,
    friendlyName: 'skills drill to details - evolution',
    pageFilters: true,
    isCustom: false,
  },
  {
    displayName: 'Skill Sets Distances and Averages',
    isDrilled: true,
    name: 'ReportSection2dbb051b301dce8b8624',
    pathName: SKILLS_URL,
    friendlyName: 'skills drill to details - distance and average',
    pageFilters: true,
    isCustom: false,
  },
  {
    displayName: 'Drill to details Skills Mng 1',
    isDrilled: true,
    name: 'ReportSection039238e27e03cb25d370',
    pathName: SKILLS_URL,
    friendlyName: 'skills drill to details - distribution',
    pageFilters: true,
    isCustom: false,
  },
];

const wfbFilters = [
  {
    $schema: POWER_BI_SCHEMA_BASIC_URL,
    target: {
      table: 'WFB_N_DIM_Country',
      column: 'LB_Country',
    },
    filterType: 1,
    displaySettings: {
      displayName: 'Country',
    },
    operator: 'All',
    values: [],
    requireSingleSelection: false,
  },
  {
    $schema: POWER_BI_SCHEMA_BASIC_URL,
    target: {
      table: 'WFB_DIM_Bridge_GBU_BL_CC',
      column: 'LB_GBUCode',
    },
    filterType: 1,
    displaySettings: {
      displayName: 'GBU',
    },
    operator: 'All',
    values: [],
    requireSingleSelection: false,
  },
  {
    $schema: POWER_BI_SCHEMA_BASIC_URL,
    target: {
      table: 'WFB_DIM_Bridge_GBU_BL_CC',
      column: 'LB_BLCode',
    },
    filterType: 1,
    displaySettings: {
      displayName: 'BL',
    },
    operator: 'All',
    values: [],
    requireSingleSelection: false,
  },
  {
    $schema: POWER_BI_SCHEMA_BASIC_URL,
    target: {
      table: 'WFB_DIM_Bridge_GBU_BL_CC',
      column: 'LB_CompetenceCenterName',
    },
    filterType: 1,
    displaySettings: {
      displayName: 'CC',
    },
    operator: 'All',
    values: [],
    requireSingleSelection: false,
  },
];

const defaultPageFilters = [
  {
    $schema: POWER_BI_SCHEMA_BASIC_URL,
    target: {
      table: 'Country',
      column: 'Country',
    },
    filterType: 1,
    displaySettings: {
      displayName: 'Country',
    },
    operator: 'All',
    values: [],
    requireSingleSelection: false,
  },
  {
    $schema: POWER_BI_SCHEMA_BASIC_URL,
    target: {
      table: 'GBU_BL_CC',
      column: 'GBU Code',
    },
    filterType: 1,
    displaySettings: {
      displayName: 'GBU',
    },
    operator: 'All',
    values: [],
    requireSingleSelection: false,
  },
  {
    $schema: POWER_BI_SCHEMA_BASIC_URL,
    target: {
      table: 'GBU_BL_CC',
      column: 'BL Code',
    },
    filterType: 1,
    displaySettings: {
      displayName: 'BL',
    },
    operator: 'All',
    values: [],
    requireSingleSelection: false,
  },
  {
    $schema: POWER_BI_SCHEMA_BASIC_URL,
    target: {
      table: 'GBU_BL_CC',
      column: 'Competence Center Name',
    },
    filterType: 1,
    displaySettings: {
      displayName: ' CC',
    },
    operator: 'All',
    values: [],
    requireSingleSelection: false,
  },
];

const defaultDrillFilters = [
  {
    $schema: POWER_BI_SCHEMA_BASIC_URL,
    target: {
      table: 'Country_RLS',
      column: 'Country',
    },
    filterType: 1,
    displaySettings: {
      displayName: 'Country',
    },
    operator: 'All',
    values: [],
    requireSingleSelection: false,
  },
  {
    $schema: POWER_BI_SCHEMA_BASIC_URL,
    target: {
      table: 'GBU_BL_CC_RLS',
      column: 'GBU Code',
    },
    filterType: 1,
    displaySettings: {
      displayName: 'GBU',
    },
    operator: 'All',
    values: [],
    requireSingleSelection: false,
  },
  {
    $schema: POWER_BI_SCHEMA_BASIC_URL,
    target: {
      table: 'GBU_BL_CC_RLS',
      column: 'BL Code',
    },
    filterType: 1,
    displaySettings: {
      displayName: 'BL',
    },
    operator: 'All',
    values: [],
    requireSingleSelection: false,
  },
  {
    $schema: POWER_BI_SCHEMA_BASIC_URL,
    target: {
      table: 'GBU_BL_CC_RLS',
      column: 'Competence Center Name',
    },
    filterType: 1,
    displaySettings: {
      displayName: 'CC',
    },
    operator: 'All',
    values: [],
    requireSingleSelection: false,
  },
];

const backUpFilters = null;
const setLoadingTimeout = null;
const initialPageChanged = false;
const filtersPerPageSaved = false;
const timeoutInitial = null;
const globalFilters = null;
const pageFilters = null;
const setFilterTimeout = null;
const pageChanged = false;
const pageChangedStarted = false;
const pageChangedStartedTimeout = null;

export default function StaticEmbeddedPowerBi({
  ssoUser,
  ssoPassword,
  embedUrl,
  accessToken,
  pbiUrls,
  id,
  reportName,
}) {
  const chartsData = useSelector(chartsSelector);
  const alertsData = useSelector(alertsSelector);
  const commentsData = useSelector(commentsSelector);
  const selectedReport = useSelector(selectedReportSelector);
  const isDrilled = useSelector(isDrilledSelector);
  const reportFilters = useSelector(reportFiltersSelector);
  const [kpiName, setKpiName] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [kpiCharts, setKpiCharts] = useState([]);
  const [kpiAlerts, setKpiAlerts] = useState([]);
  const [showAlerts, setShowAlerts] = useState(true);
  const [showLoading, setShowLoading] = useState(true);
  const [reportLoaded, setReportLoaded] = useState(false);
  const isHza = useSelector(isHzaSelector);
  const dispatch = useDispatch();
  const iframeRef = useRef();
  const powerBi = usePowerBi(kpiName, iframeRef);
  const modals = useModals(kpiName, kpiCharts, kpiAlerts, showAlerts, setShowAlerts, iframeRef);
  const initialized = useSelector(powerBiInitializedSelector);
  const { pathname } = useLocation();
  const queryParams = new URLSearchParams(window.location.search);
  const targetSectionIndex = queryParams.get('targetSectionIndex');
  const [reload, setReload] = useState(false);
  const type = 'report';
  // const id = '';
  const className = cls('flex flex-col', {
    // hidden: !isVisible,
    // block: isVisible,
  });
  const user = useUser();

  // useEffect(() => {
  //   return;
  //   async function changePage(pathname) {
  //     if (!pageChanged) {
  //       //console.log("----------------------------------------1")
  //       pageChanged = true;
  //       if (!isHza) {
  //         if (pathname !== "/engineeringcompetitivness") {
  //           try {
  //             const currentPage = await window.report.getActivePage();
  //
  //             if (currentPage.name !== "ReportSection12cd800f5b41b0e04f27") {
  //               await window.report.setPage(tdpPbiPages[pathname]);
  //             }
  //           } catch (e) {
  //             console.error(e);
  //           }
  //         }
  //       }
  //     }
  //
  //     if (!window.filtersPBIAreSet) {
  //       // powerBi.openAndSetFilters();
  //       window.filtersPBIAreSet = true;
  //       setTimeout(() => {
  //         window.tdpReportLoaded = true;
  //       }, 8000);
  //     }
  //   }
  //
  //   if (window.report && !isHza && !isDrilled) {
  //     setShowLoading(true);
  //     //////console.log("-----------------showLoading: true");
  //     if (pathNames.includes(pathname)) {
  //       setIsVisible(true);
  //       clearTimeout(setLoadingTimeout);
  //       setLoadingTimeout = setTimeout(() => {
  //         setShowLoading(false);
  //         //////console.log("-----------------showLoading: false")
  //       }, 6000);
  //       if (!window.isTabClicked || pathname === "/moodandconfidence" || pathname === "/skills") {
  //         changePage(pathname);
  //         window.isTabClicked = false;
  //       }
  //     } else {
  //       setIsVisible(false);
  //     }
  //   }
  //
  //   if (!initialized || isDrilled) {
  //     return;
  //   }
  //   overwriteIframeContentStyles();
  //
  //   let visible = false;
  //   let targets = [];
  //   let alerts = [];
  //
  //   if (pathname === pathNames[0]) {
  //     if ((iframeRef.current && getAllowedSections()) || reload) {
  //       powerBi.changeReport(iframeRef, targetSectionIndex || 3);
  //     }
  //
  //     setKpiName(MOOD_AND_CONFIDENCE);
  //     powerBi.setChartIndex([7, 8]);
  //     targets = [
  //       chartsData[MOOD_AND_CONFIDENCE][CHARTS.MOOD],
  //       chartsData[MOOD_AND_CONFIDENCE][CHARTS.CONFIDENCE_TO_DELIVER],
  //     ];
  //     alerts = [
  //       alertsData[MOOD_AND_CONFIDENCE][CHARTS.MOOD],
  //       alertsData[MOOD_AND_CONFIDENCE][CHARTS.CONFIDENCE_TO_DELIVER],
  //     ];
  //     setKpiCharts(targets);
  //     setKpiAlerts(alerts);
  //
  //     visible = true;
  //   }
  //
  //   if (pathname === pathNames[1]) {
  //     if ((iframeRef.current && getAllowedSections()) || reload) {
  //       if (selectedReport) {
  //         powerBi.changeReport(iframeRef, selectedReport);
  //       } else {
  //         powerBi.changeReport(iframeRef, targetSectionIndex || 29);
  //       }
  //     }
  //     setKpiName(ENGINEERING_COMPETITIVENESS);
  //     powerBi.setChartIndex([7, 8, 10, 11, 12, 13]);
  //     try {
  //       if (iframeRef.current && powerBi.getSectionName() === "Competitiveness_Default") {
  //         targets = [
  //           chartsData[ENGINEERING_COMPETITIVENESS][CHARTS.DOTD],
  //           chartsData[ENGINEERING_COMPETITIVENESS][CHARTS.CONFIDENCE_TO_DELIVER],
  //           chartsData[ENGINEERING_COMPETITIVENESS][CHARTS.DAVD],
  //         ];
  //         alerts = [
  //           alertsData[ENGINEERING_COMPETITIVENESS][CHARTS.DOTD],
  //           alertsData[ENGINEERING_COMPETITIVENESS][CHARTS.CONFIDENCE_TO_DELIVER],
  //           alertsData[ENGINEERING_COMPETITIVENESS][CHARTS.DAVD],
  //         ];
  //       }
  //
  //       if (iframeRef.current && powerBi.getSectionName() === "Talents Culture_Default") {
  //         targets = [
  //           chartsData[TALENTS_AND_CULTURES][CHARTS.MOOD],
  //           chartsData[TALENTS_AND_CULTURES][CHARTS.KEY_PROFILE_FIT],
  //           chartsData[TALENTS_AND_CULTURES][CHARTS.SKILL_COVERAGE],
  //         ];
  //         alerts = [
  //           alertsData[TALENTS_AND_CULTURES][CHARTS.MOOD],
  //           alertsData[TALENTS_AND_CULTURES][CHARTS.KEY_PROFILE_FIT],
  //           alertsData[TALENTS_AND_CULTURES][CHARTS.SKILL_COVERAGE],
  //         ];
  //       }
  //     } catch (e) {}
  //     setKpiCharts(targets);
  //     setKpiAlerts(alerts);
  //     visible = true;
  //   }
  //
  //   if (pathname === pathNames[2]) {
  //     if ((iframeRef && getAllowedSections()) || reload) {
  //       powerBi.changeReport(iframeRef, 66);
  //     }
  //
  //     // TODO: handle JobFamilyFocus kpi targets and alerts
  //     setKpiName("");
  //     setKpiCharts([]);
  //     setKpiAlerts([]);
  //     visible = true;
  //   }
  //
  //   setIsVisible(visible);
  //
  //   if (visible) {
  //     setTimeout(() => {
  //       powerBi.renderButtons();
  //       powerBi.renderLines(targets);
  //       powerBi.renderAlerts(alerts, targets);
  //       powerBi.renderButtons();
  //     }, 500);
  //   }
  // }, [
  //   pathname,
  //   initialized,
  //   iframeRef,
  //   chartsData,
  //   alertsData,
  //   commentsData,
  //   isVisible,
  //   showAlerts,
  //   reload,
  //   selectedReport,
  //   isDrilled,
  // ]);

  // window.logMessages = function (e, t, r) {
  //   if (e.name === "sectionObjectsEvaluated") {
  //   } else if (r && r.source === "UserAction_ChangeFilter") {
  //     powerBi.clearAllDrawings();
  //   } else if (e && e.name === "sectionChanged") {
  //     if (r.displayName.toLowerCase().includes("drill")) {
  //       dispatch(setIsDrilled(true));
  //       powerBi.clearAllDrawings();
  //     } else if (r.displayName.toLowerCase().includes("default")) {
  //       dispatch(setIsDrilled(false));
  //     }
  //     // powerBi.renderButtons();
  //   } else if (e && e.name === "printSectionReady") {
  //     dispatch(setPowerBiInitialized(true));
  //     if (getAllowedSections()) {
  //       if (kpiAlerts && kpiAlerts.length > 0 && kpiCharts && kpiCharts.length > 0) {
  //         powerBi.renderButtons();
  //         powerBi.renderAlerts(kpiAlerts, kpiCharts);
  //         powerBi.showTargets();
  //       }
  //       overwriteIframeContentStyles();
  //     }
  //   }
  // };

  const getAllowedSections = () => {
    const iframeContent = document.querySelector('.embed-container iframe').contentWindow.document;
    const currentSection = iframeContent.explorationNavigation.legacyService.currentSection;

    return allowedSections.includes(currentSection.objectId);
  };

  const overwriteIframeContentStyles = () => {
    try {
      window.frames[0].document.getElementsByClassName(
        'explorationNavigationContent'
      )[0].style.display = 'none';
      document.getElementById('main').style.overflow = 'hidden';
      document.getElementById('main').style.backgroundColor = RGB_GREY;
      document.getElementsByTagName('iframe')[0].style.border = '0px';
      window.frames[0].document.getElementsByClassName('exploration')[0].style.backgroundColor =
        RGB_GREY;
      window.frames[0].document.getElementsByClassName(
        'displayAreaViewport'
      )[0].style.backgroundColor = RGB_GREY;
      window.frames[0].document.getElementsByClassName('fitToPage')[0].style.boxShadow = 'none';
      window.frames[0].document.querySelector('.exploreCanvas').style.height = '100%';
    } catch (ex) {}
  };

  const { eventHandlers } = useEventHandlers({ reportName });

  //////console.log("-----------------------------------")
  //////console.log("embedUrl url: " + embedUrl);
  //////console.log("-----------------------------------")
  const embedConfig = {
    type: type, // Supported types: report, dashboard, tile, visual, and qna.
    id: id,
    permissions: models.Permissions.All,
    viewMode: models.ViewMode.View,
    embedUrl: embedUrl + '&navContentPaneEnabled=false' /*+
      (powerBi.findGetParameter("targetSectionIndex")
        ? "&targetSectionIndex=" + powerBi.findGetParameter("targetSectionIndex")
        : ""),*/,
    accessToken: accessToken, //window.accessToken,
    tokenType: models.TokenType.Aad,
    settings: {
      persistentFiltersEnabled: true,
      visualRenderedEvents: true,
      panes: {
        bookmarks: {
          visible: false,
        },
        fields: {
          visible: false,
          expanded: true,
        },
        filters: {
          expanded: true,
          visible: true,
        },
        pageNavigation: {
          visible: false,
        },
        selection: {
          visible: false,
        },
        syncSlicers: {
          visible: false,
        },
        visualizations: {
          visible: false,
          expanded: true,
        },
      },
    },
  };

  if (user.tdpUnderMaintenance) {
    return <UnderMaintenancePage />;
  }

  return (
    <div className="flex-grow" id={'biFrameWrapper'}>
      <>
        <PowerBIEmbed
          ref={iframeRef}
          embedConfig={embedConfig}
          eventHandlers={eventHandlers}
          cssClassName="embed-container h-full"
          getEmbeddedComponent={embeddedReport => {
            if (embedUrl.indexOf('app.powerbi.com') > 0) {
              window.report[reportName] = embeddedReport;
            } else {
              window.report[reportName] = null;
            }
          }}
        />
      </>
    </div>
  );
}
