import { styled, Dialog } from '@mui/material';

export const StyledExportDialog = styled(Dialog)(({ theme }) => ({
  '.MuiPaper-root': {
    borderRadius: '6px',
    maxWidth: '990px',
  },
  '.MuiDialogContent-root': {
    padding: '10px 24px 20px 24px',
    overflowY: 'hidden',
  },
  '.header': {
    height: '56px',
    alignItems: 'center',
    // padding: "0 0 9px 0",
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
  '#tour-header-export': {
    paddingTop: '0px',
    marginTop: '8px',
    paddingLeft: '8px',
    marginLeft: '-8px',
    paddingRight: '8px',
    marginRight: '-8px',
    paddingBottom: '8px',
    marginBottom: '-8px',
  },
  '#export-dialog-content #export-dialog-section-left': {
    overflowY: 'auto',
    maxHeight: '700px',
    '#export-dialog-eng-list > div > div:nth-child(3)': {
      '#export-dialog-color-dropdown': {
        width: '45px',
      },
    },
  },
  '#tour-bottom-export': {
    marginRight: '10px',
  },
  '#tour-thales-group-export': {
    padding: '8px',
    margin: '-8px',
  },
  '#tour-dropdowns-export': {
    padding: '8px',
    margin: '-8px',
    width: '366px',
  },
  '#tour-file-name': {
    padding: '8px',
    margin: '-8px',
    width: '366px',
  },
  '#export-buttons': {
    padding: '5px',
    paddingBottom: '5px',
    alignItems: 'center',
  },
  '#tour-export-list': {
    marginRight: '10px',
    paddingRight: '-10px',
  },
  '#input-type-color': {
    '&::after': {
      content: '""',
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '5px 4px 0 4px',
      borderColor: 'grey transparent transparent transparent',
      position: 'absolute',
      top: '8px',
      left: '25px',
    },
  },
}));

export const StyledExportOrganizationHeader = styled('div')(({ theme }) => ({
  'p, tds-typography': {
    lineHeight: 1 + '!important',
  },
  '.qtm-checkbox input[type=checkbox]:indeterminate': {
    backgroundColor: '#DEDEE6',
    border: '1px solid #DEDEE6',

    '&:after': {
      backgroundColor: '#797D9C',
    },
  },
}));
