import React, { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import UserListForm from './UserListForm';
import { useSelector } from 'react-redux';
import { guestDetailsSelector } from '../../../redux/selectors';
import TourElement from '../../../../../components/Tour/TourElement/TourElement';
import { G_PG } from '../../../constants';

export default function GuestPowerGuestModalForm({
  showPowerGuests,
  showGuests,
  showExcoms,
  excoms,
  setExcoms,
  guests,
  powerGuests,
  setPowerGuests,
  setGuests,
  defaultUser,
  canEdit,
  handleSave,
  shouldReset,
}) {
  const guestDetails = useSelector(guestDetailsSelector);

  useEffect(() => {
    if (shouldReset) {
      setGuests(guestDetails.list.data.guests);
      setPowerGuests(guestDetails.list.data.powerGuests);
      setExcoms(guestDetails.list.data.excoms);
    }
  }, [shouldReset]);

  return (
    <Grid container display="flex" flexDirection="row">
      <Grid
        item
        sm={12}
        md={6}
        display="flex"
        flexDirection="column"
        overflow="auto"
        height="746px"
        paddingRight="9px"
      >
        {showExcoms && (
          <Box className="divider border-b-2 pb-xs border-primary-500">
            <TourElement id="tour-orgCard-excom">
              <UserListForm
                name={G_PG.EXCOMS.name.toUpperCase()}
                label="Name:"
                users={excoms}
                setUsers={setExcoms}
                defaultUser={defaultUser}
                canEdit={canEdit}
                shouldReset={shouldReset}
                id="Excom"
              />
            </TourElement>
          </Box>
        )}
        {showPowerGuests && (
          <Box>
            <TourElement id="tour-orgCard-powerguest">
              <UserListForm
                name={G_PG.POWER_GUESTS.name.toUpperCase()}
                label="Name:"
                users={powerGuests}
                setUsers={setPowerGuests}
                defaultUser={defaultUser}
                canEdit={canEdit}
                shouldReset={shouldReset}
                id="PowerGuest"
                showCount={true}
              />
            </TourElement>
          </Box>
        )}
      </Grid>

      <Grid
        item
        sm={12}
        md={6}
        sx={{ paddingLeft: '15px', borderLeft: '1px solid rgba(0, 0, 0, 0.12)' }}
      >
        <Grid container>
          {showGuests && (
            <Grid item flexGrow={1} className="form">
              <Box mb={1}>
                <TourElement id="tour-orgCard-guest">
                  <UserListForm
                    name={G_PG.GUESTS.name.toUpperCase()}
                    label="Name:"
                    users={guests}
                    setUsers={setGuests}
                    defaultUser={defaultUser}
                    canEdit={canEdit}
                    shouldReset={shouldReset}
                    id="Guest"
                    showCount={true}
                  />
                </TourElement>
              </Box>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
