import React, { useEffect, useState } from 'react';
import { ENGINEERING_COMPETITIVENESS, MOOD_AND_CONFIDENCE } from '../constants';
import TargetModal from '../components/TargetModal/TargetModal';
import { useDispatch, useSelector } from 'react-redux';
import { alertHistoryModalSelector, commentHistoryModalSelector } from '../redux/selectors';
import AlertModal from '../components/AlertModal/AlertModal';
import usePowerBi from './usePowerBi';
import HistoryModal from '../components/HistoryModal/HistoryModal';
import { EVENTS, subscribe, unsubscribe } from '../events';
import MyCommentsModal from '../components/MyCommentsModal/MyCommentsModal';
import CommentsHistoryModal from '../components/CommentsHistoryModal/CommentsHistoryModal';
import { setSelectedReport } from '../redux/actions';
import { isHzaSelector } from '../../footer/redux/selectors';

window.isTabClicked = false;
window.filtersPBIAreSet = false;
window.tdpReportLoaded = false;
export default function useModals(kpiName, kpiCharts, showAlerts, setShowAlerts, iframeRef) {
  const dispatch = useDispatch();
  const historyModal = useSelector(alertHistoryModalSelector);
  const commentsHistoryModal = useSelector(commentHistoryModalSelector);
  const powerBi = usePowerBi(iframeRef);
  const [showTargetsModal, setShowTargetsModal] = useState(false);
  const [showAlertsModal, setShowAlertsModal] = useState(false);
  const [showMyCommentsModal, setShowMyCommentsModal] = useState(false);
  const [selectedChart, setSelectedChart] = useState();
  const [chartIndex, setChartIndex] = useState();
  const isHza = useSelector(isHzaSelector);
  const TALENTS_CULTURE_DEFAULT = 'Talents Culture_Default';
  const MCD_DEFAULT = 'Mood Confidence to deliver Default';
  const COMPETITIVENESS_DEFAULT = 'Competitiveness_Default';
  const OPERATING_MODEL = 'OPERATING MODEL';
  const CRAFTMANSHIP_MODEL = 'CRAFTMANSHIP MODEL';
  const TALENTS_CULTURE = 'TALENTS & CULTURE';
  const COMPETITIVENESS = 'COMPETITIVENESS';
  const JOB_FAMILY_BROWSING = 'JOB FAMILY BROWSING';
  const JOB_FAMILY_ANALYSIS = 'JOB FAMILY ANALYSIS';
  const MIGRATION_TRANSFORMATION = 'MIGRATION TRANSFORMATION';

  useEffect(() => {
    subscribe(EVENTS.TARGET_CONFIDENCE_TO_DELIVER, () => showForm(6, 'target'));
    subscribe(EVENTS.TARGETS_DADV, () => showForm(5, 'target'));
    subscribe(EVENTS.TARGETS_DOTD, () => showForm(1, 'target'));
    subscribe(EVENTS.COMPETITIVENESS, () => {
      dispatch(setSelectedReport(29));
      changePageOperatingModels(EVENTS.COMPETITIVENESS);
    });
    subscribe(EVENTS.OPERATING_MODEL, () => {
      dispatch(setSelectedReport(35));
      changePageOperatingModels(EVENTS.OPERATING_MODEL);
    });
    subscribe(EVENTS.CRAFTMANSHIP_MODEL, () => {
      dispatch(setSelectedReport(33));
      changePageOperatingModels(EVENTS.CRAFTMANSHIP_MODEL);
    });
    subscribe(EVENTS.TALENTS_AND_CULTURE, () => {
      dispatch(setSelectedReport(31));
      changePageOperatingModels(EVENTS.TALENTS_AND_CULTURE);
    });
    subscribe(EVENTS.BROWSING, () => {
      dispatch(setSelectedReport(66));
      changePageOperatingModels(EVENTS.BROWSING);
    });
    subscribe(EVENTS.ANALYSIS, () => {
      dispatch(setSelectedReport(64));
      changePageOperatingModels(EVENTS.ANALYSIS);
    });
    subscribe(EVENTS.TRANSFORMATION, () => {
      changePageOperatingModels(EVENTS.TRANSFORMATION);
    });

    return () => {
      Object.keys(EVENTS).forEach(event => unsubscribe(event));
    };
  }, [kpiName]);

  const showForm = (chartIndex, type, sectionName) => {
    setShowTargetsModal(type === 'target');
    setShowAlertsModal(type === 'alert');
    setShowMyCommentsModal(type === 'comment');

    if (sectionName === TALENTS_CULTURE_DEFAULT) {
      if (chartIndex === 1) {
      }
      setSelectedChart(kpiCharts[chartIndex]);
      setChartIndex(chartIndex);
    } else {
      setSelectedChart(kpiCharts[chartIndex]);
      setChartIndex(chartIndex);
    }
  };

  const getPageMap = () => {
    const pageMap = [];
    //pageMap["OPERATING MODEL"] = "ReportSectionfa523654a26976a87575";
    pageMap[OPERATING_MODEL] = 'ReportSection68599c270ff986aa3403';
    pageMap[CRAFTMANSHIP_MODEL] = 'ReportSection7c5f6105adc65acc4d7f';
    pageMap[TALENTS_CULTURE] = 'ReportSection966676f274e368f511af';
    pageMap[COMPETITIVENESS] = 'ReportSectionf445ac6e2fc5f81534b1';
    pageMap[JOB_FAMILY_BROWSING] = 'ReportSection27d68bf0023d76e62190';
    pageMap[JOB_FAMILY_ANALYSIS] = 'ReportSection9adae67190764c68478e';
    pageMap[MIGRATION_TRANSFORMATION] = 'ReportSection21d7f3e0099e9e5377ce';
    return pageMap;
  };

  const changePageOperatingModels = item => {
    if (item === EVENTS.OPERATING_MODEL) {
      return;
    }
    async function changePage(item) {
      window.isTabClicked = true;
      // if (item !== OPERATING_MODEL) {
      // if (item !== COMPETITIVENESS && item !== OPERATING_MODEL){
      try {
        await window.report.setPage(getPageMap()[item]);
      } catch (e) {
        console.warn(e);
      }
      // }
      // } else {

      //  }
    }
    if (window.report) {
      changePage(item);
    } else {
      try {
        const iframeContent =
          document.querySelector('.embed-container iframe').contentWindow.document;
        switch (item) {
          case COMPETITIVENESS:
            iframeContent.explorationNavigation.legacyService.updateActiveSection({
              targetSectionIndex: 29,
            });
            break;
          case OPERATING_MODEL:
            iframeContent.explorationNavigation.legacyService.updateActiveSection({
              targetSectionIndex: 35,
            });
            break;
          case CRAFTMANSHIP_MODEL:
            iframeContent.explorationNavigation.legacyService.updateActiveSection({
              targetSectionIndex: 33,
            });
            break;
          case TALENTS_CULTURE:
            iframeContent.explorationNavigation.legacyService.updateActiveSection({
              targetSectionIndex: 31,
            });
            break;
          case JOB_FAMILY_BROWSING:
            iframeContent.explorationNavigation.legacyService.updateActiveSection({
              targetSectionIndex: 66,
            });
            break;
          case JOB_FAMILY_ANALYSIS:
            iframeContent.explorationNavigation.legacyService.updateActiveSection({
              targetSectionIndex: 67,
            });
            break;
          case MIGRATION_TRANSFORMATION:
            iframeContent.explorationNavigation.legacyService.updateActiveSection({
              targetSectionIndex: 68,
            });
            break;
        }
      } catch (e) {
        console.warn('iframe content not yet loaded');
      }
    }
  };

  /**
   *
   * @param chartIndex the index from the redux state chart array
   * @param formType alert|target|comment
   * @param visualSectionIndex the visual section index from the document.explorationNavigation.legacyService group
   * @param sectionName the section name from the document.explorationNavigation.legacyService
   * @returns {JSX.Element}
   */
  const generateHiddenBtn = (chartIndex, formType, visualSectionIndex, sectionName) => {
    return (
      <button
        onClick={() => showForm(chartIndex, formType, sectionName)}
        id={`${formType}${visualSectionIndex}${sectionName}`}
      >
        Alert mood
      </button>
    );
  };

  const renderHiddenButtons = () => {
    if (kpiName === MOOD_AND_CONFIDENCE) {
      return (
        <div className="hidden">
          {generateHiddenBtn(0, 'alert', 7, MCD_DEFAULT)}
          {generateHiddenBtn(0, 'target', 7, MCD_DEFAULT)}
          {generateHiddenBtn(0, 'comment', 7, MCD_DEFAULT)}

          {generateHiddenBtn(1, 'alert', 8, MCD_DEFAULT)}
          {generateHiddenBtn(1, 'target', 8, MCD_DEFAULT)}
          {generateHiddenBtn(1, 'comment', 8, MCD_DEFAULT)}
        </div>
      );
    }

    if (kpiName === ENGINEERING_COMPETITIVENESS) {
      return (
        <div className="hidden">
          <button onClick={() => changePageOperatingModels(COMPETITIVENESS)}>
            COMPETITIVENESS
          </button>
          <button onClick={() => changePageOperatingModels(OPERATING_MODEL)}>
            OPERATING MODEL
          </button>
          <button onClick={() => changePageOperatingModels(CRAFTMANSHIP_MODEL)}>
            CRAFTMANSHIP
          </button>
          <button onClick={() => changePageOperatingModels(TALENTS_CULTURE)}>
            TALENTS & CULTURE
          </button>

          {/* DOTD buttons */}
          {generateHiddenBtn(0, 'alert', 7, COMPETITIVENESS_DEFAULT)}
          {generateHiddenBtn(0, 'target', 7, COMPETITIVENESS_DEFAULT)}
          {generateHiddenBtn(0, 'comment', 7, COMPETITIVENESS_DEFAULT)}

          {/* DAVD buttons */}
          {generateHiddenBtn(2, 'alert', 11, COMPETITIVENESS_DEFAULT)}
          {generateHiddenBtn(2, 'target', 11, COMPETITIVENESS_DEFAULT)}
          {generateHiddenBtn(2, 'comment', 11, COMPETITIVENESS_DEFAULT)}

          {/* Confidence to deliver buttons */}
          {generateHiddenBtn(1, 'alert', 10, COMPETITIVENESS_DEFAULT)}
          {generateHiddenBtn(1, 'target', 10, COMPETITIVENESS_DEFAULT)}
          {generateHiddenBtn(1, 'comment', 10, COMPETITIVENESS_DEFAULT)}

          {/*Mood*/}
          {generateHiddenBtn(0, 'alert', 11, TALENTS_CULTURE_DEFAULT)}
          {generateHiddenBtn(0, 'target', 11, TALENTS_CULTURE_DEFAULT)}
          {generateHiddenBtn(0, 'comment', 11, TALENTS_CULTURE_DEFAULT)}

          {/* Key Profile Fit */}

          {generateHiddenBtn(1, 'alert', 10, TALENTS_CULTURE_DEFAULT)}
          {generateHiddenBtn(1, 'target', 10, TALENTS_CULTURE_DEFAULT)}
          {generateHiddenBtn(1, 'comment', 10, TALENTS_CULTURE_DEFAULT)}

          {/* Skill Coverage */}
          {generateHiddenBtn(2, 'alert', 7, TALENTS_CULTURE_DEFAULT)}
          {generateHiddenBtn(2, 'target', 7, TALENTS_CULTURE_DEFAULT)}
          {generateHiddenBtn(2, 'comment', 7, TALENTS_CULTURE_DEFAULT)}
        </div>
      );
    }

    return null;
  };

  const renderModals = () => {
    return (
      <>
        {showTargetsModal && selectedChart && (
          <TargetModal
            isOpen={showTargetsModal}
            setIsOpen={setShowTargetsModal}
            selectedChart={selectedChart}
            onButtonCancelClick={() => setShowTargetsModal(false)}
          />
        )}

        {showAlertsModal && selectedChart && (
          <AlertModal
            isOpen={showAlertsModal}
            setIsOpen={setShowAlertsModal}
            charts={kpiCharts}
            selectedChart={selectedChart}
            generalFilters={powerBi.getGeneralFilters()}
            timeframeFilters={powerBi.getTimeframeFilters()}
            showAlerts={showAlerts}
            setShowAlerts={setShowAlerts}
            chartIndex={chartIndex}
          />
        )}

        {showMyCommentsModal && selectedChart && (
          <MyCommentsModal
            isOpen={showMyCommentsModal}
            setIsOpen={setShowMyCommentsModal}
            selectedChart={selectedChart}
          />
        )}

        {historyModal.isOpen && <HistoryModal />}

        {commentsHistoryModal.isOpen && <CommentsHistoryModal selectedChart={selectedChart} />}
      </>
    );
  };

  return {
    renderHiddenButtons,
    renderModals,
  };
}
