import { getUrlParam } from '../../../../util/fetch';
import { USER_ACTIVITY } from './actions';

const userActivityMiddleware = () => next => action => {
  if (action.type === USER_ACTIVITY) {
    const isAutomaticTest = localStorage.getItem('isAutomaticTest');

    if (!isAutomaticTest) {
      next(action);
    }
  } else {
    next(action);
  }
};

export default userActivityMiddleware;
