import React from 'react';

export default function SortAlphaReverse() {
  return (
    <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.1689 20.8773L7.25829 13.0201C7.20379 12.8675 7.10341 12.7354 6.97093 12.6421C6.83844 12.5487 6.68032 12.4986 6.51824 12.4986H4.48324C4.32116 12.4986 4.16304 12.5487 4.03055 12.6421C3.89807 12.7354 3.7977 12.8675 3.7432 13.0201L0.832616 20.8773C0.790292 20.9958 0.777066 21.1228 0.794053 21.2475C0.81104 21.3722 0.857742 21.491 0.930227 21.5939C1.00271 21.6968 1.09886 21.7808 1.21057 21.8388C1.32229 21.8968 1.4463 21.9271 1.57217 21.9272H2.7915C2.95768 21.9272 3.11961 21.8746 3.25399 21.7768C3.38837 21.6791 3.4883 21.5412 3.5394 21.3831L3.75646 20.7486H7.24306L7.45963 21.3831C7.51083 21.5415 7.61105 21.6796 7.74583 21.7774C7.88061 21.8752 8.04297 21.9277 8.20949 21.9272H9.42931C9.55518 21.9271 9.67919 21.8968 9.79091 21.8388C9.90262 21.7808 9.99877 21.6968 10.0713 21.5939C10.1437 21.491 10.1904 21.3722 10.2074 21.2475C10.2244 21.1228 10.2112 20.9958 10.1689 20.8773ZM4.69587 17.9986L5.50074 15.6415L6.30561 17.9986H4.69587Z"
        fill="#041295"
      />
      <path
        d="M2.35786 0.0700226H8.64357C8.85196 0.0700226 9.05181 0.152803 9.19916 0.300153C9.34651 0.447503 9.42929 0.647352 9.42929 0.855737V1.7215C9.42931 1.94343 9.38232 2.16285 9.29142 2.36532C9.20051 2.56778 9.06775 2.74869 8.90188 2.89614L5.89357 6.35574H8.64357C8.85196 6.35574 9.05181 6.43852 9.19916 6.58587C9.34651 6.73322 9.42929 6.93307 9.42929 7.14145V8.71288C9.42929 8.92126 9.34651 9.12111 9.19916 9.26846C9.05181 9.41581 8.85196 9.49859 8.64357 9.49859H2.35786C2.14947 9.49859 1.94962 9.41581 1.80227 9.26846C1.65492 9.12111 1.57214 8.92126 1.57214 8.71288V7.84712C1.57212 7.62519 1.61911 7.40576 1.71001 7.2033C1.80092 7.00084 1.93368 6.81993 2.09955 6.67248L5.10786 3.21288H2.35786C2.14947 3.21288 1.94962 3.1301 1.80227 2.98275C1.65492 2.8354 1.57214 2.63555 1.57214 2.42717V0.855737C1.57214 0.647352 1.65492 0.447503 1.80227 0.300153C1.94962 0.152803 2.14947 0.0700226 2.35786 0.0700226Z"
        fill="#041295"
      />
      <path
        d="M21.0244 14.0214H22.6671C23.1537 14.0214 23.3977 14.6121 23.0548 14.9561L20.317 18.2415C20.2143 18.3441 20.0751 18.4017 19.9299 18.4017C19.7847 18.4017 19.6455 18.3441 19.5428 18.2415L16.805 14.9561C16.4604 14.6114 16.7057 14.0214 17.1914 14.0214H18.8341V3.61759C18.8341 3.47237 18.8918 3.33309 18.9945 3.2304C19.0971 3.12771 19.2364 3.07002 19.3816 3.07002H20.4768C20.622 3.07002 20.7613 3.12771 20.864 3.2304C20.9667 3.33309 21.0244 3.47237 21.0244 3.61759V14.0214Z"
        fill="#041295"
      />
    </svg>
  );
}
