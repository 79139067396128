import React, { useEffect, useState } from 'react';
import { Popper } from '@mui/material';

export default function CustomPopper(props) {
  const { anchorEl, style, ...other } = props;
  const [popperStyle, setPopperStyle] = useState(style);

  useEffect(() => {
    if (anchorEl) {
      const { top, left, width, height } = anchorEl.getBoundingClientRect();

      setPopperStyle({
        ...style,
        top: top + window.scrollY + height,
        left: left + window.scrollX,
        width: width,
      });
    }
  }, [anchorEl, style]);

  return <Popper {...other} style={popperStyle} disablePortal={true} />;
}
