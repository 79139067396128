import React from 'react';

export default function ArrowIconDown() {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.05252 5.05951L6.99919 7.11285L4.94585 5.05951L3.99919 5.99951L6.99919 8.99951L9.99919 5.99951L9.05252 5.05951ZM6.99919 -0.000488281C3.31919 -0.000488281 0.33252 2.98618 0.33252 6.66618C0.33252 10.3462 3.31919 13.3328 6.99919 13.3328C10.6792 13.3328 13.6659 10.3462 13.6659 6.66618C13.6659 2.98618 10.6792 -0.000488281 6.99919 -0.000488281ZM6.99919 11.9995C4.05252 11.9995 1.66585 9.61285 1.66585 6.66618C1.66585 3.71951 4.05252 1.33285 6.99919 1.33285C9.94585 1.33285 12.3325 3.71951 12.3325 6.66618C12.3325 9.61285 9.94585 11.9995 6.99919 11.9995Z"
        fill="#4A4D65"
      />
    </svg>
  );
}
