import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { TdsTab, TdsTabs } from '@tds/react';
import { useLocation } from 'react-router-dom-v5-compat';
import { styled } from '@mui/material/styles';
import { EVENTS, publish } from '../../../powerBi/events';

const StyledTabsContainer = styled(Box)(() => ({
  position: 'relative',
  height: 'inherit',
  'tds-tabs': {
    height: 'inherit',
  },
}));

export default function JobFamilyMenu({ className }) {
  const location = useLocation();
  const [selected, setSelected] = useState(true);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (location.pathname === '/jobfamily') {
      return setShow(true);
    }

    setSelected(true);
    return setShow(false);
  }, [location]);

  const handleClick = eventName => {
    setSelected(false);

    publish(eventName);
  };

  return (
    <StyledTabsContainer className={className}>
      {show && (
        <>
          <TdsTabs scrollable scrollHorizontally={true}>
            <TdsTab
              classes={selected ? 'qtm-active' : ''}
              onClickedTabEvent={() => handleClick(EVENTS.BROWSING)}
            >
              Job Family Browsing
            </TdsTab>
            <TdsTab onClickedTabEvent={() => handleClick(EVENTS.ANALYSIS)}>
              Job Family Analysis
            </TdsTab>
            <TdsTab onClickedTabEvent={() => handleClick(EVENTS.TRANSFORMATION)}>
              Job Family Evolution
            </TdsTab>
          </TdsTabs>
        </>
      )}
    </StyledTabsContainer>
  );
}
