import React, { useRef, useState } from 'react';
import EmwGeneralFilters from './EmwGeneralFilters';
import { useSelector } from 'react-redux';
import { loadingSelector } from './redux/selectors';
import EmwBackdrop from '../EmwBackdrop/EmwBackdrop';

export default function GeneralFiltersContainer({ children }) {
  const loading = useSelector(loadingSelector);
  return (
    <>
      <div className="flex flex-1 justify-between overflow-hidden">
        <div className="flex flex-1 pt-xl overflow-hidden mt-xs pl-xxl pr-xs pb-l bg-bluegrey-25">
          {children}
        </div>
        <div>
          <EmwGeneralFilters />
        </div>
      </div>
      <EmwBackdrop loading={loading} />
    </>
  );
}
