import React, { useState } from 'react';
import { StyledFeedbackForm } from './styled';
import EmwButton from '../../components/EmwButton/EmwButton';
import { TdsDivider, TdsIcon } from '@tds/react';

export default function FeedbackSubmitted({ handleClick, handleClickLeaveAnotherFeedback }) {
  return (
    <StyledFeedbackForm>
      <div className="flex justify-between feedback-title">
        <p className="mb-m text-bluegrey-750 font-bold">We Value Your Feedback</p>
        
        <TdsIcon classes="feedback-close" onClick={handleClick} size="large" icon="close" />
      </div>
      <div className="feedback-submited flex items-center flex-col justify-center">
        <TdsIcon classes='feedback-submitted-icon mb-m' icon="verified" variant="outlined" size="xxlarge" />

      <div className="text-center text-bluegrey-900 font-bold mb-m">
        <p>Thank you for your feedback!</p>
        <p> We appreciate your time and effort in helping us improve.</p>
      </div>
      <div className='flex justify-center items-center'>
        <EmwButton
          variant={'outline'}
          id={`another-feedback-btn`}
          size="small"
          onClick={handleClickLeaveAnotherFeedback}
        >
          <TdsIcon classes="mr-xxs" variant="outlined" icon='replay' size={"small"}/>
          Leave another Feedback
        </EmwButton>
      </div>
      </div>
      <TdsDivider classes='mb-m mt-l' size="small" />
      <EmwButton
        variant="filled"
        color="primary"
        size="small"
        classes="feedback-submit"
        title={`Leave a feedback`}
        id="btn-close-feedback"
        onClick={handleClick}
      >
        Close
      </EmwButton>
    </StyledFeedbackForm>
  );
}
