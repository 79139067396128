import { getState, reduceAction } from '../../../../util/reducer';
import * as actions from './actions';
import { initialState } from './initialState';
import { PBI_C3_URLS } from './actions';

const reducerConfig = {
  [actions.GET_USER]: reduceAction,
  [actions.SET_USER]: reduceAction,
  [actions.GET_TREE_DATA]: reduceAction,
  [actions.DELETE_LOGIN]: reduceAction,
  [actions.GET_BI_PROPS]: reduceAction,
  [actions.GET_BI_FILTERS]: reduceAction,
  [actions.GET_NOTIFICATIONS]: reduceAction,
  [actions.HZA_IFRAME_LOADED]: reduceAction,
  [actions.DEPENDENCIES_LOADED]: reduceAction,
  [actions.PBI_C3_URLS]: reduceAction,
};

export default (state = initialState, action = {}) =>
  getState(state, action, initialState, reducerConfig);
