import React from 'react';
import { Grid } from '@mui/material';
import EmwTypography from '../../../../../../../../components/EmwTypography/EmwTypography';
import { TdsIcon } from '@tds/react';
import { HeadCount } from '../../../../../../constants';

export default function HeadCountsDeclared({ data }) {
  return (
    <Grid item className="orgCountries" title={HeadCount.DEC_HC.title}>
      <TdsIcon icon="perm_identity" size="large" />
      <Grid item className="orgCountries-list" ml={0.5} mr={2}>
        <EmwTypography fontSize={9} fontWeight="bold">
          {HeadCount.DEC_HC.label}:
        </EmwTypography>
        {data.section1.hcDeclared.visible && (
          <p className="text-scale-9" id="DEC_HC">
            {data.section1.hcDeclared.value}
          </p>
        )}
      </Grid>
    </Grid>
  );
}
