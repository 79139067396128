import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { userSelector } from '../features/header/redux/selectors';

export const ROLES = {
  ADMIN: 'admin',
  SUPER_USER: 'Super User',
};

const useUser = () => {
  const userData = useSelector(userSelector);
  const loading = userData.loading;

  const [user, setUser] = useState({
    id: '',
    firstName: '',
    tgi: '',
    lastName: '',
    updatedDate: null,
    email: '',
    endDate: null,
    startDate: null,
    endDateDate: null,
    orgRoles: [],
    favoriteOrganization: [],
    orgsUnderMyResponsability: [],
    countriesUnderMyResponsability: [],
    countries: [],
    roleOnGroup: false,
    canExportOrgs: false,
    canImpersonate: false,
    canExportSurveys: false,
    admin: false,
    additionalUser: null,
    usedRole: null,
    impersonated: false,
    suNotifications: false,
    tdpUnderMaintenance: false,
    hzaUnderMaintenance: false,
    backInTimeDate: null,
    showAdminPage: false,
  });

  useEffect(() => {
    if (userData.list.hasOwnProperty('data')) {
      setUser(userData.list.data);
    }
  }, [userData]);

  return {
    ...user,
    loading,
  };
};

export default useUser;
