import React, { useContext, useEffect, useState } from 'react';
import EmwToggleMenu from '../../../../../../../../components/EmwToggleMenu/EmwToggleMenu';
import TogglePanel from '../Section1/TogglePanel';
import { ENGINEER_TYPE } from '../../../../../../constants';
import { Box } from '@mui/material';
import { tourStatusSelector } from '../../../../../../redux/selectors';
import { useSelector } from 'react-redux';
import { OrganizationCardContext } from '../../../../context/OrganizationCardContextProvider';

export default function OperationsPanel({ name, section1, open, section, targetField }) {
  const [isOpen, setIsOpen] = useState(open);
  const tour = useSelector(tourStatusSelector);
  const context = useContext(OrganizationCardContext);
  const key = targetField.key.toLowerCase();
  const shouldOpenOperations = key.includes('hoeo') || key.includes('kpi');
  useEffect(() => {
    if (tour) {
      setIsOpen(true);
    }
  }, [tour]);

  useEffect(() => {
    if (!isOpen && shouldOpenOperations) {
      setIsOpen(context.shouldBeOpen(section1, targetField.key));
    }
  }, []);

  return (
    <EmwToggleMenu shouldOpen={isOpen} name={name}>
      <Box ml={2}>
        <Box mt={1}>
          <TogglePanel
            name={ENGINEER_TYPE.HoEO.title.toLocaleUpperCase()}
            type={ENGINEER_TYPE.HoEO.label}
            propKey="hoEOs"
            list={section1.hoEOs}
            open={isOpen}
            showArrows={section1.hoEOs.length > 1}
            targetField={targetField}
            section={section}
            title={ENGINEER_TYPE.HoEO.title}
          />
        </Box>

        <Box mt={1}>
          <TogglePanel
            name={ENGINEER_TYPE.KPI.title.toLocaleUpperCase()}
            type={ENGINEER_TYPE.KPI.title}
            propKey="kpis"
            list={section1.kpis}
            open={isOpen}
            showArrows={section1.kpis.length > 1}
            targetField={targetField}
            section={section}
            title={ENGINEER_TYPE.KPI.title}
          />
        </Box>
      </Box>
    </EmwToggleMenu>
  );
}
