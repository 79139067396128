import React, { useEffect, useMemo } from 'react';
import useUser from '../../../auth/useUser';
import { Navigate, useLocation } from 'react-router-dom-v5-compat';
import { ADMINISTRATION_PAGES } from '../constants';
import AdminTools from './AdminTools/AdminTools';
import EngineeringCompliance from './EngineeringCompliance/EngineeringCompliance';
import { setIsDrilled, setSelectedReport } from '../../powerBi/redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import UsageMonitoring from './UsageMonitoring/components/UsageMonitoring';

export default function Administration() {
  const user = useUser();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(setSelectedReport(null));
    dispatch(setIsDrilled(false));
  }, []);

  return useMemo(() => {
    if (!user.showAdminPage) {
      return null;
    }
    if (pathname === ADMINISTRATION_PAGES[0].path) {
      return <AdminTools user={user} />;
    }

    if (pathname === ADMINISTRATION_PAGES[1].path) {
      return <UsageMonitoring />;
    }

    if (pathname === ADMINISTRATION_PAGES[2].path) {
      return <EngineeringCompliance user={user} />;
    }

    return <Navigate to={ADMINISTRATION_PAGES[0].path} replace={true} />;
  }, [user.showAdminPage, pathname]);
}
