import React, { useContext, useEffect, useState } from 'react';
import { Collapse } from '@mui/material';
import { ROLES_TYPE } from '../../../../constants';
import { getEmployeeEngineersApi } from '../../../../redux/api';
import EmwTypography from '../../../../../../components/EmwTypography/EmwTypography';
import TooltipAction from '../Section1/TooltipAction';
import EmsList from './EmsList';
import { TdsIcon, TdsProgressBar } from '@tds/react';
import useAppSnackbar from '../../../../../../hooks/useAppSnankbar';
import EmwListItemName from './EmsListItemName';
import cls from 'classnames';
import { StyledEmListItem } from './styled';
import { useSelector } from 'react-redux';
import { highlightedTgiSelector, orgDetailsSelector } from '../../../../redux/selectors';
import { OrganizationCardContext } from '../../context/OrganizationCardContextProvider';
import useScrollIntoView from '../../../../../../utils/useScrollIntoView';
import EmListItemRolesInfo from './EmListItemRolesInfo';
import EmwButton from '../../../../../../components/EmwButton/EmwButton';
import clx from 'classnames';
import store from '../../../../../../../store';

export default function EmsListItem({
  em,
  label,
  overwriteLabel,
  overwriteTitle,
  index,
  title,
  isParent,
  showAll,
  section,
  engineers,
  id,
  parentId,
  parent,
  reloadParent,
  reload,
  emExpandPath,
}) {
  const snackbar = useAppSnackbar();
  const context = useContext(OrganizationCardContext);
  const organization = useSelector(orgDetailsSelector);
  const highlightedTgi = useSelector(highlightedTgiSelector);
  const scrollIntoView = useScrollIntoView({ uniqueId: em.unId });
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(showAll || false);
  const [expandAll, setExpandAll] = useState(showAll || false);
  const [children, setChildren] = useState([]);
  const [isEngineer, setIsEngineer] = useState(em.inEngineerActivity); // parentInEngineerActivity

  const disabledEngineers = store.getState().organization.disabled_eng_list;
  const disableChild = disabledEngineers.includes(parentId);

  const highlightedTgiWithoutUnId = highlightedTgi ? highlightedTgi.split('-')[0] : '';

  const typeClassName = cls({
    'pr-xxs': em.childCount === 0,
  });
  const itemClassName = cls('em-list-item', {
    section1: section === 'section1',
  });
  const className = clx('flex flex-1 rounded-xl bg-bluegrey-25 pl-xs', {
    'engineer-removed': !isEngineer //|| disableChild || !em.parentInEngineerActivity
    ,highlighted: highlightedTgi === em.unId || highlightedTgiWithoutUnId === em.unId,
  });

  useEffect(() => {
    if (engineers) {
      setChildren(engineers);
    }

    if (em && em.hasOwnProperty('inEngineerActivity')) {
      setIsEngineer(em.inEngineerActivity);
    }

    return () => {
      setExpandAll(false);
      setIsOpen(false);
    };
  }, [em]);

  useEffect(() => {
    if (isOpen && !children.length > 0 && em.childCount > 0) {
      (async () => await fetchChildrens(em.id, organization.list.data.cardDetails.id))();
    }
  }, [isOpen]);

  useEffect(() => {
    if (showAll) {
      setIsOpen(true);
      setExpandAll(true);
    } else {
      if (engineers) {
        setIsOpen(context.hasHighlightedItem(engineers, highlightedTgi));
      }
      if (emExpandPath && emExpandPath.length) {
        emExpandPath.forEach(tgi => {
          if (em.tgi === tgi) {
            setIsOpen(true);
          }
        });
      } else {
        setIsOpen(false);
      }
      setExpandAll(false);
    }
  }, [showAll]);

  const fetchChildrens = async (id, orgId, emptyCurrentChildren = true) => {
    try {
      if (emptyCurrentChildren) {
        setChildren([]);
      }

      setIsLoading(true);

      let parentInEngineerActivity = '/true';
      const parentIsDisabled =
        (parent && !parent.inEngineerActivity) || (parent && !parent.parentInEngineerActivity);

      if (parentIsDisabled || !em.inEngineerActivity || disableChild) {
        parentInEngineerActivity = '/false';
      } else if (em.inEngineerActivity) {
        parentInEngineerActivity = '/true';
      }

      const resp = await getEmployeeEngineersApi(id, orgId, parentInEngineerActivity);
      setChildren(() => resp.data);
    } catch (reason) {
      snackbar.show(reason.message, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const reloadCurrent = async parentInEngineerActivity => {
    try {
      setIsLoading(true);
      const resp = await getEmployeeEngineersApi(
        em.id,
        organization.list.data.cardDetails.id,
        parentInEngineerActivity
      );
      setChildren(() => resp.data);
    } catch (reason) {
      snackbar.show(reason.message, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleExpandAll = () => {
    if (isOpen && expandAll) {
      setExpandAll(false);
      setIsOpen(false);
    } else {
      setExpandAll(true);
      setIsOpen(true);
    }
  };

  const toggleList = () => {
    if (!isOpen) {
      setIsOpen(true);
    } else {
      if (!engineers) {
        setChildren([]);
      }
      setIsOpen(false);
      setExpandAll(false);
    }
  };

  if (em.hasOwnProperty('visible') && !em.visible) {
    return null;
  }

  return (
    <>
      <StyledEmListItem id={id ? id : `ems-${index}`} container className={itemClassName}>
        <div ref={scrollIntoView.ref} className={className}>
          <div className="flex flex-col flex-1 ">
            <div className="flex flex-1">
              <div className="flex items-center role-user">
                <EmwTypography
                  fontSize="9"
                  fontWeight="bold"
                  classes="text-bluegrey-800"
                  title={overwriteTitle || em.title || title}
                >
                  {overwriteLabel || em.label || label}:
                </EmwTypography>
                <EmListItemRolesInfo rolesInfo={em.rolesInfo} inCurrentOrg={true} />
                <EmwListItemName em={em} />
                <div>
                  {em.id && (
                    <TooltipAction
                      em={em}
                      employeeId={em.id}
                      isEngineer={isEngineer}
                      setIsEngineer={setIsEngineer}
                      parentId={parentId}
                      orgId={organization.list.data.cardDetails.id}
                      reloadParent={reloadParent}
                      reload={reloadCurrent}
                      setChildren={setChildren}
                    />
                  )}
                </div>
                {em.comment.visible && em.comment.enabled && (
                  <EmwTypography fontSize={8} classes={`ml-xxs ${typeClassName}`}>
                    {em.comment.value}
                  </EmwTypography>
                )}
              </div>
              <div className="flex items-center ml-auto">
                <div className="ml-auto mr-xxs flex items-center">
                  {isParent && (
                    <EmwTypography fontSize={9} classes={typeClassName}>
                      {ROLES_TYPE[em.type.value]}
                    </EmwTypography>
                  )}

                  {!!em.childCount && (
                    <EmwButton
                      title={`${expandAll ? 'Collapse all' : 'Expand all'}`}
                      variant="ghost"
                      size="small"
                      onClick={handleExpandAll}
                      id={`btnToggle${em.id}`}
                    >
                      <div>
                        {expandAll ? (
                          <TdsIcon
                            icon="keyboard_double_arrow_up"
                            size="small"
                            style={{ color: '#4A4D65' }}
                          />
                        ) : (
                          <TdsIcon
                            icon="keyboard_double_arrow_down"
                            size="small"
                            style={{ color: '#4A4D65' }}
                          />
                        )}
                      </div>
                    </EmwButton>
                  )}
                  {!!em.childCount && (
                    <EmwButton
                      title={`${isOpen ? 'Collapse section' : 'Expand section'}`}
                      variant="ghost"
                      size="small"
                      classes="toggle-btn"
                      onClick={toggleList}
                      id={`btnToggle${em.id}`}
                    >
                      <div className="pl-xxs pr-xxs">
                        {isOpen ? (
                          <TdsIcon
                            icon="keyboard_arrow_up"
                            size="small"
                            style={{ color: '#4A4D65' }}
                          />
                        ) : (
                          <TdsIcon
                            icon="keyboard_arrow_down"
                            size="small"
                            style={{ color: '#4A4D65' }}
                          />
                        )}
                      </div>
                    </EmwButton>
                  )}
                </div>
              </div>
            </div>
            <EmListItemRolesInfo rolesInfo={em.rolesInfo} inCurrentOrg={false} />
            {isLoading && <TdsProgressBar size="small" animation="loop" />}
          </div>
        </div>
      </StyledEmListItem>
      <div className="flex ml-m" style={{ flexGrow: 1 }}>
        <Collapse in={isOpen} sx={{ flexGrow: 1 }} id={`enginners-colapse-${index}`}>
          {children.length > 0 && (
            <>
              <EmsList
                list={children}
                label={'Engineer'}
                isParent={false}
                showAll={expandAll}
                section={section}
                parentId={em.id}
                parent={em}
                reloadParent={fetchChildrens}
                reload={reloadCurrent}
                disableChild={disableChild || !em.inEngineerActivity}
              />
            </>
          )}
        </Collapse>
      </div>
    </>
  );
}
