import { connect } from 'react-redux';

import app from './../../app';
import Login from './../components';
import * as actions from './../actions';
import * as constants from './../constants';

const mapStateToProps = state => ({
  loginResult: state[constants.MODULE_NAME][actions.POST_LOGIN],
  userLoginResult: state[constants.MODULE_NAME][actions.USER_LOGIN],
  azureLogin: state[constants.MODULE_NAME][actions.AZURE_LOGIN],
});

const mapDispatchToProps = dispatch => ({
  postLogin: data => dispatch(actions.postLogin(data)),
  loginUser: data => dispatch(actions.loginUser(data)),
  getAzureLogin: () => dispatch(actions.isAzure()),
  snackbarMessage: message => dispatch(app.actions.snackbarMessage(message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
