export const ccExcelFormColumns = [
  {
    title: 'GBU',
    type: 'text',
    data: 'gbuCode',
    readOnly: true,
    width: 52,
  },
  {
    title: 'BL',
    type: 'text',
    data: 'blCode',
    readOnly: true,
    width: 45,
  },
  {
    title: 'CC <br> Country <br> Location',
    type: 'text',
    data: 'countryCode',
    readOnly: true,
    width: 75,
    excelWidth: 8,
  },
  {
    title: 'CC name',
    type: 'text',
    data: 'ccName',
    readOnly: true,
    width: 253,
    excelWidth: 41,
  },
  {
    title: 'Type',
    type: 'text',
    data: 'type',
    readOnly: true,
    width: 53,
    excelWidth: 8,
  },
  {
    title: 'GBU EM name',
    type: 'text',
    data: 'gbuEmName',
    readOnly: true,
    width: 110,
    excelWidth: 18,
  },
  {
    title: 'BL EM name',
    type: 'text',
    data: 'blEmName',
    readOnly: true,
    width: 110,
    excelWidth: 16,
  },
  {
    title: 'CC EM name',
    type: 'text',
    data: 'ccEmName',
    readOnly: true,
    width: 139,
    excelWidth: 17,
    wordWrap: false,
  },
  {
    title: '#HC',
    type: 'text',
    data: 'hc',
    readOnly: true,
    width: 50,
    excelWidth: 8,
  },
  {
    title: 'HW',
    type: 'text',
    data: 'hardware',
    readOnly: true,
    width: 45,
    excelWidth: 4,
  },
  {
    title: 'SW',
    type: 'text',
    data: 'software',
    readOnly: true,
    width: 45,
    excelWidth: 4,
  },
  {
    title: 'SYS',
    type: 'text',
    data: 'system',
    readOnly: true,
    width: 50,
    excelWidth: 4,
  },
  {
    title: 'Industry',
    type: 'text',
    data: 'industry',
    readOnly: true,
    width: 70,
    excelWidth: 4,
  },
  {
    title: 'Wave#',
    type: 'text',
    data: 'wave',
    readOnly: true,
    width: 60,
    excelWidth: 4,
  },
  {
    title: 'Accelerator',
    data: 'accelerator',
    width: 120,
    excelWidth: 20,
  },
  {
    title: 'Accelerator-Level2',
    data: 'acceleratorLevel2',
    width: 145,
    excelWidth: 14,
  },
  {
    title: 'MoB&F Manager name',
    type: 'text',
    data: 'mobfrName',
    readOnly: true,
    excelWidth: 22,
  },
  {
    title: 'Country EM name',
    type: 'text',
    data: 'countryEmName',
    readOnly: true,
    width: 140,
    excelWidth: 22,
  },
  {
    title: 'Unique CC ID Code',
    type: 'text',
    data: 'uniqueId',
    readOnly: true,
    width: 140,
    excelWidth: 22,
  },
];
