import { styled } from '@mui/material/styles';

export const StyledEmwTable = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100%',
  overflow: 'hidden',

  '.ReactVirtualized__Table__headerRow': {
    // border: "1px solid red",
    '.ReactVirtualized__Table__headerColumn': {
      border: '1px solid #CCCCCC',

      // lineHeight: 1,
      margin: 0,
    },
  },
  '.ReactVirtualized__Table__rowColumn': {
    border: '1px solid #CCCCCC',
    // border: "1px solid red",
    margin: 0,
    height: '100%',
    textAlign: 'center',
    lineHeight: 2,
    display: 'flex',
  },

  '.cellType__text, .cellType__engineer': {
    maxWidth: '-webkit-fill-available',
    textWrap: 'balance',
    display: 'flex',
  },
  '.cellType__text': {
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',
  },
  '.cellType__engineer': {
    alignItems: 'start',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));
