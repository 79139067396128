import React from 'react';

export default function SortAlpha() {
  return (
    <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.1689 8.37868L7.25835 0.521533C7.20385 0.36889 7.10347 0.236837 6.97099 0.143472C6.8385 0.0501068 6.68038 -1.30734e-06 6.5183 1.52629e-05H4.4833C4.32122 -1.30734e-06 4.1631 0.0501068 4.03061 0.143472C3.89813 0.236837 3.79776 0.36889 3.74326 0.521533L0.832677 8.37868C0.790353 8.49722 0.777127 8.62419 0.794114 8.74891C0.811101 8.87363 0.857804 8.99244 0.930288 9.09534C1.00277 9.19825 1.09892 9.28223 1.21063 9.34022C1.32235 9.39822 1.44636 9.42852 1.57223 9.42859H2.79156C2.95774 9.42864 3.11967 9.376 3.25405 9.27824C3.38843 9.18047 3.48836 9.04261 3.53946 8.88448L3.75652 8.25002H7.24312L7.45969 8.88448C7.51089 9.04293 7.61111 9.18102 7.74589 9.27882C7.88067 9.37661 8.04303 9.42906 8.20955 9.42859H9.42937C9.55524 9.42852 9.67925 9.39822 9.79097 9.34022C9.90268 9.28223 9.99883 9.19825 10.0713 9.09534C10.1438 8.99244 10.1905 8.87363 10.2075 8.74891C10.2245 8.62419 10.2113 8.49722 10.1689 8.37868ZM4.69593 5.50002L5.5008 3.14287L6.30567 5.50002H4.69593Z"
        fill="#041295"
      />
      <path
        d="M2.35792 12.5714H8.64363C8.85202 12.5714 9.05187 12.6542 9.19922 12.8016C9.34657 12.9489 9.42935 13.1488 9.42935 13.3571V14.2229C9.42937 14.4448 9.38238 14.6643 9.29148 14.8667C9.20057 15.0692 9.06781 15.2501 8.90194 15.3975L5.89363 18.8571H8.64363C8.85202 18.8571 9.05187 18.9399 9.19922 19.0873C9.34657 19.2346 9.42935 19.4345 9.42935 19.6429V21.2143C9.42935 21.4227 9.34657 21.6225 9.19922 21.7699C9.05187 21.9172 8.85202 22 8.64363 22H2.35792C2.14953 22 1.94968 21.9172 1.80233 21.7699C1.65498 21.6225 1.5722 21.4227 1.5722 21.2143V20.3485C1.57218 20.1266 1.61917 19.9072 1.71008 19.7047C1.80098 19.5022 1.93374 19.3213 2.09961 19.1739L5.10792 15.7143H2.35792C2.14953 15.7143 1.94968 15.6315 1.80233 15.4842C1.65498 15.3368 1.5722 15.137 1.5722 14.9286V13.3571C1.5722 13.1488 1.65498 12.9489 1.80233 12.8016C1.94968 12.6542 2.14953 12.5714 2.35792 12.5714Z"
        fill="#041295"
      />
      <path
        d="M21.0244 13.9514H22.6671C23.1538 13.9514 23.3978 14.5421 23.0549 14.8861L20.317 18.1715C20.2143 18.2741 20.0751 18.3317 19.93 18.3317C19.7848 18.3317 19.6456 18.2741 19.5429 18.1715L16.805 14.8861C16.4604 14.5414 16.7058 13.9514 17.1914 13.9514H18.8341V3.54759C18.8341 3.40236 18.8918 3.26308 18.9945 3.16039C19.0972 3.05771 19.2365 3.00002 19.3817 3.00002H20.4769C20.6221 3.00002 20.7614 3.05771 20.864 3.16039C20.9667 3.26308 21.0244 3.40236 21.0244 3.54759V13.9514Z"
        fill="#041295"
      />
    </svg>
  );
}
