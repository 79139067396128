import { NavLink, useLocation } from 'react-router-dom-v5-compat';
import { useEffect, useMemo } from 'react';
import EmwTabs from '../../../../components/EmwTabs/EmwTabs';
import EmwTab from '../../../../components/EmwTab/EmwTab';
import { ADMINISTRATION_PAGES } from '../../constants';
import { matchPath } from 'react-router';
import useUser from '../../../../auth/useUser';

const paths = ADMINISTRATION_PAGES.map(link => link.path);

export default function AdministrationMenu() {
  const { pathname } = useLocation();
  const user = useUser();
  const isMatch = paths.some(path => matchPath(path, pathname));

  const tabs = useMemo(() => {
    return ADMINISTRATION_PAGES.filter(page => user[page.permissions]).map(page => {
      if (page.disabled) {
        return (
          <EmwTab key={page.key} active={page.path === pathname} disabled>
            {page.name}
          </EmwTab>
        );
      }
      return (
        <EmwTab classes="emw-tab" key={page.key} active={page.path === pathname}>
          <NavLink to={page.path}>{page.name}</NavLink>
        </EmwTab>
      );
    });
  }, [pathname, user]);

  return (
    <div className="flex flex-1 justify-center" style={{ height: '100%' }}>
      {isMatch && <EmwTabs fullHeight>{tabs}</EmwTabs>}
    </div>
  );
}
