export const HZA_ACTION_TYPE = {
  UPLOAD_REQUEST: 'HZA_UPLOAD_REQUEST',
  UPLOAD_RESPONSE: 'HZA_UPLOAD_RESPONSE',
  DOWNLOAD_REQUEST: 'HZA_DOWNLOAD_REQUEST',
  DOWNLOAD_RESPONSE: 'HZA_DOWNLOAD_RESPONSE',
  REQUEST: 'HZA_REQUEST',
  CHECK: 'CHECK',
};

export const HZA_IFRAME_ID = 'myFrameHZA';
