import React, { useEffect, useState } from 'react';
import {
  TdsButton,
  TdsDropdown,
  TdsDropdownTrigger,
  TdsDropdownOverlay,
  TdsMenuItem,
  TdsMenuItemList,
  TdsMenuItemLabel,
  TdsIcon,
  TdsTypography,
} from '@tds/react';
import EmwDialog from '../../../../../../components/EmwDialog/EmwDialog';
import { CHARTS, COMMENT_TYPES } from '../../../../constants';
import { getGeneralFilters, getTimeframeFilters } from '../../../../../../../util/biUtils';
import { deleteCommentApi } from '../../../../redux/api';
import { useSelector } from 'react-redux';
import { commentsRolesSelector, commentsSelector } from '../../../../redux/selectors';
import usePowerBi from '../../../../hooks/usePowerBi';
import useAppSnackbar from '../../../../../../hooks/useAppSnankbar';
import CreateCommentModal from '../../../CreateCommentModal/CreateCommentModal';

export default function CommentItemActions({ comment, title, selectedChart }) {
  const commentsData = useSelector(commentsSelector);
  const commentsRoles = useSelector(commentsRolesSelector);
  const powerBi = usePowerBi();
  const snackbar = useAppSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isCreateCommentOpen, setIsCreateCommentOpen] = useState(false);
  const [typeOptions, setTypeOptions] = useState([]);

  useEffect(() => {
    const _typeOptions = [];

    if (commentsRoles.canAddComment) {
      _typeOptions.push({ label: 'Comments', value: COMMENT_TYPES.COMMENT });
    }

    if (commentsRoles.canAddRootCause) {
      _typeOptions.push({ label: 'Root Cause', value: COMMENT_TYPES.ROOT_CAUSE });
    }

    if (commentsRoles.canAddActionPlan) {
      _typeOptions.push({ label: 'Action Plan', value: COMMENT_TYPES.ACTION_PLAN });
    }

    setTypeOptions(_typeOptions);
  }, [commentsRoles]);

  const handleDelete = async () => {
    setIsLoading(true);

    try {
      const requestPayload = {
        chartType: Object.keys(CHARTS).map(key => CHARTS[key]),
        generalFilters: getGeneralFilters(),
        timeframeFilters: getTimeframeFilters(),
      };

      await deleteCommentApi(comment.id);
      powerBi.fetchComments(requestPayload, commentsData);
    } catch (e) {
      snackbar.show(e.message, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  if (!comment.canDelete && !comment.canEdit) {
    return (
      <div>
        <TdsButton variant="ghost" color="primary" size="small" disabled={true}>
          <TdsIcon icon="more_vert" size="small" />
        </TdsButton>
      </div>
    );
  }

  return (
    <div onClick={event => event.stopPropagation()}>
      <TdsDropdown placement="bottom-right">
        <TdsDropdownTrigger>
          <TdsButton variant="ghost" color="primary" size="small">
            <TdsIcon icon="more_vert" size="small" />
          </TdsButton>
        </TdsDropdownTrigger>
        <TdsDropdownOverlay>
          <TdsMenuItemList>
            <TdsMenuItem size="xsmall" disabled={!comment.canEdit}>
              <TdsMenuItemLabel
                classes="text-bluegrey-400"
                onClickEvent={() => setIsCreateCommentOpen(true)}
              >
                <TdsIcon icon="edit" variant="outlined" size="small" />
                <TdsTypography fontSize="8">Edit Comment</TdsTypography>
              </TdsMenuItemLabel>
            </TdsMenuItem>
            <TdsMenuItem size="xsmall" disabled={!comment.canDelete}>
              <TdsMenuItemLabel
                classes="text-bluegrey-400"
                onClickEvent={() => setIsDeleteModalOpen(true)}
              >
                <TdsIcon icon="comments_disabled" variant="outlined" size="small" />
                <TdsTypography fontSize="8">Deactivate</TdsTypography>
              </TdsMenuItemLabel>
            </TdsMenuItem>
          </TdsMenuItemList>
        </TdsDropdownOverlay>
      </TdsDropdown>

      {isDeleteModalOpen && (
        <EmwDialog
          isOpen={isDeleteModalOpen}
          setIsOpen={setIsDeleteModalOpen}
          callback={handleDelete}
          actionButton="Deactivate"
          title={`Do you want to deactivate the comment?`}
          text="This action cannot be undone."
          icon="warning_amber"
          classes="close-icon"
          loading={isLoading}
        />
      )}

      {isCreateCommentOpen && typeOptions.length > 0 && (
        <CreateCommentModal
          isOpen={isCreateCommentOpen}
          setIsOpen={setIsCreateCommentOpen}
          selectedChart={selectedChart}
          typeOptions={typeOptions}
          data={comment}
        />
      )}
    </div>
  );
}
