import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import EmwTypography from '../../../../../../components/EmwTypography/EmwTypography';
import { EmwButton } from '../../../../../../../lib/common';
import { TdsIcon } from '@tds/react';
import AlertsType from './AlertsType';
import EmwPromptDialog from '../../../../../../../lib/commonv2/EmwPromptDialog';
import { deleteOrganizationApi, getAlertsApi } from '../../../../redux/api';
import useAppSnackbar from '../../../../../../hooks/useAppSnankbar';
import { cloneDeep } from 'lodash';
import {
  CHARTS,
  ENGINEERING_COMPETITIVENESS,
  MOOD_AND_CONFIDENCE,
  TALENTS_AND_CULTURES,
} from '../../../../constants';
import { setShouldRenderLines, updateAlerts } from '../../../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getGeneralFilters, getTimeframeFilters } from '../../../../../../../util/biUtils';
import { alertsSelector, userOrgsSelector } from '../../../../redux/selectors';
import { useLocation } from 'react-router-dom';
import EmwDialog from '../../../../../../components/EmwDialog/EmwDialog';

export default function AlertsItem({ item, title }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCurrentOrg, setIsCurrentOrg] = useState(false);
  const userOrgs = useSelector(userOrgsSelector);
  const alertsData = useSelector(alertsSelector);
  const dispatch = useDispatch();
  const snackbar = useAppSnackbar();
  const location = useLocation();

  useEffect(() => {
    setIsCurrentOrg(userOrgs.includes(item.orgName));
  }, []);

  const handleDelete = async () => {
    setIsLoading(true);

    try {
      const page = location.pathname;
      const alerts = cloneDeep(alertsData);
      const requestPayload = {
        chartType: Object.keys(CHARTS).map(key => CHARTS[key]),
        generalFilters: getGeneralFilters(),
        timeframeFilters: getTimeframeFilters(),
      };
      const deleteResponse = await deleteOrganizationApi(item.id);
      const alertsResponse = await getAlertsApi(requestPayload);

      const name =
        page === '/moodandconfidence' ? MOOD_AND_CONFIDENCE : ENGINEERING_COMPETITIVENESS;

      const { mood, confidenceToDeliver, davd, dotd, keyProfileFit, skillCoverage } =
        alertsResponse.data;

      if (name === MOOD_AND_CONFIDENCE) {
        alerts[name][CHARTS.MOOD].values = mood;
        alerts[name][CHARTS.CONFIDENCE_TO_DELIVER].values = confidenceToDeliver;
      }

      if (name === ENGINEERING_COMPETITIVENESS) {
        alerts[name][CHARTS.DOTD].values = dotd;
        alerts[name][CHARTS.DAVD].values = davd;
        alerts[name][CHARTS.CONFIDENCE_TO_DELIVER].values = confidenceToDeliver;
        alerts[TALENTS_AND_CULTURES][CHARTS.MOOD].values = mood;
        alerts[TALENTS_AND_CULTURES][CHARTS.KEY_PROFILE_FIT].values = keyProfileFit;
        alerts[TALENTS_AND_CULTURES][CHARTS.SKILL_COVERAGE].values = skillCoverage;
      }

      dispatch(updateAlerts(alerts));
    } catch (reason) {
      snackbar.show(reason.message, 'error');
    }
  };

  return (
    <>
      <Grid container alignItems="center" className="alert-item bg-bluegrey-25 rounded mt-xs pl-xs">
        <Grid item className="mr-xs">
          <AlertsType item={item} />
        </Grid>
        <Grid item>
          <EmwTypography fontSize={8} classes="text-bluegrey-600">
            {item.orgName ? item.orgName : 'Group'}
          </EmwTypography>
        </Grid>
        <Grid item>{isCurrentOrg && <TdsIcon icon="star" size="xsmall" classes="ml-xs" />}</Grid>
        <Grid item className="ml-auto">
          <EmwButton variant="ghost" color="neutral" onClick={() => setIsOpen(true)}>
            <TdsIcon
              icon="delete"
              variant="outlined"
              color=""
              classes="text-bluegray-400 text-scale-5"
            />
          </EmwButton>
        </Grid>
      </Grid>
      {isOpen && (
        <EmwDialog
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          callback={handleDelete}
          actionButton="Delete"
          title={`Do you want to delete this alert from ${title} KPI?`}
          text="This action cannot be undone."
          icon="warning_amber"
          classes="close-icon"
          loading={isLoading}
        />
      )}
    </>
  );
}
