import * as countryCodes from 'country-codes-list';

/**
 * Remove country code from a str
 *
 * @param str
 * @returns {*|null}
 */
export const removeCountryCode = str => {
  if (!str) {
    return null;
  }

  const countryList = countryCodes.all().map(country => country.countryCode);
  countryList.push('UK', 'WW');

  for (let i = 0; i <= countryList.length; i++) {
    const regExp = new RegExp(`- ${countryList[i]}$|-${countryList[i]}$`); // Add new regex cases for different string patterns

    if (regExp.test(str)) {
      str = str.replace(regExp, '');
      break;
    }
  }

  return str.trim();
};

export const countryCodesList = str => {
  if (!str) {
    return null;
  }

  const countryList = countryCodes.all().filter(country => country.countryNameEn === str);

  return countryList[0].countryCode;
};
