import React, { useEffect, useState, Suspense } from 'react';
import { Box, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { orgDetailsSelector } from '../../redux/selectors';
import { StyledOrganizationContainer } from './styled';
import moment from 'moment/moment';
import { DATE_FORMAT } from '../../../../../modules/organization/constants';
import Section1Item from './components/Section1/Section1Item';
import OrganizationCardHeaderInfo from './components/OrganizationCardHeaderInfo/OrganizationCardHeaderInfo';
import CardActions from './components/CardActions/CardActions';
import OrganizationCardContextProvider from './context/OrganizationCardContextProvider';
import CardName from './components/CardName/CardName';
import { ENGINEER_TYPE } from '../../constants';
import EmsList from './components/Ems/EmsList';
import ReferentListItem from './components/Ems/ReferentListItem';

const Section1 = React.lazy(() => import('./components/Section1/Section1'));
const OrganizationList = React.lazy(() => import('./components/OrganizationList/OrganizationList'));

export default function OrganizationCard() {
  const { list } = useSelector(orgDetailsSelector);
  const [sectionItems, setSectionItems] = useState({});
  const [deliveries, setDeliveries] = useState([]);
  const [disciplines, setDisciplines] = useState([]);
  const [targetField, setTargetField] = useState({ section: '', key: '' });

  useEffect(() => {
    if (list && list.hasOwnProperty('data')) {
      if (list.data.section1) {
        setSectionItems({
          ems: [
            <EmsList
              list={list.data.section1.ems}
              overwriteLabel={ENGINEER_TYPE.EM.label}
              overwriteTitle={ENGINEER_TYPE.EM.title}
              isParent={true}
              section="section1"
              type="ems"
            />,
          ],
          hoeos: [
            <EmsList
              list={list.data.section1.hoEOs}
              overwriteLabel={ENGINEER_TYPE.HoEO.label}
              overwriteTitle={ENGINEER_TYPE.HoEO.title}
              isParent={true}
              section="section1"
              type="hoeos"
            />,
          ],
          kpis: [
            <EmsList
              list={list.data.section1.kpis}
              isParent={true}
              section="section1"
              overwriteLabel={ENGINEER_TYPE.KPI.label}
              overwriteTitle={ENGINEER_TYPE.KPI.title}
              type="kpis"
            />,
          ],
          refs: [
            <Box>
              <ReferentListItem
                isTag={true}
                referents={list.data.section1.dr}
                type={'Data'}
                key="dr"
                title={ENGINEER_TYPE.DATARRef.title}
                label={ENGINEER_TYPE.DATARRef.label}
              />
            </Box>,
            <Box mt={1}>
              <ReferentListItem
                referents={list.data.section1.cybsr}
                type={'Cybersecurity'}
                key="cybsr"
                title={ENGINEER_TYPE.CYBSRRef.title}
                label={ENGINEER_TYPE.CYBSRRef.label}
              />
            </Box>,
            <Box mt={1}>
              <ReferentListItem
                referents={list.data.section1.ecodr}
                type={'Ecodesign'}
                key="ecodr"
                title={ENGINEER_TYPE.ECODRRef.title}
                label={ENGINEER_TYPE.ECODRRef.label}
              />
            </Box>,
            <Box mt={1}>
              <ReferentListItem
                referents={list.data.section1.uxdr}
                type={'UX Design'}
                key="uxdr"
                title={ENGINEER_TYPE.UXDRRef.title}
                label={ENGINEER_TYPE.UXDRRef.label}
              />
            </Box>,
            <Box mt={1}>
              <ReferentListItem
                referents={list.data.section1.dp}
                type={'Data Protection'}
                key="dp"
                title={ENGINEER_TYPE.DPRef.title}
                label={ENGINEER_TYPE.DPRef.label}
              />
            </Box>,
            <Box mt={1}>
              <ReferentListItem
                referents={list.data.section1.mobfr}
                type={'Make or Buy & Footprint'}
                key="mobfr"
                title={ENGINEER_TYPE.MOBFRef.title}
                label={ENGINEER_TYPE.MOBFRef.label}
              />
            </Box>,
            <Box mt={1} mb={1}>
              <ReferentListItem
                referents={list.data.section1.eql}
                type={'Engineering Quality'}
                key="eql"
                title={ENGINEER_TYPE.EQ.title}
                label={ENGINEER_TYPE.EQ.label}
              />
            </Box>,
            list.data.section1.otherReferents[0].category.value && (
              <Box borderTop="2px solid #bcbece" mb={1}>
                {renderOtherItems()}
              </Box>
            ),
          ],
        });

        const section2 = list.data.section2;
        const section3 = list.data.section3;

        setDeliveries(
          section2.visible && Array.isArray(section2.deliveries) ? section2.deliveries : []
        );
        setDisciplines(
          section3.visible && Array.isArray(section3.disciplines) ? section3.disciplines : []
        );
      }
    }
  }, [list]);

  const renderOtherItems = () => {
    // Create a map to group referents by category
    const categoryMap = {};

    // Iterate over the referents and categories and group them by category
    if (
      list.data.section1.otherReferents.length > 0 &&
      list.data.section1.otherReferents[0].category.value
    ) {
      list.data.section1.otherReferents.forEach(ref => {
        list.data.section1.referentCategories.forEach(category => {
          if (ref.category.value && category.value === ref.category.value) {
            if (!categoryMap[ref.category.value]) {
              categoryMap[ref.category.value] = [];
            }
            categoryMap[ref.category.value].push(ref);
          }
        });
      });
    }

    // Render the grouped referents
    return Object.keys(categoryMap).map(
      (category, index) =>
        category && (
          <div className="mt-xs" key={category}>
            <ReferentListItem
              isTag={true}
              referents={categoryMap[category]} // Pass the grouped referents
              label={ENGINEER_TYPE.OTHERRef.title}
              title={ENGINEER_TYPE.OTHERRef.title}
              isParent={false}
              id={`referent-${index}`}
            />
          </div>
        )
    );
  };

  const goToField = ({ section, key }) => {
    setTargetField({ section, key });
    return {
      section,
      key,
    };
  };

  if (!list.hasOwnProperty('data')) {
    return null;
  }

  return (
    <OrganizationCardContextProvider>
      <StyledOrganizationContainer container direction="column" flexWrap={'nowrap'}>
        <Grid item id="organization-card-header">
          <Grid container direction="column">
            <Grid item xs={12} className="header-row">
              <Grid container flexWrap="nowrap">
                <Grid item xs={12} sm={6} className="organization-card-name-wrapper">
                  {list.data.section1 && <CardName data={list.data} />}

                  {list.data.section1 &&
                    list.data.section1.date.visible &&
                    list.data.section1.date.value && (
                      <Grid item sx={{ color: '#383A4B', fontSize: '10px' }}>
                        START AT {moment(list.data.section1.date.value).format(DATE_FORMAT)}
                      </Grid>
                    )}

                  {list.data.section1 &&
                    list.data.section1.endDate.visible &&
                    list.data.section1.endDate.value && (
                      <Grid item sx={{ color: '#383A4B', fontSize: '10px' }}>
                        END AT {moment(list.data.section1.endDate.value).format(DATE_FORMAT)}
                      </Grid>
                    )}
                </Grid>

                {list.data.section1 && (
                  <CardActions
                    data={list.data}
                    goToField={goToField}
                    targetField={targetField}
                    setTargetField={setTargetField}
                  />
                )}
              </Grid>
            </Grid>

            {list.data.section1 && <OrganizationCardHeaderInfo data={list.data} />}
          </Grid>
        </Grid>

        <Grid item overflow="auto" className="pr-xxs" id="card-content">
          {list.data.section1 && (
            <Box sx={{ overflowY: 'hidden', overflowX: 'hidden' }}>
              {!['CBU', 'Domain'].includes(list.data.cardDetails.type) &&
                Object.keys(sectionItems).length > 0 && (
                  <Suspense fallback={<div />}>
                    <Section1 sectionItems={sectionItems} data={list.data} />
                  </Suspense>
                )}
              {deliveries.length > 0 && (
                <Box className="tour-deliveries-lists mb-xs mt-xs">
                  <Suspense fallback={<div />}>
                    <OrganizationList
                      name="Delivery Discipline"
                      list={deliveries}
                      icon={'construction'}
                      type={list.data.cardDetails.type}
                      allExpandable={true}
                      generationIndex={0}
                    />
                  </Suspense>
                </Box>
              )}

              {disciplines.length > 0 && (
                <Box sx={{ marginTop: '5px' }} className="tour-disciplines-lists">
                  <Box borderBottom="2px solid #041295" marginTop="10px" marginBottom="10px"></Box>
                  <Suspense fallback={<div />}>
                    <OrganizationList
                      name="Disciplines"
                      list={disciplines}
                      icon={'factory'}
                      type={list.data.cardDetails.type}
                      allExpandable={true}
                      generationIndex={0}
                    />
                  </Suspense>
                </Box>
              )}
            </Box>
          )}
        </Grid>
      </StyledOrganizationContainer>
    </OrganizationCardContextProvider>
  );
}
