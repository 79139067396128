import React from 'react';
import KPIModal from './KPIModal';

export default function KPIErrorModal({
  openError,
  setOpenError,
  handleOpenError,
  text,
  title,
  buttonIcon,
}) {
  return (
    <KPIModal
      isOpen={openError}
      setIsOpen={setOpenError}
      callback={handleOpenError}
      actionButton="Download errors"
      title={title}
      text={text}
      icon="info"
      classes="close-icon"
      buttonIcon={buttonIcon}
    />
  );
}
