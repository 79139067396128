import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useAppSnackbar from './useAppSnankbar';
import { setOrgDetails } from '../features/organization/redux/actions';

const useApi = () => {
  const snackBar = useAppSnackbar();

  const init = async (api, payload, silent) => {
    try {
      const response = await api(payload);
      if (!silent && response.messageType) {
        snackBar.show(response.message, response.messageType);
      }

      return response;
    } catch (e) {
      // snackBar.show(e.statusText, "error")
    }
  };

  return {
    init,
  };
};

export default useApi;
