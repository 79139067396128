import React from 'react';
import { AuthenticatedTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import DefaultLayout from '../../../../layout/DefaultLayout/DefaultLayout';
import { loginRequest } from '../../../auth/authConfig';

export default function PrivateRoute({ component: Component, disableLayout }) {
  const authRequest = {
    ...loginRequest,
  };

  if (disableLayout) {
    return (
      <AuthenticatedTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
      >
        <Component />
      </AuthenticatedTemplate>
    );
  }

  return (
    <AuthenticatedTemplate>
      <DefaultLayout>
        <Component />
      </DefaultLayout>
    </AuthenticatedTemplate>
  );
}
