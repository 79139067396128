import React, { useEffect, useState, useRef, useMemo, useContext } from 'react';
import { Grid, DialogContent, DialogTitle, DialogActions, Divider } from '@mui/material';
import { HotTable } from '@handsontable/react';
import { registerAllModules } from 'handsontable/registry';
import 'handsontable/dist/handsontable.full.min.css';
import Handsontable from 'handsontable';

import { EmwButton } from '../../../lib/common';
import EmwTypography from '../EmwTypography/EmwTypography';
import { StyledEmwWebTable } from './styled';
import EmwFooterEnvInfo from '../EmwFooterEnvInfo/EmwFooterEnvInfo';
import EmwRichTextFormatting from '../EmwHotTable/EmwRichTextFormatting';
import EmwProgressCircle from '../EmwProgressCircle/EmwProgressCircle';
import { HyperFormula } from 'hyperformula';
import { HzaContext } from '../../features/hzaSync/context/HzaContext';
import KPIReadMeTooltip from '../../features/organization/components/OrganizationCard/components/ExportKPI/KPIReadMeTooltip';
import EEIFormInfo from '../../features/organization/components/OrganizationCard/components/ExportKPI/EEIFormInfo';
import KPIDownloadButton from '../../features/organization/components/OrganizationCard/components/ExportKPI/KPIDownloadButton';
// register Handsontable's modules
registerAllModules();

export default function EmwWebExcelByCountry({
  id,
  isOpen,
  setIsOpen,
  fixedColumns,
  saveApi,
  getApi,
  title,
  orgId,
  selectedYear,
  selectedKPI,
  regionName,
  selectedOption,
  handleDelete,
}) {
  const formRef = useRef(null);
  const hzaContext = useContext(HzaContext);
  const [tableData, setTableData] = useState([]);
  const [formData, setFormData] = useState([]);
  const [formHeader, setFormHeader] = useState([]);
  const [rowIdMap, setRowIdMap] = useState({});
  const [tableMetadata, setTableMetadata] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const form = formRef.current ? formRef.current.hotInstance : '';
  const dropdownItem = [];
  const [readMeData, setReadMeData] = useState({});
  const [undoStack, setUndoStack] = useState([]);
  const [redoStack, setRedoStack] = useState([]);
  const [errors, setErrors] = useState([]);
  const [rowsChanged, setRowsChanged] = useState([]);
  const [rowsWithFormulas, setRowsWithFormulas] = useState([]);
  const [eeiData, setEeiData] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    if (hzaContext.requestStatus.name === 'exportEEIByCountry') {
      const response = hzaContext.requestStatus;
      if (response.data) {
        const sheets = response.data.sheets;
        const metadata = sheets[0].data.metadata.rows;
        const eeiResponseData = sheets[0].data;
        const readMeInfo = sheets[1].data.metadata.rows;
        setIsLoading(false);
        setEeiData(eeiResponseData.rows);
        setFormHeader([eeiResponseData.header.rows[0].cells]);
        setFormData(eeiResponseData.rows);
        setReadMeData(readMeInfo);
        setTableMetadata(metadata);
      }
    }
  }, [hzaContext.requestStatus]);

  useEffect(() => {
    const status = hzaContext.uploadStatus.messageType.toLowerCase();
    if (status === 'success') {
      rowsChanged.forEach(row => {
        form.getCellMeta(row.rowIndex, form.propToCol(row.cellIndex)).comment = null;
        form.removeCellMeta(row.rowIndex, form.propToCol(row.cellIndex), 'className');
      });
    }
    if (status == 'warning') {
      try {
        const response = hzaContext.uploadStatus;
        if (response.data) {
          const rowsWithErrors = response.data.sheets[0].data.rows;
          setErrors(rowsWithErrors);
        }
      } catch (err) {
        //silently catching error because rowIndex sometimes is not found
        console.error(err);
      }
    }
  }, [hzaContext.uploadStatus]);

  useEffect(() => {
    if (tableData && form) {
      tableData.forEach(() => {
        setCellType(form);
      });
    }
  }, [tableData]);

  useEffect(() => {
    getErrorCells();
  }, [errors]);

  const getErrorCells = () => {
    if (errors) {
      rowsChanged.forEach(row => {
        if (!Number.isNaN(Number(row.value))) {
          form.getCellMeta(row.rowIndex, form.propToCol(row.cellIndex)).comment = null;
          form.removeCellMeta(row.rowIndex, form.propToCol(row.cellIndex), 'className');
        }
      });
      const rowIndexes = [];
      const rowUniqueProps = {};
      const rowsWithErrors = errors;
      rowsWithErrors.forEach(row => {
        row.cells.forEach(cell => {
          if (
            ['Regions', 'Countries', 'CC', 'Period', 'PROJECT_ID', 'PROJECT_NAME'].includes(
              cell.property
            )
          ) {
            rowUniqueProps[cell.property] = cell.value;
          }
        });
        const index = tableData.findIndex(obj =>
          Object.keys(rowUniqueProps).every(prop => obj[prop] === rowUniqueProps[prop])
        );
        rowIndexes.push(index);
      });
      if (rowsWithErrors && rowsWithErrors.length > 0 && form) {
        rowIndexes.forEach(rowIndex => {
          rowsWithErrors.forEach(row => {
            row.cells.forEach((cell, cellIndex) => {
              if (cell.comment && rowIndex !== -1) {
                form.getCellMeta(rowIndex, cellIndex).comment = { value: cell.comment };
                form.setCellMeta(rowIndex, cellIndex, 'className', 'red-border');
              }
            });
          });
        });
        form.render();
      }
    }
  };

  const updatedFormData = formData.map(row => {
    return {
      ...row.cells.reduce((acc, cell) => {
        if (cell.formula) {
          acc[cell.property] = cell.value ? cell.value : cell.formula;
        } else {
          acc[cell.property] = cell.value;
        }

        return acc;
      }, {}),
    };
  });

  useEffect(() => {
    setTableData(updatedFormData);
    //TO-DO: refactor idMap - use the formInstance.rowIndexMapper
    const idMap = {};
    updatedFormData.forEach((row, index) => {
      idMap[index] = index;
    });
    setRowIdMap(idMap);
  }, [formData]);

  useEffect(() => {
    setFormData([]);
    setTableData([]);
    (async () => {
      const response = await getApi();
    })();
  }, [isOpen]);

  const filteredHeaders = useMemo(() => {
    return formHeader.length > 0 && formHeader.map(item => item);
  }, [formHeader]);

  const setCellType = form => {
    const rowIndexes = [];
    formData.forEach((row, rowIndex) => {
      row.cells.forEach((cell, cellIndex) => {
        if (!cell.editable) {
          form.setCellMeta(rowIndex, cellIndex, 'readOnly', true);
        }
        if (cell.values.length > 0) {
          const dropdownValues = cell.values.map(item => {
            dropdownItem.push(item);
            return item.content;
          });
          if (cell.values.length > 0) {
            form.setCellMeta(rowIndex, cellIndex, 'source', dropdownValues);
            form.setCellMeta(rowIndex, cellIndex, 'type', 'dropdown');
          }
        }
        if (cell.cellType === 'checkbox') {
          form.setCellMeta(rowIndex, cellIndex, 'type', 'checkbox');
        }
        if (cell.cellType === 'percent') {
          form.setCellMetaObject(rowIndex, cellIndex, {
            type: 'numeric',
            format: '0.00%',
          });
        }
        if (cell.style && cell.style.bgColor) {
          if (cell.style.bgColor === '#AAC0DE') {
            rowIndexes.push(rowIndex);
            form.setCellMeta(rowIndex, cellIndex, 'className', 'read-only-data');
            form.setCellMeta(rowIndex, cellIndex, 'readOnly', true);
          } else {
            form.setCellMeta(rowIndex, cellIndex, 'readOnly', false);
          }
        }
      });
    });
    const uniqueRowIndexes = [...new Set(rowIndexes)];
    setRowsWithFormulas(uniqueRowIndexes);
  };

  const getNestedHeaders = () => {
    const nestedHeaders = [];
    if (formHeader && formHeader.length > 0) {
      for (let i = 0; i < formHeader.length; i++) {
        const nh = [];
        for (let j = 0; j < formHeader[i].length; j++) {
          if (formHeader[i][j].visible) {
            nh.push({
              label: formHeader[i][j].value,
              colspan: formHeader[i][j].colspan,
            });
          }
        }
        nestedHeaders.push(nh);
      }
    }
    return nestedHeaders;
  };

  const formSettings = {
    id: id,
    height: '98%',
    width: 'auto',
    ref: formRef,
    filters: true,
    filter: true,
    stretchH: 'all',
    rowHeaders: true,
    formulas: {
      engine: HyperFormula,
      sheetId: 1,
      sheetName: 'Sheet 1',
    },
    undo: true,
    allowInsertColumn: false,
    allowInsertRow: true,
    allowRemoveColumn: false,
    allowRemoveRow: true,
    contextMenu: {
      items: {
        row_above: {},
        row_below: {},
        undo: {},
        redo: {},
        remove_row: {
          disabled() {
            if (rowsWithFormulas.includes(form.getSelectedRangeLast().to.row)) {
              return true;
            } else {
              return false;
            }
          },
        },
        clear: {
          name() {
            return 'Clear row data';
          },
          disabled() {
            if (rowsWithFormulas.includes(form.getSelectedRangeLast().to.row)) {
              return false;
            } else {
              return true;
            }
          },
          callback(key, selection, clickEvent) {
            const selectionStart = selection[0].start.row;
            const selectionEnd = selection[0].end.row;
            const physicalRows = [];
            for (let i = selectionStart; i <= selectionEnd; i++) {
              physicalRows.push(i);
            }
            handleDeleteEEI(physicalRows).then(async () => {
              await new Promise(resolve => {
                setTimeout(resolve, 1000);
              });
              return await getApi();
            });
          },
        },
      },
    },
    redo: true,
    columnHeaderHeight: 35,
    manualColumnResize: true,
    rowHeaderWidth: 30,
    className: 'className',
    comments: true,
    licenseKey: 'non-commercial-and-evaluation',
    selectionMode: 'multiple',
    outsideClickDeselects: false,
    viewportRowRenderingOffset: 20,
    afterChange: (changes, source) => {
      if (changes) {
        customUndoRedo(changes, source);
        handleSave(changes);
      }
    },
    afterFilter: () => {
      const formInstance = formRef.current.hotInstance;
      const filteredRows = formInstance.getData();
      const idMap = {};
      filteredRows.forEach((row, index) => {
        idMap[index] = formInstance.rowIndexMapper.notTrimmedIndexesCache[index];
      });
      setRowIdMap(idMap);
    },
    beforeRemoveRow: (index, amount, physicalRows) => {
      handleDeleteEEI(physicalRows).then(async () => {
        await new Promise(resolve => {
          setTimeout(resolve, 1000);
        });
        return await getApi();
      });
    },
    afterCreateRow: (index, amount) => {
      handleCreateRow(index);
    },
  };

  const handleCreateRow = rowIndex => {
    const colIndexesWithDropdown = [1, 2, 3];
    form.setDataAtCell(rowIndex, 0, regionName);
    colIndexesWithDropdown.forEach(cellIndex => {
      const dropdownValues = getDropdownValues(rowIndex, cellIndex);
      form.setCellMeta(rowIndex, cellIndex, 'source', dropdownValues);
      form.setCellMeta(rowIndex, cellIndex, 'type', 'dropdown');
    });
  };

  const getDropdownValues = (rowIndex, cellIndex) => {
    return formData[rowIndex].cells[cellIndex].values.map(item => item.value);
  };

  const handleDeleteEEI = async physicalRows => {
    const rowsMap = [];
    physicalRows.forEach(row => {
      rowsMap.push(eeiData[row]);
    });

    const tableRowsToDelete = [];

    rowsMap.forEach(row => {
      tableRowsToDelete.push({
        region: row.cells[0].value,
        country: row.cells[1].value,
        cc: row.cells[2].value,
        period: row.cells[3].value,
        projectExternalId: row.cells[6].value,
        projectName: row.cells[7].value,
      });
    });

    try {
      setIsLoading(true);
      const payload = {
        rawEntriesFromFile: tableRowsToDelete,
        year: selectedYear,
      };
      await handleDelete(payload);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const getRowsWithChanges = changes => {
    const groupedChanges = changes.reduce((acc, curr) => {
      const key = curr[0];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(curr);
      return acc;
    }, {});

    const affectedRows = Object.keys(groupedChanges);
    const rowsWithChanges = [];
    const rowsToSave = [];

    affectedRows.forEach(rowId => {
      const mappedRowId = rowIdMap[rowId];
      const rowToUpdate = formData[mappedRowId];
      const rowCells = rowToUpdate ? rowToUpdate.cells : formData[rowId].cells;
      const rowChanges = groupedChanges[rowId];
      rowChanges.forEach(change => {
        const propToUpdate = change[1];
        const newValue = change[3];
        const cellToUpdate = rowCells.filter(cell => cell.property === propToUpdate);
        cellToUpdate[0].value = newValue;

        rowsToSave.push({
          rowIndex: change[0],
          cellIndex: change[1],
          value: change[3],
        });
      });

      rowsWithChanges.push(rowToUpdate);
    });

    setRowsChanged(rowsToSave);

    return rowsWithChanges;
  };

  const handleSave = async changes => {
    if (changes.length) {
      const sheets = [
        {
          name: 'EEI',
          data: {
            metadata: {
              rows: [],
            },
            header: {
              rows: [
                {
                  id: null,
                  cells: [],
                },
              ],
            },
            rows: [],
          },
        },
      ];

      const sheetData = sheets[0].data;

      sheetData.rows = getRowsWithChanges(changes);
      sheets.push({
        name: 'Read me',
        data: {
          header: null,
          metadata: {
            rows: readMeData,
          },
          rows: [],
        },
      });

      sheets[0].data.header.rows[0].cells = formHeader[0];
      sheets[0].data.metadata.rows = tableMetadata;

      const payload = {
        lastActualsQuarter: tableMetadata[2].cells[1].value,
        sheets: sheets,
        updateDate: tableMetadata[0].cells[1].value,
        year: tableMetadata[1].cells[1].value,
        orgId: orgId,
      };
      const response = await saveApi(payload);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    hzaContext.clearStates();
  };

  const handleUndo = () => {
    if (form) {
      form.undo();
    }
  };

  const handleRedo = () => {
    if (form) {
      if (redoStack.length > 0) {
        // Get the last change from redoStack
        const lastChange = [...redoStack][redoStack.length - 1];
        const newRedoStack = [...redoStack];

        // Remove the last change from redoStack
        newRedoStack.pop();

        // Update the redoStack state
        setRedoStack(() => {
          const uniq = [...new Set(newRedoStack)];
          return uniq;
        });

        // Push the last change to undoStack
        setUndoStack(prevUndoStack => {
          const uniq = [...new Set([...prevUndoStack, lastChange])];
          return uniq;
        });

        //Set data at cell
        form.setDataAtCell(lastChange.row, lastChange.col, lastChange.newValue, 'custom_redo');
      } else {
        try {
          form.redo();
        } catch (e) {
          console.error(e);
        }
      }
    }
  };

  const customUndoRedo = (changes, source) => {
    if (source === 'edit') {
      const row = changes[0][0];
      const col = form.propToCol(changes[0][1]);
      const oldValue = changes[0][2];
      const newValue = changes[0][3];
      const changesToPush = {
        row: row,
        col: col,
        oldValue: oldValue,
        newValue: newValue,
      };
      setUndoStack(prevState => {
        const uniq = [...new Set([...prevState, changesToPush])];
        return uniq;
      });
    }
    if (source.includes('UndoRedo.undo')) {
      if (undoStack.length > 0) {
        const lastChange = undoStack[undoStack.length - 1];
        const newUndoStack = [...undoStack];
        // Remove the last change from undoStack
        newUndoStack.pop();

        setUndoStack(() => {
          const uniq = [...new Set([...newUndoStack])];
          return uniq;
        });

        // Push the last change to redoStack
        setRedoStack(prevRedoStack => {
          return [...prevRedoStack, lastChange];
        });
      }
    }
  };

  const formatHeader = (col, TH) => {
    if (col > -1) {
      const headerObject = formHeader[0][col];

      if (headerObject && headerObject.style) {
        TH.setAttribute(
          'style',
          `background-color: ${headerObject.style.bgColor}; color: ${headerObject.style.color}`
        );
      }
      if (filteredHeaders[col] && !filteredHeaders[col].filterable) {
        const button = TH.querySelector('.changeType');
        if (!button) {
          return;
        }
        button.parentElement.removeChild(button);
      }
    }
  };

  // TO-DO: use renderer in the future
  function cellColorRenderer(instance, td, row, col, prop, value, cellProperties) {
    Handsontable.renderers.TextRenderer.apply(this, arguments);
    formData.forEach((row, rowIndex) => {
      row.cells.forEach((cell, cellIndex) => {
        if (cell.style) {
          td.style.background = cell.style.bgColor;
          td.style.color = cell.style.color;
        }
      });
    });
  }

  return (
    <StyledEmwWebTable
      open={isOpen}
      fullScreen
      id="form-dialog-window"
      disableAutoFocus={true}
      disableEnforceFocus={true}
    >
      <DialogTitle className="flex">
        <Grid container id="form-dialog-header" className="flex items-center justify-between">
          <Grid item className="flex items-center" id="form-dialog-title">
            <EmwTypography fontSize={6} classes="text-primary-500 font-bold">
              {title}
            </EmwTypography>
            <KPIReadMeTooltip readMeData={readMeData} isWFB={false} isByCountry={true} />
          </Grid>
          <Grid item className="flex" id="form-dialog-header-actions">
            <Grid item className="flex" id="form-dialog-text-formatting">
              <EmwRichTextFormatting handleUndo={handleUndo} handleRedo={handleRedo} />
            </Grid>
            <Grid item className="flex" id="form-dialog-action-buttons">
              <EmwButton
                color="primary"
                variant="outline"
                classes="mr-s"
                size="small"
                onClick={handleClose}
                id="btnClose"
              >
                Close
              </EmwButton>
              <KPIDownloadButton
                selectedYear={selectedYear}
                selectedKPI={selectedKPI}
                regionName={regionName}
                selectedOption={selectedOption}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent id="form-dialog-content">
        <div className="loading-wrapper">{isLoading && <EmwProgressCircle size="small" />}</div>
        {formData.length === 0 && !isLoading && <EmwTypography> No data available</EmwTypography>}
        {formData.length > 0 && (
          <>
            <EEIFormInfo eeiInfo={tableMetadata} isFromByCountry={true} />
            <HotTable
              root="form"
              settings={formSettings}
              className="handsontable"
              id={id}
              data={tableData}
              ref={formRef}
              columnSorting={true}
              fixedColumnsStart={fixedColumns}
              manualColumnFreeze={fixedColumns > 0}
              dropdownMenu={{
                items: {
                  filter_by_value: {
                    hidden() {
                      return false;
                    },
                  },
                  filter_action_bar: {
                    hidden() {
                      return false;
                    },
                  },
                },
              }}
              nestedHeaders={getNestedHeaders()}
              afterGetColHeader={formatHeader}
            />
          </>
        )}
      </DialogContent>
      <Divider flexItem />
      <DialogActions className="justify-between h-m" id="form-dialog-actions">
        <EmwFooterEnvInfo />
      </DialogActions>
    </StyledEmwWebTable>
  );
}
