import { computeURL, fetch, fetchOptions } from '../../../../util/fetch';

export const getUser = () => fetch(computeURL('user', 'shortUser'), fetchOptions());

export const getNotifications = async payload => {
  const url = computeURL('user', 'getNotifications');

  return fetch(url, fetchOptions());
};

export const setUserDate = payload =>
  fetch(
    computeURL('data', 'setWorkDate'),
    fetchOptions({ method: 'PUT', body: JSON.stringify(payload) })
  );

export const setUser = payload =>
  fetch(
    computeURL('user', 'setUser'),
    fetchOptions({ method: 'PUT', body: JSON.stringify(payload) })
  );

export const getTreeData = () => fetch(computeURL('data', 'allTreesNodes'), fetchOptions());

export const exportAllCC = async () =>
  fetch(computeURL('organization', 'exportAllCc'), fetchOptions({ method: 'PUT' }));

export const getBiProps = () => fetch(computeURL('app', 'getBiProps'), fetchOptions());
