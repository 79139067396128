import React, { useEffect, useState } from 'react';
import EmwTypography from '../../../../../../../../components/EmwTypography/EmwTypography';
import { NO_RESOURCE_ALLOCATED } from '../../../../../../constants';
import cls from 'classnames';

export default function ItemName({ name }) {
  const [fontSize, setFontSize] = useState(9);
  const className = cls({
    'text-orange-500': name === NO_RESOURCE_ALLOCATED || !name,
    'text-bluegrey-800': name !== NO_RESOURCE_ALLOCATED,
  });

  useEffect(() => {
    if (!name || name === NO_RESOURCE_ALLOCATED) {
      return setFontSize(8);
    }

    setFontSize(9);
  }, [name]);

  return (
    <EmwTypography classes={className} fontSize={fontSize}>
      {name}
    </EmwTypography>
  );
}
