import { Grid, styled } from '@mui/material';

export const StyledEmList = styled('div')(() => ({
  '.em-list-item': {
    marginTop: '0.75rem',
    '> div': {
      minHeight: '30px',
      alignItems: 'center',
    },
  },
  button: {
    '&.toggle-btn': {
      padding: '8px',
    },
  },
  '.section1': {
    '&.em-list-item': {
      marginTop: 0,
      marginBottom: '0.75rem',
    },
  },
  '.roles': {
    a: {
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
}));

export const StyledEmListItem = styled(Grid)(() => ({
  minHeight: '30px',
  button: {
    '&.toggle-btn': {
      padding: '8px',
    },
  },

  '.roles': {
    flexGrow: 1,
    a: {
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },

  '.engineer-removed p': {
    color: '#797D9C!important',
  },
}));

export const StyledReferentListItem = styled(Grid)(() => ({
  minHeight: '30px',
  '.chip': {
    flexWrap: 'wrap',
  },
}));
