import { utils } from 'xlsx';
import ExcelJS from 'exceljs';
import { cloneDeep } from 'lodash';

export const UPLOAD_EXCEL_TYPE = {
  UPLOAD_EEI: 'uploadEEI',
};

const INDEX_SPLIT = {
  UPLOAD_EEI: {
    METADATA: { start: 0, end: 3 },
    HEADER: { start: 3, end: 4 },
    ROWS: { start: 4 },
  },
};

const CELL_TYPE = {
  value: '',
  property: '',
  colspan: '1',
  cellType: 'any',
  valueType: 'string',
  formula: '',
  values: [],
  style: {
    color: '',
    bgColor: '',
    alignment: { vertical: 'middle', horizontal: 'center' },
    bold: null,
  },
  visible: true,
  editable: false,
  sortable: false,
  filterable: false,
};

export function getWorksheetRows(worksheet) {
  const headers = [];
  const range = utils.decode_range(worksheet['!ref']);
  const firstRow = range.s.r;

  for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {
    const headerCellRef = utils.encode_cell({ r: firstRow, c: colNum });
    const headerCell = worksheet[headerCellRef];
    headers[colNum] = headerCell ? headerCell.v : `Column ${colNum + 1}`;
  }

  const rows = [];

  for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
    const row = {};
    for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {
      const cellRef = utils.encode_cell({ r: rowNum, c: colNum });
      const cell = worksheet[cellRef];
      if (cell) {
        const header = headers[colNum];
        row[header] = {
          value: cell.v,
          stringValue: cell.w,
          formula: cell.f || null,
          style: cell.s,
          range: findCellMergeRange(worksheet, cellRef),
          alignment: cell.alignment,
        };

        if (cell.w === '#VALUE!') {
          row[header].value = '';
        }
        if (headers[colNum] === 'Column 17') {
          row[header].value = row[header].formula ? row[header].formula : row[header].stringValue;
        }
      }
    }
    if (Object.keys(row).length) {
      rows.push(row);
    }
  }
  return rows;
}

export const excelToJson = (workbook, fileName, type) => {
  const json = { sheets: [] };

  workbook.SheetNames.forEach(name => {
    if (name !== 'Values') {
      const workSheet = workbook.Sheets[name];
      const data = generateSheetData(workSheet, type);
      json.sheets.push({
        name,
        data,
      });
    }
  });

  return json;
};
const generateSheetData = (workSheet, type) => {
  const metadata = { rows: [] };
  const header = { rows: [] };
  let rows = getWorksheetRows(workSheet).map(row => {
    const jsonRow = { id: null, cells: [] };

    jsonRow.cells = Object.keys(row).map((cellName, cellIndex) => {
      const xCell = row[cellName];
      const jsonCell = cloneDeep(CELL_TYPE);
      jsonCell.value = xCell.value;
      jsonCell.formula = xCell.formula;

      if (xCell.style.fgColor) {
        jsonCell.style.bgColor = `#${xCell.style.fgColor.rgb}`;
      }

      if (xCell.style.alignment) {
        jsonCell.style.alignment = xCell.style.alignment;
      }

      if (xCell.style.bold) {
        jsonCell.style.bold = true;
      }

      return jsonCell;
    });

    return jsonRow;
  });

  if (type === UPLOAD_EXCEL_TYPE.UPLOAD_EEI) {
    metadata.rows = rows.slice(
      INDEX_SPLIT.UPLOAD_EEI.METADATA.start,
      INDEX_SPLIT.UPLOAD_EEI.METADATA.end
    );
    header.rows = rows.slice(
      INDEX_SPLIT.UPLOAD_EEI.HEADER.start,
      INDEX_SPLIT.UPLOAD_EEI.HEADER.end
    );
    rows = rows.slice(INDEX_SPLIT.UPLOAD_EEI.ROWS.start);

    // Add properties to row;
    const properties = rows[0].cells.map(cell => cell.value);

    rows.splice(0, 1);
    rows = rows.map(row => {
      const cells = row.cells.map((cell, index) => ({
        ...cell,
        property: properties[index],
      }));
      return { id: row.id, cells };
    });
  }

  return {
    metadata,
    header,
    rows,
  };
};

const autoWidth = (worksheet, minimalWidth = 10) => {
  worksheet.columns.forEach(column => {
    let maxColumnLength = 0;
    column.eachCell({ includeEmpty: true }, cell => {
      maxColumnLength = Math.max(
        maxColumnLength,
        minimalWidth,
        cell.value ? cell.value.toString().length : 0
      );
    });
    column.width = maxColumnLength + 2;
  });
};

const findCellMergeRange = (sheet, cellAddress) => {
  const merges = sheet['!merges'] || [];
  for (let i = 0; i < merges.length; i++) {
    const range = merges[i];
    if (
      range.s.c <= cellAddress.c &&
      range.e.c >= cellAddress.c &&
      range.s.r <= cellAddress.r &&
      range.e.r >= cellAddress.r
    ) {
      // Cell is within this merge range
      return range;
    }
  }
  return null; // Cell is not part of a merged range
};

/**
 * Download form json to excel
 * @todo: remove empty rows generated by hot reload
 */
export const jsonToExcel = async (fileName, json, isUsageMonitoring = false, doDownload = true) => {
  // Build Excel document
  const workbook = new ExcelJS.Workbook();

  if (isUsageMonitoring) {
    const workSheet = workbook.addWorksheet('Sheet 1');
    if (json.header) {
      generateDrillToDetailHeader(workSheet, json.header[0], true);
    }
    if (json.rows) {
      generateDrillToDetailRows(workSheet, json.rows, true);
    }
  } else {
    // Build sheets
    json.sheets &&
      json.sheets.forEach((sheet, i) => {
        const workSheet = workbook.addWorksheet(sheet.name);
        if (sheet.name === 'Values') {
          workbook.getWorksheet('Values').state = 'hidden';
        }
        workSheet.properties.defaultRowHeight = 15;
        workSheet.properties.defaultColWidth = 20;
        let metadata = sheet.data.metadata && sheet.data.metadata.rows;
        if (metadata) {
          if (i === 0) {
            const tmpEmptyRow = cloneDeep(sheet.data.metadata.rows[0]);
            tmpEmptyRow.cells[0] = {
              ...CELL_TYPE,
            };
            tmpEmptyRow.cells[1] = {
              ...CELL_TYPE,
            };
            metadata.push(tmpEmptyRow);
          }

          if (i === 1) {
            // Add an empty row at the top
            // Temp hardcoded rows
            const emptyRow = { cells: [{ ...CELL_TYPE }] };
            const readMeMetadata = [];

            for (const row of metadata) {
              const index = metadata.indexOf(row);
              if (index === 0) {
                readMeMetadata.push(emptyRow);
                readMeMetadata.push(row);
                readMeMetadata.push(emptyRow);
              } else if (index === 1) {
                const customRow = { ...row };
                // customRow.cells[1].style = { ...row.cells[0].style };
                // customRow.cells[3].style = { ...row.cells[0].style };
                customRow.cells.push(customRow.cells[1]);
                readMeMetadata.push(customRow);
              } else {
                readMeMetadata.push(row);
              }
            }

            metadata = readMeMetadata;
          }
        }

        // Add metadata rows
        if (sheet.data.metadata) {
          generateRows(workSheet, metadata);
        }

        // Add columns
        if (sheet.data.header) {
          generateRows(workSheet, sheet.data.header.rows);
        }

        // Add rows
        if (sheet.data.rows) {
          generateRows(workSheet, sheet.data.rows);
        }

        // Generate Rows from sheetRows
        workSheet.protect('yourPassword', {
          insertRows: true,
          deleteRows: true,
        });

        if (sheet.name === 'Read me') {
          // Force main columns width's
          workSheet.columns = [
            { header: '', key: '1', width: 28 },
            { header: '', key: '2', width: 28 },
            { header: '', key: '3', width: 100 },
            { header: '', key: '4', width: 28 },
            { header: '', key: '5', width: 100 },
          ];
        }
      });
  }

  if (!doDownload) {
    return { workbook, fileName };
  }
  try {
    await download(workbook, fileName);
  } catch (err) {
    console.error('Error downloading file: ', err);
  }
};

// Define a row with each cell
const generateRows = (workSheet, rows) => {
  rows.forEach((row, rowIndex) => {
    const sheetRow = workSheet.addRow({});
    row.cells.forEach((cell, cellIndex) =>
      setRowCell(workSheet, sheetRow, cell, cellIndex, rowIndex)
    );
  });
  autoWidth(workSheet);
};

//methods used for usage monitoring only
const generateDrillToDetailRows = (workSheet, rows, isUsageMonitoring) => {
  rows.forEach((row, rowIndex) => {
    const sheetRow = workSheet.addRow({});
    row.cels.forEach((cell, cellIndex) =>
      setRowCell(workSheet, sheetRow, cell, cellIndex, rowIndex, isUsageMonitoring)
    );
  });
  autoWidth(workSheet);
};

const generateDrillToDetailHeader = (workSheet, rows, isUsageMonitoring, isDrillHeader = true) => {
  const sheetRow = workSheet.addRow({});
  rows.forEach((row, rowIndex) => {
    setRowCell(workSheet, sheetRow, row, rowIndex, 1, isUsageMonitoring, isDrillHeader);
  });
  autoWidth(workSheet);
};

const setDropdownValues = cell => {
  return cell.property === 'CC' && cell.values.length > 15
    ? '=Values!$A$2:$CZ$2'
    : `"${cell.values.map(value => value.value).join(',')}"`;
};

/**
 * Define a cell for each row
 */
const setRowCell = (
  workSheet,
  row,
  cell,
  cellIndex,
  rowIndex,
  isUsageMonitoring,
  isDrillHeader
) => {
  const rowCell = row.getCell(cellIndex + 1);
  rowCell.value = cell.value && cell.value !== 'null' ? cell.value : '';

  if (!isUsageMonitoring && !isNaN(rowCell.value)) {
    rowCell.value = rowCell.value.replace('.', ',');
  }

  if (cell.formula) {
    if (cell.value) {
      rowCell.value = cell.value;
    } else {
      rowCell.value = { formula: cell.formula, result: 7 };
    }
  }

  if (Array.isArray(cell.values) && cell.values.length > 0) {
    rowCell.dataValidation = {
      type: 'list',
      allowBlank: false,
      formulae: [setDropdownValues(cell)],
    };
  }

  if (workSheet.name.toLowerCase() === 'read me' && rowIndex > 3) {
    if (cellIndex !== 0) {
      rowCell.alignment = rowCell.alignment
        ? rowCell.alignment
        : { vertical: 'center', horizontal: 'left', wrapText: true };
    }
  } else {
    rowCell.alignment = rowCell.alignment
      ? rowCell.alignment
      : { vertical: 'middle', horizontal: 'center' };
  }

  rowCell.style.font = {
    size: 12,
    bold: cell.style ? cell.style.bold : false,
  };

  if (isDrillHeader) {
    row.height = 20;
    rowCell.alignment = { vertical: 'middle', horizontal: 'center' };
    rowCell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '0f186d' },
    };
    rowCell.style.font = {
      size: 14,
      color: { argb: 'FFFFFF' },
    };
  }

  if (cell.hasOwnProperty('editable')) {
    rowCell.protection = { locked: !cell.editable };
  }

  if (cell.style && cell.style.bgColor) {
    rowCell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: cell.style.bgColor.replace('#', '') },
    };
  }

  if (cell.style && cell.style.color) {
    rowCell.style.font = {
      ...rowCell.style.font,
      color: { argb: cell.style.color.replace('#', '') },
    };
  }

  if (cell.value === 'EEI (Engineering Efficiency Index)') {
    workSheet.mergeCells(2, 0, 2, 6);
  }

  if (cell.comment) {
    rowCell.note = cell.comment;
  }

  if (cell.colspan > 1) {
    // workSheet.mergeCells(1, 1, 1, parseInt(cell.colspan));
  }

  /**
   * Temporary hardcode code for read me sheet
   */
  if (workSheet.name.toLowerCase() === 'read me') {
    rowCell.alignment = { vertical: 'center', horizontal: 'left', wrapText: true };
    if (cellIndex === 0) {
      rowCell.alignment = { vertical: 'center', horizontal: 'center', wrapText: true };
      rowCell.style.font = {
        size: 12,
        color: { argb: 'FFFFFF' },
      };
    }
    if (['Description', 'Validation'].includes(cell.value)) {
      rowCell.alignment = { vertical: 'center', horizontal: 'center' };
    }
  } else {
    rowCell.alignment = { vertical: 'center', horizontal: 'center' };
    rowCell.style.border = {
      top: { style: 'thin', color: { argb: 'ffd4d4d4' } },
      left: { style: 'thin', color: { argb: 'ffd4d4d4' } },
      bottom: { style: 'thin', color: { argb: 'ffd4d4d4' } },
      right: { style: 'thin', color: { argb: 'ffd4d4d4' } },
    };
  }
};

export const download = async (workbook, fileName) => {
  setTimeout(async () => {
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
  }, 100);
};
