import React, { useEffect, useState } from 'react';
import { StyledDialog } from '../../styled';
import {
  TdsFormField,
  TdsFormLabel,
  TdsDropdownSelect,
  TdsDropdownSelectOption,
  TdsTextInput,
  TdsFormCaption,
  TdsRadioButton,
  TdsIcon,
} from '@tds/react';
import EmwTypography from '../../../../components/EmwTypography/EmwTypography';
import { Box, DialogActions, DialogContent, DialogTitle, Divider, Grid } from '@mui/material';
import { EmwButton } from '../../../../../lib/common';
import { createAlertApi } from '../../redux/api';
import { updateAlerts } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import TriangleUpIcon from '../../../../../lib/icons/TriangleUpIcon';
import TriangleDownIcon from '../../../../../lib/icons/TriangleDownIcon';
import TargetIcon from '../../../../../lib/icons/TargetIcon';
import { CHARTS, ENGINEERING_COMPETITIVENESS, MOOD_AND_CONFIDENCE } from '../../constants';
import { useLocation } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { alertsSelector, chartsSelector } from '../../redux/selectors';
import useAppSnackbar from '../../../../hooks/useAppSnankbar';
import { getGeneralFilters, getTimeframeFilters } from '../../../../../util/biUtils';
import usePowerBi from '../../hooks/usePowerBi';

export default function CreateAlertModal({
  isOpen,
  setIsOpen,
  openPrevModal,
  selectedChart,
  generalFilters,
  timeframeFilters,
}) {
  const dispatch = useDispatch();
  const [value, setValue] = useState('');
  const [type, setType] = useState('');
  const [period, setPeriod] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldHaveValue, setShouldHaveValue] = useState(true);
  const [orgName, setOrgName] = useState('');
  const [canSubmit, setCanSubmit] = useState(false);
  const alertsData = useSelector(alertsSelector);
  const chartsData = useSelector(chartsSelector);
  const location = useLocation();
  const snackbar = useAppSnackbar();
  const powerBi = usePowerBi();

  useEffect(() => {
    if (!value || !type || !period) {
      setCanSubmit(false);
    }

    if (type === 'target') {
      setValue('');

      if (period) {
        setCanSubmit(true);
      }
    } else if (type && value && period) {
      setCanSubmit(true);
    }
  }, [type, value, period]);

  useEffect(() => {
    setOrgName(powerBi.filterToOrgName());
  }, []);

  const handleValueChange = event => {
    const { value } = event.currentTarget;
    const regExp = /([0-9]+)(\.)/;

    if (isNaN(value) || !value) {
      return setValue('');
    }
    let parsedValue = 0;

    if (!selectedChart.hasDecimals && regExp.test(value)) {
      const regExpParts = regExp.exec(value);
      if (regExpParts[1]) {
        parsedValue = regExpParts[1];
      } else {
        parsedValue = value;
      }
    } else {
      parsedValue = value;
    }

    if (![CHARTS.SKILL_COVERAGE, CHARTS.KEY_PROFILE_FIT].includes(selectedChart)) {
      if (parsedValue > selectedChart.max) {
        parsedValue = selectedChart.max;
      }
    }

    setValue(parsedValue);
  };

  const handleSelectedOption = event => {
    setShouldHaveValue(event.detail.selectedOption !== 'target');
    setType(event.detail.selectedOption);
  };

  const handlePeriodChange = event => {
    const { value } = event.currentTarget;

    setPeriod(value);
  };

  const handleCreate = async () => {
    setIsLoading(true);
    const payload = {
      chartType: [selectedChart.key],
      generalFilters,
      timeframeFilters,
      alert: {
        value,
        type,
        period,
      },
    };

    try {
      const response = await createAlertApi(payload);
      const alerts = cloneDeep(alertsData);
      const page = location.pathname;
      const name =
        page === '/moodandconfidence' ? MOOD_AND_CONFIDENCE : ENGINEERING_COMPETITIVENESS;

      if (!response.data) {
        return snackbar.show(response.message, response.warning);
      }

      if (name === MOOD_AND_CONFIDENCE) {
        alerts[name][CHARTS.MOOD].values = response.data[CHARTS.MOOD] || [];
        alerts[name][CHARTS.CONFIDENCE_TO_DELIVER].values =
          response.data[CHARTS.CONFIDENCE_TO_DELIVER] || [];
      }

      dispatch(updateAlerts(alerts));
      const timeframeFilters = getTimeframeFilters();
      const requestPayload = {
        chartType: Object.keys(CHARTS).map(key => CHARTS[key]),
        generalFilters,
        timeframeFilters,
      };

      powerBi.fetchAlerts(requestPayload, chartsData);
      setIsOpen(false);
    } catch (reason) {
      snackbar.show(reason.message, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setIsOpen(true);
    }
  };

  return (
    <StyledDialog open={isOpen} maxWidth="xs" fullWidth onClose={handleClose} disableEscapeKeyDown>
      <DialogTitle className="dialog-title" sx={{ paddingBottom: '9px' }}>
        <Grid
          container
          justifyContent="space-between"
          className=" border-b-2 border-primary-500 pb-s mb-s"
        >
          <EmwTypography classes="text-primary-500" fontSize={6} fontWeight="bold">
            Create KPI Alert
          </EmwTypography>
          <EmwTypography fontSize={6} fontWeight="bold" classes="text-bluegrey-600">
            {orgName}
          </EmwTypography>
        </Grid>

        <Grid container flexDirection="column">
          <EmwTypography fontSize={9} classes="text-bluegrey-400">
            Current KPI
          </EmwTypography>
          <EmwTypography fontSize={6} fontWeight="bold" classes="text-primary-500">
            {selectedChart.title.toUpperCase()}
          </EmwTypography>
        </Grid>
      </DialogTitle>

      <Box sx={{ padding: '0 15px 0 15px' }}>
        <Divider sx={{ height: '2px' }} />
      </Box>

      <DialogContent sx={{ paddingTop: '9px', paddingBottom: '9px' }}>
        <Grid container>
          <Grid item xs={8}>
            <TdsFormField>
              <TdsFormLabel>
                <EmwTypography fontSize={9} classes="text-bluegrey-400">
                  Alert type
                </EmwTypography>
              </TdsFormLabel>
              <TdsDropdownSelect
                classes="qtm-small"
                value={type}
                onSelectedOption={handleSelectedOption}
              >
                <TdsDropdownSelectOption value="above">
                  <div className="mr-xs">
                    <TriangleUpIcon />
                  </div>
                  Values goes above
                </TdsDropdownSelectOption>
                <TdsDropdownSelectOption value="below">
                  <div className="mr-xs">
                    <TriangleDownIcon />
                  </div>
                  Value goes below
                </TdsDropdownSelectOption>
                <TdsDropdownSelectOption value="target">
                  <div className="mr-xs">
                    <TargetIcon />
                  </div>
                  Target
                </TdsDropdownSelectOption>
              </TdsDropdownSelect>
              <TdsFormCaption>
                <EmwTypography fontSize={9} classes="text-bluegrey-400">
                  Maximum 10 alerts per KPI
                </EmwTypography>
              </TdsFormCaption>
            </TdsFormField>
          </Grid>
          <Grid item xs={4}>
            <TdsFormField>
              <TdsFormLabel>
                <EmwTypography fontSize={9} classes="text-bluegrey-400">
                  Value
                </EmwTypography>
              </TdsFormLabel>
              <div>
                <div className="qtm-text-input qtm-small">
                  <input
                    type="text"
                    value={value}
                    disabled={!shouldHaveValue}
                    onChange={handleValueChange}
                  />
                </div>
              </div>
              {/*<TdsTextInput size="small" value={value} disabled={!shouldHaveValue} onValueChanged={handleValueChange} />*/}
            </TdsFormField>
          </Grid>
        </Grid>

        <TdsFormField classes="mt-s">
          <TdsFormLabel>
            <EmwTypography fontSize={9} classes="text-bluegrey-400">
              Notify Me
            </EmwTypography>
          </TdsFormLabel>
          <TdsFormField>
            <Grid container justifyContent="space-between">
              <TdsRadioButton size="small" name="period" value={1} onClick={handlePeriodChange}>
                Once
              </TdsRadioButton>
              <TdsRadioButton size="small" name="period" value={2} onClick={handlePeriodChange}>
                Always
              </TdsRadioButton>
              <TdsRadioButton size="small" name="period" value={3} onClick={handlePeriodChange}>
                End of period
              </TdsRadioButton>
            </Grid>
          </TdsFormField>
        </TdsFormField>
      </DialogContent>

      <Box sx={{ padding: '0 15px 0 15px' }}>
        <Divider sx={{ height: '2px' }} />
      </Box>

      <DialogActions>
        <EmwButton
          variant="outline"
          color="primary"
          classes="mr-xs"
          size="small"
          onClick={() => setIsOpen(false)}
        >
          Close
        </EmwButton>
        <EmwButton
          variant="filled"
          color="primary"
          size="small"
          disabled={!canSubmit}
          onClick={handleCreate}
        >
          Save Alert
        </EmwButton>
      </DialogActions>
    </StyledDialog>
  );
}
