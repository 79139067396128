import React from 'react';
import { Grid } from '@mui/material';
import { TdsTag } from '@tds/react';
import TriangleUpIcon from '../../../../../../../lib/icons/TriangleUpIcon';
import TriangleDownIcon from '../../../../../../../lib/icons/TriangleDownIcon';
import TargetIcon from '../../../../../../../lib/icons/TargetIcon';
import EmwTypography from '../../../../../../components/EmwTypography/EmwTypography';

export default function AlertsType({ alertType }) {
  const renderIcon = () => {
    if (alertType.type === 'above') {
      return <TriangleUpIcon />;
    }

    if (alertType.type === 'below') {
      return <TriangleDownIcon />;
    }

    return <TargetIcon />;
  };

  return (
    <Grid container>
      <TdsTag size="small" classes="bg-white-100 rounded">
        <div>{renderIcon()}</div>
        <EmwTypography classes="text-primary-500 text-scale-8">{alertType.value}</EmwTypography>
      </TdsTag>
    </Grid>
  );
}
