import React from 'react';

export default function TargetIcon() {
  return (
    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.42 5.2077C2.50049 3.50219 3.90634 2.14529 5.63415 2.14529C7.41024 2.14529 8.85366 3.58801 8.85366 5.36324C8.85366 7.0902 7.4961 8.49537 5.78976 8.57582L6.1278 7.44954C7.07756 7.22964 7.78049 6.37689 7.78049 5.36324C7.78049 4.17796 6.82 3.21794 5.63415 3.21794C4.62 3.21794 3.76683 3.92053 3.54683 4.86982L2.42 5.2077ZM5.63415 10.7265C5.47317 10.7265 5.3122 10.7211 5.15122 10.705L5.4678 9.64846C5.52146 9.65383 5.58049 9.65383 5.63415 9.65383C8.00585 9.65383 9.92683 7.73379 9.92683 5.36324C9.92683 2.99269 8.00585 1.07265 5.63415 1.07265C3.26244 1.07265 1.34146 2.99269 1.34146 5.36324C1.34146 5.41687 1.34146 5.47587 1.34683 5.5295L0.289757 5.84593C0.273659 5.68503 0.268293 5.52413 0.268293 5.36324C0.268293 2.40273 2.6722 -3.6402e-07 5.63415 -2.34549e-07C8.5961 -1.05078e-07 11 2.40273 11 5.36324C11 8.32374 8.5961 10.7265 5.63415 10.7265ZM3.34829 8.70453L4.02439 10.7265L5.63415 5.36324L0.268293 6.97221L2.29122 7.64798L5.19267e-07 9.93808L1.06244 11L3.34829 8.70453Z"
        fill="#041295"
      />
    </svg>
  );
}
