import React from 'react';
import { Tooltip } from '@mui/material';
import EmwIcon from '../../../EmwIcon/EmwIcon';

export default function ChartTooltip({ title }) {
  return (
    <Tooltip
      title={title}
      arrow
      placement="top"
      componentsProps={{
        tooltip: {
          className: 'bg-white-90 text-black-70 shadow-s-strong',
        },
        arrow: {
          className: 'text-black-70',
        },
      }}
    >
      <p>
        <EmwIcon icon="help_outlined" variant="outlined" size="large" classes="pl-l" />
      </p>
    </Tooltip>
  );
}
