import React from 'react';
import { TdsTypography } from '@tds/react';
import ctx from 'classnames';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const StyledLoginTab = styled(Box)(({ theme }) => ({
  width: '177px',
  height: '42px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  color: '#ffffff',
  backgroundColor: theme.palette.primary[400],
  p: {
    ...theme.typography['caption-1'],
  },
  '&.login': {
    borderRadius: '10px 0 0 0 ',
  },
  '&.getAccess': {
    borderRadius: '0 10px 0 10px ',
  },
  '&.disabled': {
    cursor: 'not-allowed',
  },
  '&.selected': {
    backgroundColor: '#ffffff',
    color: '#222227', // not in quantum palette
    cursor: 'default',
  },
}));

export default function LoginTab({ text, tab, selectedTab, handleClick, disabled }) {
  const className = ctx(tab, {
    selected: selectedTab === tab,
    disabled,
  });

  const onClick = () => {
    if (disabled) {
      return;
    }
    handleClick(tab);
  };

  return (
    <StyledLoginTab className={className} onClick={onClick}>
      <TdsTypography component="body-2">{text}</TdsTypography>
    </StyledLoginTab>
  );
}
