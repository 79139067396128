import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { EmwButton } from '../../../../../../../lib/common';
import { TdsIcon } from '@tds/react';
import { useDispatch, useSelector } from 'react-redux';
import useAppSnackbar from '../../../../../../hooks/useAppSnankbar';
import { favoriteOrganizationsSelector, orgDetailsSelector } from '../../../../redux/selectors';
import * as api from '../../../../redux/api';
import { setFavoriteOrganizations } from '../../../../redux/actions';
import { cloneDeep } from 'lodash';

const favoriteClasses = {
  isAdded: {
    color: '#E64B33',
    fontSize: '28px',
  },
  isNotAdded: {
    color: '#041295',
    fontSize: '28px',
  },
};

export default function AddToFavorites({ btn, name, id, type }) {
  const dispatch = useDispatch();
  const snackBar = useAppSnackbar();
  const { list } = useSelector(orgDetailsSelector);
  const [isAdded, setIsAdded] = useState(btn.value);
  const favorites = useSelector(favoriteOrganizationsSelector);

  useEffect(() => {
    const btn = list.data.section1.btnFavorite;
    setIsAdded(btn.value);
  }, [id]);

  const handleClick = async event => {
    let resp;
    event.currentTarget.disabled = true;
    if (isAdded) {
      resp = await api.deleteFavoriteOrganizationApi(id);
      const favs = [...favorites].filter(fav => {
        return fav.id !== resp.data;
      });
      dispatch(setFavoriteOrganizations(favs));
      setIsAdded(false);
    } else {
      resp = await api.addFavoriteOrganizationApi(id);
      const currentFavIndex = [...favorites].findIndex(fav => fav.id === resp.data.id);
      const favs = cloneDeep(favorites);
      if (currentFavIndex !== -1) {
        favs[currentFavIndex].isAdded = true;
        dispatch(setFavoriteOrganizations(favs));
      } else {
        dispatch(setFavoriteOrganizations([...favorites, resp.data]));
      }
      setIsAdded(true);
    }

    snackBar.show(resp.message, resp.messageType);
  };

  return (
    <Grid item className="organization-card-btn" ml={0.5} id={`organization-card-btn-${name}`}>
      <EmwButton
        variant="ghost"
        size="small"
        disabled={!btn.enabled}
        onClick={handleClick}
        id="btnFavorite"
      >
        {isAdded ? (
          <TdsIcon
            icon={'favorite'}
            title={'Remove from favorite'}
            size="large"
            style={favoriteClasses.isAdded}
          ></TdsIcon>
        ) : (
          <TdsIcon
            icon={'favorite_border'}
            title={'Add to favorite'}
            size="large"
            style={favoriteClasses.isNotAdded}
          ></TdsIcon>
        )}
      </EmwButton>
    </Grid>
  );
}
