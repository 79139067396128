import React, { useContext, useEffect, useState } from 'react';
import { CircularProgress, Collapse, Grid, IconButton, TextField, Chip } from '@mui/material';
import { OrganizationCardContext } from '../../../../context/OrganizationCardContextProvider';
import Autocomplete from '@mui/material/Autocomplete';
import { getCountriesApi } from '../../../../../../redux/api';
import { TdsIcon } from '@tds/react';
import EmwTypography from '../../../../../../../../components/EmwTypography/EmwTypography';
import clx from 'classnames';
import {
  ORGANIZATION_CARD_MODAL_TYPE,
  ORGANIZATION_PERSPECTIVE,
} from '../../../../../../constants';
import { perspectiveSelector, schemaSelector } from '../../../../../../redux/selectors';
import { useSelector } from 'react-redux';
import TourElement from '../../../../../../../../components/Tour/TourElement/TourElement';
/**
 * TODO: Move into a generic component EmwAutocomplete
 */
export default function CountrySelector({ isReseted, data, toggleIsOpen, setCountryName }) {
  const context = useContext(OrganizationCardContext);
  const [isOpen, setIsOpen] = useState(toggleIsOpen);
  const schema = useSelector(schemaSelector);
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [disabled, setDisabled] = React.useState(true);
  const [defaultValue, setDefaultValues] = React.useState([]);
  const [fixedOption, setFixedOption] = React.useState([]);
  const [initialValue, setInitialValue] = useState([]);
  const loading = open && options.length === 0;
  const { selectedCountry } = schema.path;
  const perspective = useSelector(perspectiveSelector);

  const className = clx('panel-item', {
    'text-orange-500 no-allocation': defaultValue.length === 0,
    'selected-countries': defaultValue.length > 0,
  });

  React.useEffect(() => {
    (async () => {
      if (!options.length) {
        const response = await getCountriesApi();
        await setOptions(() =>
          response.data.map(country => {
            return {
              code: country.code,
              label: country.name,
            };
          })
        );
      }
    })();
    setDisabled(!context.data.section1.countries.enabled);
  }, []);

  useEffect(() => {
    if (
      Array.isArray(data.section1.countries.value) &&
      data.section1.countries.value.length === 0
    ) {
      setDefaultValues(data.section1.countries.value);
    }
  }, [data]);

  useEffect(() => {
    if (options.length > 0) {
      if (context.type === ORGANIZATION_CARD_MODAL_TYPE.CREATE) {
        let defaultCountry = [...options];
        defaultCountry = options.filter(option => option.code === selectedCountry.code);
        setFixedOption(defaultCountry);
        context.updateCard('section1', 'countries', {
          value: defaultCountry.map(option => option.code),
          enabled: true,
          visible: true,
        });
        setCountryName(defaultCountry);
        setInitialValue(defaultCountry);
        return setDefaultValues(defaultCountry);
      }

      if (context.type === ORGANIZATION_CARD_MODAL_TYPE.EDIT) {
        const selectedOptions = options.filter(option => {
          return data.section1.countries.value.includes(option.code);
        });
        setCountryName([selectedOptions[0]]);
        setInitialValue([selectedOptions[0]]);
        return setDefaultValues(selectedOptions);
      }
    }
  }, [options]);

  useEffect(() => {
    if (isReseted) {
      setDefaultValues(initialValue);
    }
  }, [isReseted]);

  const handleChange = (event, value, reason, details) => {
    let selectedOptions = [...value];
    const selectedOption = details ? details.option : [];

    if (reason === 'clear') {
      setCountryName([]);
      setDefaultValues([]);
      return context.updateCard('section1', 'countries', {
        value: selectedOptions.map(option => option.code),
        enabled: true,
        visible: true,
      });
    }

    setCountryName(value);

    selectedOptions = value.filter(option => {
      if (selectedOption.code === 'WW') {
        return (selectedOptions = option.code === 'WW');
      }
      return (selectedOptions = option.code !== 'WW');
    });
    setCountryName(selectedOptions);
    setDefaultValues(selectedOptions);
    context.updateCard('section1', 'countries', {
      value: selectedOptions.map(option => option.code),
      enabled: true,
      visible: true,
    });
  };

  return (
    <TourElement id="tour-orgCard-countrySelectorId">
      <Grid
        item
        className="toggle-panel"
        sx={{ flexGrow: 1, borderRadius: isOpen ? '5px 5px 0 0' : '5px' }}
      >
        <Grid container direction="row" alignItems="center">
          <Grid item mr={1}>
            <TdsIcon icon="public" variant="outlined" style={{ color: '#4A4D65' }} />
          </Grid>

          <Grid item>
            <EmwTypography
              component="caption-2"
              fontWeight="bold"
              fontSize="9"
              classes="text-bluegrey-600"
            >
              COUNTRIES
            </EmwTypography>
          </Grid>

          <Grid item ml="auto">
            <Grid container alignItems="center">
              <IconButton
                title={`${isOpen ? 'Collapse section' : 'Expand section'}`}
                disableRipple
                size="small"
                onClick={() => setIsOpen(!isOpen)}
              >
                {isOpen ? (
                  <TdsIcon icon="keyboard_arrow_up" size="small" style={{ color: '#4A4D65' }} />
                ) : (
                  <TdsIcon icon="keyboard_arrow_down" size="small" style={{ color: '#4A4D65' }} />
                )}
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Collapse
        className={className}
        in={isOpen}
        sx={{
          width: '100%',
          display: 'flex',
          padding: '4px 4px 4px 4px',
          marginBottom: '8px',
        }}
      >
        <Autocomplete
          id="country-list"
          sx={{
            width: '100%',
            padding: `${defaultValue.length === 0 ? '0px 5px 0px 0px' : '3px 16px 3px 3px'}`,
            input: {
              fontSize: '12px',
              '&::placeholder': {
                color: '#e07903',
                opacity: '1',
                fontWeight: 'bold',
              },
            },
          }}
          open={open}
          size="small"
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          value={defaultValue}
          multiple
          disabled={
            (perspective === ORGANIZATION_PERSPECTIVE.BY_COUNTRY &&
              context.type === ORGANIZATION_CARD_MODAL_TYPE.CREATE) ||
            !context.data.section1.countries.enabled
          }
          isOptionEqualToValue={(option, value) => option.code === value.code}
          getOptionLabel={option => option.label}
          // getOptionSelected={(option, value) => option.code === value.code}
          defaultValue={defaultValue}
          options={options}
          loading={loading}
          onChange={handleChange}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                label={option.label}
                {...getTagProps({ index })}
                disabled={
                  fixedOption.indexOf(option) !== -1 || !context.data.section1.countries.enabled
                }
              />
            ))
          }
          renderInput={params => (
            <TextField
              variant="standard"
              placeholder={defaultValue.length === 0 ? 'Please select at least one country' : ''}
              {...params}
              InputProps={{
                disableUnderline: true,
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </Collapse>
    </TourElement>
  );
}
