import React, { useContext, useEffect, useState } from 'react';
import { OrganizationCardContext } from '../../../../context/OrganizationCardContextProvider';
import { ORGANIZATION_FIELD } from '../../../../../../constants';
import { Divider, Grid } from '@mui/material';
import { cloneDeep } from 'lodash';
import CheckboxItem from './CheckboxItem';

export default function CheckboxList() {
  const context = useContext(OrganizationCardContext);
  const [shouldRender, setShouldRender] = useState(false);
  const [items, setItems] = useState({
    hardware: { ...ORGANIZATION_FIELD },
    software: { ...ORGANIZATION_FIELD },
    system: { ...ORGANIZATION_FIELD },
    industry: { ...ORGANIZATION_FIELD },
  });

  useEffect(() => {
    const _items = { ...items };

    _items['hardware'] = context.data.section1.hardware;
    _items['software'] = context.data.section1.software;
    _items['system'] = context.data.section1.system;
    _items['industry'] = context.data.section1.industry;

    setItems(_items);

    // TODO: should we check visible dependency for each type instead of all???
    setShouldRender(
      context.data.section1.hardware.visible &&
        context.data.section1.software.visible &&
        context.data.section1.system.visible &&
        context.data.section1.industry.visible
    );
  }, [context.data]);

  const handleChange = event => {
    const _items = cloneDeep(items);

    _items[event.target.name].value = event.target.checked;

    setItems(_items);
    context.updateCard('section1', event.target.name, _items[event.target.name]);
  };

  if (!shouldRender) {
    return null;
  }

  return (
    <>
      <Divider orientation="vertical" flexItem />

      <Grid item ml={2} title="Eng. Domain">
        <Grid container alignItems="center" justifyContent="center">
          {context.data.section1.hardware.visible && (
            <CheckboxItem
              name="hardware"
              title="Hardware"
              label="HW"
              item={items.hardware}
              handleChange={handleChange}
            />
          )}

          {context.data.section1.software.visible && (
            <CheckboxItem
              name="software"
              title="Software"
              label="SW"
              item={items.software}
              handleChange={handleChange}
            />
          )}

          {context.data.section1.system.visible && (
            <CheckboxItem
              name="system"
              title="System"
              label="SYS"
              item={items.system}
              handleChange={handleChange}
            />
          )}

          {context.data.section1.industry.visible && (
            <CheckboxItem
              name="industry"
              title="Industry"
              label="IND"
              item={items.industry}
              handleChange={handleChange}
            />
          )}
        </Grid>
      </Grid>

      <Divider orientation="vertical" flexItem />
    </>
  );
}
