import React from 'react';
import EmwTypography from '../../../../../../components/EmwTypography/EmwTypography';
import cls from 'classnames';
import { COMMENT_TYPES } from '../../../../constants';

export default function CommentType({ comment }) {
  const className = cls('flex text-center', {
    'type-comment': comment.type === COMMENT_TYPES.COMMENT,
    'type-rootCause': comment.type === COMMENT_TYPES.ROOT_CAUSE,
    'type-actionType': comment.type === COMMENT_TYPES.ACTION_PLAN,
  });

  const renderCommentType = () => {
    if (comment.type === COMMENT_TYPES.COMMENT) {
      return 'COMMENT';
    }

    if (comment.type === COMMENT_TYPES.ROOT_CAUSE) {
      return 'ROOT CAUSE';
    }

    if (comment.type === COMMENT_TYPES.ACTION_PLAN) {
      return 'ACTION PLAN';
    }
  };

  return (
    <div className={className}>
      <div className="left-corner"></div>
      <div className="bullet"></div>
      <div className="type">{renderCommentType()}</div>
    </div>
  );
}
