import React, { useState } from 'react';
import { TdsTextInput } from '@tds/react';

export default function EmwTextField({
  name,
  value,
  placeholder,
  id,
  disabled,
  size,
  onChange,
  classes,
  numbersOnly,
  inputProps,
}) {
  const [text, setText] = useState(value);

  const handleChange = event => {
    setText(event.target.value);
    onChange(event);
  };

  return (
    <TdsTextInput
      inputProps={inputProps}
      inputId={id}
      classes={classes}
      name={name}
      type={numbersOnly ? 'number' : 'text'}
      value={text}
      placeholder={placeholder}
      onValueChanged={handleChange}
      disabled={disabled}
      size={size ? size : 'small'}
    />
  );
}
