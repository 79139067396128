import React from 'react';

export default function ConstructionIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.6026 8.77365L9.18836 10.1878L13.1858 14.1853L14.6 12.7711L10.6026 8.77365Z"
        fill="#4A4D65"
      />
      <path
        d="M11.6666 6.73994C12.9532 6.73994 13.9999 5.69327 13.9999 4.40661C13.9999 4.01994 13.8932 3.65994 13.7266 3.33994L11.9266 5.13994L10.9332 4.14661L12.7332 2.34661C12.4132 2.17994 12.0532 2.07327 11.6666 2.07327C10.3799 2.07327 9.33323 3.11994 9.33323 4.40661C9.33323 4.67994 9.38657 4.93994 9.47324 5.17994L8.2399 6.41327L7.05324 5.22661L7.52657 4.75327L6.58657 3.81327L7.9999 2.39994C7.2199 1.61994 5.95324 1.61994 5.17324 2.39994L2.81324 4.75994L3.75324 5.69994H1.87324L1.3999 6.17327L3.7599 8.53327L4.23324 8.05994V6.17327L5.17324 7.11328L5.64657 6.63994L6.83324 7.82661L1.89324 12.7666L3.30657 14.1799L10.8932 6.59994C11.1332 6.68661 11.3932 6.73994 11.6666 6.73994Z"
        fill="#4A4D65"
      />
    </svg>
  );
}
