import { createAction } from '@reduxjs/toolkit';

const props = {
  error: false,
  meta: { fetching: false },
};

export const USER_ACTIVITY = 'USER_ACTIVITY';
export const CLEAR_USER_ACTIVITY = 'CLEAR_USER_ACTIVITY';

export const userActivity = createAction(USER_ACTIVITY);
export const clearUserActivity = createAction(CLEAR_USER_ACTIVITY);
