import React, { useState } from 'react';
import { Grid, Divider } from '@mui/material';

import { EmwButton } from '../../../lib/common';
import { TdsIcon } from '@tds/react';

import ColorDropdown from '../../features/organization/components/OrganizationCard/components/ExportOrganization/ColorDropdown';
import EmwFontSizeDropdown from './EmwFontSizeDropdown';

import EmwTypography from '../EmwTypography/EmwTypography';
import EmwColorDropdown from './EmwColorDropdown';

export default function EmwRichTextFormatting({
  handleBold,
  handleItalic,
  handleUnderline,
  // handleFontSize,
  handleFontColor,
  handleBackgroundColor,
  handleUndo,
  handleRedo,
  handleAddNewLine,
  canAdd,
  isCcForm,
}) {
  const defaultBgColor = '#5CED73'; // green

  return (
    <Grid item className="flex mr-m">
      {isCcForm && (
        <>
          <Divider orientation="vertical" flexItem />
          <EmwButton
            variant="ghost"
            title="Bold"
            size="small"
            onClick={handleBold}
            classes={'ml-xs'}
          >
            <TdsIcon icon="format_bold" size="small" />
          </EmwButton>
          <EmwButton
            variant="ghost"
            title="Italic"
            size="small"
            onClick={handleItalic}
            classes={'ml-xs'}
          >
            <TdsIcon icon="format_italic" size="small" />
          </EmwButton>
          <EmwButton
            variant="ghost"
            title="Underline"
            size="small"
            onClick={handleUnderline}
            classes={'ml-xs mr-xs'}
          >
            <TdsIcon icon="format_underline" size="small" />
          </EmwButton>
          <Divider orientation="vertical" flexItem />
          {/* hide this until implementation is ready */}
          {/* <Grid item className="mr-xs ml-s flex">
        <EmwTypography fontSize="9" classes="mr-xxs mt-xs">
          FONT SIZE
        </EmwTypography>
        <EmwFontSizeDropdown selectedFontSize={12} handleFontSize={handleFontSize} disabled />
      </Grid> */}
          <Grid item className="mr-xs ml-s flex">
            <EmwTypography fontSize="9" classes="mr-xxs mt-xs">
              FONT
            </EmwTypography>
            <EmwColorDropdown
              visible={false}
              height={'32px'}
              defaultColor={'#000000'}
              handleColorChange={handleFontColor}
              isFont={true}
            />
          </Grid>
          <Grid item className="mr-s flex">
            <EmwTypography fontSize="9" classes="mr-xxs mt-xs">
              BG
            </EmwTypography>
            <EmwColorDropdown
              visible={false}
              height={'32px'}
              defaultColor={defaultBgColor}
              handleColorChange={handleBackgroundColor}
              isBg={true}
            />
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item></Grid>
        </>
      )}
      {canAdd && (
        <EmwButton
          variant="ghost"
          title="Add new line"
          size="small"
          color="primary"
          onClick={handleAddNewLine}
          classes="border-solid border-thin border-2 ml-s"
        >
          <TdsIcon icon="add" size="small" className="ml-3xs mr-3xs" />
        </EmwButton>
      )}
      <EmwButton
        variant="ghost"
        title="Undo changes"
        size="small"
        color="primary"
        onClick={handleUndo}
        classes="border-solid border-thin border-2 ml-s"
      >
        <TdsIcon icon="undo" size="small" className="ml-3xs mr-3xs" />
      </EmwButton>
      <EmwButton
        variant="ghost"
        title="Redo changes"
        size="small"
        color="primary"
        classes="ml-xxs border-solid border-thin border-2"
        onClick={handleRedo}
      >
        <TdsIcon icon="redo" size="small" className="ml-3xs mr-3xs" />
      </EmwButton>
    </Grid>
  );
}
