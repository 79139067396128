export const eeiFormColumns = [
  {
    title: 'GBU',
    type: 'dropdown',
    data: 'gbu',
    excelHeader: 'GBU',
  },
  {
    title: 'BL',
    type: 'dropdown',
    data: 'bl',
    excelHeader: 'BL',
  },
  {
    title: 'Countries',
    type: 'dropdown',
    data: 'country',
    excelHeader: 'COUNTRIES',
  },
  {
    title: 'CC',
    type: 'dropdown',
    data: 'cc',
    excelHeader: 'CC',
  },
  {
    title: 'Period',
    type: 'dropdown',
    source: ['Q1', 'Q2', 'Q3', 'Q4', 'EOY N-1'],
    data: 'period',
    excelHeader: 'PERIOD',
  },
  {
    title: 'CFRD/SFRD',
    type: 'dropdown',
    data: 'cfrd',
    source: ['CFRD', 'SFRD'],
    excelHeader: 'CFRD',
  },
  {
    title: 'NB PJT',
    type: 'text',
    data: 'nbPjt',
    excelHeader: 'NB_PJT',
  },
  {
    title: 'PROJECT_ID',
    type: 'text',
    data: 'projectExternalId',
    excelHeader: 'PROJECT_ID',
  },
  {
    title: 'PROJECT_NAME',
    type: 'text',
    data: 'projectName',
    excelHeader: 'PROJECT_NAME',
  },
  {
    title: 'CBB EOY',
    type: 'text',
    data: 'cbbEoy',
    excelHeader: 'CBB_EOY',
  },
  {
    title: 'Actuals EOY',
    type: 'text',
    data: 'actualsEoy',
    excelHeader: 'ACTUALS_EOY',
  },
  {
    title: 'EAC EOY',
    type: 'text',
    data: 'eacEoy',
    excelHeader: 'EAC_EOY',
  },
  {
    title: 'CBB',
    type: 'text',
    data: 'cbb',
    excelHeader: 'CBB',
  },
  {
    title: 'ACTUALS',
    type: 'text',
    data: 'actuals',
    excelHeader: 'ACTUALS',
  },
  {
    title: 'ETC',
    type: 'text',
    data: 'etc',
    excelHeader: 'ETC',
  },
  {
    title: 'EAC',
    type: 'text',
    data: 'eac',
    excelHeader: 'EAC',
  },
  {
    title: 'EACadj',
    type: 'text',
    data: 'eacAdj',
    excelHeader: 'EACADJ',
  },
  {
    title: 'EEI (%)',
    type: 'text',
    data: 'eei',
    excelHeader: 'EEI',
  },
  {
    title: 'EACAdj-EAC',
    type: 'text',
    data: 'eacAdjEac',
    excelHeader: 'EACADJ_EAC',
  },
  {
    title: 'Target EEI',
    type: 'text',
    data: 'target',
    excelHeader: 'TARGET_EEI',
  },
];

export const formatColumns = data => {
  eeiFormColumns.forEach(column => {
    if (column.title === 'GBU') {
      column.source = [data.gbuName];
    }
    if (column.title === 'BL') {
      column.source = data.listOfBls;
    }
    if (column.title === 'CC') {
      column.source = data.listOfCCs;
    }
    if (column.title === 'Countries') {
      column.source = data.listOfCountries;
    }
  });
  return eeiFormColumns;
};
