export const GHOST_BUTTON_CLASS = 'qtm-btn qtm-ghost qtm-neutral';
export const PRIMARY_BUTTON_CLASS = 'qtm-btn qtm-filled qtm-primary';
export const PREVIOUS_BUTTON_CLASS = 'qtm-btn qtm-outline qtm-primary previous';
export const RIGHT_START = 'right-start';
export const BOTTOM_START = 'bottom-start';
export const LEFT_START = 'left-start';
export const BTN_EXPORT_ORG_ID = '#btnExportOrg';
export const BTN_GUESTS_ID = '#btnGuests';
export const BTN_CLOSE_MODAL = '#btnCloseModal';
export const BTN_CLOSE_MODAL_ID = '#btnClose-modal';
export const BTN_EXPORT_CLOSE = '#btnExportClose';
export const TITLE_EXPORT_OPTIONS = 'Export Options';
export const BTN_EXPAND_DELIVERIES =
  '#tour-orgCard-Section2DeliveriesPanel #btnEditViewExpandDeliveries';
export const BTN_EXPAND_DELIVERY_0 = '#tour-orgCard-Section2DeliveriesPanel #btnExpand-delivery-0';
export const BTN_EXPAND_WP = '#btnExpandWorkPackages';
export const BTN_EXPAND_WPM_0 = '#btnExpand-wpm-0';
export const BTN_EXPAND_DISCIPLINES =
  '#tour-orgCard-Section3DisciplinesPanel #btnExpandDisciplines';
export const BTN_EDIT_DELIVERY = '#btnEdit-delivery-0';
export const BTN_EXPAND_SOLUTIONS = '#btnExpandSolutions';
export const BTN_EXPAND_DELIVERY_SOLUTION = '#btnExpand-delivery-0-solution-0';
export const BTN_ALLOCATE_CC = '#btnAllocateCC';
export const BTN_ADD_NEW_CC = '#btnAddNewCC';
export const BTN_CANCEL_EEI = '#btnCancelEEI';
export const BTN_UPLOAD_DOWNLOAD_KPI = "Upload/Download Kpi's data button";
