import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import { TdsTextInput } from '@tds/react';

const Root = styled('div')(({ theme }) => ({}));

export default function EmwTextField(props) {
  const [value, setValue] = useState(props.name ? props.name : '');
  const ref = React.useRef();
  const {
    name,
    atributes,
    textFieldStyle,
    inputId,
    onTextChange,
    defaultValue,
    size,
    trailingIcon,
    type,
    onBlur,
  } = props;

  useEffect(() => {
    if (ref.current && inputId) {
      setTimeout(() => {
        try {
          ref.current.querySelector('input').focus();
        } catch (e) {
          //hack to bypass tdsinput autoFocus
        }
      }, 100);
    }
  }, [ref, inputId]);

  const handleInputName = event => {
    setValue(event.target.value);

    if (typeof onTextChange === 'function') {
      onTextChange(event.target.value, inputId);
    }
  };

  const handleBlur = e => {
    if (typeof onBlur === 'function') {
      onBlur(e);
    }
  };

  return (
    <Root>
      {atributes.disabled && <div style={{ marginTop: '5px', fontSize: '1.1rem' }}>{name}</div>}
      {!atributes.disabled && (
        <TdsTextInput
          ref={ref}
          {...atributes}
          value={value} // Default value doesn't work
          size={size}
          inputProps={{
            style: textFieldStyle,
          }}
          type={type ? type : 'text'}
          trailingIcon={trailingIcon ? trailingIcon : ''}
          placeholder={defaultValue}
          onBlur={handleBlur}
          onValueChanged={handleInputName}
        />
      )}
    </Root>
  );
}
