import React, { useRef, useContext } from 'react';
import './styled';
import { getWfbDataForOrganizationApi, uploadWFBApi } from '../../../../redux/api';
import { orgDetailsSelector } from '../../../../redux/selectors';
import { useSelector } from 'react-redux';
import EmwHotTable from '../../../../../../components/EmwHotTable/EmwHotTable';
import { computeURL, fetchOptions } from '../../../../../../../util/fetch';
import deepdash from 'deepdash';
import { HyperFormula } from 'hyperformula';
import { HzaContext } from '../../../../../hzaSync/context/HzaContext';

deepdash(_);

export default function WFBForm({
  id,
  selectedOption,
  selectedYear,
  selectedKPI,
  regionName,
  countryName,
  handleUpload,
  errorComments,
  errorFileName,
  getKPIErrors,
  isOpen,
  setIsOpen,
  handleDelete,
}) {
  const orgDetails = useSelector(orgDetailsSelector);
  const cardDetails = orgDetails.list.data.cardDetails;
  const hotRef = useRef(null);
  const orgId = cardDetails.id;
  const type = cardDetails.type;
  const hzaContext = useContext(HzaContext);

  const errorMapping = {
    actualQ1ErrorMessage: 'Q1act',
    actualQ2ErrorMessage: 'Q2act',
    actualQ3ErrorMessage: 'Q3act',
    actualQ4ErrorMessage: 'Q4act',
    forecastQ1ErrorMessage: 'Q1for',
    forecastQ2ErrorMessage: 'Q2for',
    forecastQ3ErrorMessage: 'Q3for',
    forecastQ4ErrorMessage: 'Q4for',
    targetQ1ErrorMessage: 'Q1tar',
    targetQ2ErrorMessage: 'Q2tar',
    targetQ3ErrorMessage: 'Q3tar',
    targetQ4ErrorMessage: 'Q4tar',
  };

  const wfbHotSettings = {
    id: id,
    height: '98%',
    formulas: {
      engine: HyperFormula,
      sheetId: 1,
      sheetName: 'Sheet 1',
    },
    comments: true,
    width: 'auto',
    ref: hotRef,
    filters: true,
    dropdownMenu: true,
    rowHeaders: true,
    fixedColumnsLeft: 4,
    colHeaders: true,
    autoColumnSize: true,
    autoRowSize: true,
    columnSorting: true,
    undo: true,
    colWidths(index) {
      if (index === 0) {
        return 85;
      }
      if (index === 1) {
        return 135;
      }
      if (index === 3) {
        return 225;
      }
    },
    rowHeaderWidth: 30,
    allowInsertColumn: false,
    allowInsertRow: false,
    allowRemoveColumn: false,
    allowRemoveRow: true,
    redo: true,
    manualColumnResize: true,
    className: 'className',
    licenseKey: 'non-commercial-and-evaluation',
    selectionMode: 'multiple',
  };

  const loadData = async () => {
    const payload = {
      orgId: regionName ? '' : orgId,
      type: type,
      selectedYear: selectedYear,
      selectedKPI: selectedKPI,
      regionName: regionName,
      country: '',
    };
    const api = computeURL('wfb', 'getWfbDataForOrganization');
    const params = fetchOptions({ method: 'PUT' });
    params.body = JSON.stringify(payload);

    await hzaContext.handleRequest(api, params, 'getWfbDataForOrganization', true, true);
  };

  const getRowsWithErrors = data => {
    const errorData = data.workForceDetailRowErrorDtoList;
    const rowsWithErrors = [];
    if (errorData.length > 0) {
      errorData.forEach(error => {
        const errorsExist = Object.values(error).some(errorMessage => !!errorMessage);

        if (errorsExist) {
          rowsWithErrors.push(error);
        }
      });
    }
    return rowsWithErrors;
  };

  const getRowWithErrorIndex = (tableData, rowWithError) => {
    const rowWithErrorIndex = tableData.findIndex(
      obj =>
        obj.gbu === rowWithError.gbu &&
        obj.bl === rowWithError.bl &&
        obj.cc === rowWithError.cc &&
        obj.countries === rowWithError.country
    );

    return rowWithErrorIndex;
  };

  const getColsWithError = rowWithError => {
    const errorMappingKeys = Object.keys(errorMapping).filter(prop => rowWithError[prop] !== null);

    if (errorMappingKeys.length > 0) {
      return errorMappingKeys.map(errorMappingKey => {
        const tableCol = errorMapping[errorMappingKey];
        const entryType = rowWithError.entryType;
        const colName = `${entryType}.${tableCol}`;
        return colName;
      });
    }
  };

  const getErrorCommentValue = rowWithError => {
    const errorMappingKey = Object.keys(errorMapping).find(prop => rowWithError[prop] !== null);
    const errorCommentValue = rowWithError[errorMappingKey];
    return errorCommentValue;
  };

  const populateTable = data => {
    const finalData = [];
    data.forEach(values => {
      const rowObj = {};
      rowObj.gbu = values.gbu;
      rowObj.bl = values.bl;
      rowObj.countries = values.countries;
      rowObj.cc = values.cc;

      values.entryTypes.forEach(entryType => {
        const obj = {};
        entryType.quarters.forEach(quarter => {
          if (entryType.hasTarget) {
            obj[`${quarter.name}tar`] = quarter.target;
          }
          obj[`${quarter.name}for`] = quarter.forecast;
          obj[`${quarter.name}act`] = quarter.actual;
        });

        rowObj[entryType.name] = obj;
      });

      finalData.push(rowObj);
    });

    return finalData;
  };

  return (
    <EmwHotTable
      id="wfbHotTable"
      wfbHotSettings={wfbHotSettings}
      getApi={getWfbDataForOrganizationApi}
      saveApi={uploadWFBApi}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      orgId={orgId}
      type={type}
      selectedYear={selectedYear}
      selectedKPI={selectedKPI}
      regionName={regionName}
      selectedOption={selectedOption}
      countryName={countryName}
      cardDetails={cardDetails}
      handleUpload={handleUpload}
      errorComments={errorComments}
      errorFileName={errorFileName}
      getKPIErrors={getKPIErrors}
      loadWFBData={loadData}
      populateTable={populateTable}
      getRowsWithErrors={getRowsWithErrors}
      getRowWithErrorIndex={getRowWithErrorIndex}
      getColsWithError={getColsWithError}
      getErrorCommentValue={getErrorCommentValue}
      handleDelete={handleDelete}
    />
  );
}
