import React, { useEffect, useState } from 'react';
import AdministrationToggle from '../../../../../AdministrationToggle/AdministrationToggle';
import { TdsDivider } from '@tds/react';
import { useLoadDependencies } from '../../../../../../../header/components/useLoadDependencies';
import { putUpdateSettingsApi } from '../../../../../../redux/api';
import useAppSnackbar from '../../../../../../../../hooks/useAppSnankbar';

export default function NotificationSection({ user }) {
  const { init } = useLoadDependencies();
  const snackBar = useAppSnackbar();
  const [checked, setChecked] = useState(user.suNotifications);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setChecked(user.suNotifications);
  }, [user.suNotifications]);

  const handleCheck = async () => {
    try {
      setLoading(true);
      setChecked(!checked);
      await putUpdateSettingsApi({ suNotifications: !user.suNotifications });
      await init();
    } catch (reason) {
      snackBar.show(reason.message, 'error');
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <div className="flex justify-between mt-s" id="adminToold-notigications">
        <p className="text-scale-6 text-blugrey-500">Notifications to Super Users</p>

        <AdministrationToggle checked={checked} handleChecked={handleCheck} disabled={loading} />
      </div>

      <TdsDivider size="small" />
    </>
  );
}
