import { computeURL, fetch, fetchOptions } from '../../../../util/fetch';

export const getOrganizationsDataCollectionIndicatorsApi = async payload => {
  const url = computeURL('organization', 'getOrganizationsDataCollectionIndicators');
  const body = JSON.stringify(payload);
  const method = 'POST';

  return fetch(url, fetchOptions({ method, body }));
};
export const getOrganizationsDataCollectionStatusApi = async payload => {
  const url = computeURL('organization', 'organizationsDataCollectionStatus');
  const body = JSON.stringify(payload);
  const method = 'POST';

  return fetch(url, fetchOptions({ method, body }));
};
