import React, { useEffect } from 'react';
import { computeURL, fetchOptions } from '../util/fetch';

/**
 * @deprecated
 * @returns {JSX.Element}
 * @constructor
 */
export default function Logout() {
  // TODO: Move into api.js
  const logout = async () => {
    const url = computeURL('security', 'logout');
    const method = 'GET';
    return fetch(url, fetchOptions({ method }));
  };

  useEffect(() => {
    logout();
  }, []);

  return <div />;
}
