import React, { useEffect, useState } from 'react';
import { Box, Collapse, Grid, IconButton } from '@mui/material';
import EmwTypography from '../EmwTypography/EmwTypography';
import PlusIcon from '../../../lib/icons/PlusIcon';
import { StyledEmwToggleMenu } from './styled';
import { TdsIcon } from '@tds/react';
import { tourStatusSelector } from '../../features/organization/redux/selectors';
import { useSelector } from 'react-redux';

export default function EmwToggleMenu({
  name,
  children,
  addItem,
  canEdit,
  shouldOpen,
  id,
  showCount,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const tour = useSelector(tourStatusSelector);

  useEffect(() => {
    if (shouldOpen) {
      setIsOpen(shouldOpen);
    }
  }, [shouldOpen]);

  useEffect(() => {
    if (tour) {
      setIsOpen(true);
    }
  }, [tour]);

  return (
    <StyledEmwToggleMenu>
      <Grid container className={`toggle-menu section-${name} tour-${id} mt-xs`}>
        <EmwTypography classes="text-bluegrey-600 name" fontSize="9">
          {name}
        </EmwTypography>
        <Grid item className="ml-auto">
          <Grid container className="items-center">
            <EmwTypography classes="text-bluegrey-600 count" fontSize="9">
              {showCount && <p id={`${name}_HC`}>{children && children.length}</p>}
            </EmwTypography>
            <IconButton
              title={`${isOpen ? 'Collapse section' : 'Expand section'}`}
              disableRipple
              size="small"
              id={`btnToggle${id}`}
              onClick={() => setIsOpen(!isOpen)}
              sx={{ marginLeft: 'auto' }}
            >
              {isOpen ? (
                <TdsIcon icon="keyboard_arrow_up" size="small" style={{ color: '#4A4D65' }} />
              ) : (
                <TdsIcon icon="keyboard_arrow_down" size="small" style={{ color: '#4A4D65' }} />
              )}
            </IconButton>
            {canEdit && (
              <IconButton
                title="Add new"
                sx={{ padding: 0, marginRight: '8px' }}
                disableRipple
                onClick={addItem}
                id={`btnAdd${id}`}
              >
                <PlusIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Collapse in={isOpen} sx={{ width: '100%' }}>
        {children}
      </Collapse>
    </StyledEmwToggleMenu>
  );
}
