import React, { useContext, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, DialogActions, DialogContent, DialogTitle, Tooltip } from '@mui/material';
import { EmwButton } from '../../../lib/common';
import { TdsButton, TdsIcon, TdsTypography } from '@tds/react';
import { ShepherdTourContext } from 'react-shepherd';
import EmwDialog from '../../../lib/commonv2/EmwDialog';
import tourLogo from '../../../styles/images/start-tour-logo.png';
import { useLocation } from 'react-router-dom-v5-compat';
import { useDispatch, useSelector } from 'react-redux';
import { changeView, setOrgDetails, tourStatus } from '../../features/organization/redux/actions';
import {
  orgDetailsSelector,
  perspectiveSelector,
  viewSelector,
} from '../../features/organization/redux/selectors';
import { ORGANIZATION_PERSPECTIVE, ORGANIZATION_VIEW } from '../../features/organization/constants';
import buildCCSteps from './OrganizationTour/ccSteps';
import { buildFavoritesByGbuSteps } from './OrganizationTour/favoritesByGbuSteps.js';
import { buildFavoritesByCountrySteps } from './OrganizationTour/favoritesByCountrySteps.js';
import { buildThalesNodeSteps } from './OrganizationTour/thalesNodeSteps.js';
import { buildRegionSteps } from './OrganizationTour/regionSteps';
import { buildGbuSteps } from './OrganizationTour/gbuSteps';
import { buildBlSteps } from './OrganizationTour/blSteps';
import { buildCountrySteps } from './OrganizationTour/countrySteps';
import { buildCbuSteps } from './OrganizationTour/cbuSteps';
import { buildDomainSteps } from './OrganizationTour/domainSteps';
import { buildOrganizationEditBtnSteps } from './OrganizationTour/organizationEditBtnSteps';
import { buildThalesNodeGroupSteps } from './OrganizationTour/thalesNodeGroupAditionalSteps';
import { buildLandingPageSteps } from './LandingPageTour/landingPageSteps.js';
import * as api from '../../features/organization/redux/api';
import EmwTooltip from '../EmwToggleMenu/EmwTooltip';
import { hzaIframeSelector } from '../../features/header/redux/selectors.js';

const StyledUserGuide = styled('div')(() => ({
  '.close-guide': {
    color: '#BCBBBB',
    position: 'absolute',
    top: '0px',
    right: '0px',
  },
}));

const ORGANIZATION_URL = '/organization';
const LANDING_PAGE_URL = '/landingpage';

export default function UserGuideButton() {
  const hzaIframeLoaded = useSelector(hzaIframeSelector);
  const tour = useContext(ShepherdTourContext);
  const [isOpen, setIsOpen] = useState(false);
  const [canTour, setCanTour] = useState(false);
  const [welcomeMessage, setWelcomeMessage] = useState('');
  const dispatch = useDispatch();
  const location = useLocation();
  const { list } = useSelector(orgDetailsSelector);
  const [steps, setSteps] = useState([]);
  const currentView = useSelector(viewSelector);
  const perspective = useSelector(perspectiveSelector);

  useEffect(() => {
    (async () => {
      if (perspective === ORGANIZATION_PERSPECTIVE.BY_GBU) {
        const org = await api.getOrganizationDetailsApi({
          id: '00053af6-7fba-4db8-8572-2d7e23332b6e',
          type: 'GROUP',
          onlyChild: true,
        });
        dispatch(setOrgDetails({ list: org, error: null, loading: false }));
      }

      if (perspective === ORGANIZATION_PERSPECTIVE.BY_COUNTRY) {
        const org = await api.getOrganizationDetailsApi({
          id: '00053af6-7fba-4db8-8572-2d7e23332b6e',
          type: 'GROUP_COUNTRY',
          onlyChild: true,
        });
        dispatch(setOrgDetails({ list: org, error: null, loading: false }));
      }
    })();
  }, []);

  useEffect(() => {
    if ([LANDING_PAGE_URL, ORGANIZATION_URL].includes(location.pathname)) {
      setCanTour(true);
    } else {
      setCanTour(false);
    }

    setWelcomeMessage(() => {
      if (location.pathname === ORGANIZATION_URL) {
        return `
          This is your interactive user guide for the Engineering Organization.
        `;
      }

      return `
          This is your interactive user guide.
      `;
    });
  }, [location]);

  const section2DeliveriesHandler = buttonType => {
    if (
      list == null ||
      list.data == null ||
      list.data.section2 == null ||
      list.data.section2.deliveries == null ||
      list.data.section2.deliveries[0] == null
    ) {
      return false;
    }
    if (buttonType === 'edit') {
      return list.data.section2.deliveries[0].btnEdit.visible;
    }
    if (buttonType === 'add') {
      return list.data.section2.deliveries[0].btnAdd.visible;
    }
  };

  const section3DisciplinesHandler = buttonType => {
    if (
      list == null ||
      list.data == null ||
      list.data.section3 == null ||
      list.data.section3.disciplines == null ||
      list.data.section3.disciplines[0] == null
    ) {
      return false;
    }
    if (buttonType === 'edit') {
      return list.data.section3.disciplines[0].btnEdit.visible;
    }
    if (buttonType === 'add') {
      return list.data.section3.disciplines[0].btnAdd.visible;
    }
  };

  const generateSteps = (
    showExcoms,
    showPowerGuests,
    showGuests,
    excoms,
    guests,
    powerGuests,
    btnEditGuests
  ) => {
    const dinamycStep = [];
    const text = 'Engineering Organization Card Action';
    const orgType = list.data.cardDetails.type;

    if (location.pathname === ORGANIZATION_URL) {
      if (currentView === ORGANIZATION_VIEW.FAVORITES && orgType === 'GROUP') {
        buildFavoritesByGbuSteps(dinamycStep, tour);
      }
      if (currentView === ORGANIZATION_VIEW.FAVORITES && orgType === 'GROUP_COUNTRY') {
        buildFavoritesByCountrySteps(dinamycStep, tour);
      }

      if (!list.data.section1) {
        return dinamycStep.map((step, index) => {
          if (index === dinamycStep.length - 1) {
            step.buttons[2].text = 'Finish';
          }
          return step;
        });
      }

      const hasBtnEdit = list.data.section1.btnEdit.visible;
      const hasBtnEditEnabled = list.data.section1.btnEdit.enabled;
      const hasBtnAddNewCC = list.data.section1.btnAddChild.visible;
      const hasBtnAllocateCC = list.data.section1.btnAlocateCC.visible;
      const hasBtnFavorite = list.data.section1.btnFavorite.visible;
      const hasBtnWarning = list.data.section1.warnings.length > 0;
      const hasBtnGuests = list.data.section1.btnGuests.visible;
      const hasBtnDelete = list.data.section1.btnDelete.visible;
      const hasBtnPublish = list.data.section1.btnPublish.visible;
      const hasBtnExport = list.data.section1 && perspective === ORGANIZATION_PERSPECTIVE.BY_GBU;
      const hasDeliveries = list.data.section2.deliveries.length > 0;
      const hasDisciplines = list.data.section3.disciplines.length > 0;
      const hasSolutions = hasDeliveries && list.data.section2.deliveries[0].solutions[0].visible;
      const hasEdmManager = hasDeliveries && list.data.section2.deliveries[0].managers[0].visible;
      const hasWpmManager = hasDeliveries && list.data.section2.deliveries[0].managers[1].visible;
      const hasCers = list.data.sectionCer.visible;
      const hasCCTypes = list.data.section1.type.visible;
      const hasEngDomains =
        list.data.section1.hardware.visible ||
        list.data.section1.software.visible ||
        list.data.section1.system.visible ||
        list.data.section1.industry.visible;
      const hasDates = list.data.section1.date.visible;
      const hasBtnReset = list.data.section1.btnUndo.visible;
      const hasBtnSave = list.data.section1.btnSave.visible;
      const hasEms = list.data.section1.ems[0].visible;
      const hasEmsEditButton = list.data.section1.ems[0].btnEdit.visible;
      const hasHoEOs = list.data.section1.hoEOs[0].visible;
      const hasCountries = list.data.section1.countries.visible;
      const hasHc = list.data.section1.hc.visible;
      const hasHcDeclared = list.data.section1.hcDeclared.visible;
      const btnEEI = orgType === 'GROUP' ? list.data.section1.btnEEI.visible : false;
      const name = list.data.section1.name.value;
      const canAddDelivery = section2DeliveriesHandler('add');
      const canEditDelivery = section2DeliveriesHandler('edit');
      const canAddDiscipline = section3DisciplinesHandler('add');
      const canEditDiscipline = section3DisciplinesHandler('edit');
      const btnWarningsTitle = 'Warnings button';
      const btnFavoriteTitle = 'Add to favorite button';
      const btnManageGPGTitle = 'Manage Guest/Power Guest button';
      const btnPublishTitle = 'Delete button';
      const btnDeleteTitle = 'Publish button';
      const btnEditTitle = 'Edit button';
      const tourStepback = false;

      if (
        currentView === ORGANIZATION_VIEW.DETAILS &&
        (orgType === 'GROUP_COUNTRY' || orgType === 'GROUP')
      ) {
        buildThalesNodeSteps(dinamycStep, tour);
        buildThalesNodeGroupSteps(
          dinamycStep,
          tour,
          hasBtnWarning,
          btnWarningsTitle,
          hasBtnFavorite,
          btnFavoriteTitle,
          hasBtnAllocateCC,
          text,
          hasBtnGuests,
          btnManageGPGTitle,
          hasBtnEdit,
          btnEditTitle,
          hasHc,
          hasHcDeclared,
          hasCountries,
          hasEms,
          hasHoEOs,
          hasBtnEditEnabled,
          hasBtnExport,
          btnEEI,
          btnEditGuests,
          hasBtnAddNewCC,
          hzaIframeLoaded,
          showExcoms,
          showPowerGuests,
          showGuests
        );
        if (hasBtnEdit && hasBtnEditEnabled) {
          buildOrganizationEditBtnSteps(
            dinamycStep,
            tour,
            hasDisciplines,
            tourStepback,
            list,
            orgType,
            hasDeliveries,
            hasEmsEditButton,
            canAddDelivery,
            canEditDelivery,
            hasCers,
            canAddDiscipline,
            canEditDiscipline,
            hasCCTypes,
            hasHc,
            hasHcDeclared,
            hasEngDomains,
            hasDates,
            hasBtnReset,
            hasBtnSave,
            hasSolutions,
            showExcoms,
            showGuests,
            showPowerGuests,
            hasBtnAddNewCC,
            excoms,
            guests,
            powerGuests,
            btnEditGuests,
            hasBtnAllocateCC,
            hasBtnGuests,
            hasBtnExport,
            btnEEI,
            hzaIframeLoaded
          );
        }
      }
      if (orgType === 'GBU') {
        buildGbuSteps(
          dinamycStep,
          tour,
          name,
          hasBtnWarning,
          btnWarningsTitle,
          hasBtnFavorite,
          btnFavoriteTitle,
          hasBtnAllocateCC,
          text,
          hasBtnGuests,
          btnManageGPGTitle,
          hasBtnAddNewCC,
          hasBtnEdit,
          hasHc,
          hasHcDeclared,
          hasCountries,
          hasEms,
          hasHoEOs,
          hasCers,
          hasDeliveries,
          hasDisciplines,
          hasBtnEditEnabled,
          hasBtnExport,
          btnEEI,
          btnEditGuests,
          list,
          canAddDiscipline,
          canEditDelivery,
          showExcoms,
          showPowerGuests,
          showGuests
        );
        if (hasBtnEdit && hasBtnEditEnabled) {
          buildOrganizationEditBtnSteps(
            dinamycStep,
            tour,
            hasDisciplines,
            tourStepback,
            list,
            orgType,
            hasDeliveries,
            hasEmsEditButton,
            canAddDelivery,
            canEditDelivery,
            hasCers,
            canAddDiscipline,
            canEditDiscipline,
            hasCCTypes,
            hasHc,
            hasHcDeclared,
            hasEngDomains,
            hasDates,
            hasBtnReset,
            hasBtnSave,
            hasSolutions,
            showExcoms,
            showGuests,
            showPowerGuests,
            hasBtnAddNewCC,
            excoms,
            guests,
            powerGuests,
            btnEditGuests,
            hasBtnAllocateCC,
            hasBtnGuests,
            hasBtnExport,
            btnEEI,
            hzaIframeLoaded
          );
        }
      }
      if (orgType === 'BL') {
        buildBlSteps(
          dinamycStep,
          tour,
          name,
          hasBtnWarning,
          btnWarningsTitle,
          hasBtnFavorite,
          btnFavoriteTitle,
          hasBtnAllocateCC,
          text,
          hasBtnGuests,
          btnManageGPGTitle,
          hasBtnAddNewCC,
          hasBtnEdit,
          hasHc,
          hasHcDeclared,
          hasCountries,
          hasEms,
          hasHoEOs,
          hasCers,
          hasDeliveries,
          hasDisciplines,
          hasBtnEditEnabled,
          hasBtnExport,
          btnEEI,
          btnEditGuests,
          list,
          canAddDiscipline,
          canEditDelivery,
          showExcoms,
          showPowerGuests,
          showGuests
        );
        if (hasBtnEdit && hasBtnEditEnabled) {
          buildOrganizationEditBtnSteps(
            dinamycStep,
            tour,
            hasDisciplines,
            tourStepback,
            list,
            orgType,
            hasDeliveries,
            hasEmsEditButton,
            canAddDelivery,
            canEditDelivery,
            hasCers,
            canAddDiscipline,
            canEditDiscipline,
            hasCCTypes,
            hasHc,
            hasHcDeclared,
            hasEngDomains,
            hasDates,
            hasBtnReset,
            hasBtnSave,
            hasSolutions,
            showExcoms,
            showGuests,
            showPowerGuests,
            hasBtnAddNewCC,
            excoms,
            guests,
            powerGuests,
            btnEditGuests,
            hasBtnAllocateCC,
            hasBtnGuests,
            hasBtnExport,
            btnEEI,
            hzaIframeLoaded
          );
        }
      }
      if (orgType === 'CC') {
        buildCCSteps(
          dinamycStep,
          tour,
          hasBtnWarning,
          hasBtnFavorite,
          hasBtnAllocateCC,
          hasBtnGuests,
          hasBtnDelete,
          hasBtnPublish,
          hasBtnAddNewCC,
          hasBtnEdit,
          hasHc,
          hasHcDeclared,
          hasCountries,
          hasEms,
          hasHoEOs,
          hasCers,
          hasDeliveries,
          name,
          btnWarningsTitle,
          btnFavoriteTitle,
          text,
          btnManageGPGTitle,
          btnDeleteTitle,
          btnPublishTitle,
          btnEditTitle,
          hasBtnEditEnabled,
          hasDisciplines,
          hasBtnExport,
          btnEEI,
          perspective,
          btnEditGuests,
          list,
          canAddDiscipline,
          canEditDelivery,
          showExcoms,
          showPowerGuests,
          showGuests
        );
        if (hasBtnEdit && hasBtnEditEnabled) {
          buildOrganizationEditBtnSteps(
            dinamycStep,
            tour,
            hasDisciplines,
            tourStepback,
            list,
            orgType,
            hasDeliveries,
            hasEmsEditButton,
            canAddDelivery,
            canEditDelivery,
            hasCers,
            canAddDiscipline,
            canEditDiscipline,
            hasCCTypes,
            hasHc,
            hasHcDeclared,
            hasEngDomains,
            hasDates,
            hasBtnReset,
            hasBtnSave,
            hasSolutions,
            showExcoms,
            showGuests,
            showPowerGuests,
            hasBtnAddNewCC,
            excoms,
            guests,
            powerGuests,
            btnEditGuests,
            hasBtnAllocateCC,
            hasBtnGuests,
            hasBtnExport,
            btnEEI,
            hzaIframeLoaded,
            hasEdmManager,
            hasWpmManager
          );
        }
      }
      if (orgType === 'REGION') {
        buildRegionSteps(
          dinamycStep,
          tour,
          list,
          hasBtnWarning,
          btnWarningsTitle,
          hasBtnFavorite,
          btnFavoriteTitle,
          text,
          hasBtnGuests,
          btnManageGPGTitle,
          hasBtnAddNewCC,
          hasBtnEdit,
          hasHc,
          hasHcDeclared,
          hasEms,
          hasHoEOs,
          hasBtnEditEnabled,
          btnEditGuests,
          showExcoms,
          showPowerGuests,
          showGuests
        );
        if (hasBtnEdit && hasBtnEditEnabled) {
          buildOrganizationEditBtnSteps(
            dinamycStep,
            tour,
            hasDisciplines,
            tourStepback,
            list,
            orgType,
            hasDeliveries,
            hasEmsEditButton,
            canAddDelivery,
            canEditDelivery,
            hasCers,
            canAddDiscipline,
            canEditDiscipline,
            hasCCTypes,
            hasHc,
            hasHcDeclared,
            hasEngDomains,
            hasDates,
            hasBtnReset,
            hasBtnSave,
            hasSolutions,
            showExcoms,
            showGuests,
            showPowerGuests,
            hasBtnAddNewCC,
            excoms,
            guests,
            powerGuests,
            btnEditGuests,
            hasBtnAllocateCC,
            hasBtnGuests,
            hasBtnExport,
            btnEEI,
            hzaIframeLoaded
          );
        }
      }
      if (orgType === 'COUNTRY') {
        buildCountrySteps(
          dinamycStep,
          tour,
          orgType,
          name,
          hasBtnWarning,
          btnWarningsTitle,
          hasBtnFavorite,
          btnFavoriteTitle,
          hasBtnAllocateCC,
          text,
          hasBtnGuests,
          btnManageGPGTitle,
          hasBtnAddNewCC,
          hasBtnEdit,
          hasHc,
          hasHcDeclared,
          hasCountries,
          hasEms,
          hasHoEOs,
          hasCers,
          hasDeliveries,
          hasDisciplines,
          hasBtnEditEnabled,
          btnEEI,
          btnEditGuests,
          list,
          canAddDiscipline,
          canEditDelivery,
          showExcoms,
          showPowerGuests,
          showGuests
        );
        if (hasBtnEdit && hasBtnEditEnabled) {
          buildOrganizationEditBtnSteps(
            dinamycStep,
            tour,
            hasDisciplines,
            tourStepback,
            list,
            orgType,
            hasDeliveries,
            hasEmsEditButton,
            canAddDelivery,
            canEditDelivery,
            hasCers,
            canAddDiscipline,
            canEditDiscipline,
            hasCCTypes,
            hasHc,
            hasHcDeclared,
            hasEngDomains,
            hasDates,
            hasBtnReset,
            hasBtnSave,
            hasSolutions,
            showExcoms,
            showGuests,
            showPowerGuests,
            hasBtnAddNewCC,
            excoms,
            guests,
            powerGuests,
            btnEditGuests,
            hasBtnAllocateCC,
            hasBtnGuests,
            hasBtnExport,
            btnEEI,
            hzaIframeLoaded
          );
        }
      }
      if (orgType === 'CBU') {
        buildCbuSteps(
          dinamycStep,
          tour,
          orgType,
          name,
          btnEditTitle,
          hasHc,
          hasHcDeclared,
          hasCountries,
          hasBtnWarning,
          btnWarningsTitle,
          hasBtnGuests,
          btnManageGPGTitle,
          hasBtnEdit,
          hasBtnEditEnabled,
          text
        );
        if (hasBtnEdit && hasBtnEditEnabled) {
          buildOrganizationEditBtnSteps(
            dinamycStep,
            tour,
            hasDisciplines,
            tourStepback,
            list,
            orgType,
            hasDeliveries,
            hasEmsEditButton,
            canAddDelivery,
            canEditDelivery,
            hasCers,
            canAddDiscipline,
            canEditDiscipline,
            hasCCTypes,
            hasHc,
            hasHcDeclared,
            hasEngDomains,
            hasDates,
            hasBtnReset,
            hasBtnSave,
            hasSolutions,
            showExcoms,
            showGuests,
            showPowerGuests,
            hasBtnAddNewCC,
            excoms,
            guests,
            powerGuests,
            btnEditGuests,
            hasBtnAllocateCC,
            hasBtnGuests,
            hasBtnExport,
            btnEEI,
            hzaIframeLoaded
          );
        }
      }
      if (orgType === 'Domain') {
        buildDomainSteps(
          dinamycStep,
          tour,
          name,
          hasBtnWarning,
          btnWarningsTitle,
          btnEditTitle,
          hasBtnFavorite,
          btnFavoriteTitle,
          hasBtnGuests,
          btnManageGPGTitle,
          hasBtnAddNewCC,
          text,
          hasBtnEdit,
          hasBtnEditEnabled,
          hasHc,
          hasHcDeclared
        );
        if (hasBtnEdit && hasBtnEditEnabled) {
          buildOrganizationEditBtnSteps(
            dinamycStep,
            tour,
            hasDisciplines,
            tourStepback,
            list,
            orgType,
            hasDeliveries,
            hasEmsEditButton,
            canAddDelivery,
            canEditDelivery,
            hasCers,
            canAddDiscipline,
            canEditDiscipline,
            hasCCTypes,
            hasHc,
            hasHcDeclared,
            hasEngDomains,
            hasDates,
            hasBtnReset,
            hasBtnSave,
            hasSolutions,
            showExcoms,
            showGuests,
            showPowerGuests,
            hasBtnAddNewCC,
            excoms,
            guests,
            powerGuests,
            btnEditGuests,
            hasBtnAllocateCC,
            hasBtnGuests,
            hasBtnExport,
            btnEEI,
            hzaIframeLoaded
          );
        }
      }
    }

    if (location.pathname === LANDING_PAGE_URL) {
      buildLandingPageSteps(dinamycStep, tour);
    }

    return dinamycStep.map((step, index) => {
      if (index === dinamycStep.length - 1) {
        step.buttons[2].text = 'Finish';
      }
      return step;
    });
  };

  const handleStartTour = async () => {
    if (!canTour) {
      return setIsOpen(false);
    }
    tour.addSteps(steps);
    tour.start();
    dispatch(tourStatus(true));
    setIsOpen(false);
  };
  const handleClick = async () => {
    setIsOpen(true);
    if (currentView === ORGANIZATION_VIEW.GUEST) {
      dispatch(changeView(ORGANIZATION_VIEW.DETAILS));
    }

    const guestDetailsResp = await api.getOrgGuestDetailsApi({
      id: list.data.cardDetails.id,
      type: list.data.cardDetails.type,
      blCode: list.data.cardDetails.blCode,
      buCode: list.data.cardDetails.buCode,
      countryCode: list.data.cardDetails.countryCode,
    });

    if (list && list.hasOwnProperty('data')) {
      const showExcoms = guestDetailsResp.data.showExcoms;
      const showPowerGuests = guestDetailsResp.data.showPowerGuests;
      const showGuests = guestDetailsResp.data.showGuests;
      const excoms = guestDetailsResp.data.excoms.length;
      const guests = guestDetailsResp.data.guests.length;
      const powerGuests = guestDetailsResp.data.powerGuests.length;
      const btnEditGuests = guestDetailsResp.data.btnEdit.visible;

      setSteps(prevState => {
        // Clean up tour steps
        prevState.forEach((step, index) => {
          tour.removeStep(step.id);
          tour.hide();
        });
        return [
          ...generateSteps(
            showExcoms,
            showPowerGuests,
            showGuests,
            excoms,
            guests,
            powerGuests,
            btnEditGuests
          ),
        ];
      });
    }
  };

  const title = () => {
    return (
      <>
        <TdsTypography component="title-4" fontWeight="bold">
          User guide
        </TdsTypography>
        <br />
        <TdsTypography component="body-4">
          This interactive tour will guide you through the main functionality of the page.
        </TdsTypography>
      </>
    );
  };

  return (
    <>
      <EmwTooltip title={title()}>
        <EmwButton id="user-guide" size="small" variant="outline" onClick={handleClick}>
          <TdsIcon icon="info" />
          <TdsTypography>User Guide</TdsTypography>
        </EmwButton>
      </EmwTooltip>
      <EmwDialog open={isOpen} title="Welcome!" logoOff={true} logo={tourLogo}>
        <Box mt={1} />
        <DialogTitle>
          <StyledUserGuide>
            <EmwButton onClick={() => setIsOpen(false)} classes="close-guide">
              <TdsIcon icon="close"></TdsIcon>
            </EmwButton>
          </StyledUserGuide>
        </DialogTitle>
        <DialogContent sx={{ textAlign: 'center' }}>
          {canTour && (
            <>
              <TdsTypography component="body-2" classes="mt-s">
                {welcomeMessage}
              </TdsTypography>
              <TdsTypography component="body-2">
                Click Next to see what features are available.
              </TdsTypography>
            </>
          )}

          {!canTour && (
            <>
              <TdsTypography component="body-2">
                This guide is currently under development.
              </TdsTypography>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <EmwButton variant="filled" size="small" onClick={handleStartTour} id="guide-start">
            {canTour ? 'Next' : 'Close'}
          </EmwButton>
        </DialogActions>
      </EmwDialog>
    </>
  );
}
