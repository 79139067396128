import { Box, Container, Grid, styled } from '@mui/material';
import { getZoom } from '../utils/sizeUtils';

const zoom = getZoom();
export const StyledLoginContainer = styled(Container)(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100vh',
    minWidth: '100vw',
    overflow: 'auto',
  };
});

export const StyledLoginForm = styled(Grid)(({ theme }) => {
  return {
    zoom: `${zoom}%`,
    boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.25)',
    maxWidth: '354px',
    borderRadius: '10px',
    backgroundColor: '#ffffff',
    fontFamily: 'Roboto',

    '#login-form-wrapper': {
      maxWidth: '350px',
    },
  };
});

export const StyledThalesLogo = styled('img')(({ theme }) => {
  return {
    zoom: `${zoom}%`,
    marginTop: '75px',
    marginBottom: '149px',
    height: '32px',
    width: 'auto',
    cursor: 'pointer',
  };
});

export const StyledEmwLogo = styled('img')(({ theme }) => ({
  marginTop: '35px',
  marginBottom: '37.4px',
  height: '82px',
  width: 'auto',
  cursor: 'pointer',
}));

export const StyledTdsFormField = styled(Box)(({ theme }) => ({
  marginBottom: '13px',
  '& .form-label': {
    color: theme.palette.bluegrey[900],
  },
  '& .text-input': {
    'tds-icon:nth-of-type(2)': {
      color: theme.palette.red.main,
    },
  },
}));

export const StyledButtonItem = styled(Grid)(({ theme }) => ({
  width: '100%',
  marginBottom: '36px',
  '*': {
    width: '100%',
  },
}));
