import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import EmwToggleMenu from '../../../../../components/EmwToggleMenu/EmwToggleMenu';
import { guestDetailsSelector } from '../../../redux/selectors';
import GuestActionFormItem from './GuestActionFormItem';
import { G_PG } from '../../../constants';

export default function UserListForm({
  label,
  users,
  setUsers,
  canEdit,
  name,
  shouldReset,
  id,
  showCount,
}) {
  const [list, setList] = useState([]);
  const [shouldOpen, setShouldOpen] = useState(false);
  const guestDetails = useSelector(guestDetailsSelector);
  useEffect(() => {
    setList(() => {
      return users.map((item, index) => (
        <GuestActionFormItem
          key={index}
          index={index}
          label={label}
          values={item}
          isNew={false}
          canEdit={canEdit}
          addItem={addItem}
          removeItem={removeItem}
          handleChange={updateItem}
          id={id}
        />
      ));
    });
  }, []);

  useEffect(() => {
    if (shouldReset) {
      let initialList = [];
      if (name === G_PG.GUESTS.name.toUpperCase()) {
        initialList = guestDetails.list.data.guests;
      }
      if (name === G_PG.POWER_GUESTS.name.toUpperCase()) {
        initialList = guestDetails.list.data.powerGuests;
      }
      if (name === G_PG.EXCOMS.name.toUpperCase()) {
        initialList = guestDetails.list.data.excoms;
      }

      setList([]);
      setTimeout(() => {
        setList(() => {
          return initialList.map((item, index) => {
            return (
              <GuestActionFormItem
                key={index}
                index={index}
                label={label}
                values={item}
                isNew={false}
                canEdit={canEdit}
                addItem={addItem}
                removeItem={removeItem}
                handleChange={updateItem}
                id={id}
              />
            );
          });
        });
      }, 100);
    }
  }, [shouldReset]);

  function addItem() {
    setShouldOpen(true);
    const newItem = {
      id: null,
      effective: { value: null, enabled: true, visible: true },
      type: { value: null, enabled: true, visible: true },
      startDate: { value: null, enabled: true, visible: true },
      endDate: { value: null, enabled: true, visible: true },
      comment: { value: null, enabled: true, visible: true },
      name: { value: null, enabled: true, visible: true },
      btnAdd: { value: null, enabled: true, visible: true },
      btnEdit: { value: null, enabled: true, visible: true },
    };

    setUsers(currentUsers => {
      return [...currentUsers, newItem];
    });

    setList(currentList => {
      return [
        <GuestActionFormItem
          index={currentList.length}
          label={label}
          values={newItem}
          canEdit={true}
          isNew={true}
          addItem={addItem}
          removeItem={removeItem}
          handleChange={updateItem}
          id={id}
        />,
        ...currentList,
      ];
    });
  }

  const removeItem = currentIndex => {
    const filteredItems = [...list].filter((item, index) => {
      return index !== currentIndex;
    });

    setList(filteredItems);
  };

  const updateItem = (index, values) => {
    setUsers(users => {
      const currentUsers = [...users];
      currentUsers[index] = values;

      return currentUsers;
    });
  };

  return (
    <EmwToggleMenu
      shouldOpen={shouldOpen}
      children={list}
      name={name}
      label={label}
      addItem={addItem}
      canEdit={canEdit}
      id={id}
      showCount={showCount}
    />
  );
}
