import { loginRequest, securityLogin } from '../authConfig';
import MsalInstance from './MsalInstance';
import { computeURL, fetchOptions } from '../../../util/fetch';

export async function callMsGraph(url, params) {
  const accessToken = await getAccessToken();
  const { headers, ...rest } = params;
  headers.Authorization = `Bearer ${accessToken}`;

  const options = {
    method: 'GET',
    headers: headers,
    ...rest,
  };

  return fetch(url, options);
}

export const getAccessToken = async () => {
  if (localStorage.getItem('login.dev')) {
    return false;
  }
  const response = await MsalInstance.getInstance().acquireTokenSilent({
    ...loginRequest,
    account: MsalInstance.getInstance().getActiveAccount(),
  });
  return response.idToken;
};

export async function checkUserAccess() {
  let response;
  try {
    response = await securityLogin();
  } catch (reason) {
    response = reason;
  }
  return response.status;
}

export const logout = async () => {
  if (localStorage.getItem('login.dev')) {
    localStorage.clear();
    return (window.location.href = '/');
  }

  const logout = async () => {
    const url = computeURL('security', 'logout');
    const method = 'GET';
    return fetch(url, fetchOptions({ method }));
  };

  await logout();

  setTimeout(() => {
    localStorage.setItem('loggedOut', true);
  }, 200);

  await MsalInstance.getInstance().logoutRedirect();
};
