import { computeURL, fetch, fetchOptions, getUrlParam } from '../../util/fetch';

export const CLIENT_ID = 'azure.clientId';
export const TENANT_ID = 'azure.tenantId';

const isAutomaticTest = getUrlParam('isAutomaticTest');

if (isAutomaticTest) {
  localStorage.setItem('isAutomaticTest', 'true');
} else {
  localStorage.removeItem('isAutomaticTest');
}

export const loginRequest = {
  scopes: [isAutomaticTest ? '' : 'https://analysis.windows.net/powerbi/api/.default'],
};

export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};

export const securityLogin = async () => {
  const url = computeURL('security', 'login');
  const method = 'GET';
  return fetch(url, fetchOptions({ method }));
};

export const getIsAzure = async () => {
  const method = 'GET';

  return fetch(`/api/v1/emw/app/isAzure`, fetchOptions({ method, isPublic: true }));
};
