import React from 'react';
import EmwTypography from '../../../EmwTypography/EmwTypography';
import GeneralFiltersMore from '../GeneralFiltersMore/GeneralFiltersMore';
import GeneralFiltersTooltip from '../GeneralFiltersTooltip/GeneralFiltersTooltip';
import { CLASS_NAME_PREFIX } from '../../constants';

export default function GeneralFiltersWrapper({ children }) {
  return (
    <div className="flex-1 overflow-auto h-full">
      <div
        id={`${CLASS_NAME_PREFIX}filtersWrapper`}
        className={`${CLASS_NAME_PREFIX}filtersWrapper mt-xxs overflow-auto`}
      >
        <div className="px-xs mt-s">
          <div className="flex justify-between items-center">
            <GeneralFiltersTooltip
              title="Applies to the currently selected visual"
              placement="left"
            >
              <EmwTypography fontSize={8}>Filters on all pages</EmwTypography>
            </GeneralFiltersTooltip>
            <GeneralFiltersMore />
          </div>
          <>{children}</>
        </div>
      </div>
    </div>
  );
}
