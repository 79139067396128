import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { saveUserActivity } from '../redux/api';

import { clearUserActivity } from '../redux/actions';
import store from '../../../../store';

export default function useUserActivity() {
  const dispatch = useDispatch();

  const handleInterval = async () => {
    const interval = setInterval(async () => {
      const userActivity = store.getState().userActivity.USER_ACTIVITY;

      if (userActivity.length > 0) {
        try {
          const response = await saveUserActivity(userActivity);

          if (response.messageType === 'success') {
            const responseData = response.data.map(item => item.uniqueId);

            const filteredUserActivityList = userActivity.filter(
              activity => !responseData.includes(activity.uniqueId)
            );

            dispatch(clearUserActivity(filteredUserActivityList));
          }
        } catch (reason) {
          console.error('error', error);
        }
      }
    }, 5000);
  };

  useEffect(() => {
    (async () => handleInterval())();
    // return () => clearInterval(interval);
  }, []);
}
