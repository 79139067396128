import React, { useState } from 'react';
import clx from 'classnames';
import EmwTypography from '../../../../components/EmwTypography/EmwTypography';
import EmwTag from '../../../../components/EmwTag/EmwTag';
import { COMMENT_TYPES, LINKED_TO_OPTIONS } from '../../constants';
import moment from 'moment/moment';
import { markCommentAsApi } from '../../redux/api';
import useAppSnackbar from '../../../../hooks/useAppSnankbar';
import CommentItemActions from '../MyCommentsModal/components/CommentsList/CommentItemActions';
import { TdsIcon } from '@tds/react';
import TooltipAction from '../../../organization/components/OrganizationCard/components/Section1/TooltipAction';

export default function CommentsHistoryItem({ comment, syncState, title, selectedChart }) {
  const snackbar = useAppSnackbar();

  const className = clx('comment-history-item mb-s pl-xs', {
    'not-read': !comment.readIt,
  });

  const renderTag = () => {
    if (comment.type === COMMENT_TYPES.COMMENT) {
      return <EmwTag text="COMMENT" color="#74B48F" />;
    }

    if (comment.type === COMMENT_TYPES.ROOT_CAUSE) {
      return <EmwTag text="ROOT CAUSE" color="#CE7CAB" />;
    }

    if (comment.type === COMMENT_TYPES.ACTION_PLAN) {
      return <EmwTag text="ACTION PLAN" color="#192ACC" />;
    }

    return <EmwTag text="COMMENT" color="#74B48F" />;
  };

  const handleClick = async () => {
    if (comment.readIt) {
      return;
    }

    try {
      const response = await markCommentAsApi(comment.ucId, !comment.readIt);
      snackbar.show(response.message, 'success');

      syncState();
    } catch (e) {
      snackbar.show(e.message, 'error');
    }
  };

  const renderLinkedTo = () => {
    if (comment.linkedTo) {
      return (
        <div className="text-right bg-purple-50 flex py-xxs justify-end items-center rounded-md p-xxs">
          <EmwTypography fontSize={9} fontWeight="bold" classes="text-bluegrey-300 mr-xxs">
            Linked To:
          </EmwTypography>
          <EmwTypography fontSize={9} classes="text-bluegrey-300">
            {LINKED_TO_OPTIONS[comment.linkedTo]}
          </EmwTypography>
        </div>
      );
    }

    return null;
  };

  const renderWhere = () => {
    if (!comment.where.toLowerCase().includes('kpi')) {
      return `BAR - ${comment.where}`;
    }

    return 'KPI';
  };

  return (
    <div className={className} id={`comment-${comment.id}`}>
      <div className="flex">
        <EmwTypography fontSize={9} classes="pt-xxs text-bluegrey-300 mr-xxs">
          Last edited by:
        </EmwTypography>
        <EmwTypography fontSize={9} fontWeight="bold" classes="pt-xxs text-bluegrey-300">
          {comment.lastActionBy}
        </EmwTypography>
        <TooltipAction employeeId={comment.lastActionById} placement="top" />
        <EmwTypography fontSize={9} classes="pt-xxs text-bluegrey-300 mr-xxs">
          {moment(comment.updatedDate).format('DD/MM/YYYY')}
        </EmwTypography>
        <EmwTypography fontSize={9} classes="pt-xxs text-bluegrey-300 mr-xxs">
          on
        </EmwTypography>
        <EmwTypography fontSize={9} classes="pt-xxs text-bluegrey-300 underline">
          {renderWhere()}
        </EmwTypography>
        <div className="ml-auto flex text-bluegrey-400 items-center">
          <div className="mr-s">
            {comment.public && (
              <TdsIcon icon="remove_red_eye" variant="outlined" size="medium" title="public" />
            )}
            {!comment.public && (
              <TdsIcon icon="visibility_off" variant="outlined" size="medium" title="private" />
            )}
          </div>
          {renderTag()}
        </div>
        <CommentItemActions comment={comment} title={title} selectedChart={selectedChart} />
      </div>

      {renderLinkedTo()}

      <div className="comment-history-item-text" onClick={handleClick}>
        <EmwTypography fontSize={8} classes="px-xxs py-xs">
          {comment.text}
        </EmwTypography>
      </div>
    </div>
  );
}
