import { TdsTabs } from '@tds/react';

export default function EmwTabs({
  children,
  alignment,
  classes,
  divider,
  fluid,
  fullHeight,
  fullWidth,
  scrollHorizontally,
  size,
  tabDirection,
}) {
  return (
    <TdsTabs
      {...{
        alignment,
        classes,
        divider,
        fluid,
        fullHeight,
        fullWidth,
        scrollHorizontally,
        size,
        tabDirection,
      }}
    >
      {children}
    </TdsTabs>
  );
}
