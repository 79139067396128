import React, { useCallback, useEffect, useState } from 'react';
import { StyledDataCollection } from './styled';
import MultipleBarChart from '../../components/EmwCharts/components/BarChart/MultipleBarChart';
import { Grid } from '@mui/material';
import Status from './components/Status/Status';
import useChartUtils from '../../components/EmwCharts/hooks/useChartUtils';
import { defs, fill } from './constants';
import DiagramContextProvider from '../organization/components/Diagram/context/DiagramContext';
import GeneralFiltersContainer from '../../components/EmwGeneralFilters/GeneralFiltersContainer';
import EmwTypography from '../../components/EmwTypography/EmwTypography';
import TimeFrame from '../../components/EmwCharts/components/Filters/components/TimeFrame/TimeFrame';
import useDataCollection from './hooks/useDataCollection';

const colorMapping = {
  eei: {
    color: '#0F186E',
  },
  wfb: {
    color: '#6871BF',
  },
};

const info = [
  { text: 'EEI', color: '#0F186E' },
  { text: 'WFB', color: '#6871BF' },
];

export default function DataCollection() {
  const chartUtils = useChartUtils({ colorMapping });
  const { chartData, statusData, stateData, setStateData } = useDataCollection();

  return (
    <DiagramContextProvider>
      <GeneralFiltersContainer>
        <StyledDataCollection
          className="flex flex-col flex-1 overflow-auto px-xs pb-xs"
          style={{ backgroundColor: '#F7F7F9' }}
        >
          <div className="title-and-period flex mb-m items-center">
            <EmwTypography classes="text-primary-500" fontSize={4} fontWeight="bold">
              Data Collection Evolution
            </EmwTypography>
            <div className="ml-auto">
              <TimeFrame filters={stateData} setFilters={setStateData} />
            </div>
          </div>
          <div className="flex flex-wrap flex-1 justify-between overflow-y-auto overflow-x-hidden pr-xxs">
            <div className="flex flex-col flex-1 charts-container px-xxs">
              <div className="mb-xs">
                <Grid container spacing={1}>
                  <Grid item sm={12} md={6}>
                    <MultipleBarChart
                      data={chartData.dataCollection}
                      name="Data collection evolution"
                      keys={['eei', 'wfb']}
                      colorMapping={colorMapping}
                      defs={defs}
                      fill={fill}
                      info={info}
                      tooltipInfo={
                        <>
                          <p className="mb-xxs">Tooltip info</p>
                        </>
                      }
                    />
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <MultipleBarChart
                      data={chartData.actualsDataCollection}
                      name="Actuals Data collection evolution"
                      keys={['eei', 'wfb']}
                      colorMapping={colorMapping}
                      defs={defs}
                      fill={fill}
                      info={info}
                      tooltipInfo={
                        <>
                          <p className="mb-xxs">Tooltip info</p>
                        </>
                      }
                    />
                  </Grid>
                </Grid>
              </div>
              <div className="mb-xs flex-1">
                <Status
                  title={`Data collection status for Q${chartUtils.getQuarter(
                    new Date()
                  )} ${new Date().getFullYear()}`}
                  tooltipInfo={'Data collection status'}
                  data={statusData}
                />
              </div>
            </div>
          </div>
        </StyledDataCollection>
      </GeneralFiltersContainer>
    </DiagramContextProvider>
  );
}
