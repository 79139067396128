import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { TextField, InputLabel } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import enLocale from 'date-fns/locale/en-US';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
}));

const InputLabelStyled = styled(InputLabel)(({ theme }) => ({
  marginRight: 5,
  marginTop: 5,
  fontSize: '1.1rem',
  whiteSpace: 'nowrap',
}));

export default function EmwDatePicker(props) {
  const [value, setValue] = useState(props.atributes ? props.atributes.value : props.value);
  const { onDateChange, atributes, labelText, useOnlySetValue, inputId } = props;

  const showLabel = typeof labelText !== 'undefined' && labelText !== null && labelText !== '';

  const onDChange = (a, b, valueD) => {
    setValue(valueD);
    if (typeof onDateChange === 'function') {
      onDateChange(valueD, inputId);
    }
  };

  return (
    <Root>
      {showLabel === true && <InputLabelStyled>{labelText}</InputLabelStyled>}
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={enLocale}>
        <DatePicker
          inputId={inputId}
          {...atributes}
          showTodayButton={true}
          value={
            useOnlySetValue
              ? atributes.value === null
                ? ''
                : atributes.value
              : value === null
              ? ''
              : value
          }
          onChange={onDChange.bind(this, 'A', props)}
          renderInput={params => {
            const paramsNew = { ...params };
            paramsNew.inputProps.disabled = true;
            paramsNew.inputProps.style = { width: '6rem' };
            paramsNew.error = false;
            return params.disabled ? (
              <div style={{ marginTop: '5px', fontSize: '1.1rem' }}>{params.inputProps.value}</div>
            ) : (
              <TextField
                size="small"
                InputProps={{
                  readOnly: true,
                }}
                {...paramsNew}
              />
            );
          }}
        />
      </LocalizationProvider>
    </Root>
  );
}
