import { MODULE_NAME } from '../constants';
import * as actions from './actions';
import { createSelector } from '@reduxjs/toolkit';

export const filters = state => state[MODULE_NAME];
export const generalFiltersSelectors = createSelector(filters, state => state[actions.FILTERS]);
export const selectedGeneralFilters = createSelector(
  filters,
  state => state[actions.SELECTED_FILTERS]
);
export const clearAllFiltersSelector = createSelector(
  filters,
  state => state[actions.CLEAR_ALL_FILTERS]
);

export const availableFiltersSelector = createSelector(
  filters,
  state => state[actions.AVAILABLE_FILTERS]
);
export const loadingSelector = createSelector(filters, state => state[actions.LOADING]);

export const rolesFiltersSelector = createSelector(filters, state => state[actions.ROLES_FILTERS]);

export const servicesFiltersSelector = createSelector(
  filters,
  state => state[actions.SERVICES_FILTERS]
);
