import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import EmwDatePicker from './EmwDatePicker';
import { InputLabel } from '@mui/material';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
}));

const InputLabelStyled = styled(InputLabel)(({ theme }) => ({
  marginRight: 5,
  marginTop: 5,
  marginLeft: 5,
  fontSize: '1.1rem',
  whiteSpace: 'nowrap',
}));

export default function EmwFromToDatePicker(props) {
  const { onDateChange, doNotShowStandardLabels, inputId } = { ...props };
  const [fromDateAttribute, setFromDateAttribute] = useState(
    props.atributes && props.atributes.atributesFrom ? props.atributes.atributesFrom : {}
  );
  const [toDateAttribute, setToDateAttribute] = useState(
    props.atributes && props.atributes.atributesTo ? props.atributes.atributesTo : {}
  );

  useEffect(() => {
    if (toDateAttribute.value && toDateAttribute.value !== '') {
      const fDateAtribute = {
        ...fromDateAttribute,
        maxDate: toDateAttribute.value,
      };
      setFromDateAttribute(fDateAtribute);
    }

    if (typeof toDateAttribute.value === 'undefined') {
      const fDateAtribute = { ...fromDateAttribute, maxDate: new Date() };
      setFromDateAttribute(fDateAtribute);
    }

    if (fromDateAttribute.value && fromDateAttribute !== '') {
      const tDateAtribute = {
        ...toDateAttribute,
        minDate: fromDateAttribute.value,
      };
      setToDateAttribute(tDateAtribute);
    }

    if (typeof fromDateAttribute.value === 'undefined') {
      const tDateAtribute = { ...toDateAttribute, minDate: new Date() };
      setToDateAttribute(tDateAtribute);
    }
  }, []);

  const fromDateChange = value => {
    if (typeof value !== 'undefined' && value !== null) {
      const tDateAtribute = { ...toDateAttribute, minDate: value };
      setToDateAttribute(tDateAtribute);
    }

    if (typeof onDateChange === 'function') {
      onDateChange(value, inputId + '_from');
    }
  };

  const toDateChange = value => {
    if (typeof value !== 'undefined' && value !== null) {
      const fDateAtribute = { ...fromDateAttribute, maxDate: value };
      setFromDateAttribute(fDateAtribute);
    }

    if (typeof onDateChange === 'function') {
      onDateChange(value, inputId + '_to');
    }
  };

  if (fromDateAttribute.value === '' && toDateAttribute.value === '') {
    return <React.Fragment></React.Fragment>;
  }

  return (
    <Root>
      {!doNotShowStandardLabels && <InputLabelStyled>{'From:'}</InputLabelStyled>}
      <EmwDatePicker
        inputId={`{inputId}_from`}
        onDateChange={fromDateChange}
        atributes={fromDateAttribute}
      />
      <InputLabelStyled>{!doNotShowStandardLabels ? 'To:' : '-'}</InputLabelStyled>
      <EmwDatePicker
        inputId={`{inputId}_to`}
        onDateChange={toDateChange}
        atributes={toDateAttribute}
      />
    </Root>
  );
}
