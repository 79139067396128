import React, { useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  styled,
} from '@mui/material';
import { TdsTypography } from '@tds/react';
import {
  getGeneralFilters,
  getLabelsArray,
  getTimeframeFilters,
} from '../../../../../util/biUtils';
import { EmwButton, EmwTextField } from '../../../../../lib/common';
import { useDispatch, useSelector } from 'react-redux';
import { chartsSelector } from '../../redux/selectors';
import { setShouldRenderLines, updateCharts, updateTargets } from '../../redux/actions';
import useApi from '../../../../hooks/useApi';
import { createTargetApi, deleteTargetApi } from '../../redux/api';
import { cloneDeep } from 'lodash';
import { CHARTS, ENGINEERING_COMPETITIVENESS, MOOD_AND_CONFIDENCE } from '../../constants';
import useAppSnackbar from '../../../../hooks/useAppSnankbar';
import { useLocation } from 'react-router';
import EmwDialog from '../../../../components/EmwDialog/EmwDialog';
import EmwTypography from '../../../../components/EmwTypography/EmwTypography';
import usePowerBi from '../../hooks/usePowerBi';

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  borderBottom: '2px solid #041295',
  margin: '0 25px',
  padding: '16px 0 11px 0',
  '.title': {
    p: {
      color: '#041295',
    },
  },
  '.notify-label': {
    lineHeight: '11px',
  },
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  margin: '16px 25px 0 25px',
  '.labels-form': {
    fontSize: '10px',
    input: {
      color: '#5D607E',
    },
  },
}));

const StyledLabelItem = styled(Box)(({ theme }) => ({
  width: '66px',
  marginLeft: '7px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export default function TargetModal({ isOpen, setIsOpen, selectedChart, onButtonCancelClick }) {
  const chartsData = useSelector(chartsSelector);
  const powerBi = usePowerBi();
  const [labels] = useState(getLabelsArray(selectedChart));
  const [title] = useState(selectedChart.title);
  const [values, setValues] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [orgName, setOrgName] = useState('');
  const [canDelete, setCanDelete] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const dispatch = useDispatch();
  const api = useApi();
  const snackbar = useAppSnackbar();
  const location = useLocation();

  useEffect(() => {
    const _values = {};
    let inputValues;

    const initialValues = [...selectedChart.values];

    if ([CHARTS.MOOD, CHARTS.CONFIDENCE_TO_DELIVER].includes(selectedChart.key)) {
      inputValues = initialValues.splice(4, 6);
    }

    if ([CHARTS.DOTD, CHARTS.DAVD].includes(selectedChart.key)) {
      inputValues = initialValues.splice(8, 12);
    }

    if ([CHARTS.EC_CONFIDENCE_TO_DELIVER].includes(selectedChart.key)) {
      inputValues = initialValues.splice(4, 6);
    }

    if ([CHARTS.KEY_PROFILE_FIT, CHARTS.SKILL_COVERAGE].includes(selectedChart.key)) {
      //TODO check when quarter is changing
      inputValues = initialValues.splice(3, 4);
    }

    labels.forEach((label, i) => {
      if (selectedChart.hasDecimals) {
        _values[label.fieldId] = inputValues[i];
      } else {
        _values[label.fieldId] = Math.trunc(inputValues[i]);
      }
    });

    for (const label in _values) {
      if (_values[label] > 0) {
        setCanDelete(true);
        break;
      }
    }

    setValues(_values);
  }, [labels]);

  useEffect(() => {
    setOrgName(powerBi.filterToOrgName());
  }, []);

  const handleChange = (id, value) => {
    const _currentValues = { ...values };
    _currentValues[id] = value;
    const minValue = selectedChart.min;
    const maxValue = selectedChart.max;

    if (value > maxValue) {
      value = maxValue;
    }

    if (value < minValue) {
      value = minValue;
    }

    setValues(_currentValues);
  };

  const handleBlur = id => {
    // const _currentValues = { ...values }
    // const blurredValue = values[id];
    // const minValue = selectedChart.min;
    // const maxValue = selectedChart.max;
    // const validValue = () => {
    //   if (blurredValue > maxValue) {
    //     return maxValue
    //   } else if (blurredValue < minValue) {
    //     return minValue
    //   }
    //   return blurredValue
    // }
    // _currentValues[id] = Number(validValue());
    // setValues(_currentValues);
  };

  const handleDeleteTarget = async () => {
    setIsLoading(true);

    try {
      const page = location.pathname;
      const charts = cloneDeep(chartsData);
      const requestPayload = {
        chartType: [selectedChart.key],
        generalFilters: getGeneralFilters(),
      };
      const deleteResponse = await deleteTargetApi(requestPayload);
      charts[selectedChart.parent][selectedChart.key].values =
        deleteResponse.data[selectedChart.key];

      dispatch(setShouldRenderLines(true));
      dispatch(updateCharts(charts));
      dispatch(setShouldRenderLines(false));
    } catch (reason) {
      snackbar.show(reason.message, 'error');
    } finally {
      setIsOpen(false);
    }
  };

  const handleCreateTarget = async () => {
    const generalFilters = getGeneralFilters();
    const timeframeFilters = getTimeframeFilters();
    setIsLoading(true);

    const payload = {
      chartType: [selectedChart.key],
      values: Object.keys(values).map(key => values[key]),
      generalFilters,
      timeframeFilters,
      notify: false,
    };

    const response = await api.init(createTargetApi, payload, true);
    const charts = cloneDeep(chartsData);
    setIsLoading(false);
    setIsOpen(false);

    charts[selectedChart.parent][response.data.chartType[0]].values =
      response.data[response.data.chartType[0]];
    dispatch(setShouldRenderLines(true));
    dispatch(updateCharts(charts));
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setIsOpen(true);
    }
  };

  return (
    <>
      <Dialog open={isOpen} fullWidth maxWidth={'lg'} onClose={handleClose} disableEscapeKeyDown>
        <StyledDialogTitle>
          <Grid container alignItems="center" justifyContent="space-between">
            <TdsTypography component="body-1" className="title">
              Add / Edit targets for {title}
            </TdsTypography>
            <EmwTypography fontSize={6} fontWeight="bold" classes="text-bluegrey-600">
              {orgName}
            </EmwTypography>
          </Grid>
        </StyledDialogTitle>
        <StyledDialogContent dividers>
          <Grid container direction="column">
            <Grid item>
              <TdsTypography component="body-2" className="content-title">
                Enter values between {selectedChart.min} and {selectedChart.max}{' '}
                {selectedChart.unit}
              </TdsTypography>
            </Grid>

            <Grid item>
              <Grid container justifyContent="center" alignItems="center" className="labels-form">
                {labels.map((label, key) => {
                  return (
                    <StyledLabelItem key={key}>
                      <Box textAlign="center">{label.label}</Box>
                      <Box>
                        <div className="qtm-text-input qtm-small">
                          <input
                            type="number"
                            value={values[label.fieldId] > 0 ? values[label.fieldId] : ''}
                            onChange={e => handleChange(label.fieldId, Number(e.target.value))}
                          />
                        </div>
                      </Box>
                    </StyledLabelItem>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </StyledDialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <EmwButton variant="outline" size="small" onClick={onButtonCancelClick}>
            Close
          </EmwButton>
          <EmwButton
            variant="outline"
            size="small"
            disabled={!canDelete || isLoading}
            onClick={() => setIsDeleteModalOpen(true)}
          >
            Delete
          </EmwButton>
          <EmwButton
            variant="filled"
            size="small"
            disabled={isLoading}
            onClick={handleCreateTarget}
          >
            Save
          </EmwButton>
        </DialogActions>
      </Dialog>
      <EmwDialog
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        callback={handleDeleteTarget}
        actionButton="Delete"
        title={`Do you want to delete this target from ${title} KPI?`}
        text="This action cannot be undone."
        icon="warning_amber"
        classes="close-icon"
        loading={isLoading}
      />
    </>
  );
}
