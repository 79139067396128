import { styled } from '@mui/material';
import { TdsDropdownSelectOption } from '@tds/react';

export const StyledAlertOption = styled(TdsDropdownSelectOption)(({ theme }) => ({
  '.alert-option': {
    display: 'flex',
    // background: "white!important",
    alignItems: 'center',

    '> div': {
      position: 'relative',
      top: '-1px',
    },
  },
}));
