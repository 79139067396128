import React, { useMemo } from 'react';
import EmListItemOrgDetails from './EmListItemOrgDetails';

export default function EmListItemRolesInfo({ rolesInfo, inCurrentOrg }) {
  const roles = useMemo(() => {
    return rolesInfo.map(role => {
      const orgDetails = {
        ...role.orgDetails,
        name: role.name,
        unId: role.unId,
        inCurrentOrg: role.inCurrentOrg,
      };

      return <EmListItemOrgDetails key={orgDetails.unId} orgDetails={orgDetails} />;
    });
  }, [rolesInfo]);

  const roleOutsideOrg = roles.filter(role => !role.props.orgDetails.inCurrentOrg);

  if (inCurrentOrg) {
    const rolesInOrg = roles.filter(role => role.props.orgDetails.inCurrentOrg);
    return (
      <div className="flex items-center text-scale-9 text-bluegrey-500 ml-xxs roles">
        {rolesInOrg.map((role, index) => (
          <>
            {index === 0 ? '(' : ''}
            {role}
            {index < rolesInOrg.length - 1 ? ',' : ''}
            {index === rolesInOrg.length - 1 ? ')' : ''}
            &nbsp;
          </>
        ))}
      </div>
    );
  }

  if (roleOutsideOrg.length > 0) {
    return <div className="ml-xs roles">{roleOutsideOrg}</div>;
  }
}
