import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';

import EmwTypography from '../EmwTypography/EmwTypography';

const StyledEnvInfo = styled('div')(({ theme }) => {
  return {
    position: 'absolute',
    left: 0,
    right: 0,
    textAlign: 'center',
    zIndex: 1,
    padding: '10px',
  };
});

export default function EmwFooterEnvInfo({ isEEI }) {
  const THALES_GROUP_C3 = 'THALES GROUP CONFIDENTIAL';
  const THALES_GROUP_C2 = 'THALES GROUP LIMITED DISTRIBUTION';

  useEffect(() => {}, []);

  return (
    <StyledEnvInfo>
      {isEEI ? (
        <EmwTypography classes="text-orange-500" fontWeight="bold" fontSize={8}>
          {THALES_GROUP_C3}
        </EmwTypography>
      ) : (
        <EmwTypography classes="text-green-500" fontWeight="bold" fontSize={8}>
          {THALES_GROUP_C2}
        </EmwTypography>
      )}
    </StyledEnvInfo>
  );
}
