import React, { useContext, useEffect, useState } from 'react';
import { Collapse, Grid, IconButton } from '@mui/material';
import { OrganizationCardContext } from '../../../../context/OrganizationCardContextProvider';
import Delivery from './Delivery';
import ConstructionIcon from '../../../../../../../../../lib/icons/ContructionIcon';
import PlusIcon from '../../../../../../../../../lib/icons/PlusIcon';
import EmwTypography from '../../../../../../../../components/EmwTypography/EmwTypography';
import { reverseAndUnshift, sortValidItems, splitList } from '../../utils';
import { TdsIcon } from '@tds/react';

export default function DeliveriesPanel({ deliveries, targetField, open }) {
  const context = useContext(OrganizationCardContext);
  const [isOpen, setIsOpen] = useState(false);
  const [canAdd, setCanAdd] = useState(false);
  const [openNewDelivery, setOpenNewDelivery] = useState(false);
  const [incompleteList, setIncompleteList] = useState([]);
  const [completeList, setCompleteList] = useState([]);

  useEffect(() => {
    if (deliveries.length > 0) {
      setCanAdd(deliveries[0].btnAdd.visible);
    }
    setDeliveriesItems();
  }, [deliveries]);

  const addNewDelivery = () => {
    setIsOpen(true);
    setOpenNewDelivery(true);
    context.addNewDelivery();
  };

  const setDeliveriesItems = () => {
    if (!isOpen) {
      setIsOpen(context.shouldBeOpen(deliveries, targetField.key));
    }

    const regExp = /^Delivery [0-9]+$/;
    let { _incompleteItems, _completeItems } = splitList(deliveries, regExp);

    _incompleteItems = _incompleteItems.map(delivery => {
      return (
        <Delivery
          key={delivery.originalIndex}
          delivery={delivery}
          index={delivery.originalIndex}
          color="#E6F8FC"
          targetField={targetField}
          openNewDelivery={openNewDelivery}
        />
      );
    });

    _completeItems = _completeItems.map(delivery => {
      return (
        <Delivery
          key={delivery.originalIndex}
          delivery={delivery}
          index={delivery.originalIndex}
          color="#E6F8FC"
          targetField={targetField}
          openNewDelivery={openNewDelivery}
        />
      );
    });

    _incompleteItems.map((_item, index) => {
      if (
        !_item.props.delivery.name.value ||
        /^Delivery [0-9]+$/.test(_item.props.delivery.name.value)
      ) {
        _item.props.delivery.name.value = `Delivery ${index + 1}`;
      }

      return _item;
    });

    setIncompleteList(_incompleteItems);
    setCompleteList(_completeItems);
  };

  return (
    <>
      <Grid item className="toggle-panel" sx={{ borderRadius: isOpen ? '5px 5px 0 0' : '5px' }}>
        <Grid container alignItems="center">
          <Grid item>
            <Grid container direction="row" alignItems="center">
              <Grid item mr={1}>
                <ConstructionIcon />
              </Grid>
              <Grid item>
                <EmwTypography component="caption-2" fontWeight="bold" classes="text-bluegrey-600">
                  DELIVERY DISCIPLINE
                </EmwTypography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item ml="auto">
            <Grid container alignItems="center">
              {canAdd && (
                <IconButton
                  title="Add new"
                  sx={{ padding: '5px' }}
                  disableRipple
                  onClick={addNewDelivery}
                  id={`btnEditViewAddDeliveries`}
                >
                  <PlusIcon />
                </IconButton>
              )}
              <IconButton
                title={`${isOpen ? 'Collapse section' : 'Expand section'}`}
                sx={{ paddingLeft: 0 }}
                disableRipple
                size="small"
                onClick={() => setIsOpen(!isOpen)}
                id={`${isOpen ? 'btnEditViewCollapseDeliveries' : 'btnEditViewExpandDeliveries'}`}
              >
                {/* TODO: handle id */}
                {isOpen ? (
                  <TdsIcon icon="keyboard_arrow_up" size="small" style={{ color: '#4A4D65' }} />
                ) : (
                  <TdsIcon icon="keyboard_arrow_down" size="small" style={{ color: '#4A4D65' }} />
                )}
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Collapse className="toggle-collapse" in={isOpen}>
        {reverseAndUnshift(incompleteList, 'delivery')}
        {sortValidItems(completeList, 'delivery')}
      </Collapse>
    </>
  );
}
