import React from 'react';
import { Box, Grid } from '@mui/material';
import { TdsFormLabel } from '@tds/react';
import { EmwCheckbox } from '../../../../../../../../../lib/common';
import EmwTypography from '../../../../../../../../components/EmwTypography/EmwTypography';

export default function CheckboxItem({ name, label, title, item, handleChange }) {
  return (
    <Grid item>
      <TdsFormLabel>
        <Grid container direction="column" title={`Eng. Domain: ${title}`}>
          <EmwTypography component="caption-2">{label}</EmwTypography>
          <Box title={title}>
            <EmwCheckbox
              size="small"
              name={name}
              inputId={name}
              checked={item.value}
              disabled={!item.enabled}
              onCheckBoxChanged={event => handleChange(event, name)}
            />
          </Box>
        </Grid>
      </TdsFormLabel>
    </Grid>
  );
}
