import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';

export const StyledEmwDialog = styled(Dialog)(({ theme, width }) => ({
  '& .MuiPaper-root': {
    borderRadius: '0px',
    width: `${width ? width : '380px'}`,
  },
  '& .MuiDialogTitle-root': {
    padding: '18px',
  },
  '& .MuiDialogContent-root': {
    padding: '0px 16px 16px 16px',
    borderTop: 'none',
  },
  '& .MuiDialogActions-root': {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '18px',
  },
  '& .save-icon .material-icons-outlined': {
    position: 'relative',
    top: '1px',
  },
  '& .close-icon .material-icons-outlined': {
    color: theme.palette.orange[500],
    position: 'relative',
    top: '1px',
  },
  '& .reset-icon .material-icons-outlined': {
    color: theme.palette.orange[500],
    position: 'relative',
    top: '1px',
  },
  '& .settings-icon .material-icons-outlined': {
    color: theme.palette.primary[500],
    position: 'relative',
    top: '1px',
  },
  '#customized-dialog-title': {
    display: 'flex',
    justifyContent: 'row',
    alignItems: 'flex-start',
  },
  '#btnClose-modal': {
    padding: '3px',
    margin: '-3px',
  },
}));
