import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changePerspective, changeView, updateSchema } from '../redux/actions';
import { Box, Grid } from '@mui/material';
import OrganizationHeader from './OrganizationHeader/OrganizationHeader';
import Diagram from './Diagram/Diagram';
import DiagramContextProvider from './Diagram/context/DiagramContext';
import FavoriteCard from './FavoriteCard/FavoriteCard';
import { hasNodeLinkSelector, viewSelector } from '../redux/selectors';
import OrganizationCard from './OrganizationCard/OrganizationCard';
import { ORGANIZATION_VIEW, ORGANIZATION_PERSPECTIVE } from '../constants';
import { StyledOrganizationPage } from './styled';
import GuestPowerGuestCard from './GuestPowerGuestCard/GuestPowerGuestCard';
import EmptyCard from './EmptyCard/EmptyCard';
import initialState from '../redux/initialstate';
import * as actions from '../redux/actions';
import { dependenciesLoadedSelector } from '../../header/redux/selectors';
import EmwProgressCircle from '../../../components/EmwProgressCircle/EmwProgressCircle';

export default function OrganizationPage() {
  const dispatch = useDispatch();
  const currentView = useSelector(viewSelector);
  const hasNodeLink = useSelector(hasNodeLinkSelector);
  const dependenciesLoaded = useSelector(dependenciesLoadedSelector);
  const ref = useRef();

  useEffect(() => {
    if (!hasNodeLink) {
      dispatch(changePerspective(ORGANIZATION_PERSPECTIVE.BY_GBU));
      dispatch(changeView(ORGANIZATION_VIEW.FAVORITES));
    }

    return () => {
      dispatch(updateSchema(initialState[actions.DIAGRAM_SCHEMA]));
      dispatch(changePerspective(ORGANIZATION_PERSPECTIVE.BY_GBU));
      dispatch(changeView(ORGANIZATION_VIEW.FAVORITES));
    };
  }, []);

  if (!dependenciesLoaded) {
    return (
      <div className="flex flex-1 flex-col items-center mt-xs">
        <EmwProgressCircle />
      </div>
    );
  }

  return (
    <StyledOrganizationPage container id="organization-page">
      <DiagramContextProvider>
        <Grid item xs={12} sm={7} className="organization-content">
          <Grid container className="organization-diagram-container">
            <Grid item className="organization-diagram-header ml-3xl mr-7xl">
              <OrganizationHeader />
            </Grid>

            <Grid item className="organization-diagram-nodes" ref={ref} id="diagramScrollingPoint">
              <Box
                className="tour-organization-diagram-nodes"
                display="flex"
                position="relative"
                padding="16px 10px 0 20px"
                top="-16px"
              >
                <Diagram />
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={5} className="aside" id="organization-card-container">
          <Box className="organization-card-container">
            {currentView === ORGANIZATION_VIEW.FAVORITES && <FavoriteCard />}
            {currentView === ORGANIZATION_VIEW.DETAILS && <OrganizationCard />}
            {currentView === ORGANIZATION_VIEW.GUEST && <GuestPowerGuestCard />}
            {currentView === ORGANIZATION_VIEW.EMPTY && <EmptyCard />}
          </Box>
        </Grid>
      </DiagramContextProvider>
    </StyledOrganizationPage>
  );
}
