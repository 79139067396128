import React from 'react';

export default function ArrowOutlined() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.58398 8.00016C1.58398 4.50236 4.4195 1.66683 7.91732 1.66683C11.4151 1.66683 14.2507 4.50236 14.2507 8.00016C14.2507 11.498 11.4151 14.3335 7.91732 14.3335C4.41949 14.3335 1.58398 11.498 1.58398 8.00016ZM7.91732 0.0834957C12.2896 0.0834959 15.834 3.62791 15.834 8.00016C15.834 12.3724 12.2896 15.9168 7.91732 15.9168C3.5451 15.9168 0.000648915 12.3724 0.000649106 8.00016C0.000649297 3.62791 3.5451 0.0834956 7.91732 0.0834957Z"
        fill="white"
      />
      <path
        d="M11.8339 8.74719C11.8342 8.83257 11.8154 8.91694 11.7788 8.99408C11.7422 9.07123 11.6888 9.13919 11.6225 9.19298L8.19429 11.9535C8.09205 12.0375 7.96381 12.0835 7.83147 12.0835C7.69912 12.0835 7.57088 12.0375 7.46864 11.9535L4.0404 9.09582C3.92371 8.99881 3.85033 8.85941 3.8364 8.70828C3.82247 8.55715 3.86913 8.40668 3.96612 8.28996C4.0631 8.17325 4.20247 8.09985 4.35355 8.08591C4.50464 8.07198 4.65507 8.11865 4.77175 8.21566L7.83432 10.7704L10.8969 8.30139C10.9808 8.23151 11.0829 8.18711 11.1912 8.17346C11.2995 8.15982 11.4094 8.17749 11.508 8.22438C11.6066 8.27128 11.6897 8.34544 11.7474 8.43809C11.8052 8.53074 11.8352 8.63801 11.8339 8.74719Z"
        fill="white"
      />
      <path
        d="M11.8339 4.74719C11.8342 4.83257 11.8154 4.91694 11.7788 4.99408C11.7422 5.07123 11.6888 5.13919 11.6225 5.19298L8.19429 7.95348C8.09205 8.03754 7.96381 8.0835 7.83147 8.0835C7.69912 8.0835 7.57088 8.03754 7.46864 7.95348L4.0404 5.09582C3.92371 4.99881 3.85033 4.85941 3.8364 4.70828C3.82247 4.55715 3.86913 4.40668 3.96612 4.28996C4.0631 4.17325 4.20247 4.09985 4.35355 4.08591C4.50464 4.07198 4.65507 4.11865 4.77175 4.21566L7.83432 6.77041L10.8969 4.30139C10.9808 4.23151 11.0829 4.18711 11.1912 4.17346C11.2995 4.15982 11.4094 4.17749 11.508 4.22438C11.6066 4.27128 11.6897 4.34544 11.7474 4.43809C11.8052 4.53074 11.8352 4.63801 11.8339 4.74719Z"
        fill="white"
      />
    </svg>
  );
}
