import { styled } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';
import { EmwPopup } from '../../../../../lib/common';
import { TdsTypography } from '@tds/react';

export const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: '11px',
  fontWeight: '700',
  lineHeight: 'inherit',
}));

export const StyledTogglePanel = styled(Grid)(({ theme }) => ({
  backgroundColor: '#CCF2F8',
  borderRadius: '10px',
  padding: '0 15px',
  height: '50px',
  display: 'flex',
}));

export const StyledTogglePanelItem = styled(Grid)(({ theme }) => ({
  fontSize: '10px',
  marginTop: '10px',
  borderRadius: '10px',
  padding: '5px',
  fontWeight: 'bold',
  backgroundColor: '#F7F7F9',
  '.name': {
    marginLeft: '5px',
  },
}));

export const StyledTogglePanelItemType = styled(Grid)(({ theme }) => ({
  color: '#00000',
  fontSize: '10px',
  width: '55px',
  marginRight: theme.spacing(1),
}));

export const StyledTogglePanelItemName = styled(Grid)(({ theme }) => ({
  color: '#00000',
  fontSize: '10px',
  textDecoration: 'underline',
}));

export const StyledLinkedTypography = styled(TdsTypography)(({ theme }) => ({
  textDecoration: 'underline',
  fontWeight: 800,
  fontFamily: 'Roboto',
  fontSize: '0.625rem',
  lineHeight: '1rem',
}));

export const StylesEmwPopup = styled(EmwPopup)(({ theme }) => ({
  '.modal-content': {
    width: '400px!important',
  },
}));
