import { SECURITY_API_ENDPOINT } from './../../constants';
import { queryString, fetch, fetchOptions, computeURL } from './../../util/fetch';

export const postLogin = data => {
  const addTokenToStorage = res => {
    // Add authorization header to local storage
    if (res.headers.get('authorization')) {
      window.localStorage.setItem('authorization', res.headers.get('authorization'));
    }
  };

  const dataToSend = {
    username: encodeURIComponent(data.username),
    password: encodeURIComponent(data.password),
  };

  return fetch(
    `${SECURITY_API_ENDPOINT}/login`,
    fetchOptions({
      method: 'post',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: queryString(dataToSend).substr(1),
    }),
    addTokenToStorage
  );
};

export const loginUser = data => {
  const addTokenToStorage = res => {
    // Add authorization header to local storage
    if (res.headers.get('authorization')) {
      window.localStorage.setItem('authorization', res.headers.get('authorization'));
    }
  };

  return fetch(
    computeURL('security', 'loginWithUser'),
    fetchOptions({ method: 'POST', body: JSON.stringify(data) }),
    addTokenToStorage
  );
};

export const updateOrgGuestDetails = payload =>
  fetch(
    computeURL('organization', 'updateOrgGuestPowerGuest'),
    fetchOptions({ method: 'PUT', body: JSON.stringify(payload) })
  );

export const isAzure = () => fetch(computeURL('app', 'isAzureLogin'), fetchOptions());
