import React from 'react';
import { QtmDropdownSelectOption } from '@qtm/react';

export default function EmwDropdownSelectOption({ active, classes, onClickOption, value }) {
  return (
    <QtmDropdownSelectOption classes={classes} value={value} active={active}>
      {value}
    </QtmDropdownSelectOption>
  );
}
