import React, { useEffect, useState } from 'react';
import { TdsToggleSwitch } from '@tds/react';
import { EmwButton } from '../../../../../lib/common';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  styled,
} from '@mui/material';
import EmwTypography from '../../../../components/EmwTypography/EmwTypography';
import { StyledDialog } from '../../styled';
import CreateAlertModal from '../CreateAlertModal/CreateAlertModal';
import { getGeneralFilters, getTimeframeFilters } from '../../../../../util/biUtils';
import { useSelector } from 'react-redux';
import { alertsSelector } from '../../redux/selectors';
import AlertsList from './components/AlertsList/AlertsList';

export default function AlertModal({
  isOpen,
  setIsOpen,
  selectedChart,
  charts,
  generalFilters,
  timeframeFilters,
  showAlerts,
  setShowAlerts,
  chartIndex,
}) {
  const [isCreateAlertOpen, setIsCreateAlertOpen] = useState(false);
  const [canCreate, setCanCreate] = useState(true);
  const alerts = useSelector(alertsSelector);

  useEffect(() => {
    if (alerts[selectedChart.parent][selectedChart.key].values.length === 10) {
      return setCanCreate(false);
    }

    setCanCreate(true);
  }, [selectedChart, alerts]);

  useEffect(() => {
    if (!showAlerts) {
      document
        .querySelector('.embed-container iframe')
        .contentWindow.document.querySelectorAll(`[class^=alert-svg]`)
        .forEach(el => (el.style.visibility = 'hidden'));
    } else {
      document
        .querySelector('.embed-container iframe')
        .contentWindow.document.querySelectorAll(`[class^=alert-svg]`)
        .forEach(el => (el.style.visibility = ''));
    }
  }, [showAlerts]);

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setIsOpen(true);
    }
  };

  const handleShowAlerts = () => {
    setShowAlerts(!showAlerts);
  };

  return (
    <>
      <StyledDialog
        open={isOpen}
        maxWidth="xs"
        fullWidth
        onClose={handleClose}
        disableEscapeKeyDown
      >
        <DialogTitle className="dialog-title pb-xxs">
          <Grid container className="items-center border-b-2 border-primary-500 pb-s">
            <EmwTypography classes="text-primary-500" fontSize={6} fontWeight="medium">
              My KPI Alerts
            </EmwTypography>

            <TdsToggleSwitch
              size="small"
              className="ml-auto"
              checked={showAlerts}
              onValueChanged={handleShowAlerts}
            >
              <EmwTypography fontSize={9}>Show alerts ON</EmwTypography>
            </TdsToggleSwitch>
          </Grid>
        </DialogTitle>

        <DialogContent>
          {Object.keys(alerts[selectedChart.parent]).map(key => {
            return (
              <AlertsList
                key={key}
                chart={alerts[selectedChart.parent][key]}
                shouldBeOpen={key === selectedChart.key}
              />
            );
          })}
        </DialogContent>

        <Box sx={{ padding: '0 15px 10px 0' }}>
          <Divider sx={{ height: '2px' }} />
        </Box>

        <DialogActions>
          <EmwButton
            variant="outline"
            color="primary"
            classes="mr-xs"
            size="small"
            onClick={() => setIsOpen(false)}
          >
            Close
          </EmwButton>
          <EmwButton
            variant="filled"
            color="primary"
            size="small"
            disabled={!canCreate}
            onClick={() => setIsCreateAlertOpen(true)}
          >
            Create Alert
          </EmwButton>
        </DialogActions>

        <Grid container justifyContent="center" className="footer-info">
          <Grid item>
            <EmwTypography fontSize={9} classes="text-bluegrey-400">
              Maximum 10 alerts per KPI
            </EmwTypography>
          </Grid>
        </Grid>
      </StyledDialog>

      {isCreateAlertOpen && (
        <CreateAlertModal
          isOpen={isCreateAlertOpen}
          setIsOpen={setIsCreateAlertOpen}
          openPrevModal={setIsOpen}
          selectedChart={selectedChart}
          generalFilters={getGeneralFilters()}
          timeframeFilters={getTimeframeFilters()}
        />
      )}
    </>
  );
}
