import { getState } from './../../util/reducer';
import * as actions from './actions';

const initialState = {
  [actions.REQUEST_PENDING]: false,
  [actions.SNACKBAR_MESSAGE]: '',
};

const actionLocal = (state, action) => {
  return {
    ...state,
    [action.type]: action.payload,
  };
};

const reducerConfig = {
  [actions.SNACKBAR_MESSAGE]: actionLocal,
  [actions.REQUEST_PENDING]: actionLocal,
};

export default (state = initialState, action = {}) =>
  getState(state, action, initialState, reducerConfig);
