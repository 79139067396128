import React, { useState } from 'react';
import { computeURL, fetchDownload, fetchOptions } from '../../../util/fetch';
import { TdsProgressCircle } from '@tds/react';
import ExportFileIcon from '../../../lib/icons/ExportFileIcon';
import { EmwButton } from '../../../lib/common';

export default function EmwExportBtn({ root, action, name }) {
  const [isLoading, setIsLoading] = useState(false);
  const exportFromEdge = (fileName, blob) => {
    window.navigator.msSaveBlob(
      new Blob([blob], { type: 'data:application/vnd.ms-excel' }),
      fileName
    );
    return {};
  };

  const exportFile = (fileName, blob) => {
    if (window.navigator.userAgent.match(/Edge/)) {
      return exportFromEdge(fileName, blob);
    }
    if (blob.byteLength < 1000) {
      return JSON.parse(new TextDecoder('utf-8').decode(blob));
    }
    const newBlob = new Blob([blob], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const data = window.URL.createObjectURL(newBlob);
    const link = document.createElement('a');
    link.href = data;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    setTimeout(function () {
      window.URL.revokeObjectURL(data);
    }, 500);
    return {};
  };

  const exportData = async () =>
    fetchDownload(
      computeURL(root, action),
      fetchOptions({
        method: 'put',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/octet-stream',
          'Accept-Encoding': 'gzip, deflate, br',
        },
      })
    );

  const handleClick = async () => {
    setIsLoading(true);

    const response = await exportData();
    let fileName = response.headers.get('Content-Disposition')
      ? response.headers.get('Content-Disposition')
      : 'All_data.xlsx';
    fileName = fileName.replace('attachment; filename="', '').replace('"', '');

    response.arrayBuffer().then(function (buffer) {
      exportFile(fileName, buffer);
    });

    setIsLoading(false);
  };

  return (
    <EmwButton
      variant="ghost"
      size="small"
      classes="ml-auto"
      onClick={handleClick}
      disabled={isLoading}
    >
      {isLoading ? <TdsProgressCircle /> : <ExportFileIcon />}
    </EmwButton>
  );
}
