import React, { useContext, useEffect, useState } from 'react';
import EmwProgressCircle from '../../../../../../../../components/EmwProgressCircle/EmwProgressCircle';
import EmwButton from '../../../../../../../../components/EmwButton/EmwButton';
import { TdsIcon } from '@tds/react';
import { HzaContext } from '../../../../../../../hzaSync/context/HzaContext';
import { useSelector } from 'react-redux';
import { perspectiveSelector } from '../../../../../../../organization/redux/selectors';

import { computeURL, fetchOptions } from '../../../../../../../../../util/fetch';
import moment from 'moment';
import { DATE_TIME } from '../../../../../../../organization/constants';

export default function ExportOpsCockpitBtn({
  selectedYear,
  selectedQuarter,
  id,
  variant,
  text,
  title,
  size,
  icon,
}) {
  const hzaContext = useContext(HzaContext);
  const perspective = useSelector(perspectiveSelector);
  const [isLoading, setIsLoading] = useState(false);
  const [reqBody, setReqBody] = useState(null);

  useEffect(() => {
    if (hzaContext.downloadStatus.name === 'opsCockpit_download') {
      setIsLoading(hzaContext.downloadStatus.isLoading);
    }
  }, [hzaContext.downloadStatus]);

  useEffect(() => {
    setReqBody({
      selectedYear: selectedYear,
      selectedQuarter: selectedQuarter,
    });
  }, [selectedYear, selectedQuarter]);

  const handleDownload = async () => {
    let api = '';
    const date = moment(new Date()).format(DATE_TIME);
    const fileName = `Operation Cockpit (Off Shoring FTE_${date}.xlsx`;
    const options = {
      method: 'put',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip, deflate, br',
      },
      body: JSON.stringify(reqBody),
    };
    api = computeURL('engineeringKpi', 'exportEngineeringKpiXls');
    const params = fetchOptions(options);
    hzaContext.downloadFile(fileName, api, params, 'opsCockpit_download', true, perspective);
  };

  return (
    <EmwButton
      size={size}
      variant={variant}
      title={title}
      id={id}
      onClick={handleDownload}
      disabled={isLoading}
    >
      <p>{text}</p>
      {!isLoading && icon && <TdsIcon icon="arrow_forward" />}
      {isLoading && <EmwProgressCircle size="small" />}
    </EmwButton>
  );
}
