import { buildStep } from '../tour';

export const buildFavoritesByCountrySteps = (dinamycStep, tour) => {
  dinamycStep.push(
    buildStep(tour, null, {
      id: 'organization-diagram-nodes',
      title: 'Favorites Node',
      text: `This node will open your favorites list, while the next column of the tree unfolds, revealing the list of Regions that can be selected.
    The nodes that have a darker gray colour represent "Intuitive Path" - they lead to nodes that are under your responsibility.
    The nodes that have a star symbol are under your responsibility.
    Click Next to continue to your favorites list.`,
      element: '.tour-diagram-body',
      on: 'right-start',
    })
  );

  dinamycStep.push(
    buildStep(tour, null, {
      id: 'favCard',
      title: 'Favorites Card',
      text: `After selecting the "Favorites" Node, you can now visualise your favorite organizations, and all that are under your responsibility.
    By clicking on the name, the graph will take you to that specific location.
    By clicking on the heart symbol, we can remove that item from the list.`,
      element: '.card',
      on: 'right-start',
    })
  );
};
