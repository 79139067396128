import React, { useContext, useState } from 'react';
import { Grid } from '@mui/material';
import EmwDatePicker from '../../../../../../../../../lib/commonv2/EmwDatePicker';
import { OrganizationCardContext } from '../../../../context/OrganizationCardContextProvider';
import EmwTypography from '../../../../../../../../components/EmwTypography/EmwTypography';
import TourElement from '../../../../../../../../components/Tour/TourElement/TourElement';
import { Box } from '@mui/system';

export default function FromToDates() {
  const context = useContext(OrganizationCardContext);
  const [dateFrom, setDateFrom] = useState();

  const handleDateFromChange = date => {
    setDateFrom(date);
    context.updateCardDates('date', date);
  };
  const handleDateToChange = date => {
    context.updateCardDates('endDate', date);
  };

  return (
    <>
      <TourElement id="tour-orgCard-dates">
        <Box display={'flex'} alignItems="center">
          {context.data.section1.date.visible && (
            <Grid item mx={1}>
              <EmwTypography fontSize="8">From:</EmwTypography>
            </Grid>
          )}

          {context.data.section1.date.visible && (
            <Grid item mr={1}>
              <EmwDatePicker
                inputId={`${
                  context.data.section1.name.value ? context.data.section1.name.value : 'create'
                }-start-date`}
                date={context.data.section1.date.value}
                disabled={!context.data.section1.date.enabled}
                handleChange={handleDateFromChange}
              />
            </Grid>
          )}

          {context.data.section1.endDate.visible && (
            <Grid item mr={1}>
              <EmwTypography fontSize="8">To:</EmwTypography>
            </Grid>
          )}

          {context.data.section1.endDate.visible && (
            <Grid item mr={1}>
              <EmwDatePicker
                inputId={`${
                  context.data.section1.name.value ? context.data.section1.name.value : 'create'
                }-end-date`}
                date={context.data.section1.endDate.value}
                disabled={!context.data.section1.endDate.enabled}
                handleChange={handleDateToChange}
                disablePast={true}
                minDate={dateFrom}
              />
            </Grid>
          )}
        </Box>
      </TourElement>
    </>
  );
}
