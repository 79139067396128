import React, { useEffect, useState, useContext } from 'react';
import { Collapse, Grid, IconButton } from '@mui/material';
import Section1FormItem from './Section1FormItem';
import PlusIcon from '../../../../../../../../../lib/icons/PlusIcon';
import { TdsIcon } from '@tds/react';
import EmwTypography from '../../../../../../../../components/EmwTypography/EmwTypography';
import TourElement from '../../../../../../../../components/Tour/TourElement/TourElement';
import { tourStatusSelector } from '../../../../../../redux/selectors';
import { useSelector, useDispatch } from 'react-redux';
import { OrganizationCardContext } from '../../../../context/OrganizationCardContextProvider';

const newITem = {
  btnAdd: { value: null, enabled: true, visible: true },
  btnEdit: { value: null, enabled: true, visible: true },
  id: -1,
  name: {
    value: null,
    enabled: false,
    visible: true,
  },
  comment: {
    value: null,
    enabled: true,
    visible: true,
  },
  type: {
    value: 0,
    enabled: false,
    visible: true,
  },
  startDate: {
    value: null,
    enabled: true,
    visible: true,
  },
  endDate: {
    value: null,
    enabled: true,
    visible: true,
  },
  visible: true,
  dateAdded: '',
};

export default function TogglePanel({
  title,
  type,
  open,
  list,
  showArrows,
  propKey,
  targetField,
  section,
  sectionCer,
  id,
  isReferent,
  isOtherReferent,
  categories,
}) {
  const [isOpen, setIsOpen] = useState(open ? open : false);
  const context = useContext(OrganizationCardContext);
  const [items, setItems] = useState([]);
  const [canAdd, setCanAdd] = useState(false);
  const [hasNewItem, setHasNewItem] = useState(false);
  const tour = useSelector(tourStatusSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (tour) {
      setIsOpen(true);
    }
  }, [tour]);

  useEffect(() => {
    if (!isOpen) {
      setIsOpen(context.shouldBeOpen(list, targetField.key));
    }
  }, []);

  useEffect(() => {
    if (section === 'sectionCer') {
      setCanAdd(sectionCer.canAdd);
    }

    if (list.length > 0) {
      setCanAdd(list[0].btnAdd.visible);
      const filteredList = [...list].filter(listItems => listItems);
      const sortedItems = [];

      //Sort CER items - display new items first
      filteredList.forEach(item => {
        if (item.hasOwnProperty('dateAdded') && item.dateAdded) {
          sortedItems.unshift(item);
        } else {
          sortedItems.push(item);
        }
      });

      setItems(
        sortedItems.map((item, index) => {
          return (
            <TourElement id="tour-orgCard-Section1DropdownContent">
              <Section1FormItem
                key={item.id + '_' + index}
                item={item}
                type={type}
                isNew={false}
                propKey={propKey}
                index={index}
                targetKey={targetField.key}
                section={section}
                title={title}
                isOtherReferent={isOtherReferent}
                categories={categories}
              />
            </TourElement>
          );
        })
      );
    }
  }, [list]);

  const addItem = () => {
    const _items = [...items];
    if (section === 'sectionCer') {
      setHasNewItem(true);
      setIsOpen(true);
      const _newItem = { ...newITem, dateAdded: new Date().getTime() };

      _items.unshift(
        <Section1FormItem
          item={_newItem}
          type={type}
          showArrows={true}
          isNew={false}
          isEditOpen={false}
          index={_items.length}
          propKey={propKey}
          targetKey={targetField.key}
          section={section}
          title={title}
        />
      );
    }
    if (isReferent) {
      let _newItem = {};
      if (isOtherReferent) {
        _newItem = {
          ...newITem,
          type: { visible: false },
          category: { visible: true, enabled: true, value: null },
        };
      } else {
        _newItem = {
          ...newITem,
          type: { visible: false },
          category: { visible: false, enabled: false, value: null },
        };
      }
      _items.push(
        <Section1FormItem
          item={_newItem}
          type={type}
          showArrows={showArrows}
          isNew={true}
          isEditOpen={false}
          index={_items.length}
          propKey={propKey}
          targetKey={targetField.key}
          section={section}
          title={title}
          isOtherReferent={isOtherReferent}
          categories={categories}
        />
      );
    } else {
      _items.push(
        <Section1FormItem
          item={newITem}
          type={type}
          showArrows={showArrows}
          isNew={false}
          isEditOpen={false}
          index={_items.length}
          propKey={propKey}
          targetKey={targetField.key}
          section={section}
          title={title}
        />
      );
    }

    setItems(_items);
  };

  return (
    <>
      <Grid
        item
        className="toggle-panel"
        sx={type === 'CER' ? { paddingLeft: 0 + '!important' } : {}}
      >
        <Grid container direction="row" alignItems="center">
          {type === 'CER' && (
            <Grid item mx={1}>
              <TdsIcon icon="public" style={{ color: '#4a4d65', fontSize: '16px' }} />
            </Grid>
          )}
          <Grid item>
            <EmwTypography component="caption-2" classes="text-bluegrey-600" fontWeight="bold">
              {type.toLocaleUpperCase()}
            </EmwTypography>
          </Grid>
          <Grid item ml="auto">
            <Grid container alignItems="center">
              {canAdd && (
                <IconButton
                  title="Add new"
                  sx={{ padding: 0 }}
                  disableRipple
                  onClick={addItem}
                  id={`btnAdd${title}`}
                >
                  <PlusIcon />
                </IconButton>
              )}

              {(list.length > 0 || hasNewItem) && (
                <IconButton
                  title={`${isOpen ? 'Collapse section' : 'Expand section'}`}
                  id={id && !isOpen ? id : `btnToggle${type}`}
                  disableRipple
                  size="small"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  {isOpen ? (
                    <TdsIcon icon="keyboard_arrow_up" size="small" style={{ color: '#4A4D65' }} />
                  ) : (
                    <TdsIcon icon="keyboard_arrow_down" size="small" style={{ color: '#4A4D65' }} />
                  )}
                </IconButton>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Collapse in={isOpen} sx={{ width: '100%', display: 'flex' }}>
        {items}
      </Collapse>
    </>
  );
}
