import React, { useEffect, useState, useContext } from 'react';
import { Box, Collapse, Grid, IconButton } from '@mui/material';
import { StyledTogglePanel } from '../../../../../../../../../modules/organization/components/organizationCard/components/OrganizationCardStyled';
import EmwTypography from '../../../../../../../../components/EmwTypography/EmwTypography';
import { ENGINEER_TYPE } from '../../../../../../constants';
import { TdsIcon } from '@tds/react';
import TogglePanel from './TogglePanel';
import { tourStatusSelector } from '../../../../../../redux/selectors';
import { useSelector } from 'react-redux';
import { OrganizationCardContext } from '../../../../context/OrganizationCardContextProvider';

export default function Referees({ section1, targetField, open }) {
  const [isOpen, setIsOpen] = useState(open);
  const tour = useSelector(tourStatusSelector);
  const context = useContext(OrganizationCardContext);
  const key = targetField.key.toLowerCase();
  const shouldNotOpenReferents = !(key.includes('hoeo') || key.includes('kpi'));

  useEffect(() => {
    if (tour) {
      setIsOpen(true);
    }
  }, [tour]);

  useEffect(() => {
    if (!isOpen && shouldNotOpenReferents) {
      setIsOpen(context.shouldBeOpen(section1, targetField.key));
    }
  }, []);

  return (
    <>
      <StyledTogglePanel item sx={{ flexGrow: 1 }}>
        <Grid container direction="row" alignItems="center">
          <Grid item>
            <EmwTypography classes="text-bluegrey-600" fontSize="9" fontWeight="bold">
              REFERENTS
            </EmwTypography>
          </Grid>
          <Grid item ml="auto">
            <Grid container alignItems="center">
              <IconButton
                title={`${isOpen ? 'Collapse section' : 'Expand section'}`}
                disableRipple
                size="small"
                onClick={() => setIsOpen(!isOpen)}
                id={`btnEditViewToggleReferees`}
              >
                {isOpen ? (
                  <TdsIcon icon="keyboard_arrow_up" size="small" style={{ color: '#4A4D65' }} />
                ) : (
                  <TdsIcon icon="keyboard_arrow_down" size="small" style={{ color: '#4A4D65' }} />
                )}
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </StyledTogglePanel>

      <Collapse in={isOpen} className="ml-m">
        <Box mt={1}>
          <TogglePanel
            title={ENGINEER_TYPE.DATARRef.title}
            type={ENGINEER_TYPE.DATARRef.label}
            propKey="dr"
            list={section1.dr}
            open={open}
            showArrows={section1.dr.length > 1}
            targetField={targetField}
            section={'section1'}
            isReferent={true}
          />
        </Box>
        <Box mt={1}>
          <TogglePanel
            title={ENGINEER_TYPE.CYBSRRef.title}
            type={ENGINEER_TYPE.CYBSRRef.label}
            propKey="cybsr"
            list={section1.cybsr}
            open={open}
            showArrows={section1.cybsr.length > 1}
            targetField={targetField}
            section={'section1'}
            isReferent={true}
          />
        </Box>
        <Box mt={1}>
          <TogglePanel
            title={ENGINEER_TYPE.ECODRRef.title}
            type={ENGINEER_TYPE.ECODRRef.label}
            propKey="ecodr"
            list={section1.ecodr}
            open={open}
            showArrows={section1.ecodr.length > 1}
            targetField={targetField}
            section={'section1'}
            isReferent={true}
          />
        </Box>
        <Box mt={1}>
          <TogglePanel
            title={ENGINEER_TYPE.UXDRRef.title}
            type={ENGINEER_TYPE.UXDRRef.label}
            propKey="uxdr"
            list={section1.uxdr}
            open={open}
            showArrows={section1.uxdr.length > 1}
            targetField={targetField}
            section={'section1'}
            isReferent={true}
          />
        </Box>
        <Box mt={1}>
          <TogglePanel
            title={ENGINEER_TYPE.DPRef.title}
            type={ENGINEER_TYPE.DPRef.label}
            propKey="dp"
            list={section1.dp}
            open={open}
            showArrows={section1.dp.length > 1}
            targetField={targetField}
            section={'section1'}
            isReferent={true}
          />
        </Box>
        <Box mt={1}>
          <TogglePanel
            title={ENGINEER_TYPE.MOBFRef.title}
            type={ENGINEER_TYPE.MOBFRef.label}
            propKey="mobfr"
            list={section1.mobfr}
            open={open}
            showArrows={section1.mobfr.length > 1}
            targetField={targetField}
            section={'section1'}
            isReferent={true}
          />
        </Box>
        <Box mt={1}>
          <TogglePanel
            title={ENGINEER_TYPE.EQ.title}
            type={ENGINEER_TYPE.EQ.label}
            propKey="eql"
            list={section1.eql}
            open={open}
            showArrows={section1.eql.length > 1}
            targetField={targetField}
            section={'section1'}
            isReferent={true}
          />
        </Box>
        <Box mt={1}>
          <TogglePanel
            title={ENGINEER_TYPE.OTHERRef.title}
            type={ENGINEER_TYPE.OTHERRef.title}
            propKey="otherReferents"
            list={section1.otherReferents}
            categories={section1.referentCategories}
            open={open}
            showArrows={section1.otherReferents.length > 1}
            targetField={targetField}
            section={'section1'}
            isReferent={true}
            isOtherReferent={true}
          />
        </Box>
      </Collapse>
    </>
  );
}
