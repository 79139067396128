import React, { useEffect } from 'react';
import { StyledBarChart } from './styled';
import { ResponsiveBar } from '@nivo/bar';
import useChartUtils from '../../hooks/useChartUtils';
import ChartInfo from '../ChartInfo/ChartInfo';
import ChartTooltip from '../ChartTooltip/ChartTooltip';
import ChartBarTooltip from '../ChartBarTooltip/ChartBarTooltip';

export default function MultipleBarChart({
  name,
  data,
  keys,
  defs,
  fill,
  info,
  tooltipInfo,
  colorMapping,
  comingSoon = false,
  unit = '%',
}) {
  const chartUtils = useChartUtils({ data, colorMapping });

  return (
    <StyledBarChart className="flex flex-col bg-white-100 rounded p-s shadow-s-strong relative">
      <div className="flex flex-row justify-between">
        <p className="text-scale-7 font-bold ml-s">{name}</p>
        <ChartTooltip title={tooltipInfo} />
      </div>
      <div className="flex-1 chart">
        <ResponsiveBar
          data={data}
          keys={keys}
          indexBy="label"
          margin={chartUtils.margin}
          padding={0.4}
          animate={true}
          enableLabel={true}
          groupMode="grouped"
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          defs={chartUtils.chartColors().defs()}
          fill={chartUtils.chartColors().fill()}
          axisTop={null}
          axisRight={null}
          axisLeft={chartUtils.axisLeft(unit)}
          tooltipLabel={tooltip => `${tooltip.data.label} <br />`}
          valueFormat={value => `${value}${unit}`}
          labelTextColor={'white'}
          tooltip={chartBar => <ChartBarTooltip chartBar={chartBar} />}
        />
      </div>

      <ChartInfo info={info} />
    </StyledBarChart>
  );
}
