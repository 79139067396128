import React, { useEffect, useState } from 'react';
import UsageMonitoringDrillToDetail from './UsageMonitoringDrillToDetail';
import UsageMonitoringDrillThrough from './UsageMonitoringDrillThrough';
import UsageMonitoringGraph from './UsageMonitoringGraph';
import { getUsageMonitoringIndicatorsApi } from '../../../redux/api';
import TimeFrame from '../../../../../components/EmwCharts/components/Filters/components/TimeFrame/TimeFrame';
import EmwTypography from '../../../../../components/EmwTypography/EmwTypography';
import { StyledUsageMonitoring } from '../styled';
import EmwButton from '../../../../../components/EmwButton/EmwButton';
import EmwIcon from '../../../../../components/EmwIcon/EmwIcon';
import GeneralFiltersContainer from '../../../../../components/EmwGeneralFilters/GeneralFiltersContainer';
import { useDispatch, useSelector } from 'react-redux';
import {
  rolesFiltersSelector,
  selectedGeneralFilters,
  servicesFiltersSelector,
} from '../../../../../components/EmwGeneralFilters/redux/selectors';
import useAppSnackbar from '../../../../../hooks/useAppSnankbar';
import { setLoading } from '../../../../../components/EmwGeneralFilters/redux/actions';
import moment from 'moment';
import { getFiltersPayload } from '../../../../../components/EmwGeneralFilters/utils';

export default function UsageMonitoring() {
  const [activeUsersData, setActiveUsersData] = useState([]);
  const selectedFilters = useSelector(selectedGeneralFilters);
  const [isUsageMonitoring, setIsUsageMonitoring] = useState(true);
  const [isDrillToDetails, setIsDrillToDetails] = useState(false);
  const [isDrillThrough, setIsDrillThrough] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const snackbar = useAppSnackbar();
  const dates = [];
  const rolesFilters = useSelector(rolesFiltersSelector);
  const servicesFilters = useSelector(servicesFiltersSelector);
  const generateDateOptions = months => {
    for (let i = 0; i < months; i++) {
      const date = moment().subtract(i, 'months');
      dates.push(date.format('MMM YY'));
    }
    return dates;
  };
  const currentMonth = generateDateOptions(21)[0];
  const defaultPeriod = '12 Months';
  const [stateData, setStateData] = useState({
    organizationFilters: getFiltersPayload(selectedFilters),
    periodFilters: {
      period: defaultPeriod,
      date: currentMonth,
    },
    roles: rolesFilters,
    services: servicesFilters,
  });

  const handleClick = () => {
    setIsOpen(!isOpen);
  };
  const handleClickBack = () => {
    window.hash = '';
    setIsOpen(!isOpen);
    window.location.hash = '';
    setIsUsageMonitoring(true);
    setIsDrillToDetails(false);
    setIsDrillThrough(false);
  };

  const handleClickDrillToDetails = () => {
    window.location.hash = 'drilltodetails';
    setIsUsageMonitoring(false);
    setIsDrillThrough(false);
    setIsDrillToDetails(!isDrillToDetails);
  };

  const handleClickDrillThrough = () => {
    window.location.hash = 'drillthrough';
    setIsUsageMonitoring(false);
    setIsDrillThrough(!isDrillThrough);
    setIsDrillToDetails(false);
  };

  useEffect(() => {
    setStateData(currentState => {
      return {
        ...currentState,
        organizationFilters: getFiltersPayload(selectedFilters),
        roles: rolesFilters,
        services: servicesFilters,
      };
    });
  }, [selectedFilters, rolesFilters, servicesFilters]);

  useEffect(() => {
    (async () => {
      dispatch(setLoading(true));

      try {
        const response = await getUsageMonitoringIndicatorsApi(stateData);
        setActiveUsersData(response.data);
      } catch (reason) {
        snackbar.show(reason.message || reason.statusText, 'warning');
      } finally {
        dispatch(setLoading(false));
      }
    })();
  }, [stateData]);

  return (
    <GeneralFiltersContainer>
      <div
        className="flex flex-col flex-1 overflow-auto px-xs pb-xs"
        style={{ backgroundColor: '#F7F7F9' }}
      >
        <div className="title-and-period flex mb-m items-center">
          {isUsageMonitoring && (
            <EmwTypography classes="text-primary-500" fontSize={4} fontWeight="bold">
              Usage Monitoring
            </EmwTypography>
          )}
          {isDrillToDetails && (
            <StyledUsageMonitoring>
              <div className="back flex items-center">
                <div className="flex justify-center items-center">
                  <EmwButton
                    variant="ghost"
                    size="small"
                    title="Drill to detail"
                    id="drillToDetail"
                    onClick={handleClickBack}
                  >
                    <EmwIcon
                      icon="arrow_back_ios"
                      variant="outlined"
                      size="xsmall"
                      classes="pl-l"
                    />
                    <EmwTypography fontSize="18" classes="text-black-100">
                      Back
                    </EmwTypography>
                  </EmwButton>
                </div>
                <EmwTypography classes="text-primary-500" fontSize={5} fontWeight="bold">
                  Usage Monitoring - drill to detail
                </EmwTypography>
              </div>
            </StyledUsageMonitoring>
          )}
          {isDrillThrough && (
            <StyledUsageMonitoring>
              <div className="back flex items-center">
                <div className="flex justify-center items-center">
                  <EmwButton
                    variant="ghost"
                    size="small"
                    title="Drill through"
                    id="drillThrough"
                    onClick={handleClickBack}
                  >
                    <EmwIcon
                      icon="arrow_back_ios"
                      variant="outlined"
                      size="xsmall"
                      classes="pl-l"
                    />
                    <EmwTypography fontSize="18" classes="text-black-100">
                      Back
                    </EmwTypography>
                  </EmwButton>
                </div>
                <EmwTypography classes="text-primary-500" fontSize={5} fontWeight="bold">
                  Usage Monitoring - drill through
                </EmwTypography>
              </div>
            </StyledUsageMonitoring>
          )}

          <div className="ml-auto">
            <TimeFrame
              filters={stateData}
              setFilters={setStateData}
              setActiveUsersData={setActiveUsersData}
              isUsageMonitoring={true}
              defaultPeriod={defaultPeriod}
              defaultDate={currentMonth}
            />
          </div>
        </div>

        {isUsageMonitoring && (
          <UsageMonitoringGraph
            activeUsersData={activeUsersData}
            handleClick={handleClick}
            handleClickDrillToDetails={handleClickDrillToDetails}
            handleClickDrillThrough={handleClickDrillThrough}
          />
        )}
        {isDrillToDetails && (
          <UsageMonitoringDrillToDetail
            payload={stateData}
            handleClickBack={handleClickBack}
            stateData={stateData}
          />
        )}
        {isDrillThrough && (
          <UsageMonitoringDrillThrough
            activeUsersData={activeUsersData}
            payload={stateData}
            handleClickBack={handleClickBack}
            stateData={stateData}
          />
        )}
      </div>
    </GeneralFiltersContainer>
  );
}
