import React, { useContext, useEffect, useState } from 'react';
import { EmwButton, EmwDropDownList } from '../../../../../../../lib/common';
import { TdsIcon } from '@tds/react';
import EmwDialog from '../../../../../../components/EmwDialog/EmwDialog';
import { useDispatch, useSelector } from 'react-redux';
import useAppSnackbar from '../../../../../../hooks/useAppSnankbar';
import { schemaSelector, gbuListSelector, perspectiveSelector } from '../../../../redux/selectors';
import { DiagramContext } from '../../../Diagram/context/DiagramContext';
import * as api from '../../../../redux/api';
import { Box, Grid } from '@mui/material';
import EmwTypography from '../../../../../../components/EmwTypography/EmwTypography';
import EmwTextField from '../../../../../../../lib/commonv2/EmwTextField';
import TourElement from '../../../../../../components/Tour/TourElement/TourElement';
import { ORGANIZATION_PERSPECTIVE } from '../../../../constants';
import { removeCountryCode } from '../../../../../../utils/countryUtils';
import { feedbackInsideModal } from '../../../../../feedback/redux/actions';
import { userActivity } from '../../../../../userActivity/redux/actions';
import { USER_ACTIVITY_SERVICES } from '../../../../../userActivity/constants';
import { v4 as uuidv4 } from 'uuid';

export default function AllocateCC({ btn, data, name, setName, cardName }) {
  const snackbar = useAppSnackbar();
  const schema = useSelector(schemaSelector);
  const perspective = useSelector(perspectiveSelector);
  const diagramContext = useContext(DiagramContext);
  const gbuList = useSelector(gbuListSelector);
  const [isOpen, setIsOpen] = useState(false);
  const [gbuValue, setGbuValue] = useState('');
  const [blValue, setBlValue] = useState('');
  const [gbuOptions, setGbuOptions] = useState([]);
  const [blOptions, setBlOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    dispatch(
      userActivity({
        uniqueId: uuidv4(),
        service: USER_ACTIVITY_SERVICES.ALLOCATE_CC,
        details: 'VIEW',
        uri: '',
      })
    );
    setGbuValue(schema.path.selectedGBU.code || removeCountryCode(schema.path.selectedCBU.code));

    setGbuOptions(() => {
      return gbuList.map(gbu => ({ value: gbu.code, text: gbu.code }));
    });
  }, [isOpen]);

  useEffect(() => {
    if (!gbuValue || !isOpen) {
      return;
    }

    setBlOptions(() => {
      const regExp = /^(.*)-([a-zA-Z]{2})$/;
      let filteredGbuValue = gbuValue;
      if (regExp.test(gbuValue)) {
        filteredGbuValue = regExp.exec(gbuValue)[1];
      }

      const selectedGbu = gbuList.find(gbu => gbu.code === filteredGbuValue);
      const selectedBl = schema.path.selectedBL.code;

      return selectedGbu.childs
        .filter(bl => {
          return bl.code !== selectedBl;
        })
        .map(bl => ({ value: bl.code, text: bl.code }));
    });
  }, [isOpen, gbuValue]);

  const toggle = () => {
    dispatch(feedbackInsideModal(true));
    setIsOpen(!isOpen);
  };

  const saveHandler = async () => {
    const payload = {
      id: data.cardDetails.id,
      blCode: blValue,
      originBlCode: data.cardDetails.blCode,
      name: name,
    };

    try {
      setIsLoading(true);
      const response = await api.allocateCCApi(payload);
      snackbar.show(response.message, response.messageType);

      if (perspective === ORGANIZATION_PERSPECTIVE.BY_COUNTRY) {
        await diagramContext.fetchAndSelectNode(
          schema.path.selectedBL.id,
          'DOMAIN',
          null,
          null,
          data.cardDetails.parentCountryId
        );
        await diagramContext.fetchAndSelectNode(
          data.cardDetails.id,
          response.data.cardDetails.type,
          data.cardDetails.blCode,
          null,
          data.cardDetails.parentCountryId
        );
      } else {
        await diagramContext.fetchAndSelectNode(schema.path.selectedBL.id, 'BL');
        await diagramContext.fetchAndSelectNode(
          data.cardDetails.id,
          response.data.cardDetails.type,
          data.cardDetails.blCode
        );
      }
      setIsOpen(false);
    } catch (e) {
      snackbar.show(e.statusText, 'error');
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const onChange = e => {
    setName(e.currentTarget.value);
  };

  return (
    <Grid item ml={0.5}>
      <EmwButton
        variant="ghost"
        size="small"
        disabled={!btn.enabled}
        title="Allocate CC"
        id="btnAllocateCC"
        onClick={toggle}
      >
        <TdsIcon icon="settings" variant="outlined" size="large" />
      </EmwButton>
      {isOpen && (
        <EmwDialog
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          title={`Allocate ${cardName} to GBU/BL`}
          actionButton="Save"
          callback={saveHandler}
          icon="settings"
          classes="settings-icon"
          width="455px"
          loading={isLoading}
          disabled={!blValue}
          dispalyFeedback={true}
        >
          <TourElement id="allocateName">
            <Box textAlign="center">
              <EmwTypography classes="popupTextFirst" fontSize={7} fontFamily="roboto">
                Name
              </EmwTypography>
              <EmwTextField
                name={name}
                value={name}
                atributes={{}}
                id="cc_name"
                onChange={onChange}
                size="medium"
              />
            </Box>
          </TourElement>
          <TourElement id="allocateGBU">
            <Grid container display={'flex'} justifyContent="space-between" mt={3}>
              <Grid item>
                <Box>
                  <EmwTypography fontSize={7} fontFamily="roboto">
                    GBU
                  </EmwTypography>
                </Box>
                <Box
                  sx={{
                    width: '190px',
                    '.qtm-menu-item-list': {
                      background: 'white',
                      width: '190px',
                      position: 'fixed',
                      zIndex: 1500,
                      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)',
                    },
                  }}
                >
                  <EmwDropDownList
                    options={gbuOptions}
                    onDropDownListChanged={e => setGbuValue(e.target.value)}
                    disabled={false}
                    value={gbuValue}
                    placement="bottom"
                    inputId="GBU"
                  />
                </Box>
              </Grid>
              <Grid item>
                <Box>
                  <EmwTypography fontSize={7} fontFamily="roboto">
                    BL
                  </EmwTypography>
                </Box>
                <Box
                  sx={{
                    width: '190px',
                    '.qtm-menu-item-list': {
                      background: 'white',
                      width: '190px',
                      position: 'fixed',
                      zIndex: 1500,
                      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)',
                    },
                  }}
                >
                  <EmwDropDownList
                    placeHolder="Select option"
                    options={blOptions}
                    onDropDownListChanged={e => setBlValue(e.target.value)}
                    disabled={false}
                    value={blValue}
                    placement="bottom"
                    inputId="BL"
                  />
                </Box>
              </Grid>
            </Grid>
          </TourElement>
        </EmwDialog>
      )}
    </Grid>
  );
}
