import LocalStorage from './util/localStorage';
import history from './browserHistory';
import { computeURL, fetch, fetchOptions } from './util/fetch';

const redirectToLogin = () => {
  if (localStorage.getItem('login.dev')) {
    localStorage.clear();
    window.location.href = '/';
  } else {
    const logout = async () => {
      const url = computeURL('security', 'logout');
      const method = 'GET';
      return fetch(url, fetchOptions({ method }));
    };

    logout().then(response => {
      window.location.href = '/logout';
    });
  }
};

export default () => next => action => {
  if (action.payload instanceof Promise) {
    const apiAction = {
      ...action,
      payload: null,
      meta: { ...action.meta, fetching: true },
    };

    next(apiAction);

    action.payload.then(
      result =>
        next({
          ...action,
          payload: result,
        }),
      error => {
        next({
          ...action,
          payload: error,
          error: true,
        });

        if (
          error.url &&
          !error.url.endsWith('/security/login') &&
          (error.status === 401 || error.status === 403 || error.status === 504)
        ) {
          redirectToLogin();
        }
      }
    );
  } else {
    next(action);
  }
};
