import React, { useContext, useEffect, useState } from 'react';
import { Collapse, Grid, IconButton, Box } from '@mui/material';
import { TdsIcon } from '@tds/react';
import { EmwCheckbox } from '../../../../../../../../../lib/common';
import EmwTypography from '../../../../../../../../components/EmwTypography/EmwTypography';
import ExportOrganizationListItem from './ExportOrganizationListItem';
import { StyledExportOrganizationHead } from './styled';
import { ExportOrganizationContext } from '../../context/ExportOrganizationContext';
import deepdash from 'deepdash';
import { generateRandom } from '../../../../../../../../../util/generateRandom';

deepdash(_);

const deliveryChildren = [
  'deliveryNameRequested',
  'hoedRequested',
  'solutionsRequested',
  'edmRequested',
  'pdaRequested',
  'daRequested',
  'wpRequested',
  'wpmRequested',
  'wpmManagerRequested',
  'edmManagerRequested',
];

const solutionChildren = [
  'edmRequested',
  'pdaRequested',
  'daRequested',
  'wpRequested',
  'wpmRequested',
];

const disciplineChildren = ['hodRequested', 'disciplineNameRequested', 'engineersRequested'];

export default function ExportOrganizationList({ name, value, list, icon, expandAll }) {
  const [isOpen, setIsOpen] = useState(false || expandAll);
  const [openAll, setOpenAll] = useState(false || expandAll);
  const context = useContext(ExportOrganizationContext);

  const handleClick = (e, value) => {
    const { checked } = e.target;
    if (value === 'deliveriesRequested') {
      deliveryChildren.forEach(child => {
        context.setEngRequested(child, checked);
      });
    }
    if (value === 'edmManagerRequested') {
      deliveryChildren.forEach(child => {
        context.setEngRequested(child, checked);
      });
    }
    if (value === 'wpmManagerRequested') {
      deliveryChildren.forEach(child => {
        context.setEngRequested(child, checked);
      });
    }
    if (value === 'solutionsRequested') {
      solutionChildren.forEach(child => {
        context.setEngRequested(child, checked);
      });
    }
    if (value === 'wpRequested') {
      context.setEngRequested('wpmRequested', checked);
    }
    if (value === 'disciplinesRequested') {
      disciplineChildren.forEach(child => {
        context.setEngRequested(child, checked);
      });
    }
    context.setEngRequested(value, checked);
  };

  useEffect(() => {
    return () => {
      setOpenAll(false);
      setIsOpen(false);
    };
  }, [list]);

  const handleCloseOrganizationList = () => {
    if (openAll && isOpen) {
      setOpenAll(!openAll);
    }
    setIsOpen(!isOpen);
  };

  const renderList = () => {
    return list.map((item, index) => {
      return (
        <ExportOrganizationListItem
          key={item.id + generateRandom()}
          item={item}
          expandAll={openAll}
          id={`${name}-${index}`}
        />
      );
    });
  };

  return (
    <Grid container id="export-org-list" sx={{ display: 'flex' }} xs={12}>
      <StyledExportOrganizationHead container xs={9} id="export-org-list-head">
        {icon && (
          <Grid item mx={1}>
            <TdsIcon icon={icon} variant="outlined" classes="text-bluegrey-600"></TdsIcon>
          </Grid>
        )}
        <EmwTypography classes="text-bluegrey-600" fontSize="9" fontWeight="bold">
          {name}
        </EmwTypography>
        <Grid item ml={'auto'}>
          <Grid container>
            <IconButton
              title={`${isOpen ? 'Collapse section' : 'Expand section'}`}
              disableRipple
              size="small"
              id={`btnToggle${name}`}
              onClick={() => handleCloseOrganizationList()}
              sx={{ marginLeft: 'auto' }}
            >
              {isOpen ? (
                <TdsIcon icon="keyboard_arrow_up" size="small" style={{ color: '#4A4D65' }} />
              ) : (
                <TdsIcon icon="keyboard_arrow_down" size="small" style={{ color: '#4A4D65' }} />
              )}
            </IconButton>
          </Grid>
        </Grid>
      </StyledExportOrganizationHead>
      <Grid item ml={2} className="mt-xs ml-m">
        <EmwCheckbox
          size="small"
          checked={context.state[value]}
          // indeterminate={indeterminate}
          onCheckBoxChanged={event => handleClick(event, value)}
        />
      </Grid>
      <Collapse in={isOpen || openAll} sx={{ width: '100%' }}>
        <Box className="mt-xxs"> {renderList()}</Box>
      </Collapse>
    </Grid>
  );
}
