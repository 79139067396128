import React from 'react';

export default function BulbIcon() {
  return (
    <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.53382 0.666504C4.24571 0.666504 3.95133 0.693171 3.65696 0.759837C1.92828 1.11317 0.550346 2.59984 0.224653 4.43984C-0.0759862 6.17984 0.525293 7.77984 1.61511 8.81317C1.88444 9.0665 2.02849 9.41984 2.02849 9.79317V11.3332C2.02849 12.0665 2.59219 12.6665 3.28116 12.6665H3.45653C3.67575 13.0665 4.07034 13.3332 4.53382 13.3332C4.99731 13.3332 5.39816 13.0665 5.61111 12.6665H5.78649C6.47545 12.6665 7.03915 12.0665 7.03915 11.3332V9.79317C7.03915 9.4265 7.17695 9.0665 7.44001 8.81984C8.34819 7.9665 8.91815 6.71984 8.91815 5.33317C8.91815 2.75317 6.95773 0.666504 4.53382 0.666504ZM5.78649 9.99984H3.28116V9.33317H5.78649V9.99984ZM3.28116 11.3332V10.6665H5.78649V11.3332H3.28116ZM6.60698 7.8265C6.55061 7.87984 6.50677 7.9465 6.45666 7.99984H2.60472C2.55461 7.93984 2.51077 7.87317 2.4544 7.81984C1.62764 7.03317 1.2581 5.85984 1.45853 4.6865C1.68401 3.39317 2.68614 2.31984 3.89496 2.0665C4.10792 2.01984 4.32087 1.99984 4.53382 1.99984C6.2625 1.99984 7.66549 3.49317 7.66549 5.33317C7.66549 6.2865 7.28342 7.19317 6.60698 7.8265Z"
        fill="#4A4D65"
      />
      <path d="M4.84699 5.99984H4.22066V7.99984H4.84699V5.99984Z" fill="#4A4D65" />
      <path
        d="M3.51885 4.58232L3.07597 5.05372L4.40461 6.46792L4.84749 5.99652L3.51885 4.58232Z"
        fill="#4A4D65"
      />
      <path
        d="M4.22128 6.00329L4.66416 6.47469L5.9928 5.06049L5.54992 4.58909L4.22128 6.00329Z"
        fill="#4A4D65"
      />
    </svg>
  );
}
