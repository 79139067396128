import { styled } from '@mui/material';
import { getZoom } from '../../../../utils/sizeUtils';

const zoom = getZoom();

export const StyledEmwUnderMaintenanceLogo = styled('img')(({ theme }) => {
  return {
    zoom: `${zoom}%`,
    height: '430px',
    width: 'auto',
    cursor: 'pointer',
  };
});
