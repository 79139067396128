export const timeUtils = () => {
  const date = new Date();
  const month = date.getMonth();
  const startYear = new Date().getFullYear() + 1;
  const currentYear = startYear - 1;
  const endYear = 2022;

  const years = getYear(startYear, endYear);
  const quarters = getYearQuarters();
  const currentQuarter = getCurrentQuarter(month);

  return { startYear, currentYear, endYear, years, quarters, currentQuarter };
};

const getYear = (startYear, endYear) => {
    const years = Array.from({ length: startYear - endYear + 1 }, (_, index) => startYear - index);
    const yearList = years.map(year => ({ value: year, text: year }));
    return yearList
}

const getCurrentQuarter = month => {
  return `Q${Math.floor(month / 3) + 1}`;
};

const getYearQuarters = () => {
  const quarters = ['All'];
  for (let quarter = 1; quarter <= 4; quarter++) {
    quarters.push(`Q${quarter}`);
  }

  const quarterList = quarters.map(quarter => ({ value: quarter, text: quarter }));
  return quarterList;
};
