import { createSelector } from '@reduxjs/toolkit';
import * as actions from './actions';
import { MODULE_NAME } from '../constants';
import { PBI_C3_URLS } from './actions';

export const header = state => state[MODULE_NAME];
export const notificationsSelector = createSelector(
  header,
  state => state[actions.GET_NOTIFICATIONS]
);
export const biPropsSelector = createSelector(header, state => state[actions.GET_BI_PROPS]);
export const userSelector = createSelector(header, state => state[actions.GET_USER]);
export const setUserSelector = createSelector(header, state => state[actions.SET_USER]);
export const treeDataSelector = createSelector(header, state => state[actions.GET_TREE_DATA]);
export const hzaIframeSelector = createSelector(header, state => state[actions.HZA_IFRAME_LOADED]);
export const pbiC3UrlsSelector = createSelector(header, state => state[actions.PBI_C3_URLS]);
export const dependenciesLoadedSelector = createSelector(
  header,
  state => state[actions.DEPENDENCIES_LOADED]
);
