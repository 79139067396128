import React, { useState } from 'react';
import useZoom from '../../utils/useZoom';
import { ClickAwayListener, Tooltip } from '@mui/material';

export default function EmwTooltip({ title, children, maxWidth }) {
  const [open, setOpen] = useState(false);

  const zoom = useZoom().zoom();
  const positionRef = React.useRef({
    x: 0,
    y: 0,
  });
  const popperRef = React.useRef(null);
  const areaRef = React.useRef(null);

  const handleMouseMove = event => {
    positionRef.current = { x: event.clientX, y: event.clientY };
  };

  const handleOnMouseEnter = event => {
    setOpen(true);
  };

  // force zoom workaround
  if (zoom < 100) {
    return (
      <div onMouseLeave={() => setOpen(false)}>
        <Tooltip
          placement="bottom-start"
          arrow
          title={title}
          ref={areaRef}
          componentsProps={{
            tooltip: {
              sx: {
                maxWidth: { maxWidth },
              },
            },
          }}
          onMouseMove={handleMouseMove}
          PopperProps={{
            keepMounted: true,
            disablePortal: false,
            popperRef,
            onMouseLeave: () => setOpen(false),
            anchorEl: () => {
              if (zoom < 100) {
                return {
                  getBoundingClientRect: () => {
                    return new DOMRect(
                      positionRef.current.x + 3,
                      areaRef.current.getBoundingClientRect().y * (zoom / 100) + 10,
                      0,
                      0
                    );
                  },
                };
              }
            },
          }}
          enterTouchDelay={0}
          leaveTouchDelay={500}
          leaveDelay={500}
          open={open}
        >
          <div onMouseEnter={handleOnMouseEnter}>{children}</div>
        </Tooltip>
      </div>
    );
  }

  return (
    <div onMouseLeave={() => setOpen(false)}>
      <Tooltip
        placement="bottom-start"
        arrow
        title={title}
        ref={areaRef}
        onMouseMove={handleMouseMove}
        enterTouchDelay={0}
        leaveTouchDelay={500}
        leaveDelay={500}
        open={open}
        componentsProps={{
          tooltip: {
            sx: {
              maxWidth: { maxWidth },
            },
          },
        }}
      >
        <div onMouseEnter={handleOnMouseEnter}>{children}</div>
      </Tooltip>
    </div>
  );
}
