import React from 'react';
import TriangleUpIcon from '../../../../../../../lib/icons/TriangleUpIcon';
import TriangleDownIcon from '../../../../../../../lib/icons/TriangleDownIcon';
import TargetIcon from '../../../../../../../lib/icons/TargetIcon';
import { StyledAlertOption } from './styled';

const ALERT_TYPE = {
  ABOVE: 'above',
  BELLOW: 'below',
  TARGET: 'target',
};

export default function AlertOption({ alert }) {
  const renderIcon = () => {
    if (alert.type === ALERT_TYPE.ABOVE) {
      return <TriangleUpIcon />;
    }

    if (alert.type === ALERT_TYPE.BELLOW) {
      return <TriangleDownIcon />;
    }

    if (alert.type === ALERT_TYPE.TARGET) {
      return <TargetIcon />;
    }
  };

  return (
    <StyledAlertOption value={alert.id}>
      <div className="alert-option mr-xxs">
        <div className="mr-xxs">{renderIcon()}</div>
        {alert.value}
      </div>
      {alert.orgName}
    </StyledAlertOption>
  );
}
