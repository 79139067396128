import React, { useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import ExportOrganizationList from './ExportOrganizationList';
import ExportEngineersList from '../Section1/ExportEngineersList';
import { ExportOrganizationContext } from '../../context/ExportOrganizationContext';

export default function ExportOrganizationListItem({ item, expandAll }) {
  const [openAll, setOpenAll] = useState(false || expandAll);
  const context = useContext(ExportOrganizationContext);

  const handleClick = (event, value) => {
    const { checked } = event.target;
    context.setEngRequested(value, checked);
  };

  const renderLeader = () => {
    if (item.hasOwnProperty('leader') && item.leader.name !== 'EDM') {
      return (
        <Box className="ml-l mr-xs">
          <ExportEngineersList
            label={item.leader.label}
            value={item.leader.value}
            title={item.leader.title}
            handleClick={handleClick}
            checked={context.state[item.leader.value]}
          />
        </Box>
      );
    }
  };

  const renderDeliveryNames = () => {
    if (!item.hasOwnProperty('delivery')) {
      return null;
    }
    return (
      <Box className="ml-s mr-xxs mt-xxs">
        <ExportEngineersList
          value={item.delivery.value}
          title={'Delivery Discipline'}
          handleClick={handleClick}
          checked={context.state[item.delivery.value]}
        />
      </Box>
    );
  };

  const renderDisciplineNames = () => {
    if (!item.hasOwnProperty('discipline')) {
      return null;
    }
    return (
      <Box className="ml-s mr-xxs mt-xxs">
        <ExportEngineersList
          value={item.discipline.value}
          title={item.discipline.title}
          handleClick={handleClick}
          checked={context.state[item.discipline.value]}
        />
      </Box>
    );
  };

  const renderWpms = () => {
    if (!item.hasOwnProperty('wpms')) {
      return null;
    }
    return (
      <Box className="ml-s mt-xxs mr-xxs">
        <ExportOrganizationList
          name="WORK PACKAGE"
          list={item.wpms}
          icon="work_outline"
          expandAll={openAll}
          value={item.wpms[0].value}
        />
      </Box>
    );
  };

  const renderChildren = () => {
    if (item.hasOwnProperty('solutions')) {
      return (
        <Box className="mt-xxs ml-l mr-xs">
          <ExportOrganizationList
            name="SOLUTIONS"
            list={item.solutions}
            icon="emoji_objects"
            expandAll={openAll}
            value={item.solutions[0].value}
          />
        </Box>
      );
    }

    if (item.hasOwnProperty('engineers')) {
      return (
        <Box className="ml-xxl" sx={{ marginRight: '13px' }}>
          <ExportEngineersList
            type={item.engineers.name}
            value={item.engineers.value}
            title={item.engineers.title}
            handleClick={handleClick}
            checked={context.state[item.engineers.value]}
          />
        </Box>
      );
    }
  };

  const renderManagers = () => {
    const engineersManagers = [];

    if (item.hasOwnProperty('edmManager')) {
      engineersManagers.push(
        <ExportEngineersList
          label={item.edmManager.label}
          value={item.edmManager.value}
          title={item.edmManager.title}
          handleClick={handleClick}
          checked={context.state[item.edmManager.value]}
        />
      );
    }

    if (item.hasOwnProperty('wpmManager')) {
      engineersManagers.push(
        <ExportEngineersList
          label={item.wpmManager.label}
          value={item.wpmManager.value}
          title={item.wpmManager.title}
          handleClick={handleClick}
          checked={context.state[item.wpmManager.value]}
        />
      );
    }
    return (
      engineersManagers.length > 0 && <Box className="mt-xxs ml-l mr-xs">{engineersManagers}</Box>
    );
  };

  const renderEngineersManagers = () => {
    const engineersManagers = [];

    if (item.hasOwnProperty('leader') && item.leader.name === 'EDM') {
      engineersManagers.push(
        <ExportEngineersList
          label={item.leader.label}
          value={item.leader.value}
          title={item.leader.title}
          handleClick={handleClick}
          checked={context.state[item.leader.value]}
        />
      );
    }

    if (item.hasOwnProperty('da')) {
      engineersManagers.push(
        <ExportEngineersList
          label={item.da.label}
          value={item.da.value}
          title={item.da.title}
          handleClick={handleClick}
          checked={context.state[item.da.value]}
        />
      );
    }
    if (item.hasOwnProperty('pda')) {
      engineersManagers.push(
        <ExportEngineersList
          label={item.pda.label}
          value={item.pda.value}
          title={item.pda.title}
          handleClick={handleClick}
          checked={context.state[item.pda.value]}
        />
      );
    }
    return (
      engineersManagers.length > 0 && <Box className="mt-xxs ml-s mr-xxs">{engineersManagers}</Box>
    );
  };

  return (
    <>
      {renderDeliveryNames()}
      {renderDisciplineNames()}
      {renderLeader()}
      {renderManagers()}
      {renderEngineersManagers()}
      {renderChildren()}
      {renderWpms()}
    </>
  );
}
