import React, { useContext, useEffect, useState } from 'react';
import { Collapse, Grid, IconButton } from '@mui/material';
import WorkPackage from './WorkPackage';
import { OrganizationCardContext } from '../../../../context/OrganizationCardContextProvider';
import { StyledTogglePanel } from '../../../../../../../../../modules/organization/components/organizationCard/components/OrganizationCardStyled';
import SuitCaseIcon from '../../../../../../../../../lib/icons/SuitCaseIcon';
import PlusIcon from '../../../../../../../../../lib/icons/PlusIcon';
import EmwTypography from '../../../../../../../../components/EmwTypography/EmwTypography';
import { reverseAndUnshift, sortValidItems, splitList } from '../../utils';
import { TdsIcon } from '@tds/react';

export default function WorkPackagesPanel({ wpms, deliveryIndex, solutionIndex, targetField }) {
  const context = useContext(OrganizationCardContext);
  const [isOpen, setIsOpen] = useState(false);
  const [canAdd, setCanAdd] = useState(false);
  const [openNewWpm, setOpenNewWpm] = useState(false);
  const [incompleteList, setIncompleteList] = useState([]);
  const [completeList, setCompleteList] = useState([]);

  useEffect(() => {
    setCanAdd(wpms[0].btnAdd.visible);
    setWpms();
  }, [wpms]);

  const setWpms = () => {
    if (!isOpen) {
      setIsOpen(context.shouldBeOpen(wpms, targetField.key));
    }

    const regExp = /^Work Package [0-9]+$/;
    let { _incompleteItems, _completeItems } = splitList(wpms, regExp);

    _incompleteItems = _incompleteItems.map(workPackage => {
      return (
        <WorkPackage
          key={workPackage.originalIndex}
          workPackage={workPackage}
          index={workPackage.originalIndex}
          deliveryIndex={deliveryIndex}
          solutionIndex={solutionIndex}
          color="#E6F8FC"
          targetField={targetField}
          openNewWpm={openNewWpm}
        />
      );
    });

    _completeItems = _completeItems.map(workPackage => {
      return (
        <WorkPackage
          key={workPackage.originalIndex}
          workPackage={workPackage}
          index={workPackage.originalIndex}
          deliveryIndex={deliveryIndex}
          solutionIndex={solutionIndex}
          color="#E6F8FC"
          targetField={targetField}
          openNewWpm={openNewWpm}
        />
      );
    });

    _incompleteItems.map((_item, index) => {
      if (!_item.props.workPackage.name.value || regExp.test(_item.props.workPackage.name.value)) {
        _item.props.workPackage.name.value = `Work Package ${index + 1}`;
      }

      return _item;
    });

    setIncompleteList(_incompleteItems);
    setCompleteList(_completeItems);
  };

  const addItem = () => {
    setIsOpen(true);
    setOpenNewWpm(true);
    context.addNewWorkPackage(deliveryIndex, solutionIndex);
  };

  return (
    <>
      <StyledTogglePanel
        item
        sx={{
          flexGrow: 1,
          borderRadius: isOpen ? '5px 5px 0 0' : '5px',
          cursor: 'pointer',
        }}
      >
        <Grid container direction="row" alignItems="center">
          <Grid item mr={1}>
            <SuitCaseIcon />
          </Grid>

          <Grid item>
            <EmwTypography component="caption-2" fontWeight="bold">
              Work Package
            </EmwTypography>
          </Grid>

          <Grid item ml="auto">
            <Grid container alignItems="center">
              {canAdd && (
                <IconButton title="Add new" sx={{ padding: 0 }} disableRipple onClick={addItem}>
                  <PlusIcon />
                </IconButton>
              )}
              <IconButton
                title={`${isOpen ? 'Collapse section' : 'Expand section'}`}
                disableRipple
                size="small"
                onClick={() => setIsOpen(!isOpen)}
                id={`${isOpen ? 'btnCollapseWorkPackages' : 'btnExpandWorkPackages'}`}
              >
                {isOpen ? (
                  <TdsIcon icon="keyboard_arrow_up" size="small" style={{ color: '#4A4D65' }} />
                ) : (
                  <TdsIcon icon="keyboard_arrow_down" size="small" style={{ color: '#4A4D65' }} />
                )}
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </StyledTogglePanel>

      <Collapse className="toggle-collapse" in={isOpen}>
        {reverseAndUnshift(incompleteList, 'workPackage')}
        {sortValidItems(completeList, 'workPackage')}
      </Collapse>
    </>
  );
}
