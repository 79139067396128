import { styled } from '@mui/material/styles';
import { Dialog } from '@mui/material';
import { getZoom } from '../../../../../../utils/sizeUtils';

export const StyledOrganizationDialog = styled(Dialog)(({ theme }) => {
  const zoom = getZoom();

  return {
    '.MuiPaper-root': {
      borderRadius: '6px',
    },

    '.dialog-header': {
      // maxHeight: "72px",

      zoom: `${zoom}%`,
      margin: '0 30px 12px 30px',
      padding: '23px 10px 11px 0',
      borderBottom: `2px solid ${theme.palette.primary[500]}`,
      // overflow: "hidden",

      '.description input': {
        width: '704px',
      },

      '.dialog-header-content': {
        height: '100%',
        flexWrap: 'nowrap',

        '#organizationCard-headCount-declared': {
          input: {
            display: 'flex',
          },
        },

        '.cardName-editBtn': {
          width: '33px',
          height: '29px',
        },

        '.headCount-editBtn': {
          width: '16px',
          height: '20px',
          i: {
            fontSize: '12px',
          },
        },

        '#organizationCard-cc-types': {
          padding: '5px',
          margin: '-5px',
          width: '100px',
          maxWidth: '106px',
          '.qtm-text-input.qtm-medium input, .qtm-text-input input': {
            height: '32px',
            borderRadius: '2px',
            fontSize: '12px',
          },
          '.dropdownLabelText': {
            margin: 0,
            fontSize: '12px',
            fontWeight: 'normal',
          },
        },
        '#tour-orgCard-engDomains': {
          padding: '3px',
          margin: '-3px',
        },
        '#tour-orgCard-headcount': {
          padding: '5px 2px',
          margin: '-5px -2px',
        },
        '#tour-orgCard-dates': {
          padding: '5px',
          margin: '-5px',
        },
        '#btnCloseModal': {
          padding: '3px',
          margin: '-3px',
        },
        '#btnSave': {
          padding: '3px',
          margin: '-3px',
        },
      },
    },

    '.dialog-content': {
      fontFamily: 'Roboto',
      'span, p, tds-typography, .MuiBox-root': {
        color: 'black',
      },

      '.toggle-collapse': {
        width: '100%',
        display: 'flex',
        border: '1px solid #CCF2F8',
        padding: '0 4px 4px 4px',
        flexGrow: 1,
      },

      '.toggle-panel': {
        backgroundColor: '#CCF2F8',
        borderRadius: '10px',
        padding: '0 15px',
        height: '50px',
        display: 'flex',
        flexGrow: 1,
        color: 'black',
      },

      '.panel-item': {
        fontSize: '10px',
        marginTop: '10px',
        borderRadius: '10px',
        padding: '10px',
        fontWeight: 'bold',
        backgroundColor: '#F7F7F9',

        '&.no-allocation': {
          border: `1px solid ${theme.palette.red[500]}`,
        },

        '&.selected-countries': {
          padding: '0px',
          border: '1px solid #cbf2f8',
        },
      },

      '.edit-button': {
        width: '33px',
        height: '29px',
      },
      '.discipline-input': {
        marginRight: '14px',
      },
      '.comment': {
        '& input': {
          height: '30px',
        },
        width: '100%',
      },
    },
  };
});
