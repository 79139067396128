export const DATA_COLLECTION_PAGES = [
  {
    key: 'data-collection',
    path: '/data-collection-evolution',
    name: 'Data Collection Evolution',
    icon: 'assessment',
    variant: 'outlined',
    disabled: false,
    permissions: 'showAdminPage',
    admin: false,
  },
];

const defs = [
  {
    id: 'eei',
    type: 'patternLines',
    background: 'inherit',
    color: '#0F186E',
    spacing: 0.1,
  },
  {
    id: 'wfb',
    type: 'patternLines',
    background: '#6871BF',
    color: '#6871BF',
    spacing: 1,
  },
];

const fill = [
  {
    match: {
      id: 'eei',
    },
    id: 'eei',
  },
  {
    match: {
      id: 'wfb',
    },
    id: 'wfb',
  },
];

export { defs, fill };
