import React from 'react';
import clx from 'classnames';

export default function ChartInfo({ info, comingSoon, color }) {
  const bulletClassName = clx('rounded-xl mr-xxs', {
    'bg-primary-500': !color,
    [`${color}`]: color,
  });
  if (Array.isArray(info)) {
    return (
      <div className="flex items-center ml-m">
        {info.map(value => {
          return (
            <>
              <div
                className="rounded-xl mx-xxs"
                style={{
                  backgroundColor: value.color,
                  width: '8px',
                  height: '8px',
                }}
              ></div>
              <p className="text-scale-8">{value.text}</p>
            </>
          );
        })}
      </div>
    );
  }

  return (
    <>
      {info && (
        <div className="flex bullet items-center ml-m">
          <div className={bulletClassName} />
          <p className="text-scale-8">{info}</p>
        </div>
      )}
      {comingSoon && (
        <div
          style={{
            position: 'absolute',
            width: '100%',
            textAlign: 'center',
            top: '45%',
          }}
        >
          <p className="text-primary-500">Coming soon</p>
        </div>
      )}
    </>
  );
}
