class LocalStorage {
  keys = {
    user: 'user',
    onLoginRoute: 'onLoginRoute',
    token: 'adal.token.keys',
    idToken: 'adal.idtoken',
  };

  get(key) {
    const data = localStorage.getItem(key);

    if (data) {
      return data;
    }

    if (data) {
      return JSON.parse(data);
    }

    return null;
  }

  set(key, data) {
    // localStorage.setItem(key, data)
    localStorage.setItem(key, JSON.stringify(data));
  }

  getUser() {
    return this.get(this.keys.user) || {};
  }

  setUser(user) {
    this.set(this.keys.user, user);
  }

  getOnLoginRoute() {
    return this.get(this.keys.onLoginRoute) || {};
  }

  setOnLoginRoute(route) {
    this.set(this.keys.onLoginRoute, route);
  }

  getToken() {
    return this.get(this.keys.idToken);
  }
}

export default new LocalStorage();
