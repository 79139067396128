import * as actions from './actions';
import { initialProps } from '../../../../util/reducer';
import { PBI_C3_URLS } from './actions';

export const initialState = {
  [actions.GET_USER]: {
    ...initialProps,
    list: {},
  },
  [actions.SET_USER]: {
    ...initialProps,
    list: {},
  },
  [actions.GET_TREE_DATA]: {
    ...initialProps,
    list: {},
  },
  [actions.GET_BI_PROPS]: {
    ...initialProps,
    list: {},
  },
  [actions.GET_BI_FILTERS]: {
    ...initialProps,
    list: {},
  },
  [actions.DELETE_LOGIN]: initialProps,
  [actions.GET_NOTIFICATIONS]: {
    ...initialProps,
    list: {},
  },
  [actions.HZA_IFRAME_LOADED]: false,
  [actions.DEPENDENCIES_LOADED]: false,
  [actions.PBI_C3_URLS]: [],
};
