import React from 'react';
import { styled } from '@mui/material/styles';
import backgroundImg from './BackgroundCookies.png';

const Wrapper = styled('div')(() => ({
  display: 'flex',
  backgroundColor: '#ffffff',
  background: `url(${backgroundImg}) no-repeat right bottom fixed`,
  backgroundSize: '750px',
  height: '100%',
  overflow: 'hidden',
  fontFamily: 'Roboto',
}));

export default function CookiesLayout({ children }) {
  return (
    <>
      <Wrapper>{children}</Wrapper>
    </>
  );
}
