import React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';

const CustomizedTooltip = styled(theme => ({
  tooltip: {
    backgroundColor: theme.palette.backgroundGreyColor,
    maxWidth: 420,
    border: `1px solid ${theme.palette.borderGreyColor}`,
  },
}))(Tooltip);

export default function HtmlTooltip(props) {
  const { children, titleContent } = props;
  return <CustomizedTooltip title={titleContent}>{children}</CustomizedTooltip>;
}
