import useChartUtils from '../../administration/components/EngineeringCompliance/hooks/useChartUtils';
import useAppSnackbar from '../../../hooks/useAppSnankbar';
import { useSelector } from 'react-redux';
import { selectedGeneralFilters } from '../../../components/EmwGeneralFilters/redux/selectors';
import { useEffect, useState } from 'react';
import {
  generateDateOptions,
  getFiltersPayload,
} from '../../../components/EmwGeneralFilters/utils';
import {
  getOrganizationsDataCollectionIndicatorsApi,
  getOrganizationsDataCollectionStatusApi,
} from '../redux/api';

export default function useDataCollection() {
  const chartUtils = useChartUtils();
  const snackbar = useAppSnackbar();
  const selectedFilters = useSelector(selectedGeneralFilters);

  const [statusData, setStatusData] = useState();
  const [chartData, setChartData] = useState({
    dataCollection: [],
    actualsDataCollection: [],
  });
  const [stateData, setStateData] = useState({
    organizationFilters: getFiltersPayload(selectedFilters),
    periodFilters: {
      period: '12 Months',
      date: generateDateOptions(21)[0],
    },
  });

  useEffect(() => {
    setStateData({
      ...stateData,
      organizationFilters: getFiltersPayload(selectedFilters),
    });
  }, [selectedFilters]);

  useEffect(() => {
    (async () => {
      await fetchIndicators(stateData);
      await fetchCollectionStatus(stateData);
    })();
  }, [stateData]);

  function transformData(data) {
    const date = new Date();
    const currentYear = date.getFullYear();
    const currentQuarter = chartUtils.getQuarter(date);
    const dataCollection = chartUtils
      .formatData(data.dataCollection, currentQuarter, currentYear)
      .map(mapForChart);
    const actualsDataCollection = chartUtils
      .formatData(data.actualsDataCollection, currentQuarter, currentYear)
      .map(mapForChart);

    function mapForChart(barData) {
      return {
        label: barData.label,
        ...barData.value,
      };
    }

    setChartData(currentChartData => {
      return {
        ...currentChartData,
        dataCollection,
        actualsDataCollection,
      };
    });
  }

  const fetchIndicators = async payload => {
    try {
      const response = await getOrganizationsDataCollectionIndicatorsApi(
        payload ? payload : getFiltersPayload(selectedFilters)
        // stateData
      );
      transformData(response.data);
    } catch (reason) {
      snackbar.show(reason.message || reason.statusText, 'warning');
    }
  };

  const fetchCollectionStatus = async payload => {
    try {
      const response = await getOrganizationsDataCollectionStatusApi(
        payload ? payload : getFiltersPayload(selectedFilters)
      );
      console.log(statusData);
      setStatusData(response.data);
    } catch (reason) {
      snackbar.show(reason.message || reason.statusText, 'warning');
    }
  };

  return {
    chartData,
    statusData,
    stateData,
    setStateData,
  };
}
