import React from 'react';

export default function SuitCaseIcon() {
  return (
    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.66959 3.33317V1.99984H5.95388V3.33317H8.66959ZM1.88032 4.6665V11.9998H12.7431V4.6665H1.88032ZM12.7431 3.33317C13.4968 3.33317 14.101 3.9265 14.101 4.6665V11.9998C14.101 12.7398 13.4968 13.3332 12.7431 13.3332H1.88032C1.12671 13.3332 0.522461 12.7398 0.522461 11.9998L0.52925 4.6665C0.52925 3.9265 1.12671 3.33317 1.88032 3.33317H4.59602V1.99984C4.59602 1.25984 5.20027 0.666504 5.95388 0.666504H8.66959C9.4232 0.666504 10.0274 1.25984 10.0274 1.99984V3.33317H12.7431Z"
        fill="#4A4D65"
      />
    </svg>
  );
}
