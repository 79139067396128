import React from 'react';
import TourElement from '../../../../../../../../components/Tour/TourElement/TourElement';
import TogglePanel from '../Section1/TogglePanel';

export default function SectionCer({ sectionCer, targetField }) {
  return (
    <>
      <TourElement id="tour-orgCard-CER">
        <TogglePanel
          id="toggleCER"
          title="COUNTRY ENGINEERING REPRESENTATIVES"
          type="CER"
          name="COUNTRY ENGINEERING REPRESENTATIVES"
          propKey="cers"
          list={sectionCer.cers}
          open={false}
          showArrows={false}
          targetField={targetField}
          section={'sectionCer'}
          sectionCer={sectionCer}
        />
      </TourElement>
    </>
  );
}
