import { buildStep } from '../tour';

export const buildLandingPageSteps = (dinamycStep, tour) => {
  dinamycStep.push(
    buildStep(tour, null, {
      id: 'moodAndConfidence',
      title: '',
      text: [
        'This option will allow you to visualize KPI’s related to Mood & Confidence to deliver.',
      ],
      element: '#moodAndConfidence',
      on: 'right',
    })
  );
  if (document.getElementById('enineeringCompetitiveness')) {
    dinamycStep.push(
      buildStep(tour, null, {
        id: 'engineeringcompetitivness',
        title: '',
        text: [
          'This option will allow you to visualize KPI’s related to Engineering Competitiveness.',
        ],
        element: '#enineeringCompetitiveness',
        on: 'right',
      })
    );
  }

  if (document.getElementById('skills')) {
    dinamycStep.push(
      buildStep(tour, null, {
        id: 'skills',
        title: '',
        text: [
          "This option will allow you to visualize KPI's focusing on Skills that are based on skills assessments data from the Impact Tool.",
        ],
        element: '#skills',
        on: 'right',
      })
    );
  }

  dinamycStep.push(
    buildStep(tour, null, {
      id: 'engineeringOrganisation',
      title: '',
      text: [
        'This option will allow you to visualize and manage the Engineering Organization structure.',
      ],
      element: '#engineeringOrganisation',
      on: 'left',
    })
  );
  dinamycStep.push(
    buildStep(tour, null, {
      id: 'emw-version',
      title: '',
      text: ['By hovering here, you will see the version history of the application.'],
      element: '.emw-version',
      on: 'top',
    })
  );
  dinamycStep.push(
    buildStep(tour, null, {
      id: 'emw-email',
      title: '',
      text: [
        'This is the e-mail that you can contact for support or info. By clicking on it, your email client will automatically open.',
      ],
      element: '.emw-email',
      on: 'top',
    })
  );

  dinamycStep.push(
    buildStep(tour, null, {
      id: 'gdpr-notes',
      title: '',
      text: ['This is the area where you can read the Data Protection notes'],
      element: '.gdpr-notes',
      on: 'top',
    })
  );
};
