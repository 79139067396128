import React from 'react';
import { styled } from '@mui/system';
import { createFilterOptions, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

const filter = createFilterOptions();

const StyledDatePicker = styled(Autocomplete)(({ theme }) => ({
  ' > div': {
    border: '1px solid #5d607e!important',
    borderRadius: '2px',

    '*': {
      border: 'none',
    },
    '.MuiInputBase-root': {
      padding: 0,
      fontSize: '12px',
      height: '32px!important',
    },
  },
}));

export default function CustomAutocomplete({
  options,
  handleChange,
  id,
  disabled,
  name,
  defaultValue,
}) {
  const [value, setValue] = React.useState(defaultValue || null);

  const onChange = (event, newValue) => {
    if (typeof newValue === 'string') {
      setValue({
        label: newValue,
      });
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      setValue({
        label: newValue.inputValue,
      });
    } else {
      setValue(newValue);
    }

    let value;
    event.target.name = name;

    if (newValue) {
      value = newValue.value;
    }

    event.target.value = value;
    handleChange(event, value);
  };

  const filterOptions = (options, params) => {
    const filtered = filter(options, params);

    const { inputValue } = params;
    // Suggest the creation of a new value
    const isExisting = options.some(option => inputValue === option.label);
    if (inputValue !== '' && !isExisting) {
      filtered.push({
        inputValue,
        label: `Add "${inputValue}"`,
      });
    }

    return filtered;
  };

  const getOptionLabel = option => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option.label;
  };

  return (
    <>
      <StyledDatePicker
        size="small"
        name={name}
        value={value}
        onChange={onChange}
        filterOptions={filterOptions}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        disabled={disabled}
        id={id}
        options={options}
        getOptionLabel={getOptionLabel}
        renderOption={(props, option) => <li {...props}>{option.label}</li>}
        sx={{ width: '100%' }}
        renderInput={params => <TextField {...params} />}
      />
    </>
  );
}
