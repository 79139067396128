import React, { useEffect, useState } from 'react';
import EmwTypography from '../../../EmwTypography/EmwTypography';
import cls from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
  availableFiltersSelector,
  clearAllFiltersSelector,
  generalFiltersSelectors,
  selectedGeneralFilters,
} from '../../redux/selectors';
import { setAvailableFilters, setSelectedFilters } from '../../redux/actions';
import { FILTERS_SECTIONS } from '../../constants';
import { QtmCheckbox } from '@qtm/react';

export default function GeneralFilterListItem({ type, item, style }) {
  const dispatch = useDispatch();
  const selectedFilters = useSelector(selectedGeneralFilters);
  const availableFilters = useSelector(availableFiltersSelector);
  const [name, setName] = useState();

  useEffect(() => {
    if (item) {
      setName(item.region ? item.name : item.code);
      const _availableFilters = { ...availableFilters };
      const _selectedFilters = { ...selectedFilters };

      _availableFilters[type].map(filter => {
        const filterChecked = _selectedFilters[type].find(
          filterCheck => filterCheck.id === filter.id
        );

        if (filterChecked) {
          filter.checked = filterChecked.checked;
        }

        return filter;
      });
    }
  }, [item]);

  useEffect(() => {
    if (!selectedFilters[type].length) {
      availableFilters[type].map(filter => (filter.checked = false));
    }
  }, [selectedFilters]);

  const handleClick = event => {
    const _selectedFilters = { ...selectedFilters };
    const filterExists = _selectedFilters[type].find(filter => filter.id === item.id);

    if (filterExists) {
      _selectedFilters[type] = _selectedFilters[type].filter(filter => filter.id !== item.id);
    } else {
      _selectedFilters[type].push(item);
    }

    const _availableFilters = { ...availableFilters };
    _availableFilters[type].map(filter => {
      if (filter.id === item.id) {
        filter.checked = !item.checked;
      }

      return filter;
    });

    dispatch(setSelectedFilters(_selectedFilters));
  };

  return (
    <div style={style} title={name}>
      <label className="listItem flex ml-xxs overflow-hidden items-center">
        <QtmCheckbox
          inputId={`${type}-${item.code}`}
          checked={item && item.checked}
          size="small"
          onValueChanged={handleClick}
        />
        <EmwTypography fontSize={8}>{name}</EmwTypography>
      </label>
    </div>
  );
}
