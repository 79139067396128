import { styled } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

export const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  backgroundColor: 'white',
  '*': {
    padding: 0 + '!important',
  },

  '.MuiInputBase-root': {
    padding: 0,
    height: '30px',
    width: 'auto',
    borderRadius: '2px',
  },

  input: {
    paddingLeft: '6px!important',
    fontSize: '12px',
    fontFamily: 'Roboto',
  },
}));
