import React, { useEffect, useState } from 'react';
import EmwTypography from '../../../EmwTypography/EmwTypography';

export default function CellText({ cell, style, className }) {
  return (
    <p style={style} className={className}>
      {cell.value}
    </p>
  );
}
