import React, { useState } from 'react';
import { EmwDropDownList } from '../../../../../../../../../lib/common';
import { timeUtils } from '../../../../../../../../utils/timeUtils';
import ExportOpsCockpitBtn from '../ExportOpsCockpitBtn/ExportOpsCockpitBtn';

export default function OpsCockpit() {
    const time = timeUtils()
    const [selectedYear, setSelectedYear] = useState(time.currentYear)
    const [selectedQuarter, setSelectedQuarter] = useState(time.currentQuarter)
    
    const handleChangeYear = (e) => {
        setSelectedYear(e.target.value)
  
    }
    const handleChangeQuarter = (e) => {
        setSelectedQuarter(e.target.value)
    }

  return (
    <>
      <div className="flex mt-s mb-s">
        <p className="text-scale-6 text-blugrey-500 mr-xs">Operations Cockpit Export</p>
      </div>
      <div className="flex justify-between gap-xl">
        <EmwDropDownList
          disabled={false}
          options={time.years}
          onDropDownListChanged={handleChangeYear}
          value={selectedYear}
          placement="bottom"
          inputId="year"
          placeHolder="Year"
          size="small"
        />

        <EmwDropDownList
          disabled={false}
          options={time.quarters}
          onDropDownListChanged={handleChangeQuarter}
          value={selectedQuarter}
          placement="bottom"
          inputId="quarter"
          placeHolder="Quarter"
          size="small"
        />
        <ExportOpsCockpitBtn
          icon={false}
          classes="file-download"
          size="small"
          id="export-OpsCockpit"
          variant="filled"
          text="Download"
          title="Operations Cockpit Export"
          selectedYear={selectedYear}
          selectedQuarter={selectedQuarter}
        />
      </div>
    </>
  );
}
