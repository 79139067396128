import React, { useContext, useEffect, useState } from 'react';
import { Box, Collapse } from '@mui/material';
import WorkPackagesPanel from './WorkPackagesPanel';
import { cloneDeep } from 'lodash';
import { OrganizationCardContext } from '../../../../context/OrganizationCardContextProvider';
import FormItem from '../FormItem/FormItem';
import { ENGINEER_TYPE, NO_RESOURCE_ALLOCATED } from '../../../../../../constants';
import { dateIsExpired } from '../../utils';
import EmwTypography from '../../../../../../../../components/EmwTypography/EmwTypography';

export default function Solution({
  solution,
  index,
  color,
  deliveryIndex,
  targetField,
  openNewSolution,
}) {
  const context = useContext(OrganizationCardContext);
  const [isOpen, setIsOpen] = useState(solution.isOpen);
  const [name, setName] = useState(solution.name.value || `Solution/Project ${index + 1}`);
  const [values, setValues] = useState(cloneDeep(solution));
  const [item, setItem] = useState(solution);
  const [showWpms, setShowWpms] = useState(false);
  const [isDropdownAlreadyOpen, setIsDropdownAlreadyOpen] = useState(solution.isOpen || isOpen);
  const [forceOpen, setForceOpen] = useState(false);

  useEffect(() => {
    setName(solution.name.value || `Solution/Project ${index + 1}`);
    setItem(solution);

    for (let i = 0; i < solution.wpms.length; i++) {
      if (solution.wpms[i].visible) {
        setShowWpms(true);
        break;
      }
    }

    if (!isOpen && !forceOpen) {
      const shouldForceOpen = context.shouldBeOpen(solution, targetField.key);
      setIsOpen(shouldForceOpen);
      setForceOpen(shouldForceOpen);
    }
  }, [solution]);

  const handleIsMainObjOpen = open => {
    const _solution = {
      ...solution,
      isOpen: open,
    };
    context.updateSolution(_solution, deliveryIndex, index);
  };

  const handleIsMainObjInEditMode = isEdit => {
    const _solution = {
      ...solution,
      isEdit: isEdit,
    };
    context.updateSolution(_solution, deliveryIndex, index);
  };

  const handleUpdate = (objectValues, key) => {
    const updatedValues = { ...values };

    if (key) {
      updatedValues[key] = objectValues;
      // updatedValues.solutions = objectValues;
      updatedValues.wpms = context.data.section2.deliveries[deliveryIndex].solutions[index].wpms;
      updatedValues.isOpen = item.isOpen;
      updatedValues.isEdit = item.isEdit;
      context.updateSolution(updatedValues, deliveryIndex, index);
      setValues(updatedValues);
    } else {
      objectValues.leader = updatedValues.leader;
      objectValues.pda = updatedValues.pda;
      objectValues.da = updatedValues.da;
      objectValues.isOpen = true;
      objectValues.isEdit = false;
      context.updateSolution(objectValues, deliveryIndex, index);
      setValues(objectValues);
    }
  };

  const setWarningBorder = () => {
    let hasBorder = false;
    const regExp = /^Solution\/Project [0-9]+$/;
    if (!values.leader.name.value || values.leader.name.value === NO_RESOURCE_ALLOCATED) {
      hasBorder = true;
    } else if (values.name.value && regExp.test(values.name.value)) {
      hasBorder = true;
    } else if (dateIsExpired(values.leader.endDate.value)) {
      hasBorder = true;
    }
    return hasBorder;
  };

  return (
    <>
      <FormItem
        isNew={false}
        type={null}
        customName={name}
        item={item}
        handleUpdate={handleUpdate}
        showArrows={false}
        color={color}
        isOpen={isOpen}
        isEditOpen={solution.isEdit}
        setIsOpen={value => {
          setIsOpen(value);
          setIsDropdownAlreadyOpen(value);
          handleIsMainObjOpen(value);
        }}
        collapsable={true}
        isTextField={true}
        targetKey={targetField.key}
        id={`delivery-${deliveryIndex}-solution-${index}`}
        noResource={setWarningBorder()}
        beforeEditClick={() => {
          if (!isDropdownAlreadyOpen) {
            setIsOpen(true);
          }
          handleIsMainObjInEditMode(true);
        }}
        beforeCancelClick={() => {
          if (!isDropdownAlreadyOpen) {
            setIsOpen(false);
          }
          handleIsMainObjInEditMode(false);
        }}
        customInfoMsg={!item.btnEdit.visible ? 'C3 THALES GROUP CONFIDENTIAL (anonymous name)' : ''}
      />

      <Collapse
        in={isOpen}
        sx={{
          width: '100%',
          display: 'flex',
          border: '1px solid #CCF2F8',
          padding: '0 4px 4px 4px',
        }}
      >
        <FormItem
          isNew={false}
          type="EDM"
          item={solution.leader}
          handleUpdate={handleUpdate}
          showArrows={false}
          propKey="leader"
          targetKey={targetField.key}
          id={`delivery-${deliveryIndex}-solution-${index}-EDM`}
          title={ENGINEER_TYPE.EDM.title}
        />

        <FormItem
          isNew={false}
          type="DA"
          item={solution.da}
          handleUpdate={handleUpdate}
          showArrows={false}
          propKey="da"
          targetKey={targetField.key}
          id={`delivery-${deliveryIndex}-solution-${index}-DA`}
          title={ENGINEER_TYPE.DA.title}
        />

        <FormItem
          isNew={false}
          type="PDA"
          item={solution.pda}
          handleUpdate={handleUpdate}
          showArrows={false}
          propKey="pda"
          targetKey={targetField.key}
          id={`delivery-${deliveryIndex}-solution-${index}-PDA`}
          title={ENGINEER_TYPE.PDA.title}
        />

        {showWpms && (
          <Box mt={1}>
            <WorkPackagesPanel
              wpms={solution.wpms}
              deliveryIndex={deliveryIndex}
              solutionIndex={index}
              targetField={targetField}
            />
          </Box>
        )}
      </Collapse>
    </>
  );
}
