import { Grid, styled } from '@mui/material';

export const StyledEmwTag = styled(Grid)(({ theme, ...props }) => ({
  '&.emw-tag': {
    width: '101px',
    height: '19px',
    overflow: 'hidden',
    fontSize: '8px',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',

    '.left-corner': {
      width: 0,
      height: 0,
      borderTop: '10px solid transparent',
      borderBottom: '9px solid transparent',
      borderRight: `9px solid ${props.color}`,
    },

    '.bullet': {
      width: '3px',
      height: '3px',
      top: '8px',
      left: '4px',
      position: 'absolute',
      borderRadius: '3px',
      backgroundColor: 'white',
    },

    '.type': {
      height: 'inherit',
      flexGrow: 1,
      lineHeight: '19px',
      color: 'white',
      textAlign: 'center',
      borderRadius: '0 4px 4px 0',
      backgroundColor: props.color,
    },
  },
  '&.emw-tag-name': {
    display: 'flex',
    alignItems: 'center',
    width: 'auto',
    height: '22px',
    borderRadius: '10px',
    padding: '0px 5px',
    margin: '3px',
  },
}));
