import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { TdsIcon } from '@tds/react';
import { useSelector } from 'react-redux';
import { userOrgsSelector } from '../../../../redux/selectors';
import EmwTypography from '../../../../../../components/EmwTypography/EmwTypography';
import CommentType from './CommentType';
import CommentItemActions from './CommentItemActions';

export default function CommentItem({ comment, title, selectedChart }) {
  const [isCurrentOrg, setIsCurrentOrg] = useState(false);
  const userOrgs = useSelector(userOrgsSelector);

  useEffect(() => {
    setIsCurrentOrg(userOrgs.includes(comment.orgName));
  }, [comment]);

  return (
    <div className="comment-item flex items-center bg-bluegrey-25 rounded mt-xs pl-xs place-content-between">
      <div className="flex items-center">
        <EmwTypography fontSize={8}>{comment.orgName || 'GROUP'}</EmwTypography>
        {isCurrentOrg && <TdsIcon icon="star" size="xsmall" classes="mx-xxs" />}
      </div>
      <div className="flex items-center justify-end">
        <CommentType comment={comment} />
        <CommentItemActions comment={comment} title={title} selectedChart={selectedChart} />
      </div>
    </div>
  );
}
