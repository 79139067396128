import { styled } from '@mui/material/styles';
import { getZoom } from '../../../../utils/sizeUtils';

export const StyledEngineeringCompliance = styled('div')(({ theme }) => {
  const zoom = getZoom();
  return {
    zoom: `${zoom}%`,

    '.MuiGrid-item': {
      height: '281px',
    },
    '.filter-cc-types-dropdown': {
      '.qtm-text-input > input': {
        height: '32px',
        paddingRight: '0px',
      },
    },
  };
});
