import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  guestDetailsSelector,
  orgDetailsSelector,
  searchExcomSelector,
  searchGuestSelector,
  searchPowerGuestSelector,
} from '../../redux/selectors';
import { getGuestDetails, changeView } from '../../redux/actions';
import { StyledGuestPowerGuestCard } from './styled';
import GuestPowerGuestHeader from './components/GuestPowerGuestHeader';
import GuestPowerGuestList from './components/GuestPowerGuestList';
import { Grid } from '@mui/material';
import { updateOrgGuestDetailsApi } from '../../redux/api';
import useAppSnackbar from '../../../../hooks/useAppSnankbar';
import { G_PG } from '../../constants';
import { userActivity } from '../../../userActivity/redux/actions';
import { v4 as uuidv4 } from 'uuid';
import { USER_ACTIVITY_SERVICES } from '../../../userActivity/constants';
const defaultUser = {
  id: null,
  effective: { value: null, enabled: true, visible: true },
  type: { value: null, enabled: true, visible: true },
  startDate: { value: null, enabled: true, visible: true },
  endDate: { value: null, enabled: true, visible: true },
  comment: { value: null, enabled: true, visible: true },
  name: { value: null, enabled: true, visible: true },
  btnAdd: { value: null, enabled: true, visible: true },
  btnEdit: { value: null, enabled: true, visible: true },
};

export default function GuestPowerGuestCard() {
  const { list } = useSelector(orgDetailsSelector);
  const guestDetails = useSelector(guestDetailsSelector);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [guests, setGuests] = useState([]);
  const [powerGuests, setPowerGuests] = useState([]);
  const [excoms, setExcoms] = useState([]);
  const [showExcoms, setShowExcoms] = useState(false);
  const [showGuests, setShowGuests] = useState(false);
  const [showPowerGuests, setShowPowerGuests] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [btnSave, setBtnSave] = useState({
    value: null,
    enabled: true,
    visible: false,
  });
  const searchExcomDetails = useSelector(searchExcomSelector);
  const searchGuestDetails = useSelector(searchGuestSelector);
  const searchPowerGuestDetails = useSelector(searchPowerGuestSelector);

  const [btnCancel, setBtnCancel] = useState({
    value: null,
    enabled: true,
    visible: false,
  });
  const snackbar = useAppSnackbar();

  useLayoutEffect(() => {
    setGuests([]);
    setPowerGuests([]);
    setExcoms([]);
  }, []);

  useEffect(() => {
    dispatch(
      getGuestDetails({
        id: list.data.cardDetails.id,
        type: list.data.cardDetails.type,
        blCode: list.data.cardDetails.blCode,
        buCode: list.data.cardDetails.buCode,
        countryCode: list.data.cardDetails.countryCode,
      })
    );
    dispatch(
      userActivity({
        uniqueId: uuidv4(),
        service: USER_ACTIVITY_SERVICES.G_PG,
        details: 'VIEW',
        uri: '',
      })
    );

    return () => {
      setGuests([]);
      setPowerGuests([]);
      setExcoms([]);
    };
  }, []);

  useEffect(() => {
    if (guestDetails.list && guestDetails.list.hasOwnProperty('data')) {
      setBtnSave(guestDetails.list.data.btnSave);
      setBtnCancel(guestDetails.list.data.btnCancel);
      setCanEdit(guestDetails.list.data.btnSave.visible);

      setGuests(() => {
        return guestDetails.list.data.guests.length > 0 ? guestDetails.list.data.guests : [];
      });

      setPowerGuests(() => {
        return guestDetails.list.data.powerGuests.length > 0
          ? guestDetails.list.data.powerGuests
          : [];
      });

      setExcoms(() => {
        return guestDetails.list.data.excoms.length > 0 ? guestDetails.list.data.excoms : [];
      });

      setShowExcoms(guestDetails.list.data.showExcoms);
      setShowGuests(guestDetails.list.data.showGuests);
      setShowPowerGuests(guestDetails.list.data.showPowerGuests);
    }
  }, [guestDetails]);

  const handleSave = async () => {
    const payload = {
      id: list.data.cardDetails.id,
      blCode: list.data.cardDetails.blCode,
      type: list.data.cardDetails.type,
      guests,
      powerGuests,
      excoms,
    };

    const response = await updateOrgGuestDetailsApi(payload);
    snackbar.show(response.message, response.messageType);
    dispatch(
      getGuestDetails({
        id: list.data.cardDetails.id,
        type: list.data.cardDetails.type,
        blCode: list.data.cardDetails.blCode,
        buCode: list.data.cardDetails.buCode,
        countryCode: list.data.cardDetails.countryCode,
      })
    );
    dispatch(
      userActivity({
        uniqueId: uuidv4(),
        service: USER_ACTIVITY_SERVICES.G_PG_EDIT,
        details: 'EDIT',
        uri: '',
      })
    );
    if (response.messageType === 'success') {
      setIsOpen(false);
    }
  };

  const handleCancel = async () => {
    dispatch(
      getGuestDetails({
        id: list.data.cardDetails.id,
        type: list.data.cardDetails.type,
        blCode: list.data.cardDetails.blCode,
        buCode: list.data.cardDetails.buCode,
        countryCode: list.data.cardDetails.countryCode,
      })
    );
  };

  return (
    <StyledGuestPowerGuestCard container id="orgGuestPowerGuestContainer">
      <GuestPowerGuestHeader
        btnSave={btnSave}
        guests={guests}
        powerGuests={powerGuests}
        excoms={excoms}
        setExcoms={setExcoms}
        showExcoms={showExcoms}
        showGuests={showGuests}
        showPowerGuests={showPowerGuests}
        setPowerGuests={setPowerGuests}
        setGuests={setGuests}
        defaultUser={defaultUser}
        canEdit={canEdit}
        handleSave={handleSave}
        handleCancel={handleCancel}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
      <Grid container display="block" flexGrow="1" overflow="auto">
        {showExcoms && (
          <Grid item className="divider pb-xs mb-xs border-b-2 border-primary-500">
            <GuestPowerGuestList
              name={G_PG.EXCOMS.name}
              list={excoms}
              isSearched={searchExcomDetails}
            />
          </Grid>
        )}
        {showPowerGuests && (
          <Grid item className="divider pb-xs mb-xs border-b-2 border-primary-500">
            <GuestPowerGuestList
              name={G_PG.POWER_GUESTS.name}
              list={powerGuests}
              isSearched={searchPowerGuestDetails}
            />
          </Grid>
        )}
        {showGuests && (
          <Grid item className="mb-xs">
            <GuestPowerGuestList
              name={G_PG.GUESTS.name}
              list={guests}
              isSearched={searchGuestDetails}
            />
          </Grid>
        )}
      </Grid>
    </StyledGuestPowerGuestCard>
  );
}
