import React, { useState } from 'react';
export default function ExportFileIcon({ width, height }) {
  const [svgWidth] = useState(width ? width : 16);
  const [svgHeight] = useState(height ? height : 20);
  return (
    <svg
      width={svgWidth}
      height={svgHeight}
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.59 11.58L12 12.99L7.99 17L4 12.99L5.41 11.57L7 13.16V9H9L9 13.16L10.59 11.58Z"
        fill="#132099"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 0H10L16 6V18C16 19.1 15.1 20 14 20H1.99C0.89 20 0 19.1 0 18L0.0100002 2C0.0100002 0.9 0.9 0 2 0ZM2 18H14V7H9V2H2V18Z"
        fill="#132099"
      />
    </svg>
  );
}
