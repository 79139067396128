import React, { useEffect } from 'react';
import { EmwButton } from '../../../../../../../lib/common';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { TdsIcon } from '@tds/react';
import { changeView } from '../../../../redux/actions';
import { ORGANIZATION_VIEW } from '../../../../constants';
import {
  searchExcomSelector,
  searchGuestSelector,
  searchPowerGuestSelector,
} from '../../../../redux/selectors';

export default function AddGuestPowerGuest({ btn, data }) {
  const dispatch = useDispatch();
  const searchGuestDetails = useSelector(searchGuestSelector);
  const searchPowerGuestDetails = useSelector(searchPowerGuestSelector);
  const searchExcomDetails = useSelector(searchExcomSelector);

  useEffect(() => {
    if (searchExcomDetails || searchGuestDetails || searchPowerGuestDetails) {
      dispatch(changeView(ORGANIZATION_VIEW.GUEST));
    }
  }, [searchExcomDetails, searchGuestDetails, searchPowerGuestDetails]);

  const handleClick = () => {
    dispatch(changeView(ORGANIZATION_VIEW.GUEST));
  };

  return (
    <Box>
      <EmwButton
        variant="ghost"
        size="small"
        disabled={!btn.enabled}
        onClick={handleClick}
        id="btnGuests"
        title="Manage Guests/Power Guests"
      >
        <TdsIcon icon="manage_accounts" variant="outlined" size="large" />
      </EmwButton>
    </Box>
  );
}
