import React, { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import FavoriteCardItems from './FavoriteCardItems';
import {
  favoriteOrganizationsSelector,
  underMyResponsabilitySelector,
} from '../../redux/selectors';
import { setFavoriteOrganizations } from '../../redux/actions';

export default function FavoriteCard() {
  const favorites = useSelector(favoriteOrganizationsSelector);
  const underMyResponsability = useSelector(underMyResponsabilitySelector);
  const dispatch = useDispatch();

  useEffect(() => {
    const filteredFavorites = [...favorites].filter(fav => fav.isAdded !== false);
    dispatch(setFavoriteOrganizations(filteredFavorites));
  }, []);

  return (
    <Box className="card" sx={{ flexGrow: 1, height: '100%', overflow: 'auto', padding: '22px' }}>
      <Grid container className="card" id="favorites" gap={4}>
        <FavoriteCardItems
          items={favorites}
          title="My Favorite Organizations"
          hasIcon
          id="FavoriteOrganizations"
        />

        <FavoriteCardItems
          items={underMyResponsability}
          title="Organizations Under My Responsibility"
          id="MyOrganizations"
        />
      </Grid>
    </Box>
  );
}
