import { Dialog, styled } from '@mui/material';

const WHITE_BACKGROUND = '#FFFFFF !important';
const DEFAULT_FONT = 'Calibri, sans-serif';

export const StyledEmwHotTable = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogActions-root': {
    height: '40px !important',
  },
  '#hot-dialog-header': {
    height: '60px',
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
  '#input-type-color': {
    '&::after': {
      content: '""',
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '5px 4px 0 4px',
      borderColor: 'grey transparent transparent transparent',
      position: 'absolute',
      top: '8px',
      left: '25px',
    },
  },
  '#font-size-dropdown': {},

  /* ========ECC STYLES(MOVE TO SEPARATE FILE)======== */

  /* header styles */
  '#ccHotTable .handsontable thead th:not(:first-child)': {
    backgroundColor: WHITE_BACKGROUND,
    fontFamily: DEFAULT_FONT,
    color: '#002060',
    fontWeight: 'bold',
    paddingTop: '23px',
    fontSize: '11px',
  },

  '#ccHotTable .handsontable': {
    color: '#000000',
  },

  '#ccHotTable .handsontable .htDimmed': {
    color: '#000000',
  },

  '.handsontable .htCommentCell:after': {
    borderTop: '6px solid #FF0707',
  },

  /* initialize table with color green */
  '#ccHotTable .handsontable tbody td:nth-child(-n+9)': {
    backgroundColor: '#66ff66',
  },

  /* make first 2 columns bold */
  '#ccHotTable .handsontable tbody td:nth-child(-n+3)': {
    fontWeight: 'bold',
  },

  /* add border to delimitate the fixed columns */
  '#ccHotTable .handsontable tbody td:nth-child(6)': {
    borderRight: '1px solid #9b9b9b',
  },

  '#ccHotTable .handsontable thead th:nth-child(6)': {
    borderRight: '1px solid #9b9b9b',
  },

  /* forced padding for cc country location to look like excel file */
  '#ccHotTable .handsontable thead th:nth-child(4)': {
    paddingLeft: '3px',
  },

  /* set font styles to match excel file */
  '#ccHotTable .handsontable tbody td:not(:first-child)': {
    fontSize: '11px',
    fontFamily: DEFAULT_FONT,
    whiteSpace: 'nowrap',
  },

  // custom classes for background colors
  '.handsontable .background-66ff66': {
    backgroundColor: '#66ff66',
  },

  '.handsontable .background-ff0707': {
    backgroundColor: '#ff0707 !important',
  },

  '.handsontable .background-bfbfbf': {
    backgroundColor: '#bfbfbf !important',
  },

  '.handsontable .background-ffbf00': {
    backgroundColor: '#ffbf00 !important',
  },

  '.handsontable .background-fefe00': {
    backgroundColor: '#fefe00 !important',
  },

  '.handsontable .background-000000': {
    backgroundColor: '#000000 !important',
  },

  '.handsontable .background-ffffff': {
    backgroundColor: WHITE_BACKGROUND,
  },

  '.handsontable .background-194d33': {
    backgroundColor: '#194d33 !important',
  },

  '.handsontable .background-0C797d': {
    backgroundColor: '#0C797d !important',
  },

  '.handsontable .background-0062b1': {
    backgroundColor: '#0062b1 !important',
  },

  '.handsontable .background-653294': {
    backgroundColor: '#653294 !important',
  },

  // custom classes for font colors
  '.handsontable .fontColor-66ff66': {
    color: '#66ff66',
  },

  '.handsontable .fontColor-ff0707': {
    color: '#ff0707 !important',
  },

  '.handsontable .fontColor-bfbfbf': {
    color: '#bfbfbf !important',
  },

  '.handsontable .fontColor-ffbf00': {
    color: '#ffbf00 !important',
  },

  '.handsontable .fontColor-fefe00': {
    color: '#fefe00 !important',
  },

  '.handsontable .fontColor-000000': {
    color: '#000000 !important',
  },

  '.handsontable .fontColor-ffffff': {
    color: WHITE_BACKGROUND,
  },

  '.handsontable .fontColor-194d33': {
    color: '#194d33 !important',
  },

  '.handsontable .fontColor-0C797d': {
    color: '#0C797d !important',
  },

  '.handsontable .fontColor-0062b1': {
    color: '#0062b1 !important',
  },

  '.handsontable .fontColor-653294': {
    color: '#653294 !important',
  },

  //

  /* custom classes for rich text formatting */
  '.handsontable .font-bold': {
    fontWeight: '700',
  },

  '.handsontable .font-italic': {
    fontStyle: 'italic',
  },

  '.handsontable .font-underline': {
    textDecoration: 'underline',
  },

  '.handsontable .remove-font-underline': {
    textDecoration: 'none',
  },

  '#eeiHotTable .htDropdownMenu tbody': {
    backgroundColor: '#FFFFFF',
  },

  // set header style
  '#eeiHotTable .handsontable thead th:not(:first-child)': {
    backgroundColor: '#3e6caf !important',
    color: 'white',
    fontFamily: DEFAULT_FONT,
    fontWeight: 'bold',
    fontSize: '11px',
    paddingTop: '6px',
  },

  '#eeiHotTable .handsontable .read-only-data, #wfbHotTable .handsontable .read-only-data': {
    backgroundColor: '#aac0de !important',
    color: 'black',
  },

  '#eeiHotTable .handsontable thead th:nth-last-child(2), #eeiHotTable .handsontable thead th:nth-last-child(3), #eeiHotTable .handsontable thead th:nth-last-child(4)':
    {
      backgroundColor: '#f38336 !important',
    },

  '#eeiHotTable .handsontable.listbox td': {
    backgroundColor: WHITE_BACKGROUND,
  },

  '#eeiHotTable .handsontable .red-border': {
    border: '1px solid red',
  },

  '#eeiHotTable': {
    overflowY: 'auto !important',
    maxHeight: '85% !important',
  },

  //==============WFB================

  '#wfbHotTable .handsontable .blue-header': {
    backgroundColor: '#3e6caf',
    border: 0,
    fontFamily: DEFAULT_FONT,
    fontWeight: 'bold',
    fontSize: '11px',
    color: 'white',
  },

  '#wfbHotTable .handsontable .pink-header': {
    backgroundColor: '#db9595 !important',
    fontFamily: DEFAULT_FONT,
    fontWeight: 'bold',
    fontSize: '12px',
    color: 'white',
  },

  '#wfbHotTable .handsontable .gold-header': {
    backgroundColor: '#c4bc94 !important',
    fontFamily: DEFAULT_FONT,
    fontWeight: 'bold',
    fontSize: '12px',
    color: 'white',
  },

  '#wfbHotTable .handsontable .datalake-cell': {
    backgroundColor: '#cccccc',
  },

  '#wfbHotTable .handsontable .white-cell': {
    backgroundColor: '#ffffff',
  },

  '#wfbHotTable .handsontable thead tr th:first-child': {
    backgroundColor: '#f0f0f0; !important',
  },
  '#wfbHotTable .handsontable .red-border': {
    border: '1px solid red',
  },

  '#bccHotTable .handsontable thead th:not(:first-child)': {
    backgroundColor: '#3e6caf !important',
    color: 'white',
    fontFamily: DEFAULT_FONT,
    fontWeight: 'bold',
    fontSize: '11px',
    paddingTop: '6px',
  },
  '.loading-wrapper': {
    display: 'flex',
    justifyContent: 'center',
  },

  '#hot-dialog-content': {
    overflowY: 'hidden',
    overflowX: 'auto',
  },

  '#wfbHotTable': {
    overflowY: 'auto !important',
    maxHeight: '97% !important',
  },
}));

export const StyledEmwHotSheetsWrapper = styled('div')(() => ({
  paddingLeft: '24px',
}));
