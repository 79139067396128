import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StyledDialog } from '../../styled';
import { DialogActions, DialogContent, DialogTitle, Divider, Grid } from '@mui/material';
import EmwTypography from '../../../../components/EmwTypography/EmwTypography';
import usePowerBi from '../../hooks/usePowerBi';
import { EmwButton } from '../../../../../lib/common';
import {
  TdsDropdownSelect,
  TdsDropdownSelectOption,
  TdsToggleSwitch,
  TdsTextarea,
  TdsFormCaption,
  TdsProgressCircle,
} from '@tds/react';
import { CHARTS, COMMENT_TYPES, LINKED_TO, LINKED_TO_OPTIONS } from '../../constants';
import { createCommentApi } from '../../redux/api';
import { commentsRolesSelector, commentsSelector } from '../../redux/selectors';
import { cloneDeep } from 'lodash';
import { getLabelsArray } from '../../../../../util/biUtils';
import CustomAutocomplete from '../CustomAutocomplete/CustomAutocomplete';

const maxLength = 500;

export default function CreateCommentModal({
  isOpen,
  setIsOpen,
  selectedChart,
  typeOptions,
  data,
}) {
  const comments = useSelector(commentsSelector);
  const powerBi = usePowerBi();
  const commentsRoles = useSelector(commentsRolesSelector);
  const { getGeneralFilters, getTimeframeFilters } = usePowerBi();
  const [isLoading, setIsLoading] = useState(false);
  const [orgName, setOrgName] = useState('');
  const [currentLength, setCurrentLength] = useState(0);
  const [canPublish, setCanPublish] = useState(false);
  const [canMakePublic, setCanMakePublic] = useState(false);
  const [isLinkToDisabled, setIsLinkToDisabled] = useState(true);

  const [comment, setComment] = useState({
    type: '',
    linkedTo: '',
    where: '',
    public: false,
    publicIn: '',
    text: '',
  });

  const linkedToOptions = Object.keys(LINKED_TO).map(key => {
    return {
      label: LINKED_TO_OPTIONS[key],
      value: key,
    };
  });

  useEffect(() => {
    if (data) {
      setComment({
        id: data.id,
        type: data.type,
        linkedTo: data.linkedTo ? LINKED_TO_OPTIONS[data.linkedTo] : '',
        where: data.where || '',
        public: data.public || false,
        publicIn: data.publicIn || '',
        text: data.text || '',
      });
    }
  }, [data]);

  useEffect(() => {
    setOrgName(powerBi.filterToOrgName());
  }, []);

  useEffect(() => {
    setCanPublish(() => {
      if (comment.type && comment.where && comment.text) {
        return !(comment.type === COMMENT_TYPES.COMMENT && comment.public && !comment.publicIn);
      }

      return false;
    });
    setCanMakePublic(() => {
      if (commentsRoles.canMakePublic && comment.type === 'comment') {
        return true;
      }
    });
  }, [comment]);

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setIsOpen(true);
    }
  };

  const handleChange = (event, customValue) => {
    const { name, value, checked } = event.target;
    const { selectedOption } = event.detail;

    setComment(currentState => {
      const newState = { ...currentState };

      switch (name) {
        case 'type':
          setIsLinkToDisabled(
            [COMMENT_TYPES.COMMENT, COMMENT_TYPES.ACTION_PLAN].includes(selectedOption)
          );
          newState[name] = selectedOption;

          if (selectedOption !== COMMENT_TYPES.COMMENT) {
            newState.publicIn = '';
            newState.public = false;
          }

          if (selectedOption !== COMMENT_TYPES.ROOT_CAUSE) {
            newState.linkedTo = '';
          }
          break;
        case 'where':
        case 'publicIn':
          newState[name] = selectedOption;
          break;
        case 'linkedTo':
          newState[name] = customValue;
          break;
        case 'public':
          if (!checked) {
            newState.publicIn = '';
          }
          newState[name] = checked;
          break;
        case 'text':
          if (value.length > maxLength) {
            setCurrentLength(maxLength);
            newState[name] = value.substring(0, 500);
          } else {
            setCurrentLength(value.length);
            newState[name] = value;
          }
          break;
        default:
          newState[name] = value;
          break;
      }
      return newState;
    });
  };

  const handleCreate = async () => {
    setIsLoading(true);
    const payload = {
      chartType: [selectedChart.key],
      generalFilters: getGeneralFilters(),
      timeframeFilters: getTimeframeFilters(),
      comment: { ...comment, orgName },
    };

    try {
      const response = await createCommentApi(payload);
      const currentComments = cloneDeep(comments);

      currentComments[selectedChart.parent][selectedChart.key].values =
        response.data[selectedChart.key];

      powerBi.fetchComments(
        {
          chartType: Object.keys(CHARTS).map(key => CHARTS[key]),
          generalFilters: getGeneralFilters(),
          timeframeFilters: getTimeframeFilters(),
        },
        comments
      );
    } catch (e) {
      // TODO: add a global req handler
    } finally {
      setIsLoading(false);
      setIsOpen(false);
    }
  };

  return (
    <StyledDialog
      open={isOpen}
      maxWidth="sm"
      fullWidth
      onClose={handleClose}
      disableEscapeKeyDown
      id="create-comment"
    >
      <DialogTitle className="dialog-title pb-xs">
        <Grid
          container
          justifyContent="space-between"
          className="items-center border-b-2 border-primary-500 pb-s mb-s"
        >
          <EmwTypography classes="text-primary-500" fontSize={6} fontWeight="bold">
            {data ? 'Update' : 'Create'} Comment - {selectedChart.title.toUpperCase()}
          </EmwTypography>
          <EmwTypography fontSize={6} fontWeight="bold" classes="text-bluegrey-600">
            {orgName}
          </EmwTypography>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <Grid container flexWrap="nowrap" alignItems="center" className="mb-s">
          <EmwTypography fontSize={9} classes="text-bluegrey-400">
            TYPE:
          </EmwTypography>
          <TdsDropdownSelect
            name="type"
            id="comment-type"
            className="ml-xs mr-xs"
            placeholder="Select option"
            value={comment.type}
            disabled={!!data}
            onSelectedOption={handleChange}
          >
            <div>
              {typeOptions.map(type => (
                <TdsDropdownSelectOption key={type.value} value={type.value}>
                  {type.label}
                </TdsDropdownSelectOption>
              ))}
            </div>
          </TdsDropdownSelect>
          <EmwTypography fontSize={9} classes="text-bluegrey-400">
            WHERE:
          </EmwTypography>
          <TdsDropdownSelect
            name="where"
            id="comment-where"
            className="mx-xs"
            placeholder="Select option"
            value={comment.where}
            disabled={!!data}
            onSelectedOption={handleChange}
          >
            <div>
              <TdsDropdownSelectOption value="On KPI">On KPI</TdsDropdownSelectOption>
              {getLabelsArray(selectedChart).map((bar, index) => {
                const option = `${bar.label.replace('-', '/')}`;
                return (
                  <TdsDropdownSelectOption key={index} value={option}>
                    {option}
                  </TdsDropdownSelectOption>
                );
              })}
            </div>
          </TdsDropdownSelect>
          <TdsToggleSwitch
            name="public"
            size="small"
            id="comment-public-toggle"
            disabled={!!data || !canMakePublic /*|| comment.public*/}
            checked={comment.public}
            onValueChanged={handleChange}
          >
            <EmwTypography fontSize={9} classes="text-bluegrey-400">
              Make Public
            </EmwTypography>
          </TdsToggleSwitch>
          <TdsDropdownSelect
            name="publicIn"
            id="comment-public-in"
            className="ml-auto"
            placeholder="Select option"
            disabled={!!data || !comment.public}
            onSelectedOption={handleChange}
            value={comment.publicIn}
          >
            <TdsDropdownSelectOption value="inThales">In Thales</TdsDropdownSelectOption>
            <TdsDropdownSelectOption value="inMyOrgAll">In my org - all</TdsDropdownSelectOption>
            <TdsDropdownSelectOption value="inMyOrgLocal">
              In my org - local
            </TdsDropdownSelectOption>
          </TdsDropdownSelect>
        </Grid>

        <Grid container flexWrap="nowrap" alignItems="center">
          <EmwTypography fontSize={9} classes="text-bluegrey-400">
            LINKED TO:
          </EmwTypography>
          <div className="ml-s flex-grow">
            <CustomAutocomplete
              name="linkedTo"
              options={linkedToOptions}
              disabled={!!data || isLinkToDisabled}
              defaultValue={comment.linkedTo}
              handleChange={handleChange}
            />
          </div>
        </Grid>

        <Divider className="mt-s" />

        <TdsTextarea
          name="text"
          id="comment-body"
          size="small"
          classes="mt-s"
          value={comment.text}
          onValueChanged={handleChange}
        />
        <TdsFormCaption classes="text-right">
          {maxLength - currentLength} characters left
        </TdsFormCaption>
      </DialogContent>

      <DialogActions>
        <EmwButton
          variant="outline"
          color="primary"
          classes="mr-xs"
          size="small"
          onClick={() => setIsOpen(false)}
        >
          Close
        </EmwButton>
        <EmwButton
          variant="filled"
          color="primary"
          size="small"
          disabled={isLoading || !canPublish}
          onClick={handleCreate}
        >
          <>
            {isLoading && <TdsProgressCircle size="small" classes="mr-xxs" />}
            {data ? 'Update' : 'Publish'}
          </>
        </EmwButton>
      </DialogActions>
    </StyledDialog>
  );
}
