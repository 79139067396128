import React, { useEffect, useState } from 'react';
import EmwButton from '../../../../../../../../components/EmwButton/EmwButton';
import EmwWebExcel from '../../../../../../../../components/EmwWebExcel/EmwWebExcel';
import EmwIcon from '../../../../../../../../components/EmwIcon/EmwIcon';
import EmwTypography from '../../../../../../../../components/EmwTypography/EmwTypography';

export default function AdminToolsEditBtn({
  id,
  title,
  fixedColumns,
  getApi,
  saveApi,
  colWidth,
  pageTitle,
  canAdd,
  saveNewRowApi,
  mandatoryCols,
  hasCustomIcon,
  icon,
}) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      return;
    }
  }, [isOpen]);

  const handleClick = async () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="flex-col justify-between mt-s mr-s" id="adminToold-notigications">
      <EmwButton variant="filled" size="small" onClick={handleClick} id={id} title={title}>
        {hasCustomIcon && <EmwIcon icon={`${icon}`} variant="outlined" size="small" />}
        <EmwTypography>{title}</EmwTypography>
      </EmwButton>
      {isOpen && (
        <EmwWebExcel
          title={title}
          id="webExcel"
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          fixedColumns={fixedColumns}
          canAdd={canAdd}
          saveApi={saveApi}
          getApi={getApi}
          colWidth={colWidth}
          pageTitle={pageTitle}
          saveNewRowApi={saveNewRowApi}
          mandatoryCols={mandatoryCols}
        />
      )}
    </div>
  );
}
