import { buildStep } from '../tour';
import { RIGHT_START, BTN_CLOSE_MODAL_ID, BTN_CLOSE_MODAL } from '../constants';

export const buildDomainSteps = (
  dinamycStep,
  tour,
  name,
  hasBtnWarning,
  btnWarningsTitle,
  btnEditTitle,
  hasBtnFavorite,
  btnFavoriteTitle,
  hasBtnGuests,
  btnManageGPGTitle,
  hasBtnAddNewCC,
  text,
  hasBtnEdit,
  hasBtnEditEnabled,
  hasHc,
  hasHcDeclared
) => {
  dinamycStep.push(
    buildStep(tour, null, {
      id: 'organization-diagram-nodes',
      title: 'Domain Column',
      text: `This column shows all the Domains available. In this example ${name} has been selected, revealing all the CC’s that are part of it.
    The nodes that have a darker gray colour represent "Intuitive Path" - they lead to nodes that are under your responsibility. 
    The nodes that have a star symbol are under your responsibility.
    Click Next to continue.`,
      element: '.organization-diagram-nodes',
      on: RIGHT_START,
      scroll: true,
    })
  );

  dinamycStep.push(
    buildStep(tour, null, {
      id: 'orgCard',
      title: 'Engineering Organization Card',
      text: `Domain section represents the corresponding for Business Line - "BL", it is used only for navigation purposes and you can create new CC from Domain corresponding BL.`,
      element: '.organization-card-container',
      on: RIGHT_START,
    })
  );

  if (hasBtnWarning && hasBtnEdit && hasBtnEditEnabled) {
    dinamycStep.push(
      buildStep(tour, null, {
        id: 'btnWarnings',
        title: btnWarningsTitle,
        text: `Alerts (highlights missing items in your organization).`,
        element: '#btnWarnings',
        on: RIGHT_START,
      })
    );
  }

  if (hasBtnFavorite) {
    dinamycStep.push(
      buildStep(tour, null, {
        id: 'btnFavorite',
        title: btnFavoriteTitle,
        text: `Add to Favorites (will add/remove selected card to your favorites list).`,
        element: '#btnFavorite',
        on: RIGHT_START,
      })
    );
  }

  if (hasBtnGuests) {
    dinamycStep.push(
      buildStep(tour, null, {
        id: 'btnGuests',
        title: btnManageGPGTitle,
        text: `Guest/Power Guest (opens a modal where you can manage Guest/Power Guest access).`,
        element: '#btnGuests',
        on: RIGHT_START,
      })
    );
  }
  if (hasBtnAddNewCC) {
    dinamycStep.push(
      buildStep(tour, null, {
        id: 'btnAddNewOrganization',
        title: text,
        text: `New organization (creates new organization).`,
        element: '#btnAddNewOrganization',
        on: RIGHT_START,
      })
    );
  }

  if (hasBtnEdit) {
    dinamycStep.push(
      buildStep(tour, null, {
        id: 'btnEdit',
        title: btnEditTitle,
        text: `Edit (make changes to the Engineering Organization Card).`,
        element: '.tour-edit',
        on: RIGHT_START,
      })
    );
  }

  if (hasHc || hasHcDeclared) {
    dinamycStep.push(
      buildStep(
        tour,
        null,
        {
          id: 'headcount',
          title: 'Engineering Organization Card Info Area',
          text: `In this area you can visualise information related to the selected organization, such as head count (calculated, declared), you can view what countries the organization belongs to, CC Type, and Industries it belongs to.`,
          element: '.header-elements',
          on: RIGHT_START,
        },
        null,
        null,
        100,
        () => {
          if (hasBtnEdit && hasBtnEditEnabled && document.querySelector('#btnEdit')) {
            document.querySelector('#btnEdit').click();
          }
        }
      )
    );
  }
  if (hasBtnAddNewCC) {
    dinamycStep.push(
      buildStep(
        tour,
        null,
        {
          id: 'btnAddNewOrganization',
          title: 'Add new organization button',
          text: `New organization (creates new organization).`,
          element: '#btnAddNewOrganization',
          on: RIGHT_START,
        },
        null,
        null,
        500,
        async () => {
          setTimeout(() => {
            if (document.querySelector('#btnAddNewCC')) {
              document.querySelector('#btnAddNewCC').click();
            }
          }, 500);
        }
      )
    );

    dinamycStep.push(
      buildStep(
        tour,
        async () => {
          if (document.querySelector(BTN_CLOSE_MODAL)) {
            await document.querySelector(BTN_CLOSE_MODAL).click();
            setTimeout(() => {
              if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
                document.querySelector(BTN_CLOSE_MODAL_ID).click();
              }
            }, 200);
          }
        },
        {
          id: 'countriesAddNewCc',
          title: 'Countries',
          text: `This is the countries category, where you need to select at least one country where the CC Belongs to. Multiple options can be selected.
        Click next to continue.`,
          element: '#tour-orgCard-countrySelectorId',
          on: RIGHT_START,
          scroll: true,
        },
        null,
        null,
        1000,
        async () => {},
        async () => {
          await document.querySelector(BTN_CLOSE_MODAL).click();
          setTimeout(() => {
            if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
              document.querySelector(BTN_CLOSE_MODAL_ID).click();
            }
          }, 200);
        }
      )
    );

    dinamycStep.push(
      buildStep(
        tour,
        null,
        {
          id: 'emAddNewCc',
          title: 'Engineering Manager',
          text: `This is the Engineering Manager category, where we can add 
        a person (or more) in the role of Engineering Manager. We can add or change the person in this role by pressing the Edit (pencil symbol)
        button at the right side. Click next to continue.`,
          element: '#tour-orgCard-ENGINEERING_MANAGER_SECTION_ID',
          on: RIGHT_START,
          scroll: true,
        },
        null,
        null,
        500,
        async () => {
          await document.querySelector(BTN_CLOSE_MODAL).click();
          setTimeout(() => {
            if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
              document.querySelector(BTN_CLOSE_MODAL_ID).click();
            }
          }, 200);
        },
        async () => {
          await document.querySelector(BTN_CLOSE_MODAL).click();
          setTimeout(() => {
            if (document.querySelector(BTN_CLOSE_MODAL_ID)) {
              document.querySelector(BTN_CLOSE_MODAL_ID).click();
            }
          }, 200);
        }
      )
    );
  }
};
