import { getState, initialProps, reduceAction } from './../../util/reducer';
import * as actions from './actions';

const initialState = {
  [actions.AZURE_LOGIN]: {
    ...initialProps,
    list: {},
  },
  [actions.POST_LOGIN]: initialProps,
  [actions.USER_LOGIN]: initialProps,
};

const reducerConfig = {
  [actions.AZURE_LOGIN]: reduceAction,
  [actions.POST_LOGIN]: reduceAction,
  [actions.USER_LOGIN]: reduceAction,
};

export default (state = initialState, action = {}) =>
  getState(state, action, initialState, reducerConfig);
