import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { StyledBarChart } from './styled';
import useChartUtils from '../../hooks/useChartUtils';
import ChartInfo from '../ChartInfo/ChartInfo';
import ChartTooltip from '../ChartTooltip/ChartTooltip';
import EmwTypography from '../../../EmwTypography/EmwTypography';
import { BasicTooltip } from '@nivo/tooltip';

/**
 * @desc https://nivo.rocks/bar/
 */
export default function HorizontalBarChart({
  name,
  data,
  info,
  tooltipInfo,
  comingSoon = false,
  unit = '%',
  padding,
  color,
  infoColor,
  hasCustomTooltip,
}) {
  const chartUtils = useChartUtils({});

  const margin = { top: 10, right: 20, bottom: 35, left: 255 };

  const CustomTooltip = props => {
    return (
      <BasicTooltip
        id={props.label}
        value={`${props.data.num} out of ${props.data.total}`}
        color={props.color}
        enableChip
      />
    );
  };

  return (
    <StyledBarChart className="styled-bar-chart flex flex-col bg-white-100 rounded p-s relative w-2xl">
      <>
        <div className="flex flex-row justify-between">
          <p className="text-scale-7 font-bold ml-s">{name}</p>
          <ChartTooltip title={tooltipInfo} />
        </div>
        <div className="flex-1 chart overflow-y-auto mt-s">
          {!data.length && (
            <EmwTypography classes="text-bluegrey-400 mt-16xl ml-8xl" fontSize="5">
              No data available for selected filters
            </EmwTypography>
          )}
          {data.length > 0 && (
            <ResponsiveBar
              data={data}
              keys={['value']}
              indexBy="label"
              margin={margin}
              enableGridX={true}
              enableGridY={false}
              axisLeft={chartUtils.axisLeft(unit)}
              axisBottom={chartUtils.axisBottom(unit)}
              padding={padding ? padding : 0.4}
              valueScale={{ type: 'linear' }}
              colors={color ? color : '#0F186E'}
              animate={true}
              layout="horizontal"
              height={700}
              enableLabel={true}
              label={d => `${d.value}${unit}`}
              tooltip={hasCustomTooltip ? CustomTooltip : undefined}
              tooltipLabel={tooltip => `${tooltip.data.label}`}
              valueFormat={value => `${value}${unit}`}
              labelTextColor={'white'}
              axisTop={null}
              axisRight={null}
              labelSkipWidth={8}
              indexScale={{ type: 'band', round: true }}
              minValue={0}
              borderRadius={2}
            />
          )}
        </div>
        <ChartInfo info={info} comingSoon={comingSoon} color={infoColor} />
      </>
    </StyledBarChart>
  );
}
