import React from 'react';

export default function ChartBarTooltip({ chartBar }) {
  if (chartBar.data[`${chartBar.id}Info`]) {
    return (
      <div className="bg-black-100 text-white-100 opacity-75 text-scale-8 p-m">
        {chartBar.data[`${chartBar.id}Info`]}
      </div>
    );
  }
  return null;
}
