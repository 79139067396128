import { styled } from '@mui/material';

export const StyledOrgRole = styled('div')(({ theme }) => ({
  ':hover': {
    '&>div:first-of-type': {
      background: '#1e2aa0',
    },
  },
  '&.active': {
    background: '#1e2aa0',
  },
}));
