import React from 'react';
import BarChart from '../../../../../components/EmwCharts/components/BarChart/BarChart';

export default function UsageMonitoringGraph({
  handleClick,
  activeUsersData,
  handleClickDrillThrough,
  handleClickDrillToDetails,
}) {
  return (
    <div className="flex-1 overflow-auto shadow-s-light" id="usage-monitoring">
      <div className="chart-item h-full overflow-hidden ">
        <BarChart
          unit=""
          data={activeUsersData}
          name="Active Users/Month"
          tooltipInfo={<p className="mb-xxs">&#8226; Active Users/Month.</p>}
          info="Active Users"
          hasDrillToDetailButton={true}
          hasDrillThroughButton={true}
          handleClick={handleClick}
          handleClickDrillThrough={handleClickDrillThrough}
          handleClickDrillToDetails={handleClickDrillToDetails}
        />
      </div>
    </div>
  );
}
