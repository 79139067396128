import React from 'react';
import { WORKER_API_ENDPOINT, COUNTRY_API_ENDPOINT } from '../../../constants';
// import { EmwComponentB, EmwComponentD, EmwComponentE } from "../../../lib/specific";

const getComponentE = (
  el,
  label,
  type,
  onAutocompleteChanged,
  onDateChanged,
  onDropDownListChanged,
  inputId
) => {
  if (typeof inputId === 'undefined') {
    inputId = type + '_' + el.id + '_';
  }

  // return (
  //   <EmwComponentE
  //     chkLabel={"type"}
  //     label={label ? label + ":" : ""}
  //     inputId={inputId}
  //     fromDate={el && el.startDate && el.startDate.value ? new Date(el.startDate.value) : ""}
  //     toDate={el && el.endDate && el.endDate.value ? new Date(el.endDate.value) : ""}
  //     /*hideDates={el.startDate ? !el.startDate.visible : false}*/
  //     urlToFetch={WORKER_API_ENDPOINT}
  //     checked={el.type ? el.type.value : 0}
  //     selectedValue={el.name ? el.name.value : ""}
  //     onAutocompleteChange={onAutocompleteChanged}
  //     onDateChange={onDateChanged}
  //     onDropDownListChanged={onDropDownListChanged}
  //     disabled={!el.name.enabled}
  //     placeHolder={"New " + label ? label : ""}
  //     showDropDownList={el.type.visible}
  //   />
  // );
};

const getComponentD = (label, el, type, onTextBoxChanged, onDateChanged, inputId) => {
  if (typeof inputId === 'undefined') {
    inputId = type + '_' + el.id + '_';
  }

  const disciplineAttributes = {
    atributesStartAt: {
      value: el.date && el.date.value ? new Date(el.date.value) : '',
      disabled: !el.date.enabled,
    },
    atributesOrganisation: { disabled: !el.name.enabled },
  };

  // return (
  //   <EmwComponentD
  //     label={label}
  //     name={el.name.value}
  //     atributes={disciplineAttributes}
  //     onNameChange={onTextBoxChanged}
  //     onDateChange={onDateChanged}
  //     isOrganisationHidden={!el.name.visible}
  //     isStartAtHidden={!el.date.visible}
  //     inputId={inputId}
  //   />
  // );
};

const getComponentB = (
  label,
  el,
  selectedBu,
  selectedBl,
  type,
  onTextBoxChanged,
  onDateChanged,
  onAutocompleteChanged,
  showAlertMessage,
  allocateProps,
  addCCInTree,
  inputId
) => {
  if (typeof inputId === 'undefined') {
    inputId = type + '_' + el.id + '_';
  }

  const disciplineAttributes = {
    atributesStartAt: {
      value: el.date && el.date.value ? new Date(el.date.value) : '',
      disabled: !el.date.enabled,
    },
    atributesOrganisation: { disabled: !el.name.enabled },
  };

  // return (
  //   <EmwComponentB
  //     label={label}
  //     name={el.name.value}
  //     baseName={el.baseName}
  //     orgId={el.id}
  //     atributes={disciplineAttributes}
  //     onNameChange={onTextBoxChanged}
  //     onDateChange={onDateChanged}
  //     isOrganisationHidden={!el.name.visible}
  //     addCCInTree={addCCInTree}
  //     isStartAtHidden={!el.date.visible}
  //     onAutocompleteChanged={onAutocompleteChanged}
  //     showAlertMessage={showAlertMessage}
  //     inputId={inputId}
  //     urlToFetch={COUNTRY_API_ENDPOINT}
  //     calculateAutocompleteSugestion={calculateAutocompleteSugestionForCountry}
  //     gbu={el.gbu}
  //     bl={el.bl}
  //     isReadOnly={el.id !== -1}
  //     selectedBl={selectedBl}
  //     selectedBu={selectedBu}
  //     selectedValue={el.orgcountry}
  //     allocateProps={allocateProps}
  //     country={el.country ? el.country : null}
  //   />
  // );
};

const calculateAutocompleteSugestionForEmployee = value => {
  const info = [value.firstName + ' ' + value.lastName + ' ' + value.tgi];
  value.roles.forEach(element => {
    // info.push(<br />)
    info.push(element);
  });

  return info;
};

const calculateAutocompleteSugestionForCountry = value => {
  return [value.name];
};

const calculateAutocompleteLabelForEmployee = value => {
  return value && value.object ? value.object.firstName + ' ' + value.object.lastName : '';
};

const calculateAutocompleteLabelForCountry = value => {
  return value.object.name;
};

const newDiscipline = id => {
  return {
    id: id,
    date: newDate(true),
    name: newName(),
    leader: newLeader(id - 1),
    btnAdd: newBtn(),
    btnRemove: newBtn(),
    engineers: [newEngineer(id - 2)],
    collapsed: false,
  };
};

const newEngineer = id => {
  return {
    id: id,
    name: newName(),
    startDate: newDate(true),
    endDate: newDate(),
    type: newCheckBox(),
    btnAdd: newBtn(),
    btnRemove: newBtn(),
    visible: true,
  };
};

const newWp = id => {
  return {
    id: id,
    date: newDate(true),
    name: newName(),
    leader: newLeader(id + 1),
    btnAdd: newBtn(),
    btnRemove: newBtn(),
    collapsed: false,
    visible: true,
  };
};

const newSolution = id => {
  return {
    id: id,
    date: newDate(true),
    name: newName(),
    leader: newLeader(id + 1),
    btnAdd: newBtn(),
    btnRemove: newBtn(),
    wpms: [newWp(id + 2)],
    collapsed: false,
    visible: true,
  };
};

const newDelivery = (id, node) => {
  return {
    id: id,
    date: newDate(true),
    name: newName(),
    leader: newLeader(id - 1),
    btnAdd: newBtn(),
    btnRemove: newBtn(),
    solutions: node.attributes.type === 'CC' ? [newSolution(id - 2)] : [],
    collapsed: false,
    visible: true,
  };
};

const newBtn = () => {
  return { value: null, enabled: true, visible: true };
};
const newDate = withValue => {
  return { value: withValue ? new Date() : '', enabled: true, visible: true };
};

const newName = () => {
  return { value: '', enabled: true, visible: true };
};

const newCheckBox = () => {
  return { value: true, enabled: true, visible: true };
};

const newLeader = id => {
  return {
    id: id,
    name: newName(),
    startDate: newDate(true),
    endDate: newDate(),
    type: newCheckBox(),
    btnAdd: newBtn(),
    btnRemove: newBtn(),
  };
};

export {
  getComponentB,
  getComponentD,
  getComponentE,
  calculateAutocompleteSugestionForEmployee,
  calculateAutocompleteSugestionForCountry,
  calculateAutocompleteLabelForEmployee,
  calculateAutocompleteLabelForCountry,
  newBtn,
  newDate,
  newLeader,
  newName,
  newDelivery,
  newDiscipline,
  newSolution,
  newWp,
  newEngineer,
};
