import React, { useState } from 'react';
import { Collapse, Grid } from '@mui/material';
import EmwTypography from '../../../../../../components/EmwTypography/EmwTypography';
import { EmwButton } from '../../../../../../../lib/common';
import { TdsIcon } from '@tds/react';
import ArrowIconUp from '../../../../../../../lib/icons/ArrowIconUp';
import ArrowIconDown from '../../../../../../../lib/icons/ArrowIconDown';
import AlertsItem from './AlertsItem';
import { StyledAlertsList } from './styled';
import { useDispatch } from 'react-redux';
import { alertHistoryModal } from '../../../../redux/actions';

export default function AlertsList({ chart, shouldBeOpen }) {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(shouldBeOpen || false);

  const handleHistoryClick = () => {
    dispatch(
      alertHistoryModal({
        isOpen: true,
        chart: {
          parent: chart.parent,
          name: chart.key,
        },
      })
    );
  };

  return (
    <StyledAlertsList>
      <Grid
        container
        alignItems="center"
        className="bg-lightblue-100 mt-xs pl-xs rounded alert-list"
      >
        <EmwTypography fontSize="8" fontWeight="bold" classes="text-bluegrey-600">
          {chart.title}
        </EmwTypography>

        <Grid item className="ml-auto alert-item">
          <Grid container>
            <EmwButton variant="ghost" color="neutral" onClick={handleHistoryClick}>
              <TdsIcon icon="history_toggle_off" className="text-scale-5" />
            </EmwButton>
            {isOpen && (
              <EmwButton variant="ghost" onClick={() => setIsOpen(!isOpen)}>
                <ArrowIconUp />
              </EmwButton>
            )}
            {!isOpen && (
              <EmwButton variant="ghost" onClick={() => setIsOpen(!isOpen)}>
                <ArrowIconDown />
              </EmwButton>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Collapse in={isOpen}>
        <div>
          {chart.values.map(alert => {
            return <AlertsItem key={alert.id} item={alert} title={chart.title} />;
          })}
        </div>
      </Collapse>
    </StyledAlertsList>
  );
}
