import React, { useEffect, useState } from 'react';
import { TdsProgressCircle, TdsTypography } from '@tds/react';
import { Box, Container } from '@mui/material';
import EmwTypography from '../../../../components/EmwTypography/EmwTypography';
import EmwProgressCircle from '../../../../components/EmwProgressCircle/EmwProgressCircle';

export default function Welcome({ user }) {
  const [message, setMessage] = useState(`Welcome, ${user.firstName} ${user.lastName}`);

  useEffect(() => {
    if (user.additionalUser) {
      setMessage(`Welcome, ${user.additionalUser.firstName} ${user.additionalUser.lastName}!`);
    }
  }, []);

  return <EmwTypography component="title-3">{message}</EmwTypography>;
}
