import React from 'react';
import { Box, DialogActions, DialogContent } from '@mui/material';
import EmwDialog from './EmwDialog';
import { TdsTypography } from '@tds/react';
import { EmwButton } from '../common';

export default function EmwPromptDialog({
  open,
  onClosePopup,
  text1,
  text2,
  button1Text,
  button2Text,
  onButton1Click,
  onButton2Click,
  loading,
}) {
  return (
    <>
      <EmwDialog open={open} /*handleClose={onClosePopup}*/>
        <DialogContent dividers>
          <Box textAlign="center">
            <TdsTypography component="body-1">{text1}</TdsTypography>

            <Box mt={1} />

            <TdsTypography component="body-2">{text2}</TdsTypography>
          </Box>

          <DialogActions>
            <EmwButton
              variant="outline"
              size="small"
              onClick={onButton2Click}
              id={`btn${button2Text}-modal`}
            >
              {button2Text}
            </EmwButton>
            <EmwButton
              variant="filled"
              size="small"
              disabled={loading}
              onClick={onButton1Click}
              id={`btn${button1Text}-modal`}
            >
              {button1Text}
            </EmwButton>
          </DialogActions>
        </DialogContent>
      </EmwDialog>
    </>
  );
}
