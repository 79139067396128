import * as api from './api';
import { createAction } from '@reduxjs/toolkit';

const props = {
  error: false,
  meta: { fetching: false },
};

export const UPDATE_SETTINGS = 'settings';

export const updateSettings = createAction(UPDATE_SETTINGS, payload => ({
  ...props,
  payload: api.putUpdateSettingsApi(payload),
}));
