import { Box, Container, Dialog, Grid, styled } from '@mui/material';
import { getZoom } from '../../utils/sizeUtils';

const zoom = getZoom();

export const StyledUnderMaintenanceDialog = styled(Dialog)(({ theme }) => {
  return {
    zoom: `${zoom}%`,
  };
});
