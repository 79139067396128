import React from 'react';
import EMWUnderConstructuion from '../../../../../styles/images/Under construction.svg';
import { StyledEmwUnderMaintenanceLogo } from './styled';
import EmwTypography from '../../../../components/EmwTypography/EmwTypography';
import EmwButton from '../../../../components/EmwButton/EmwButton';
import { setIsDrilled, setSelectedReport } from '../../redux/actions';
import { useNavigate } from 'react-router-dom-v5-compat';
import { useDispatch } from 'react-redux';

export default function UnderMaintenancePage() {
  const dispatch = useDispatch();
  const history = useNavigate();

  const handleClick = () => {
    dispatch(setSelectedReport(null));
    dispatch(setIsDrilled(false));
    history('/landingpage');
  };

  return (
    <div className="flex flex-col flex-grow justify-center items-center">
      <EmwTypography
        fontSize={3}
        classes="flex justify-center items-center text-primary-500 font-bold"
      >
        Page Under Maintenance
      </EmwTypography>
      <EmwTypography fontSize={7} classes="flex justify-center items-center mb-s text-black-100">
        We are currently working on it.
      </EmwTypography>
      <EmwButton
        variant="filled"
        size="small"
        classes="back-button"
        onClick={handleClick}
        id="btnEdit"
        title="Go back"
      >
        Go back
      </EmwButton>
      <div className="flex justify-center items-center">
        <StyledEmwUnderMaintenanceLogo src={EMWUnderConstructuion} alt="EMW" />
      </div>
    </div>
  );
}
