import { CLIENT_ID, TENANT_ID } from '../authConfig';
import { PublicClientApplication } from '@azure/msal-browser';
const ua = window.navigator.userAgent;
const msie = ua.indexOf('MSIE ');
const msie11 = ua.indexOf('Trident/');
const msedge = ua.indexOf('Edge/');
const firefox = ua.indexOf('Firefox');
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

export default class MsalInstance {
  static instance = null;
  static config = {
    auth: {
      clientId: '',
      authority: '',
      redirectUri: '/',
      postLogoutRedirectUri: '/',
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: isIE || isEdge || isFirefox,
    },
    system: {
      allowNativeBroker: false,
    },
  };

  static getInstance() {
    if (MsalInstance.instance instanceof PublicClientApplication) {
      return MsalInstance.instance;
    }

    MsalInstance.config.auth.clientId = localStorage.getItem(CLIENT_ID);
    MsalInstance.config.auth.authority = `https://login.microsoftonline.com/${localStorage.getItem(
      TENANT_ID
    )}`;
    MsalInstance.instance = new PublicClientApplication(MsalInstance.config);

    return MsalInstance.instance;
  }
}
