export const ENVS = {
  DEV: 'dev',
  CLOUD: 'cloud',
  CLOUD_DEV: 'cloud_dev',
  CLOUD_TEST: 'cloud_test',
  CLOUD_INT: 'cloud_int',
  DIGITAL: 'digital',
  DIGITAL_DEV: 'digital_dev',
  DIGITAL_TEST: 'digital_test',
  DIGITAL_INT: 'digital_int',
  DIGITAL_PPD: 'digital_ppd',
};
