import React from 'react';
import { Grid } from '@mui/material';
import EmwTypography from '../../../../../../components/EmwTypography/EmwTypography';
import AlertsType from '../AlertsType/AlertsType';
import moment from 'moment';

export default function HistoryItem({ item }) {
  return (
    <>
      <Grid
        container
        alignItems="center"
        className="history-item bg-bluegrey-25 rounded mt-xs px-xs"
      >
        <Grid item className="mr-xs">
          <AlertsType alertType={item.alertType} />
        </Grid>
        <Grid item>
          <EmwTypography fontSize={8} classes="text-bluegrey-600">
            {item.alertType.orgName}
          </EmwTypography>
        </Grid>
        <Grid item className="ml-auto">
          <EmwTypography fontSize={8}>
            on {moment(item.raiseDate).format('MM.DD.YYYY')}
          </EmwTypography>
        </Grid>
      </Grid>
    </>
  );
}
