import React from 'react';

export default function DoubleArrow() {
  return (
    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.31508 13.7383L5.59607 8.65693L9.87706 13.7383L11.1921 12.1773L5.59607 5.53506L-6.36373e-08 12.1773L1.31508 13.7383ZM-4.69833e-07 2.21391L-5.60099e-07 -0.000184691L11.1921 -0.000185216L11.1921 2.21391L-4.69833e-07 2.21391Z"
        fill="#5D607E"
      />
    </svg>
  );
}
