import { styled, Autocomplete } from '@mui/material';

export const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  backgroundColor: 'white',
  display: 'flex',

  '.MuiInputBase-root': {
    padding: 0,
    width: '350px',
    borderRadius: '2px',
  },
  '.MuiOutlinedInput-notchedOutline': {
    border: '1px solid #5D607E',
  },
  input: {
    paddingLeft: '6px!important',
    fontSize: '12px',
    fontFamily: 'Roboto',
  },
  '.MuiButtonBase-root, MuiChip-root, MuiChip-filled': {
    alignItems: 'center',
    margin: '0 !important',
    height: '20px',
  },
}));
