import { computeURL, fetchDownload, fetchOptions } from '../../util/fetch';

const exportFromEdge = (fileName, blob) => {
  window.navigator.msSaveBlob(
    new Blob([blob], { type: 'data:application/vnd.ms-excel' }),
    fileName
  );
  return {};
};

export const exportFile = (fileName, blob) => {
  if (window.navigator.userAgent.match(/Edge/)) {
    return exportFromEdge(fileName, blob);
  }
  if (blob.byteLength < 1000) {
    return JSON.parse(new TextDecoder('utf-8').decode(blob));
  }
  const newBlob = new Blob([blob], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  const data = window.URL.createObjectURL(newBlob);
  const link = document.createElement('a');
  link.href = data;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  setTimeout(function () {
    window.URL.revokeObjectURL(data);
  }, 500);
  return {};
};

export const exportData = async (url, options, fallbackFileName) => {
  const response = await fetchDownload(url, options);
  let fileName = response.headers.get('Content-Disposition')
    ? response.headers.get('Content-Disposition')
    : fallbackFileName;

  fileName = fileName.replace('attachment; filename="', '').replace('"', '');

  response.arrayBuffer().then(function (buffer) {
    exportFile(fileName, buffer);
  });
};
