import React, { useEffect, useState } from 'react';
import { Box, Divider, Grid } from '@mui/material';
import moment from 'moment';
import {
  DATE_FORMAT,
  ENGINEER_TYPE,
  NO_RESOURCE_ALLOCATED,
  ROLES_TYPE,
} from '../../../../../../constants';
import { TdsIcon } from '@tds/react';
import FormEditItem from '../FormItem/FormEditItem';
import clx from 'classnames';
import { EmwButton } from '../../../../../../../../../lib/common';
import EmwTypography from '../../../../../../../../components/EmwTypography/EmwTypography';
import ItemName from '../FormItem/ItemName';

export default function Section1FormItem({
  item,
  type,
  isNew,
  index,
  propKey,
  isEditOpen,
  setIsEditOpen,
  targetKey,
  section,
  title,
  isOtherReferent,
  categories,
}) {
  const [name, setName] = useState(NO_RESOURCE_ALLOCATED);
  const [editMode, setEditMode] = useState(!!isNew || isEditOpen);
  const [canEdit, setCanEdit] = useState(isEditOpen);
  const className = clx('panel-item');

  useEffect(() => {
    if (!item.name.value) {
      item.name.value = NO_RESOURCE_ALLOCATED;
    }
    setName(item.name.value || NO_RESOURCE_ALLOCATED);
  }, [item]);

  useEffect(() => {
    if (item.key && item.key === targetKey && item.name.value === NO_RESOURCE_ALLOCATED) {
      setEditMode(true);
    } else {
      setEditMode(false);
    }

    setCanEdit(item.btnEdit.visible);
  }, []);

  const toggle = () => {
    setEditMode(false);
  };

  const renderType = () => {
    if (item.hasOwnProperty('type') && item.type.visible) {
      let typeName = 'Effective';

      if (item.type.value) {
        typeName = ROLES_TYPE[item.type.value];
      }

      return (
        <Box ml={2}>
          <EmwTypography fontSize="9" classes="text-black-100">
            {typeName}
          </EmwTypography>
        </Box>
      );
    }

    return null;
  };

  const renderComment = () => {
    if (
      item.hasOwnProperty('comment') &&
      item.comment.value &&
      item.comment.visible &&
      item.comment.enabled
    ) {
      return (
        <Box ml={2}>
          <EmwTypography fontSize="9" classes="text-black-100">
            {item.comment.value}
          </EmwTypography>
        </Box>
      );
    }

    return null;
  };

  const renderDates = () => {
    if (item.hasOwnProperty('startDate') && item.hasOwnProperty('endDate')) {
      return (
        <>
          {item.startDate.visible && (
            <Box>
              <span style={{ marginRight: '5px' }}>From:</span>{' '}
              {Boolean(item.startDate.value) && moment(item.startDate.value).format(DATE_FORMAT)}
            </Box>
          )}

          {item.endDate.visible && (
            <Box>
              <span style={{ marginRight: '5px' }}>To:</span>{' '}
              {Boolean(item.endDate.value) && moment(item.endDate.value).format(DATE_FORMAT)}
            </Box>
          )}
        </>
      );
    }

    return null;
  };

  if (editMode) {
    return (
      <FormEditItem
        item={item}
        label={type}
        toggle={toggle}
        setEditMode={setEditMode}
        section={section}
        type={type}
        index={index}
        propKey={propKey}
        setIsEditOpen={setIsEditOpen}
        isNew={isNew}
        isOtherReferent={isOtherReferent}
        categories={categories}
      />
    );
  }
  return (
    <Grid item data-key={item.key} className={className}>
      <Grid item>
        <Grid container alignItems="center">
          <Grid item>
            <Grid container alignItems="center">
              <Box marginRight="8px" title={title}>
                <EmwTypography classes="text-bluegrey-800" fontWeight="bold">
                  {type === 'GUEST'
                    ? ''
                    : type === 'Other Referents' && item.category.value
                    ? item.category.value + ':'
                    : type === 'Other Referents' && !item.category.value
                    ? 'Referent' + ':'
                    : type + ':'}
                  {item.countryCode ? `${item.countryName} (${item.countryCode})` : ''}
                </EmwTypography>
              </Box>
              <ItemName name={name} />
              {type === ENGINEER_TYPE.KPI.label && renderComment()}
              {renderType()}
            </Grid>
          </Grid>
          <Grid item>
            {' '}
            {type === 'GUEST' ? (
              <EmwTypography classes="text-black-80 ml-xs" fontSize="8">
                Team Spider
              </EmwTypography>
            ) : (
              ''
            )}{' '}
          </Grid>

          <Grid item ml="auto">
            <Grid container direction="column" alignItems="end" sx={{ lineHeight: 'normal' }}>
              {renderDates()}
            </Grid>
          </Grid>

          {canEdit && <Divider orientation="vertical" flexItem sx={{ margin: '0 10px' }} />}

          <Grid item>
            <Grid container alignItems="self-end">
              {canEdit && (
                <EmwButton
                  classes="edit-button"
                  variant="filled"
                  size="small"
                  onClick={() => setEditMode(true)}
                  disableRipple
                  title="Edit"
                  id={`btnEdit${type}-${index ? index : '0'}`}
                >
                  <TdsIcon icon="edit" variant="outlined" size="small" />
                </EmwButton>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
