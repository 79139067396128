import React, { useState } from 'react';
import { Box, Grid } from '@mui/material';
import { StyledOrganizationHeader } from './styled';
import { EmwAutocomplete } from '../../../../../lib/common';
import { useDispatch, useSelector } from 'react-redux';
import PerspectiveBtn from './PerspectiveBtn';
import { SEARCH_ORGANIZATION } from '../../../../../constants';
import { styled } from '@mui/material/styles';
import { ORGANIZATION_PERSPECTIVE, ORGANIZATION_VIEW } from '../../constants';
import { perspectiveSelector } from '../../redux/selectors';
import {
  changePerspective,
  changeView,
  goToNode,
  updateSchema,
  changeCCType,
  setExpandPath,
} from '../../redux/actions';
import initialState from '../../redux/initialstate';
import * as actions from '../../redux/actions';
import { getZoom } from '../../../../utils/sizeUtils';

const zoom = getZoom();

//Was used for Zoom.
const calculateMargin = () => {
  if (zoom > 99) {
    return '468px';
  } else if (zoom > 86 && zoom < 99) {
    return '452px';
  } else if (zoom > 72 && zoom < 86) {
    return '367px';
  } else {
    return '260px';
  }
};

const Search = styled('div')(({ theme }) => ({
  display: 'inline-block',
}));

const calculateAutocompleteLabel = value => {
  return value && value.object ? value.object.label : '';
};

const calculateAutocompleteSugestion = value => {
  const info = [value.sugestion];
  info.push('');
  info.push(value.detail);

  return info;
};

export default function OrganizationHeader() {
  const dispatch = useDispatch();
  const perspective = useSelector(perspectiveSelector);
  const [searchedText, setSearchedText] = useState(/*srcText ? srcText :*/ '');
  const [isSearchVisible, setIsSearchVisible] = useState(true);

  const getAutocomplete = (onAutocompleteChange, inputId, selectedValue) => {
    if (typeof inputId === 'undefined') {
      inputId = 'SearchAutocomplete';
    }

    return (
      <div className="tour-search">
        <EmwAutocomplete
          isSearchHeader
          inputId={inputId + `name`}
          urlToFetch={SEARCH_ORGANIZATION}
          additionalCSS={{ minWidth: '205px' }}
          placement="top"
          variant={'outlined'}
          size={'small'}
          onAutocompleteChange={onAutocompleteChange}
          defaultValue={selectedValue}
          disabled={false}
          placeHolder={'Search'}
          calculateAutocompleteSugestion={calculateAutocompleteSugestion}
          calculateAutocompleteLabel={calculateAutocompleteLabel}
        />
      </div>
    );
  };

  const onAutocompleteChange = event => {
    if (event && event.object) {
      dispatch(goToNode(event.object));
      dispatch(changeCCType([]));
      dispatch(setExpandPath([]));
    }
  };

  const perspectiveHandler = perspectiveType => {
    dispatch(updateSchema(initialState[actions.DIAGRAM_SCHEMA]));
    dispatch(changePerspective(perspectiveType));
    dispatch(changeView(ORGANIZATION_VIEW.FAVORITES));
  };

  return (
    <StyledOrganizationHeader
      container
      direction="column"
      id="tree-root"
      className="tour-filterButtons"
    >
      <Grid item className="header-perspective-buttons flex items-center">
        <PerspectiveBtn
          text="By GBU"
          name={ORGANIZATION_PERSPECTIVE.BY_GBU}
          perspective={perspective}
          handleClick={() => {
            if (perspective !== ORGANIZATION_PERSPECTIVE.BY_GBU) {
              perspectiveHandler(ORGANIZATION_PERSPECTIVE.BY_GBU);
            }
          }}
        />
        <Box mr={1} />
        <PerspectiveBtn
          text="By Country"
          name={ORGANIZATION_PERSPECTIVE.BY_COUNTRY}
          perspective={perspective}
          handleClick={() => {
            if (perspective !== ORGANIZATION_PERSPECTIVE.BY_COUNTRY) {
              perspectiveHandler(ORGANIZATION_PERSPECTIVE.BY_COUNTRY);
            }
          }}
        />
      </Grid>
      <Grid item className="flex justify-end tour-searchButton ml-auto">
        {isSearchVisible && (
          <Search>
            {getAutocomplete(onAutocompleteChange, 'searchAutocomplete', searchedText)}
          </Search>
        )}
      </Grid>
    </StyledOrganizationHeader>
  );
}
