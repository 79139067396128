import React from 'react';
import { useSelector } from 'react-redux';
import { canAddTargetsSelector, chartsSelector } from '../redux/selectors';
import { cloneDeep } from 'lodash';
import usePowerBi from './usePowerBi';

export default function useDraggable() {
  const chartsData = useSelector(chartsSelector);
  const canAddTargets = useSelector(canAddTargetsSelector);

  window.makeDraggable = function (
    evt,
    index,
    yMin,
    yMax,
    min,
    max,
    chartIndex,
    columnIndex,
    hasDecimals,
    unit
  ) {
    if (!canAddTargets) {
      return;
    }
    const svg = evt.target;
    let pointValue;

    svg.addEventListener('touchmove', drag);
    svg.addEventListener('mousemove', drag);
    svg.addEventListener('mousedown', startDrag);
    svg.addEventListener('touchstart', startDrag);
    svg.addEventListener('mouseup', endDrag);
    svg.addEventListener('mouseleave', endDrag);
    svg.addEventListener('touchend', endDrag);
    svg.addEventListener('touchleave', endDrag);
    svg.addEventListener('touchcancel', endDrag);
    svg.addEventListener('onmouseup', endDrag);

    function getMousePosition(evt) {
      const CTM = svg.getScreenCTM();
      if (evt.touches) {
        evt = evt.touches[0];
      }
      return {
        x: (evt.clientX - CTM.e) / CTM.a,
        y: (evt.clientY - CTM.f) / CTM.d,
      };
    }

    let selectedElement, offset, transform;

    function initialiseDragging(evt) {
      offset = getMousePosition(evt);

      // Make sure the first transform on the element is a translate transform
      const transforms = selectedElement.transform.baseVal;

      if (
        transforms.length === 0 ||
        transforms.getItem(0).type !== SVGTransform.SVG_TRANSFORM_TRANSLATE
      ) {
        // Create an transform that translates by (0, 0)
        const translate = svg.createSVGTransform();
        translate.setTranslate(0, 0);
        selectedElement.transform.baseVal.insertItemBefore(translate, 0);
      }

      // Get initial translation
      transform = transforms.getItem(0);
      offset.x -= transform.matrix.e;
      offset.y -= transform.matrix.f;
    }

    function startDrag(evt) {
      if (evt.target.classList.contains('draggable')) {
        selectedElement = evt.target;
        initialiseDragging(evt);
      } else if (evt.target.parentNode.classList.contains('draggable-group')) {
        selectedElement = evt.target.parentNode;
        initialiseDragging(evt);
      }
    }

    function drag(evt) {
      if (selectedElement) {
        evt.preventDefault();
        const coord = getMousePosition(evt);
        const totalHeight = yMax - yMin;
        const value = (totalHeight - coord.y) / (totalHeight / (max - min));

        if (totalHeight - coord.y >= 0 && value <= max - min) {
          transform.setTranslate(0, coord.y - offset.y);
          selectedElement.querySelector('text').innerHTML = parseFloat(value + min).toFixed(
            hasDecimals
          );
          alignLine(value, coord, evt);
        }
      }
    }

    function alignLine(value, coord, evt) {
      const iframeContent =
        document.querySelector('.embed-container iframe').contentWindow.document;
      const selectedLine = iframeContent.querySelector('#myLine' + chartIndex + ' path');
      const currentD = selectedLine.getAttribute('d').split(' ');
      pointValue = parseFloat(value + min).toFixed(2);
      let addition = 12;
      if (evt.target.localName.indexOf('rect') === 0) {
        addition = 2;
      }
      currentD[(index + 1) * 3 - 1] = parseFloat(coord.y + addition).toFixed(2) + unit;
      selectedLine.setAttribute('d', currentD.join(' '));
    }

    function endDrag(evt, name) {
      selectedElement = false;

      if (pointValue && evt.type === 'mouseleave') {
        const event = new CustomEvent('drag_event', {
          detail: {
            index: columnIndex - 1,
            chartIndex,
            kpiName: evt.currentTarget.dataset.kpiname,
            chartName: evt.currentTarget.dataset.chartname,
            value: Number(pointValue),
            charts: cloneDeep(chartsData),
          },
        });

        document.dispatchEvent(event);
      }
    }
  };
}
