import { styled } from '@mui/material/styles';

export const StyledTimeframe = styled('div')(({ theme }) => {
  return {
    '.toggle': {
      rotate: '180deg',
    },

    '#period': {
      maxWidth: '112px',
    },
    '.qtm-dropdown-overlay': {
      width: '150px',
      maxHeight: '390px',
      overflowY: 'scroll',
      zIndex: '999',
    },
  };
});
