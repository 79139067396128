import { getState, reduceAction } from '../../../../util/reducer';
import * as actions from './actions';
import { initialState } from './initialState';

const reducerConfig = {
  [actions.FEEDBACK_STATUS]: reduceAction,
  [actions.FEEDBACK_INSIDE_MODAL]: reduceAction,
};

export default (state = initialState, action = {}) =>
  getState(state, action, initialState, reducerConfig);
