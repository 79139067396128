import { styled, Dialog, TableRow, TableCell, Table, Grid } from '@mui/material';

const PADDING_STYLE = '5px !important';

const blueValues = [
  'Total WorkLoad',
  'Total WorkForce',
  ' Make Internal ',
  'Make Offshoring',
  'Make Country CC',
  'Buy Outsourcing',
];
const pinkValues = ['Tar.', 'For.', 'Act.'];
const yellowValues = ['In Situ'];
const BLUE_BACKGROUND = '#3e6caf !important';
const WHITE_TEXT = '#FFFFFF !important';

const getFontColor = value => {
  if (blueValues.includes(value) || pinkValues.includes(value) || yellowValues.includes(value)) {
    return '#ffffff';
  } else {
    return '';
  }
};

const getCellColor = value => {
  if (blueValues.includes(value)) {
    return '#3e6caf';
  } else if (pinkValues.includes(value)) {
    return '#db9595';
  } else if (yellowValues.includes(value)) {
    return '#c4bb94';
  } else {
    return '';
  }
};

export const StyledKPIDialog = styled(Dialog)(({ theme }) => ({
  '.MuiPaper-root': {
    borderRadius: '6px',
    width: '500px',
    overflowY: 'visible',
  },
  '.MuiDialogContent-root': {
    overflowY: 'visible',
  },
  '.browse-button': {
    width: '70px !important',
  },
  '#export-kpi-header': {
    minHeight: '50px',
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },

  '#select-kpi-dropdown': {
    // width: "177px",
    '.qtm-dropdown, .qtm-dropdown-select, .qtm-dropdown-trigger, .qtm-text-input, .qtm-medium': {
      input: {
        height: '32px !important',
      },
    },
  },

  '#download-container': {
    alignItems: 'end',
  },

  '#upload-container': {
    alignItems: 'center',
  },

  '#tour-download': {
    marginRight: '5px',
    marginLeft: '5px',
  },

  '#export-kpi-actions': {
    marginRight: '5px',
    marginLeft: 'auto',
    marginBottom: '5px',
  },

  '#export-kpi-warning-text': {
    backgroundColor: '#D9D9D933',
    padding: '8px',
  },

  '#tour-KPI': {
    marginRight: '5px',
    marginLeft: '5px',
    marginTop: '5px',
  },

  '#select-year-dropdown': {
    width: '70px',
    height: '32px',
    '.qtm-text-input, .qtm-medium': {
      input: {
        height: '32px !important',
        fontSize: '12px',
        paddingRight: 0,
        paddingLeft: '8px',
      },
    },
    '.qtm-dropdown-overlay': {
      paddingBottom: PADDING_STYLE,
    },
    '.qtm-dropdown-overlay, .qtm-menu-item-list': {
      width: '150px',
      maxHeight: '150px',
      overflowX: 'hidden',
      overflowY: 'auto',
      zIndex: 1500,
      '&::-webkit-scrollbar': {
        width: PADDING_STYLE,
        height: '3px !important',
      },
    },
  },

  '#kpi-actions': {
    // width: "368px",
  },
  '.kpi-modal-btn': {
    height: '32px',
  },

  '#upload-label': {
    maxWidth: '240px',
  },
  '#upload-container, #download-container': {
    '.qtm-btn, .qtm-filled, .qtm-small': {
      width: '113px',
    },
  },
}));

export const StyledHeaderTitle = styled('div')(({ theme }) => ({
  //
}));

export const StyledKPIModal = styled(Dialog)(({ theme, width }) => ({
  '& .MuiPaper-root': {
    borderRadius: '0px',
    width: `${width ? width : '380px'}`,
  },
  '& .MuiDialogTitle-root': {
    padding: '18px',
  },
  '& .MuiDialogContent-root': {
    padding: '0px 16px 16px 16px',
    borderTop: 'none',
  },
  '& .MuiDialogActions-root': {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '18px',
  },
  '& .save-icon .material-icons-outlined': {
    position: 'relative',
    top: '1px',
  },
  '& .close-icon .material-icons-outlined': {
    color: theme.palette.orange[500],
    position: 'relative',
    top: '1px',
  },
  '& .reset-icon .material-icons-outlined': {
    color: theme.palette.orange[500],
    position: 'relative',
    top: '1px',
  },
  '& .settings-icon .material-icons-outlined': {
    color: theme.palette.primary[500],
    position: 'relative',
    top: '1px',
  },
  '#customized-dialog-title': {
    display: 'flex',
    justifyContent: 'row',
    alignItems: 'flex-start',
  },
  '#btnClose-modal': {
    padding: '3px',
    margin: '-3px',
  },
}));

export const StyledEEIInfoTable = styled(Table)(({ theme }) => ({
  width: '300px',
  marginBottom: '10px',
  marginLeft: '30px !important',
}));

export const StyledEEIInfoCell = styled(TableCell)(({ theme }) => ({
  width: '150px',
  fontSize: '11px',
  color: '#FFFFFF',
  fontWeight: 'bold',
  borderRight: '1px solid rgb(224, 224, 224)',
  paddingRight: PADDING_STYLE,
  backgroundColor: '#3e6caf',
}));

export const StyledEEIInfoData = styled(TableCell)(({ theme }) => ({
  width: '78px',
  color: '#000000',
  marginTop: '5px',
  marginLeft: '15px',
  fontWeight: 'bold',
  fontSize: '12px',
  paddingRight: '10px !important',
  paddingLeft: '10px !important',
}));

export const StyledEEIInfoRow = styled(TableRow)(({ theme }) => ({
  border: '1px solid rgb(224, 224, 224) !important',
}));

export const StyledEEIReadMeTitle = styled(Grid)(({ theme }) => ({
  backgroundColor: BLUE_BACKGROUND,
  color: WHITE_TEXT,
  fontWeight: 'bold',
  justifyContent: 'center',
  marginBottom: '10px',
  paddingTop: '5px',
  paddingLeft: '15px',
  paddingRight: '15px',
}));

export const StyledEEIReadMeHeaderCells = styled(TableCell)(({ theme }) => ({
  backgroundColor: BLUE_BACKGROUND,
  color: WHITE_TEXT,
  fontWeight: 'bold !important',
  fontSize: '12px',
}));

export const StyledEEIReadMeSubtitle = styled(Grid)(({ theme }) => ({
  backgroundColor: WHITE_TEXT,
  color: 'red !important',
  fontWeight: 'bold',
  justifyContent: 'center',
  marginBottom: '10px',
  paddingTop: '5px',
  paddingLeft: '15px',
  paddingRight: '15px',
  paddingBottom: '5px',
  display: 'flex',
}));

export const StyledEEIReadMeLeftCells = styled(TableCell)(({ theme }) => ({
  width: '100px',
  height: '10px',
  padding: PADDING_STYLE,
  fontSize: '11px',
  backgroundColor: BLUE_BACKGROUND,
  color: WHITE_TEXT,
  fontWeight: 'bold !important',
}));

export const StyledEEIReadMeDescriptionCells = styled(TableCell)(({ theme }) => ({
  width: '300px',
  fontSize: '11px',
}));

export const StyledWfbReadMeDescriptionCells = styled(TableCell)(
  ({ theme, value, issmallcell }) => ({
    backgroundColor: getCellColor(value),
    color: getFontColor(value),
    fontWeight: 'bold',
    width: issmallcell ? '200px' : '500px',
    fontSize: '11px',
  })
);
