import { Box, Collapse, Grid, IconButton } from '@mui/material';
import React, { useState, useEffect } from 'react';
import EmwTypography from '../../../../../components/EmwTypography/EmwTypography';
import GuestPowerGuestListItem from './GuestPowerGuestListitem';
import { TdsIcon } from '@tds/react';
import { G_PG } from '../../../constants';
import { tourStatusSelector } from '../../../redux/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { ORGANIZATION_VIEW } from '../../../constants';
import { changeView } from '../../../redux/actions';

export default function GuestPowerGuestList({ name, list, isSearched }) {
  const tour = useSelector(tourStatusSelector);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (tour) {
      setIsOpen(true);
    }
  }, [tour]);

  useEffect(() => {
    if (isSearched) {
      setIsOpen(true);
    }
  }, [isSearched]);

  const renderList = () => {
    return list.map(item => {
      return <GuestPowerGuestListItem item={item} name={item.name} key={item.id} />;
    });
  };

  return (
    <>
      <Grid container className="guest-sections">
        <Grid container className="justify-between">
          <EmwTypography classes="text-bluegrey-600 name" fontSize="8">
            {name}
          </EmwTypography>
          <EmwTypography classes="text-bluegrey-600 count" fontSize="8">
            <p id={`${name}_HC`}>{name !== G_PG.EXCOMS.name && list && list.length}</p>
          </EmwTypography>
        </Grid>

        <Grid item ml={'auto'}>
          <Grid container>
            <IconButton
              title={`${isOpen ? 'Collapse section' : 'Expand section'}`}
              disableRipple
              size="small"
              id={`btnToggle${name}`}
              onClick={() => setIsOpen(!isOpen)}
              sx={{ marginLeft: 'auto' }}
            >
              {isOpen ? (
                <TdsIcon icon="keyboard_arrow_up" size="small" style={{ color: '#4A4D65' }} />
              ) : (
                <TdsIcon icon="keyboard_arrow_down" size="small" style={{ color: '#4A4D65' }} />
              )}
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Collapse in={isOpen} sx={{ width: '100%' }}>
        <Box>{renderList()}</Box>
      </Collapse>
    </>
  );
}
