import { Dialog, styled } from '@mui/material';
import { StyledDialog } from '../../styled';

const BORDER_STYLE = '1px solid #DEDEE6';

export const StyledHistoryModal = styled(StyledDialog)(({ theme }) => ({
  '.MuiPaper-root': {
    borderRadius: '3px',
    maxWidth: '600px',
    minHeight: '450px',

    '.dialog-title': {
      padding: '18px 16px 10px 16px',
      maxHeight: '120px',
      overflow: 'hidden',
    },

    '.filters': {
      // maxHeight: "47px",
      paddingBottom: '12px',

      '.type-selector': {
        position: 'relative',
        top: '-3px',
        height: '35px',

        'tds-typography': {
          position: 'relative',
          top: '3px',
        },
      },

      '.sort-filter': {
        borderLeft: BORDER_STYLE,
        borderRight: BORDER_STYLE,
      },

      '.qtm-text-input > input': {
        fontSize: '12px',
      },

      '.tds-dropdown-select': {
        maxWidth: '90px',
      },
    },

    '.MuiDialogContent-root': {
      padding: '0 16px',

      '.no-alerts-available': {
        flexGrow: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'black',
        paddingBottom: '36px',

        '> div': {
          marginTop: '65px',
          marginBottom: '36px',
        },
      },

      '.history-list': {
        borderTop: BORDER_STYLE,

        '.history-item': {
          height: '38px',
        },
      },
    },
  },
}));
