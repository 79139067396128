import React from 'react';

export default function ArrowOutlinedRight() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.91667 14.3335C4.41886 14.3335 1.58333 11.498 1.58333 8.00016C1.58333 4.50236 4.41886 1.66683 7.91667 1.66683C11.4145 1.66683 14.25 4.50236 14.25 8.00016C14.25 11.498 11.4145 14.3335 7.91667 14.3335ZM0 8.00016C0 3.62791 3.54441 0.0834961 7.91667 0.0834961C12.2889 0.0834961 15.8333 3.62791 15.8333 8.00016C15.8333 12.3724 12.2889 15.9168 7.91667 15.9168C3.54441 15.9168 0 12.3724 0 8.00016Z"
        fill="white"
      />
      <path
        d="M8.66369 4.08354C8.74908 4.08324 8.83344 4.10208 8.91059 4.13867C8.98773 4.17525 9.0557 4.22865 9.10949 4.29494L11.87 7.72319C11.954 7.82543 12 7.95367 12 8.08601C12 8.21836 11.954 8.3466 11.87 8.44884L9.01233 11.8771C8.91532 11.9938 8.77591 12.0671 8.62478 12.0811C8.47366 12.095 8.32318 12.0483 8.20647 11.9514C8.08975 11.8544 8.01635 11.715 8.00242 11.5639C7.98848 11.4128 8.03516 11.2624 8.13217 11.1457L10.6869 8.08316L8.2179 5.02059C8.14801 4.93672 8.10361 4.83459 8.08997 4.72629C8.07632 4.61799 8.09399 4.50804 8.14089 4.40946C8.18778 4.31088 8.26195 4.2278 8.3546 4.17004C8.44725 4.11228 8.55451 4.08226 8.66369 4.08354Z"
        fill="white"
      />
      <path
        d="M4.66369 4.08354C4.74908 4.08324 4.83344 4.10208 4.91059 4.13867C4.98773 4.17525 5.0557 4.22865 5.10949 4.29494L7.86998 7.72319C7.95405 7.82543 8 7.95367 8 8.08601C8 8.21836 7.95405 8.3466 7.86998 8.44884L5.01233 11.8771C4.91532 11.9938 4.77591 12.0671 4.62478 12.0811C4.47366 12.095 4.32318 12.0483 4.20647 11.9514C4.08975 11.8544 4.01635 11.715 4.00242 11.5639C3.98848 11.4128 4.03516 11.2624 4.13217 11.1457L6.68691 8.08316L4.2179 5.02059C4.14801 4.93672 4.10361 4.83459 4.08997 4.72629C4.07632 4.61799 4.09399 4.50804 4.14089 4.40946C4.18778 4.31088 4.26195 4.2278 4.3546 4.17004C4.44725 4.11228 4.55451 4.08226 4.66369 4.08354Z"
        fill="white"
      />
    </svg>
  );
}
