import { styled } from '@mui/material/styles';

export const StyledUserMenu = styled('div')(({ theme }) => {
  return {
    height: 'inherit',
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',

    '.trigger-menu': {
      margin: '0 12px 0',
      cursor: 'pointer',
    },

    '.qtm-dropdown-overlay': {
      width: '212px',
    },

    '.user-tooltip': {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  };
});
