import { MODULE_NAME } from '../constants';
import { createSelector } from '@reduxjs/toolkit';
import * as actions from './actions';

const userActivity = state => state[MODULE_NAME];

export const userActivitySelector = createSelector(
  userActivity,
  state => state[actions.USER_ACTIVITY]
);
