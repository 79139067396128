import React from 'react';

export default function ArrowIconUp() {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.94602 8.27301L6.99935 6.21967L9.05268 8.27301L9.99935 7.33301L6.99935 4.33301L3.99935 7.33301L4.94602 8.27301ZM6.99935 13.333C10.6793 13.333 13.666 10.3463 13.666 6.66634C13.666 2.98634 10.6793 -0.000326157 6.99935 -0.000326157C3.31935 -0.000326157 0.332682 2.98634 0.332682 6.66634C0.332682 10.3463 3.31935 13.333 6.99935 13.333ZM6.99935 1.33301C9.94602 1.33301 12.3327 3.71967 12.3327 6.66634C12.3327 9.61301 9.94602 11.9997 6.99935 11.9997C4.05268 11.9997 1.66602 9.61301 1.66602 6.66634C1.66602 3.71967 4.05268 1.33301 6.99935 1.33301Z"
        fill="#4A4D65"
      />
    </svg>
  );
}
