import React from 'react';
import { Grid } from '@mui/material';
import EmwTypography from '../../../../../components/EmwTypography/EmwTypography';
import TooltipAction from '../../OrganizationCard/components/Section1/TooltipAction';
import moment from 'moment';
import { DATE_FORMAT } from '../../../constants';
import { useSelector } from 'react-redux';
import { highlightedTgiSelector } from '../../../redux/selectors';
import clx from 'classnames';

export default function GuestPowerGuestListItem({ item, name }) {
  const highlightedTgi = useSelector(highlightedTgiSelector);
  const className = clx('guest-list-item', {
    highlighted: highlightedTgi === item.unId,
  });
  return (
    <Grid container className={className}>
      <Grid item ml={'13px'}>
        <EmwTypography classes="text-bluegrey-600" fontSize="8" fontWeight="bold">
          {name.value}
        </EmwTypography>
      </Grid>
      <Grid item>
        <TooltipAction employeeId={item.id} />
      </Grid>
      <Grid item title={item.comment.value}>
        {item.comment.value && (
          <EmwTypography classes="text-black-100 ml-xs comment" fontWeight={'400'} fontSize="8">
            {item.comment.value}
          </EmwTypography>
        )}
      </Grid>
      <Grid item ml="auto" mr={'12px'} display="flex">
        {item.startDate.value && (
          <EmwTypography classes="text-black-100" fontSize="8">
            From:{' '}
            <p style={{ fontWeight: 'bold', display: 'inline' }}>
              {Boolean(item.startDate.value) && moment(item.startDate.value).format(DATE_FORMAT)}
            </p>
          </EmwTypography>
        )}
        {item.endDate.value && (
          <EmwTypography classes="text-black-100 ml-xs" fontSize="8">
            To:{' '}
            <p style={{ fontWeight: 'bold', display: 'inline' }}>
              {Boolean(item.endDate.value) && moment(item.endDate.value).format(DATE_FORMAT)}
            </p>
          </EmwTypography>
        )}
      </Grid>
    </Grid>
  );
}
