import React, { cloneElement } from 'react';
import { ShepherdTour, ShepherdTourContext } from 'react-shepherd';
import 'shepherd.js/dist/css/shepherd.css';
import '../style.css';
import {
  RIGHT_START,
  GHOST_BUTTON_CLASS,
  PRIMARY_BUTTON_CLASS,
  PREVIOUS_BUTTON_CLASS,
} from '../constants';

const tourOptions = {
  defaultStepOptions: {
    cancelIcon: {
      enabled: true,
    },
  },
  useModalOverlay: true,
  confirmCancel: false,
};

const defaultSteps = [
  {
    id: 'tour-filterButtons',
    title: 'Filter Buttons',
    text: [
      'These are the filter buttons, and they will help you visualise the graph By GBU or By Country perspective.',
    ],
    attachTo: { element: '.tour-filterButtons', on: RIGHT_START },
    classes: '',
    highlightClass: 'highlightClass',
    buttons: [
      {
        type: 'cancel',
        classes: GHOST_BUTTON_CLASS,
        text: 'Cancel',
      },
      {
        type: 'back',
        classes: PREVIOUS_BUTTON_CLASS,
        text: 'Previous',
      },
      {
        type: 'next',
        classes: PRIMARY_BUTTON_CLASS,
        text: 'Next',
      },
    ],
  },
  {
    id: 'tour-search',
    title: 'Search Function',
    text: ['Use the Search function to find any person in the organization, any GBU, BL or CC.'],
    attachTo: { element: '.tour-search', on: RIGHT_START },
    classes: '',
    highlightClass: 'highlightClass',
    buttons: [
      {
        type: 'cancel',
        classes: GHOST_BUTTON_CLASS,
        text: 'Cancel',
      },
      {
        type: 'back',
        classes: PREVIOUS_BUTTON_CLASS,
        text: 'Previous',
      },
      {
        type: 'next',
        classes: PRIMARY_BUTTON_CLASS,
        text: 'Next',
      },
    ],
  },
];

export default function OrganizationTour({ children }) {
  return (
    <ShepherdTour steps={defaultSteps} tourOptions={tourOptions}>
      {cloneElement(children, { tourContext: ShepherdTourContext })}
    </ShepherdTour>
  );
}
