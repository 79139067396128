import React, { useEffect } from 'react';

export default function useZoom() {
  useEffect(() => {
    // TODO: Ascullta dupa resizul ferestrei
  }, []);

  const zoom = () => {
    let ignoreZoom;

    if (navigator.cookieEnabled) {
      ignoreZoom = localStorage.getItem('ignoreZoom');
    }

    if (ignoreZoom === 'true') {
      return 100;
    }

    const zoom = (window.innerHeight / 860) * 100;

    if (zoom > 100) {
      return 100;
    }

    return zoom;
  };

  return {
    zoom,
  };
}
