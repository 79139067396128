import React from 'react';
import EmwTypography from '../../../../../../components/EmwTypography/EmwTypography';

import { StyledReferentListItem } from './styled';
import EmwTag from '../../../../../../components/EmwTag/EmwTag';
import { useSelector } from 'react-redux';
import { orgDetailsSelector } from '../../../../redux/selectors';
import { NO_RESOURCE_ALLOCATED } from '../../../../constants';

export default function ReferentListItem({
  referents,
  label,
  index,
  title,
  parentId,
  reloadParent,
  reload,
}) {
  const organization = useSelector(orgDetailsSelector);

  const refPerType = referents.map(ref => {
    return ref.name;
  });

  const refName = refPerType.map(ref => {
    return ref.value;
  });

  const refCategory = Array.from(
    new Set(
      referents.map(ref => {
        return ref.category.value;
      })
    )
  );

  if (refName.includes(NO_RESOURCE_ALLOCATED)) {
    return (
      <StyledReferentListItem
        id={`referents-no-resource-allocated-${label}`}
        className="flex flex-1 rounded-xl em-list-item items-center bg-bluegrey-25 pl-xs"
      >
        <EmwTypography fontSize="9" fontWeight="bold" classes="text-bluegrey-800" title={title}>
          {label}:
        </EmwTypography>
        <EmwTypography classes="text-orange-500 ml-xxs" fontSize="8" fontWeight="bold">
          {NO_RESOURCE_ALLOCATED}
        </EmwTypography>
      </StyledReferentListItem>
    );
  }

  if (refPerType[0].value) {
    return (
      <>
        <StyledReferentListItem id={`referents-${label}`} container className="em-list-item">
          <div className={'flex flex-1 items-center rounded-xl bg-bluegrey-25 pl-xs'}>
            <div className="flex">
              <div className="flex items-center role-user">
                <EmwTypography
                  fontSize="9"
                  fontWeight="bold"
                  classes="text-bluegrey-800"
                  title={title}
                >
                  {label === 'Other Referents' && refCategory
                    ? refCategory + ':'
                    : label === 'Other Referents' && !refCategory
                    ? 'Referent' + ':'
                    : label + ':'}
                </EmwTypography>
              </div>
              <div className="flex chip">
                {referents.map(ref => {
                  return (
                    <EmwTypography fontSize={8}>
                      <EmwTag
                        variant="CUSTOMIZED"
                        classes="mr-xs mb-xs"
                        size="small"
                        dismissible={false}
                        text={ref.name.value}
                        referent={ref}
                        employeeId={ref.id}
                        parentId={parentId}
                        orgId={organization.list.data.cardDetails.id}
                        reloadParent={reloadParent}
                        reload={reload}
                        isEngineer={true}
                      />
                    </EmwTypography>
                  );
                })}
              </div>
            </div>
          </div>
        </StyledReferentListItem>
      </>
    );
  }
}
