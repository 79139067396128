import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Grid } from '@mui/material';
import history from '../../../browserHistory';
import LocalStorage from './../../../util/localStorage';
import { LOGIN_TABS } from '../constants';
import LoginTab from './LoginTab';
import EMWLogo from '../../../styles/images/icon/EMW_logo-01.svg';
import ThalesLogo from '../../../styles/images/icon/Thales_logo.svg';
import { useSnackbar } from 'notistack';
import { useLocation } from 'react-router-dom';
import LoginWithCredentials from './LoginWithCredentials';

import { StyledEmwLogo, StyledLoginContainer, StyledLoginForm, StyledThalesLogo } from './styles';
import EmwTypography from '../../../V2/components/EmwTypography/EmwTypography';

export const privateRoutes = [
  '/landingpage',
  '/organization',
  '/moodandconfidence',
  '/engineeringcompetitivness',
  '/jobfamily',
];

export default function Login({ postLogin, loginResult }) {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedTab, setSelectedTab] = useState(LOGIN_TABS.login);
  const [username, setUsername] = useState('');
  const [showErrorHeading, setShowErrorHeading] = useState(false);
  const [referrerRoute, setReferrerRoute] = useState(null);

  useEffect(() => {
    if (loginResult.loading) {
      return;
    }

    if (loginResult.error) {
      const status = loginResult.error.status;
      status !== 800 && status !== 801 && setShowErrorHeading(true);
      enqueueSnackbar('Bad Authentication', { variant: 'error' });

      status === 800 &&
        enqueueSnackbar(
          'You dont have rights to access application! Please contact Daniel Exertier for rights!',
          {
            variant: 'error',
          }
        );

      status === 801 &&
        enqueueSnackbar('All yor rights are expired, so you can not access application!', {
          variant: 'error',
        });
    } else {
      if (username !== '') {
        LocalStorage.setUser({ username: username });
        const onLoginRoute = LocalStorage.getOnLoginRoute();

        if (referrerRoute) {
          history.push(referrerRoute);
          return window.location.reload(false);
        }

        if (typeof onLoginRoute.pathname === 'string' && onLoginRoute.pathname !== '') {
          LocalStorage.setOnLoginRoute({});
          history.push(onLoginRoute);
        } else {
          history.push('/');
        }

        window.location.reload(false);
      }
    }
  }, [loginResult]);

  const handleSelectTab = tab => {
    setSelectedTab(tab);
  };

  return (
    <StyledLoginContainer id="login-container">
      <Grid container alignItems="center" justifyContent="center">
        <StyledThalesLogo src={ThalesLogo} alt="Thales" />
      </Grid>

      <StyledLoginForm item id="login-form">
        <Grid container alignItems="center" borderRadius={10}>
          <LoginTab
            tab={LOGIN_TABS.login}
            selectedTab={selectedTab}
            text="Login"
            handleClick={handleSelectTab}
          />

          <LoginTab
            tab={LOGIN_TABS.getAccess}
            text="Get access"
            selectedTab={selectedTab}
            handleClick={handleSelectTab}
            disabled
          />
        </Grid>

        <Grid container alignItems="center" justifyContent="center">
          <StyledEmwLogo src={EMWLogo} alt="EMW Logo" />
        </Grid>

        <LoginWithCredentials
          postLogin={postLogin}
          loginResult={loginResult}
          setUsername={setUsername}
          showErrorHeading={showErrorHeading}
        />

        <Grid container justifyContent="center" mb={1}>
          <Grid item>
            <EmwTypography classes="text-orange-500" fontWeight="bold" fontSize={8}>
              THALES GROUP CONFIDENTIAL
            </EmwTypography>
          </Grid>
        </Grid>
      </StyledLoginForm>
    </StyledLoginContainer>
  );
}
