import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import '../styles/globals.css';
import store from '../store';
import routes from '../routes';
import { ThemeProvider } from '@mui/material/styles';
import { thalesTheme } from '../theme';

const container = document.getElementById('root');
const root = createRoot(container);
const app = (
  <ThemeProvider theme={thalesTheme}>
    <Provider store={store}>
      <SnackbarProvider
        autoHideDuration={3000}
        maxSnack={3}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {routes}
      </SnackbarProvider>
    </Provider>
  </ThemeProvider>
);

root.render(app);
