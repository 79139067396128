import React from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { goToNode } from '../../../../redux/actions';

export default function DiagramLink({ children, item }) {
  const dispatch = useDispatch();

  return (
    <Box component="span" onClick={() => dispatch(goToNode(item))}>
      {children}
    </Box>
  );
}
