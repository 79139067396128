import React from 'react';
import { StyledOrganizationContainer } from '../OrganizationCard/styled';
import { TdsProgressCircle } from '@tds/react';

export default function EmptyCard() {
  return (
    <StyledOrganizationContainer
      container
      direction="column"
      flexWrap="nowrap"
      alignItems="center"
      justifyContent="center"
    >
      {/*<TdsProgressCircle size="small" />*/}
    </StyledOrganizationContainer>
  );
}
