import { Container, styled } from '@mui/material';

export const StyledCookiesContainer = styled(Container)(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100vh',
    minWidth: '100vw',
    overflow: 'auto',
  };
});

export const StyledEmwCookiesLogo = styled('img')(({ theme }) => ({
  height: '100px',
  width: 'auto',
  cursor: 'pointer',
}));

export const StyledEmwCookiesStep = styled('div')(({ theme }) => ({
  height: '50px',
  width: '50px',
  backgroundColor: '#bdbdbd68',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '24px',
  borderRadius: '50%',
}));

export const StyledEmwCookiesWarning = styled('div')(({ theme }) => ({
  color: '#e07900',
  marginRight: '24px',
}));
