import React, { useContext, useEffect, useState } from 'react';
import DisciplinesPanel from './DisciplinesPanel';
import { OrganizationCardContext } from '../../../../context/OrganizationCardContextProvider';
import TourElement from '../../../../../../../../components/Tour/TourElement/TourElement';

export default function Section3({ section3, targetField }) {
  const context = useContext(OrganizationCardContext);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!open) {
      setOpen(context.shouldBeOpen(section3, targetField.key));
    }
  }, []);

  return (
    <>
      <TourElement id="tour-orgCard-Section3DisciplinesPanel">
        <DisciplinesPanel
          disciplines={section3.disciplines}
          targetField={targetField}
          open={open}
        />
      </TourElement>
    </>
  );
}
