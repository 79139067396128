import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { TdsIcon, TdsProgressCircle } from '@tds/react';
import { EmwButton } from '../../../../../../../lib/common';
import EmwTypography from '../../../../../../components/EmwTypography/EmwTypography';
import { StyledKPIModal } from './styled';

export default function KPIModal({
  isOpen,
  setIsOpen,
  icon,
  title,
  text,
  actionButton,
  callback,
  classes,
  children,
  width,
  loading,
  buttonIcon,
}) {
  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleClick = () => {
    callback();
  };

  return (
    <div>
      <StyledKPIModal aria-labelledby="customized-dialog" open={isOpen} width={width}>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          <TdsIcon classes={classes} icon={icon} size="large" variant="outlined" />
          <EmwTypography fontSize={6} classes="text-black-100 ml-xxs">
            {title}
          </EmwTypography>
        </DialogTitle>
        <DialogContent dividers id="customized-dialog-content">
          <EmwTypography classes={'qtm-body-2'}>
            {children} {text}
          </EmwTypography>
        </DialogContent>
        <DialogActions>
          <EmwButton
            color="primary"
            classes="mr-xs qtm-ghost qtm-neutral"
            size="small"
            onClick={handleCancel}
            id="btnClose"
          >
            Close
          </EmwButton>
          {buttonIcon && (
            <EmwButton
              variant="filled"
              color="primary"
              size="small"
              onClick={handleClick}
              disabled={loading}
              id="btnClose-modal"
            >
              {loading && <TdsProgressCircle size="xsmall" />}
              {!loading && (
                <TdsIcon
                  icon={buttonIcon}
                  size="xsmall"
                  variant="outlined"
                  className="font-bold"
                ></TdsIcon>
              )}
              <EmwTypography className="font-bold">{actionButton}</EmwTypography>
            </EmwButton>
          )}
        </DialogActions>
      </StyledKPIModal>
    </div>
  );
}
