import React from 'react';

export default function SortValueReverse() {
  return (
    <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.50244 19.745H4.25244C4.43477 19.745 4.60964 19.6726 4.73857 19.5436C4.8675 19.4147 4.93993 19.2398 4.93993 19.0575V17.6825C4.93993 17.5002 4.8675 17.3253 4.73857 17.1964C4.60964 17.0674 4.43477 16.995 4.25244 16.995H1.50244C1.3201 16.995 1.14524 17.0674 1.01631 17.1964C0.887375 17.3253 0.814941 17.5002 0.814941 17.6825V19.0575C0.814941 19.2398 0.887375 19.4147 1.01631 19.5436C1.14524 19.6726 1.3201 19.745 1.50244 19.745Z"
        fill="#041295"
      />
      <path
        d="M7.00243 14.245H1.50244C1.3201 14.245 1.14524 14.1726 1.01631 14.0436C0.887375 13.9147 0.814941 13.7398 0.814941 13.5575V12.1825C0.814941 12.0002 0.887375 11.8253 1.01631 11.6964C1.14524 11.5674 1.3201 11.495 1.50244 11.495H7.00243C7.18477 11.495 7.35964 11.5674 7.48857 11.6964C7.6175 11.8253 7.68993 12.0002 7.68993 12.1825V13.5575C7.68993 13.7398 7.6175 13.9147 7.48857 14.0436C7.35964 14.1726 7.18477 14.245 7.00243 14.245Z"
        fill="#041295"
      />
      <path
        d="M9.75243 8.49501H1.50244C1.3201 8.49501 1.14524 8.42257 1.01631 8.29364C0.887375 8.16471 0.814941 7.98984 0.814941 7.80751V6.43251C0.814941 6.25017 0.887375 6.0753 1.01631 5.94637C1.14524 5.81744 1.3201 5.74501 1.50244 5.74501H9.75243C9.93476 5.74501 10.1096 5.81744 10.2386 5.94637C10.3675 6.0753 10.4399 6.25017 10.4399 6.43251V7.80751C10.4399 7.98984 10.3675 8.16471 10.2386 8.29364C10.1096 8.42257 9.93476 8.49501 9.75243 8.49501Z"
        fill="#041295"
      />
      <path
        d="M1.50244 0.24501H12.5024C12.6848 0.24501 12.8596 0.317444 12.9886 0.446375C13.1175 0.575306 13.1899 0.750174 13.1899 0.93251V2.30751C13.1899 2.48984 13.1175 2.66471 12.9886 2.79364C12.8596 2.92257 12.6848 2.99501 12.5024 2.99501H1.50244C1.3201 2.99501 1.14524 2.92257 1.01631 2.79364C0.887375 2.66471 0.814941 2.48984 0.814941 2.30751V0.93251C0.814941 0.750174 0.887375 0.575306 1.01631 0.446375C1.14524 0.317444 1.3201 0.24501 1.50244 0.24501Z"
        fill="#041295"
      />
      <path
        d="M20.9993 12.8214H22.642C23.1287 12.8214 23.3727 13.4121 23.0298 13.7561L20.2919 17.0415C20.1893 17.1441 20.05 17.2017 19.9049 17.2017C19.7597 17.2017 19.6205 17.1441 19.5178 17.0415L16.78 13.7561C16.4353 13.4114 16.6807 12.8214 17.1663 12.8214H18.8091V2.41758C18.8091 2.27236 18.8667 2.13308 18.9694 2.03039C19.0721 1.9277 19.2114 1.87001 19.3566 1.87001H20.4518C20.597 1.87001 20.7363 1.9277 20.839 2.03039C20.9416 2.13308 20.9993 2.27236 20.9993 2.41758V12.8214Z"
        fill="#041295"
      />
    </svg>
  );
}
