import { formatDateTimeForCompare, formatDateForCompare } from './format';

const sortDateAndTime = (a, b) => {
  const firstDate = new Date(...formatDateTimeForCompare(a)).getTime();
  const secondDate = new Date(...formatDateTimeForCompare(b)).getTime();
  if (firstDate < secondDate) {
    return 1;
  } else if (firstDate > secondDate) {
    return -1;
  } else {
    return 0;
  }
};

const sortDate = (a, b) => {
  const firstDate = new Date(...formatDateForCompare(a)).getTime();
  const secondDate = new Date(...formatDateForCompare(b)).getTime();
  if (firstDate < secondDate) {
    return 1;
  } else if (firstDate > secondDate) {
    return -1;
  }
  return 0;
};

const exportFromEdge = (fileName, blob) => {
  window.navigator.msSaveBlob(
    new Blob([blob], { type: 'data:application/vnd.ms-excel' }),
    fileName
  );
  return {};
};

const exportFile = (fileName, blob) => {
  if (window.navigator.userAgent.match(/Edge/)) {
    return exportFromEdge(fileName, blob);
  }
  if (blob.byteLength < 1000) {
    return JSON.parse(new TextDecoder('utf-8').decode(blob));
  }
  const newBlob = new Blob([blob], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  const data = window.URL.createObjectURL(newBlob);
  const link = document.createElement('a');
  link.href = data;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  setTimeout(function () {
    window.URL.revokeObjectURL(data);
  }, 500);
  return {};
};

export { sortDateAndTime, sortDate, exportFile };
