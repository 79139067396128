import React, { useEffect, useState } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import EmwProgressCircle from '../EmwProgressCircle/EmwProgressCircle';

export default function EmwBackdrop({ loading }) {
  const [open, setOpen] = useState(loading);

  useEffect(() => {
    setOpen(loading);
  }, [loading]);

  return (
    <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={open}>
      <EmwProgressCircle />
    </Backdrop>
  );
}
