import moment from 'moment/moment';

export const MODULE_NAME = 'organization';

export const DATE_TIME_FORMAT = 'yyyy-MM-DDTHH:mm:ss';
export const DATE_FORMAT = 'DD/MM/yyyy';
export const ROLES_TYPE = ['Effective', 'Acting', 'External'];
export const NO_RESOURCE_ALLOCATED = 'No resource allocated';
export const ORGANIZATION_CARD_MODAL_TYPE = {
  EDIT: 'EDIT',
  CREATE: 'CREATE',
};

export const ORGANIZATION_TYPE = {
  BL: 'BL',
};

export const ORGANIZATION_WARNING = {
  roleName: '',
  key: '',
};

export const ORGANIZATION_FIELD = {
  value: null,
  enabled: true,
  visible: true,
};

export const ORGANISATION_FORM_ELEMENT = {
  id: null,
  key: '',
  btnAdd: { ...ORGANIZATION_FIELD },
  btnEdit: { ...ORGANIZATION_FIELD },
  name: { ...ORGANIZATION_FIELD },
  type: {
    ...ORGANIZATION_FIELD,
    value: 0,
  },
  startDate: { ...ORGANIZATION_FIELD },
  date: { ...ORGANIZATION_FIELD },
  endDate: { ...ORGANIZATION_FIELD },
  effective: { ...ORGANIZATION_FIELD },
  visible: true,
};

export const ORGANISATION_FORM_ELEMENT_WITHOUT_TYPE = {
  id: null,
  key: '',
  btnAdd: { ...ORGANIZATION_FIELD },
  btnEdit: { ...ORGANIZATION_FIELD },
  name: { ...ORGANIZATION_FIELD },
  startDate: { ...ORGANIZATION_FIELD },
  date: { ...ORGANIZATION_FIELD },
  endDate: { ...ORGANIZATION_FIELD },
  effective: { ...ORGANIZATION_FIELD },
  visible: true,
};

export const ORGANIZATION_CARD_DETAILS = {
  id: null,
  name: null,
  inEditMode: true,
  blCode: null,
  buCode: null,
  originBlCode: null,
  blId: null,
  type: 'CC',
  countryCode: null,
  parentBlsId: null,
  parentBlsCode: null,
  parentBusId: null,
  parentBusCode: null,
};

export const ORGANIZATION_CARD_WPM = {
  id: null,
  key: '',
  btnAdd: { ...ORGANIZATION_FIELD },
  btnEdit: { ...ORGANIZATION_FIELD },
  name: { ...ORGANIZATION_FIELD },
  startDate: { ...ORGANIZATION_FIELD },
  date: { ...ORGANIZATION_FIELD },
  endDate: { ...ORGANIZATION_FIELD },
  effective: { ...ORGANIZATION_FIELD },
  visible: true,
  leader: {
    ...ORGANISATION_FORM_ELEMENT,
  },
};

export const ORGANIZATION_CARD_SOLUTION = {
  id: null,
  key: '',
  btnAdd: { ...ORGANIZATION_FIELD },
  btnEdit: { ...ORGANIZATION_FIELD },
  name: { ...ORGANIZATION_FIELD },
  startDate: { ...ORGANIZATION_FIELD },
  date: { ...ORGANIZATION_FIELD },
  endDate: { ...ORGANIZATION_FIELD },
  effective: { ...ORGANIZATION_FIELD },
  visible: true,
  leader: {
    ...ORGANISATION_FORM_ELEMENT,
  },
  pm: {
    ...ORGANISATION_FORM_ELEMENT,
  },
  da: {
    ...ORGANISATION_FORM_ELEMENT,
  },
  pda: {
    ...ORGANISATION_FORM_ELEMENT,
  },
  wpms: [{ ...ORGANIZATION_CARD_WPM }],
};

export const ORGANIZATION_CARD_DELIVERY = {
  ...ORGANISATION_FORM_ELEMENT_WITHOUT_TYPE,
  leader: {
    ...ORGANISATION_FORM_ELEMENT,
  },
  solutions: [
    {
      ...ORGANIZATION_CARD_SOLUTION,
    },
  ],
};

export const ORGANIZATION_CARD_DISCIPLINE = {
  id: null,
  btnAdd: { ...ORGANIZATION_FIELD },
  btnEdit: { ...ORGANIZATION_FIELD },
  name: { ...ORGANIZATION_FIELD },
  date: { ...ORGANIZATION_FIELD },
  endDate: { ...ORGANIZATION_FIELD },
  visible: true,
  leader: {
    ...ORGANISATION_FORM_ELEMENT,
  },
  engineers: [
    {
      ...ORGANISATION_FORM_ELEMENT,
    },
  ],
};

export const ORGANIZATION_SECTION_1 = {
  visible: true,
  gPgEnabled: true,
  baseName: '',
  prefixName: '',
  id: null,
  btnSave: { ...ORGANIZATION_FIELD },
  btnUndo: { ...ORGANIZATION_FIELD },
  btnDelete: {
    ...ORGANIZATION_FIELD,
    visible: false,
  },
  btnPublish: {
    ...ORGANIZATION_FIELD,
    visible: false,
  },
  btnGuests: { ...ORGANIZATION_FIELD },
  btnFavorite: { ...ORGANIZATION_FIELD },
  btnPrint: { ...ORGANIZATION_FIELD },
  btnEdit: { ...ORGANIZATION_FIELD },
  btnBack: { ...ORGANIZATION_FIELD },
  name: { ...ORGANIZATION_FIELD },
  date: {
    ...ORGANIZATION_FIELD,
    value: moment(Date.now()).format(DATE_TIME_FORMAT),
  },
  endDate: { ...ORGANIZATION_FIELD },
  hcDeclared: { ...ORGANIZATION_FIELD },
  hardware: {
    ...ORGANIZATION_FIELD,
    value: false,
  },
  software: {
    ...ORGANIZATION_FIELD,
    value: false,
  },
  system: {
    ...ORGANIZATION_FIELD,
    value: false,
  },
  industry: {
    ...ORGANIZATION_FIELD,
    value: false,
  },
  hc: { ...ORGANIZATION_FIELD },
  ems: [{ ...ORGANISATION_FORM_ELEMENT }],
  hoEOs: [{ ...ORGANISATION_FORM_ELEMENT }],
  mobfr: {
    ...ORGANISATION_FORM_ELEMENT,
  },
  dr: {
    ...ORGANISATION_FORM_ELEMENT,
  },
  dp: {
    ...ORGANISATION_FORM_ELEMENT,
  },
  type: {
    ...ORGANIZATION_FIELD,
    value: 0,
  },
  // warnings: [
  //     ORGANIZATION_WARNING
  // ]
};

export const ORGANIZATION_SECTION_2 = {
  visible: true,
  deliveries: [{ ...ORGANIZATION_CARD_DELIVERY }],
};

export const ORGANIZATION_CARD_SECTION_3 = {
  visible: true,
  disciplines: [{ ...ORGANIZATION_CARD_DISCIPLINE }],
};
