import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

export const Root = styled(Grid)(() => ({
  width: '300px',
  backgroundColor: '#ECEBEB4D',
  borderRadius: '10px',
  padding: '13px 0 16px 0',
  marginTop: '37px',
}));

export const Root2 = styled(Grid)(() => ({
  width: '300px',
  padding: '13px 0 16px 0',
  marginTop: '37px',
}));
