import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { TdsIcon } from '@tds/react';
import useAppSnackbar from '../../../../../../hooks/useAppSnankbar';
import { publishOrganizationApi } from '../../../../redux/api';
import { getOrganizationDetails } from '../../../../redux/actions';
import { ORGANIZATION_PERSPECTIVE, ORGANIZATION_TYPE } from '../../../../constants';
import { EmwButton } from '../../../../../../../lib/common';
import EmwDialog from '../../../../../../components/EmwDialog/EmwDialog';
import { orgDetailsSelector, perspectiveSelector } from '../../../../redux/selectors';
import { removeCountryCode } from '../../../../../../utils/countryUtils';
import { getTreeData } from '../../../../../header/redux/actions';

export default function PublishBtn({ btn, id, name, blCode, parentCountryId }) {
  const dispatch = useDispatch();
  const orgDetails = useSelector(orgDetailsSelector);
  const perspective = useSelector(perspectiveSelector);
  const [isOpen, setIsOpen] = useState(false);
  const snackbar = useAppSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const onSaveClickYes = async () => {
    setIsLoading(true);
    const response = await publishOrganizationApi(id);

    const payload = {
      id,
      type: ORGANIZATION_TYPE.CC,
      blCode,
      parentCountryId,
    };

    if (orgDetails.list.data.cardDetails.type === ORGANIZATION_TYPE.GBU) {
      payload.type = ORGANIZATION_TYPE.GBU;
    }

    if (orgDetails.list.data.cardDetails.type === ORGANIZATION_TYPE.BL) {
      payload.type = ORGANIZATION_TYPE.BL;
    }

    if (orgDetails.list.data.cardDetails.type === ORGANIZATION_TYPE.CC) {
      payload.type = ORGANIZATION_TYPE.CC;
      payload.blCode = blCode;
    }

    if (perspective === ORGANIZATION_PERSPECTIVE.BY_COUNTRY) {
      payload.blCode = removeCountryCode(payload.blCode);
      payload.parentCountryId = parentCountryId;
    }

    dispatch(getOrganizationDetails(payload));
    dispatch(getTreeData());

    setIsLoading(false);
    snackbar.show(response.message, response.messageType);
    setIsOpen(false);
  };
  return (
    <>
      <Grid item ml={0.5}>
        <EmwButton
          variant="ghost"
          size="small"
          title="Publish"
          disabled={!btn.enabled}
          onClick={() => setIsOpen(!isOpen)}
          id="btnPublish"
        >
          <TdsIcon icon="upload" />
        </EmwButton>
      </Grid>
      {isOpen && (
        <EmwDialog
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          callback={onSaveClickYes}
          actionButton="Publish"
          title={`Do you want to publish the Engineering Organization Card: ${name}?`}
          text="All other users will see this Engineering Organization."
          icon="warning_amber"
          classes="close-icon"
          loading={isLoading}
        />
      )}
    </>
  );
}
