import React from 'react';
import { StyledToggleSwitch } from './styled';
import { Stack } from '@mui/material';

export default function EmwToggleSwitch({
  checked,
  handleChecked,
  loading,
  disabled,
  textScale = 7,
}) {
  return (
    <div className="flex items-center">
      <Stack direction="row" spacing={1} alignItems="center">
        <p className={`text-scale-${textScale}`}>Off</p>

        <StyledToggleSwitch
          checked={checked}
          onChange={handleChecked}
          size="small"
          color="default"
          disabled={disabled || loading}
        />
        <p className={`text-scale-${textScale}`}>On</p>
      </Stack>
    </div>
  );
}
