export const MODULE_NAME = 'powerBi';
export const MOOD_AND_CONFIDENCE = 'moodAndConfidence';
export const OPERATING_MODEL = 'operatingModel';
export const ENGINEERING_COMPETITIVENESS = 'engineeringCompetitiveness';
export const TALENTS_AND_CULTURES = 'talents_and_cultures';
export const CHARTS = {
  CONFIDENCE_TO_DELIVER: 'confidenceToDeliver',
  EC_CONFIDENCE_TO_DELIVER: 'ecConfidenceToDeliver',
  MOOD: 'mood',
  DOTD: 'dotd',
  DAVD: 'davd',
  SKILL_COVERAGE: 'skillCoverage',
  KEY_PROFILE_FIT: 'keyProfileFit',
};

export const PBI_BUTTON_TYPE = {
  TARGET: 'target',
  ALERT: 'alert',
  COMMENT: 'comment',
};
export const COMMENT_TYPES = {
  COMMENT: 'comment',
  ROOT_CAUSE: 'rootCause',
  ACTION_PLAN: 'actionPlan',
};
export const LINKED_TO = {
  OPT_1: '1',
  OPT_2: '2',
  OPT_3: '3',
  OPT_4: '4',
  OPT_5: '5',
  OPT_6: '6',
  OPT_7: '7',
  OPT_8: '8',
  OPT_9: '9',
  OPT_10: '10',
  OPT_11: '11',
  OPT_12: '12',
  OPT_13: '13',
  OPT_14: '14',
  OPT_15: '15',
  OPT_16: '16',
  OPT_17: '17',
  OPT_18: '18',
};

export const LINKED_TO_OPTIONS = {
  OPT_1: 'Understand Needs & Context',
  OPT_2: 'Translate Needs Into Requirements',
  OPT_3: 'Design the Architecture',
  OPT_4: 'Define the Appropriate Reuse',
  OPT_5: 'Make your Solution Maintainable, Manufacturable, Deployable & Operable',
  OPT_6: 'Anticipate and Perform IVV',
  OPT_7: 'Develop a Safe and Cyber Secured Solution',
  OPT_8: 'Master HW Development',
  OPT_9: 'Master SW Development',
  OPT_10: 'Build the Development Strategy Collaboratively',
  OPT_11: 'Master the Solution Configuration',
  OPT_12: 'Master the Technical Debt',
  OPT_13: 'Master Co/Subcontracting',
  OPT_14: 'Structure & Master the Delivery Progress',
  OPT_15: 'Define Engineering Governance',
  OPT_16: 'Build Performance Plan Meeting Competitiveness & Business Objectives',
  OPT_17: 'Provide Right Skill at the Right Time',
  OPT_18: 'Capitalize on the Collective Knowledge of the Organization ',
};
