import React, { useEffect, useState } from 'react';
import { Table, Column, AutoSizer, SortDirection } from 'react-virtualized';
import 'react-virtualized/styles.css';
import CellRenderer from './components/CellRenderer/CellRenderer';
import { StyledEmwTable } from './styled';

export default function EmwTable({ data }) {
  const [headers, setHeaders] = useState([]);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (data && data.header.length > 0) {
      const generatedHeaders = generateHeaders(data.header);
      const extractedRows = extractData(data.rows);

      setHeaders(generatedHeaders);
      setRows(extractedRows);
    }
  }, [data]);

  function extractData(dataRows) {
    return dataRows.map(row => {
      const _row = {};
      row.cels.forEach(cell => (_row[cell.property] = cell));
      return _row;
    });
  }

  function generateHeaders(dataHeaders) {
    return dataHeaders.map(row => {
      return row.map(header => (
        <Column
          key={header.property}
          label={header.value}
          dataKey={header.property}
          flexGrow={1}
          flexShrink={1}
          width={200} // A default value, can be anything reasonable
          cellRenderer={({ cellData, columnData, dataKey, rowData, rowIndex }) => (
            <CellRenderer
              cellData={cellData}
              columnData={columnData}
              rowData={rowData}
              dataKey={dataKey}
              rowIndex={rowIndex}
            />
          )}
        />
      ));
    });
  }

  function getRowHeight({ index }) {
    // Implement logic to calculate row height based on cell content
    const row = rows[index];
    const cellHeights = Object.values(row).map(cell => {
      const cellValue = cell.value || '';
      const content = cellValue;
      const lineHeight = 20; // Example line height, adjust as needed
      const padding = 10; // Example padding, adjust as needed
      const lines = Math.ceil(content.length / 20); // Example calculation, adjust as needed
      return lines * lineHeight + padding;
    });
    return Math.max(...cellHeights);
  }

  return (
    <StyledEmwTable>
      <AutoSizer>
        {({ width, height }) => (
          <Table
            // sort={handleSort}
            // sortBy={sortBy}
            // sortDirection={sortDirection}
            width={width}
            height={height}
            headerHeight={35}
            rowHeight={getRowHeight} // Use the custom row height function
            headerClassName="text-scale-8 text-center bg-black-5 flex justify-center py-xs"
            rowClassName="text-scale-8 text-center"
            rowCount={rows.length}
            rowGetter={({ index }) => rows[index]}
          >
            {headers.flat()}
          </Table>
        )}
      </AutoSizer>
    </StyledEmwTable>
  );
}
