import React, { useContext, useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import FormItem from '../FormItem/FormItem';
import { ENGINEER_TYPE, NO_RESOURCE_ALLOCATED } from '../../../../../../constants';
import { dateIsExpired } from '../../utils';
import { OrganizationCardContext } from '../../../../context/OrganizationCardContextProvider';

export default function Wpm({
  wpms,
  deliveryIndex,
  targetField,
  type,
  childrenIndex,
  propKey,
  isNew,
}) {
  const [values, setValues] = useState(cloneDeep(wpms));
  const [item, setItem] = useState(wpms);
  const context = useContext(OrganizationCardContext);

  useEffect(() => {
    setItem(wpms);
  }, [wpms]);

  const handleUpdate = (objectValues, key) => {
    setItem(objectValues);
    setValues(objectValues);
    context.updateDeliveryManager(objectValues, deliveryIndex, 1, childrenIndex, isNew);
  };

  const setWarningBorder = () => {
    let hasBorder = false;
    if (values.name.value === NO_RESOURCE_ALLOCATED) {
      hasBorder = true;
    } else if (values.name.value) {
      hasBorder = false;
    } else if (dateIsExpired(values.endDate.value)) {
      hasBorder = true;
    }
    return hasBorder;
  };

  return (
    <>
      <FormItem
        section={'section2'}
        isNew={false}
        childrenIndex={childrenIndex}
        index={deliveryIndex}
        type={type}
        item={item}
        handleUpdate={handleUpdate}
        showArrows={false}
        isTextField={false}
        targetKey={targetField.key}
        propKey={propKey}
        id={`delivery-${deliveryIndex}-wpm-${childrenIndex}`}
        noResource={setWarningBorder()}
        title={ENGINEER_TYPE.WPM.title}
      />
    </>
  );
}
