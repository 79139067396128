import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { biPropsSelector } from '../header/redux/selectors';
import { setIsHza } from '../footer/redux/actions';
import { isHzaSelector } from '../footer/redux/selectors';

export default function useHzaSync() {
  const biProps = useSelector(biPropsSelector);
  const isHza = useSelector(isHzaSelector);
  const dispatch = useDispatch();

  /**
   * Listen on isHzaSelector  and hide proxyIframe in isHza is false
   */
  useEffect(() => {
    const proxyIframe = document.getElementById('myFrameHZA');
    if (!isHza && proxyIframe) {
      proxyIframe.style.display = 'none';
    }
  }, [isHza]);

  /**
   * Toggle show cs bi page
   *
   * @param id
   */
  const showBiC3 = id => {
    const proxyIframe = document.getElementById('myFrameHZA');

    proxyIframe.contentWindow.postMessage(
      {
        type: id,
        iframeURL: biProps.list.data[id],
      },
      '*'
    );
    proxyIframe.style.display = 'block';
    dispatch(setIsHza(true));
  };

  const filterWorker = filters => {
    const proxyFrame = document.getElementById('myFrameHZA');
    const worker = proxyFrame.contentWindow.window.filterWorker;

    console.log(filters, worker);
  };

  return {
    showBiC3,
    filterWorker,
  };
}
