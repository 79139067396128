import React, { useEffect, useState } from 'react';
import { StyledFeedbackForm } from './styled';
import { TdsDivider, TdsIcon, TdsRadioButton, TdsTextarea } from '@tds/react';
import EmwButton from '../../components/EmwButton/EmwButton';
import { Rating } from '@mui/material';

export default function FeedbackForm({
  handleClick,
  handleClickSubmit,
  setPayload,
  payload,
  service,
  title,
}) {
  const [workedWell, setWorkedWell] = useState(payload.workedWell);
  const [toBeImproved, setToBeImproved] = useState(payload.toBeImproved);
  const [value, setValue] = useState(payload.service);
  const [rating, setRating] = useState(payload.rating);

  useEffect(() => {
    setValue(title ? title : service);
    setPayload({ ...payload, service: title ? title : service });
  }, []);

  const handleChangeWorkedWell = e => {
    setWorkedWell(e.target.value);
    setPayload({ ...payload, workedWell: e.target.value });
  };

  const handleChangeCanBeImproved = e => {
    setToBeImproved(e.target.value);
    setPayload({ ...payload, toBeImproved: e.target.value });
  };

  const handleSelectService = () => {
    setValue(title ? title : service);
    setPayload({ ...payload, service: title ? title : service });
  };

  const handleSelectEntireApp = () => {
    setValue('EMW');
    setPayload({ ...payload, service: 'EMW' });
  };

  return (
    <StyledFeedbackForm className="feedback-form">
      <div className="flex justify-between">
        <p className="mb-m feedback-title">We Value Your Feedback</p>
        <TdsIcon classes="feedback-close" onClick={handleClick} size="large" icon="close" />
      </div>

      <p className="feedback-text mb-xs text-bluegrey-900">Feedback for:</p>
      <TdsRadioButton size="small" name="period" checked={value} onClick={handleSelectService}>
        <p className="feedback-text mb-xs text-bluegrey-900">{title ? title : service}</p>
      </TdsRadioButton>
      <TdsRadioButton
        size="small"
        name="period"
        checked={payload.service === 'EMW'}
        onClick={handleSelectEntireApp}
      >
        <p className="feedback-text mb-m text-bluegrey-900"> EMW</p>
      </TdsRadioButton>
      <p className="feedback-text mb-xs text-bluegrey-900 required-question">
        Please rate your experience:
      </p>
      <div className="rate">
        <Rating
          IconContainerComponent={props => {
            const { value, ...other } = props;
            return <label id={`feedaback-${value}`} {...other}></label>;
          }}
          id="mood-stars"
          size="medium"
          name="simple-controlled"
          value={rating}
          onChange={(event, newValue) => {
            setRating(newValue);
            setPayload({ ...payload, rating: newValue });
          }}
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            'label:last-child': {
              display: 'none',
            },
            '& .MuiRating-iconFilled': {
              color: '#242a75',
            },
            '& .MuiRating-iconHover': {
              color: '#242a75',
            },
            '& .MuiRating-icon': {},
          }}
        />
        <div className="feedback-text text-bluegrey-500 flex justify-between mb-m">
          <p>Not Satisfied</p>
          <p>Very Satisfied</p>
        </div>
      </div>
      <p className="feedback-text mb-xs text-bluegrey-900">What worked well for you?</p>
      <TdsTextarea
        name="text"
        id="comment-body-worked-well"
        size="small"
        classes="mb-m feedback-comment"
        value={workedWell}
        onValueChanged={handleChangeWorkedWell}
      />
      <p className="feedback-text mb-xs text-bluegrey-900">What could be improved?</p>
      <TdsTextarea
        name="text"
        id="comment-body-can-be-improved"
        size="small"
        classes="mb-l feedback-comment"
        value={toBeImproved}
        onValueChanged={handleChangeCanBeImproved}
      />
      <TdsDivider classes="mb-m" size="small" />

      <EmwButton
        variant="filled"
        color="primary"
        size="small"
        classes="feedback-submit"
        title={`Leave a feedback`}
        id="btn-submit-feedback"
        onClick={handleClickSubmit}
        disabled={!rating}
      >
        Send
      </EmwButton>
    </StyledFeedbackForm>
  );
}
