import React, { useEffect, useState } from 'react';
import EmwButton from '../../components/EmwButton/EmwButton';
import { StyledFeedbackButton } from './styled';
import { TdsIcon } from '@tds/react';
import FeedbackForm from './FeedbackForm';
import FeedbackSubmitted from './FeedbackSubmitted';
import { Backdrop } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { feedbackStatus } from './redux/actions';
import { perspectiveSelector, viewSelector } from '../organization/redux/selectors';
import { ORGANIZATION_PERSPECTIVE, ORGANIZATION_VIEW } from '../organization/constants';
import { saveUserSatisfaction } from '../organization/redux/api';

let availableHash = [
  '#drilltodetails',
  '#drillthrough',
  '#workers',
  '#evolution',
  '#distance&avg',
  '#distribution',
  '#data',
];
export default function FeedbackButton({ title }) {
  const currentView = useSelector(viewSelector);
  const [isOpen, setIsOpen] = useState(false);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const dispatch = useDispatch();
  const perspective = useSelector(perspectiveSelector);
  const service = formatUrlPath(window.location.pathname);

  const [payload, setPayload] = useState({
    service: service,
    rating: null,
    workedWell: '',
    toBeImproved: '',
  });

  useEffect(() => {
    setPayload({
      service: '',
      rating: null,
      workedWell: '',
      toBeImproved: '',
    });
  }, [service]);

  function formatUrlPath(url) {
    // Extract the path after the last slash
    const segments = url.split('/');
    const path = segments[segments.length - 1];

    let formattedPath;
    switch (path) {
      case 'landingpage':
        formattedPath = 'Landing Page';
        break;
      case 'organization':
        if (perspective === ORGANIZATION_PERSPECTIVE.BY_GBU) {
          formattedPath = 'Engineering Organization - By GBU';
        } else {
          formattedPath = 'Engineering Organization - By Country';
        }
        break;
      case 'moodandconfidence':
        formattedPath = 'Mood And Confidence';
        break;
      default:
        formattedPath = path
          .split('-')
          .map(word => {
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
          })
          .join(' ');
    }
    if (availableHash.includes(window.location.hash)) {
      if (window.location.hash === '#workers') {
        formattedPath = 'Workers Assessments - Drill to detail';
      }
      if (window.location.hash === '#evolution') {
        formattedPath = 'Skill Sets Yearly Evolution - Drill to detail';
      }
      if (window.location.hash === '#distance&avg') {
        formattedPath = 'Skill Sets Distances & Averages - Drill to detail';
      }
      if (window.location.hash === '#distribution') {
        formattedPath = 'Skill Distribution - Drill to detail';
      }
      if (window.location.hash === '#data') {
        formattedPath = 'Skill Data - Drill to detail';
      }
      if (window.location.hash === '#drilltodetails') {
        formattedPath = 'Usage Monitoring - Drill to detail';
      }

      if (window.location.hash === '#drillthrough') {
        formattedPath = 'Usage Monitoring - Drill through';
      }
    }
    if (currentView === ORGANIZATION_VIEW.GUEST) {
      formattedPath = 'View Guest/Power Guest';
    }

    return formattedPath;
  }

  const handleClick = () => {
    dispatch(feedbackStatus(!isOpen));
    setIsOpen(!isOpen);
    setFeedbackSubmitted(false);
  };
  const handleClickSubmit = () => {
    setFeedbackSubmitted(!feedbackSubmitted);
    saveUserSatisfaction(payload);
    setPayload({
      service: '',
      rating: null,
      workedWell: '',
      toBeImproved: '',
    });
  };
  const handleClickLeaveAnotherFeedback = () => {
    setFeedbackSubmitted(!feedbackSubmitted);
    setPayload({
      service: '',
      rating: null,
      workedWell: '',
      toBeImproved: '',
    });
  };
  return (
    <StyledFeedbackButton>
      <Backdrop sx={theme => ({ color: '#fff' })} open={isOpen}>
        {isOpen && !feedbackSubmitted && (
          <FeedbackForm
            setPayload={setPayload}
            payload={payload}
            handleClickSubmit={handleClickSubmit}
            handleClick={handleClick}
            service={service}
            title={title}
          />
        )}
        {isOpen && feedbackSubmitted && (
          <FeedbackSubmitted
            handleClick={handleClick}
            handleClickLeaveAnotherFeedback={handleClickLeaveAnotherFeedback}
          />
        )}
      </Backdrop>
      <EmwButton
        classes={`${isOpen ? 'feedback-button-open' : 'feedback-button-closed'}`}
        variant="filled"
        color="primary"
        size="small"
        title={`Leave a feedback`}
        id="btn-feedback"
        onClick={handleClick}
      >
        <TdsIcon icon={`${isOpen ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}`} size="small" />
        <p> Feedback </p>
      </EmwButton>
    </StyledFeedbackButton>
  );
}
