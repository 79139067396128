import React, { useEffect, useState } from 'react';
import { StyledDialog } from '../../styled';
import { DialogActions, DialogContent, DialogTitle, Divider, Grid } from '@mui/material';
import EmwTypography from '../../../../components/EmwTypography/EmwTypography';
import CommentsList from './components/CommentsList/CommentsList';
import { useSelector } from 'react-redux';
import { commentsRolesSelector, commentsSelector } from '../../redux/selectors';
import { EmwButton } from '../../../../../lib/common';
import CreateCommentModal from '../CreateCommentModal/CreateCommentModal';
import { COMMENT_TYPES } from '../../constants';

export default function MyCommentsModal({ isOpen, setIsOpen, selectedChart }) {
  const comments = useSelector(commentsSelector);
  const commentsRoles = useSelector(commentsRolesSelector);
  const [isCreateCommentOpen, setIsCreateCommentOpen] = useState(false);
  const [typeOptions, setTypeOptions] = useState([]);

  useEffect(() => {
    const _typeOptions = [];

    if (commentsRoles.canAddComment) {
      _typeOptions.push({ label: 'Comments', value: COMMENT_TYPES.COMMENT });
    }

    if (commentsRoles.canAddRootCause) {
      _typeOptions.push({ label: 'Root Cause', value: COMMENT_TYPES.ROOT_CAUSE });
    }

    if (commentsRoles.canAddActionPlan) {
      _typeOptions.push({ label: 'Action Plan', value: COMMENT_TYPES.ACTION_PLAN });
    }

    setTypeOptions(_typeOptions);
  }, [commentsRoles]);

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setIsOpen(true);
    }
  };

  return (
    <>
      <StyledDialog
        open={isOpen}
        maxWidth="xs"
        fullWidth
        onClose={handleClose}
        disableEscapeKeyDown
      >
        <DialogTitle className="dialog-title pb-xxs">
          <Grid
            container
            justifyContent="space-between"
            className="items-center border-b-2 border-primary-500 pb-s"
          >
            <EmwTypography classes="text-primary-500" fontSize={6} fontWeight="bold">
              My Comments
            </EmwTypography>
          </Grid>
        </DialogTitle>

        <DialogContent>
          {Object.keys(comments[selectedChart.parent]).map(key => {
            return (
              <CommentsList
                key={key}
                chart={comments[selectedChart.parent][key]}
                shouldBeOpen={key === selectedChart.key}
                selectedChart={selectedChart}
              />
            );
          })}
        </DialogContent>

        <Divider className="mb-xs mx-m" />

        <DialogActions>
          <EmwButton
            variant="outline"
            color="primary"
            classes="mr-xs"
            size="small"
            onClick={() => setIsOpen(false)}
          >
            Close
          </EmwButton>
          <EmwButton
            variant="filled"
            color="primary"
            size="small"
            onClick={() => setIsCreateCommentOpen(true)}
          >
            Create Comment
          </EmwButton>
        </DialogActions>
      </StyledDialog>

      {isCreateCommentOpen && typeOptions.length > 0 && (
        <CreateCommentModal
          isOpen={isCreateCommentOpen}
          setIsOpen={setIsCreateCommentOpen}
          selectedChart={selectedChart}
          typeOptions={typeOptions}
        />
      )}
    </>
  );
}
