import React, { createContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import _, { cloneDeep } from 'lodash';
import deepdash from 'deepdash';
import {
  filterCCTypeSelector,
  perspectiveSelector,
  schemaSelector,
} from '../../../redux/selectors';
import {
  DIAGRAM_SCHEMA,
  setOrgDetails,
  setRequestedOrgId,
  updateSchema,
} from '../../../redux/actions';
import { ORGANIZATION_PERSPECTIVE, ORGANIZATION_TYPE, ORGANIZATION_VIEW } from '../../../constants';
import makeSvgPath from '../../../../../../lib/diagram/shared/functions/makeSvgPath';
import { getOrganizationDetailsApi } from '../../../redux/api';
import initialstate from '../../../redux/initialstate';

deepdash(_);

export const DiagramContext = createContext({});

export default function DiagramContextProvider({ children }) {
  const orgFilter = useSelector(filterCCTypeSelector);
  const dispatch = useDispatch();
  const perspective = useSelector(perspectiveSelector);
  const schema = useSelector(schemaSelector);
  const theme = useTheme();
  const currentSchema = cloneDeep(schema);

  /**
   * Add or replace a column in schema
   *
   * @param nodes Array
   */
  const addColumn = nodes => {
    if (!nodes.length) {
      return;
    }

    const type = nodes[0].type;
    const columnIndex = currentSchema.columns.findIndex(column => {
      return column.type === type;
    });

    updateColumn(currentSchema, type, columnIndex, nodes);

    dispatch(updateSchema(currentSchema));
  };

  /**
   * Update an existing schema column
   *
   * @param currentSchema Schema
   * @param type string
   * @param index number
   * @param nodes Node[]
   */
  const updateColumn = (currentSchema, type, index, nodes) => {
    if (index > 0) {
      currentSchema.columns[index] = {
        type,
        nodes: !nodes.length ? currentSchema.columns[index].nodes : nodes,
      };
    } else {
      currentSchema.columns.push({ type, nodes });
    }

    if (index > 0 && currentSchema.columns[index + 1]) {
      currentSchema.columns.splice(index + 1, currentSchema.columns.length);
    }
  };

  /**
   * Determine the type of a node child
   *
   * @param parentNode Node
   * @param childNodes Node[]
   * @returns string
   */
  const getChildType = (parentNode, childNodes) => {
    return !childNodes.length ? parentNode.type : childNodes[0].type;
  };

  /**
   * Get the name of a node
   *
   * @param parentNode
   * @returns string
   */
  const getNodeName = parentNode => {
    return parentNode.code ? parentNode.code : parentNode.name;
  };

  /**
   *  Go to a node in the diagram schema
   *
   * @param id number
   * @param type string
   * @param found
   * @param setFound
   * @param parentBlId
   * @returns {Promise<void>}
   */
  const goToNode = async (id, type, parentBlId, countryId) => {
    if (schema.columns.find(column => column.type === type)) {
      if (!id) {
        return;
      }
      if (type === ORGANIZATION_TYPE.CC && parentBlId) {
        const blNodes = schema.columns.find(column => column.type === ORGANIZATION_TYPE.BL).nodes;
        const blNode = blNodes.find(node => node.id === parentBlId);

        await fetchAndSelectNode(id, type, blNode.code, null, countryId);
      } else {
        await fetchAndSelectNode(id, type, countryId);
      }
    }
  };

  /**
   * Fetch a diagram node and update the diagram schema and select the node
   * @deprecated Will be removed in the near future
   * @param id int
   * @param type string
   * @param blCode
   * @param region
   * @param countryId
   * @param p
   * @returns {Promise<void>}
   */
  const fetchAndSelectNode = async (id, type, blCode, region, countryId, p) => {
    dispatch(setRequestedOrgId(id));
    const payload = { id, type };

    if (blCode) {
      payload.blCode = blCode;
    }

    if (region) {
      payload.region = region;
      payload.parentCountryId = null;
    }

    if (countryId) {
      if (perspective === ORGANIZATION_PERSPECTIVE.BY_COUNTRY) {
        payload.parentCountryId = countryId;
      } else {
        payload.parentCountryId = null;
      }
    }

    if (type === 'GROUP' && perspective === ORGANIZATION_PERSPECTIVE.BY_COUNTRY) {
      payload.type = 'GROUP_COUNTRY';
    }

    const response = await getOrganizationDetailsApi(payload);
    dispatch(setOrgDetails({ list: { data: response.data }, error: null, loading: false }));

    await selectNode(response.data.cardDetails, response.data.childs);

    return response;
  };

  /**
   * Fetch a diagram node and update the diagram schema and select the node
   *
   * @param payload
   * @returns {Promise<void>}
   */
  const fetchAndSelectNode2 = async payload => {
    dispatch(setRequestedOrgId(payload.id));
    const response = await getOrganizationDetailsApi(payload);
    dispatch(setOrgDetails({ list: { data: response.data }, error: null, loading: false }));

    await selectNode(response.data.cardDetails, response.data.childs);

    return response;
  };

  /**
   * Update a node in the schema column and generate the next column of children nodes
   *
   * @param parentNode Node
   * @param nodes Node[]
   */
  const selectNode = async (parentNode, nodes) => {
    let parentType = parentNode.type;
    const childType = getChildType(parentNode, nodes);
    let mergedNode;

    if (parentType === 'GROUP_COUNTRY') {
      parentType = 'GROUP';
    }

    const parentColumnIdx = currentSchema.columns.findIndex(column => {
      // ?? Some time the parentType could BL or Domain instead of always only 1 of them
      if (parentType === 'BL' || parentType === 'Domain') {
        return column.type === 'BL' || column.type === 'Domain';
      }
      return column.type.toUpperCase() === parentType.toUpperCase();
    });

    const childColumnIdx = currentSchema.columns.findIndex(column => {
      // ?? Some time the childType could BL or Domain instead of always only 1 of them
      if (childType === 'BL' || childType === 'Domain') {
        return column.type === 'BL' || column.type === 'Domain';
      }
      return column.type === childType;
    });

    let parenNodeIndex = currentSchema.columns[parentColumnIdx].nodes.findIndex(node => {
      return node.id === parentNode.id;
    });

    if (
      parentNode.code !== ORGANIZATION_VIEW.FAVORITES &&
      [ORGANIZATION_TYPE.GROUP, ORGANIZATION_TYPE.GROUP_COUNTRY].includes(parentType)
    ) {
      parenNodeIndex = 0;
    }

    if (parentNode.code !== ORGANIZATION_VIEW.FAVORITES) {
      // Reset schema
    }

    if (parenNodeIndex === -1) {
      // Node has been deleted or expired
      return;
    }

    currentSchema.columns[parentColumnIdx].nodes.map(node => {
      node.selected = node.id === parentNode.id;
      if (node.type !== ORGANIZATION_TYPE.CC) {
        node['hasAllocation'] = false;
      }

      if (node.id === parentNode.id) {
        mergedNode = {
          ...parentNode,
          ...node,
          select: true,
        };
        return mergedNode;
      }
      return node;
    });

    currentSchema.columns[parentColumnIdx].nodes.map((node, i) => {
      node.svgColor = theme.palette.bluegrey[100];

      if (i === parenNodeIndex) {
        node.svgColor = theme.palette.primary.main;
      }

      return node;
    });

    dispatch(setRequestedOrgId(null));
    updatePath(currentSchema, mergedNode);
    updateHeaders(currentSchema, mergedNode, getNodeName(mergedNode));
    updateColumn(
      currentSchema,
      childType,
      childColumnIdx,
      generateNodesSvgPath(parenNodeIndex, nodes)
    );
    await traverseNodesAndUpdateSchema(currentSchema, mergedNode);
  };

  /**
   * Update the schema path
   *
   * @todo Add path for country code if necessary
   *
   * @param currentSchema Schema
   * @param node Node
   */
  const updatePath = (currentSchema, node) => {
    if (!node) {
      return;
    }
    if (node.type === ORGANIZATION_TYPE.GROUP) {
      currentSchema.path.selectedGROUP.id = node.id;
      currentSchema.path.selectedGROUP.code = node.type;

      currentSchema.path.selectedGBU = initialstate[DIAGRAM_SCHEMA].path.selectedGBU;
      currentSchema.path.selectedBL = initialstate[DIAGRAM_SCHEMA].path.selectedBL;
      currentSchema.path.selectedCountry = initialstate[DIAGRAM_SCHEMA].path.selectedCountry;
      currentSchema.path.selectedCBU = initialstate[DIAGRAM_SCHEMA].path.selectedCBU;
    }

    if (node.type === ORGANIZATION_TYPE.GBU) {
      currentSchema.path.selectedGBU.id = node.id;
      currentSchema.path.selectedGBU.code = node.code;

      currentSchema.path.selectedBL = initialstate[DIAGRAM_SCHEMA].path.selectedBL;
      currentSchema.path.selectedCountry = initialstate[DIAGRAM_SCHEMA].path.selectedCountry;
      currentSchema.path.selectedCBU = initialstate[DIAGRAM_SCHEMA].path.selectedCBU;
    }

    if (node.type === ORGANIZATION_TYPE.BL || node.type === ORGANIZATION_TYPE.DOMAIN) {
      currentSchema.path.selectedBL.id = node.id;
      currentSchema.path.selectedBL.code = node.code;
    }

    if (node.type === ORGANIZATION_TYPE.REGION) {
      currentSchema.path.selectedRegion.id = node.id;
      currentSchema.path.selectedRegion.code = node.code;

      currentSchema.path.selectedCountry = initialstate[DIAGRAM_SCHEMA].path.selectedCountry;
      currentSchema.path.selectedGBU = initialstate[DIAGRAM_SCHEMA].path.selectedGBU;
      currentSchema.path.selectedBL = initialstate[DIAGRAM_SCHEMA].path.selectedBL;
    }

    if (node.type === ORGANIZATION_TYPE.COUNTRY) {
      currentSchema.path.selectedCountry.id = node.id;
      currentSchema.path.selectedCountry.code = node.code;
      currentSchema.path.selectedCountry.name = node.name;

      currentSchema.path.selectedGBU = initialstate[DIAGRAM_SCHEMA].path.selectedGBU;
      currentSchema.path.selectedBL = initialstate[DIAGRAM_SCHEMA].path.selectedBL;
      currentSchema.path.selectedCBU = initialstate[DIAGRAM_SCHEMA].path.selectedCBU;
    }

    if (node.type === ORGANIZATION_TYPE.CBU) {
      currentSchema.path.selectedCBU.id = node.id;
      currentSchema.path.selectedCBU.code = node.code;
    }
  };

  /**
   * Traverse each node and apply various checking (eg: add/remove hasFavorite)
   *
   * @param currentSchema Schema
   * @param node Node
   * @returns {Promise<void>}
   */
  const traverseNodesAndUpdateSchema = async (currentSchema, node, section1Name) => {
    const clonedSchema = cloneDeep(currentSchema);

    if (node.type === ORGANIZATION_TYPE.CC) {
      _.mapKeysDeep(clonedSchema, (value, key, parentObject) => {
        if (key === 'id') {
          parentObject['hasAllocation'] = hasAllocation(node, value);

          if (value === node.id) {
            parentObject['hasAllocation'] =
              node.parentBusId.length > 0 || node.parentBlsId.length > 0;
            if (section1Name) {
              parentObject['name'] = section1Name;
            }
          }
        }

        return value;
      });
      await dispatch(updateSchema(clonedSchema));
    } else {
      const cleanSchema = _.mapValuesDeep(cloneDeep(currentSchema), (value, key) => {
        if (key === 'hasAllocation') {
          value = false;
        }

        return value;
      });
      await dispatch(updateSchema(cleanSchema));
    }
  };

  /**
   * Check if a node has allocated nodes
   *
   * @param node Node
   * @param value int
   * @returns {boolean|*}
   */
  const hasAllocation = (node, value) => {
    if (!Array.isArray(node.parentBusId) || !Array.isArray(node.parentBlsId)) {
      return false;
    }

    return node.parentBusId.includes(value) || node.parentBlsId.includes(value);
  };

  /**
   * Update schema headers
   *
   * @param currentSchema Schema
   * @param parentNode Node
   * @param name string
   * @returns void
   */
  const updateHeaders = (currentSchema, parentNode, name) => {
    if (parentNode.type === 'GROUP') {
      currentSchema.headers[perspective].forEach(header => {
        header.selectedUnit = '';
      });
      return;
    }

    const headerIndex = currentSchema.headers[perspective].findIndex(header => {
      if (parentNode.type === 'Domain') {
        return header.type === 'BL';
      }
      return header.type === parentNode.type;
    });

    currentSchema.headers[perspective].forEach((header, index) => {
      header.canAddCC = false;
      if (headerIndex === index) {
        header.selectedUnit = name;
      }

      if (index > headerIndex) {
        header.selectedUnit = '';
      }

      if (header.type === 'CC') {
        header.canAddCC = parentNode.canAddCC;
      }
    });

    try {
      currentSchema.headers[perspective][headerIndex].selectedUnit = name;
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * Generate svg path for each node
   *
   * @param parenNodeIndex int
   * @param nodes Node[]
   * @returns {*}
   */
  const generateNodesSvgPath = (parenNodeIndex, nodes) => {
    return nodes.map((node, i) => {
      const y = parenNodeIndex > 0 ? parenNodeIndex * 66 : 0;
      const x = 66;
      const constant = 9;

      node.svgPath = makeSvgPath([0, y + constant], [30, i * x + constant - (i > 7 ? i * 1 : 0)]);
      node.svgColor = theme.palette.bluegrey[100];

      return node;
    });
  };

  /**
   * Update a schema node if it exists in the currentSchema, if not then fetch and select the parent node
   *
   * @param orgDetails
   * @returns {Promise<void>}
   */
  const updateNode = async orgDetails => {
    const currentSchema = cloneDeep(schema);
    const id = orgDetails.cardDetails.id;
    const type = orgDetails.cardDetails.type;
    const name = orgDetails.section1.name.value;
    const column = currentSchema.columns.find(column => column.type === type);
    const nodeIndex = column.nodes.findIndex(node => node.id === id);

    if (nodeIndex !== -1) {
      column.nodes[nodeIndex] = { ...column.nodes[nodeIndex], name };
      await dispatch(updateSchema(currentSchema));
    } else {
      await fetchAndSelectNode(
        currentSchema.path.selectedBL.id,
        currentSchema.path.selectedBL.type
      );
    }
  };

  return (
    <DiagramContext.Provider
      value={{
        selectNode,
        goToNode,
        fetchAndSelectNode,
        fetchAndSelectNode2,
        updateNode,
        traverseNodesAndUpdateSchema,
        addColumn,
        generateNodesSvgPath,
      }}
    >
      {children}
    </DiagramContext.Provider>
  );
}
