import iconErase12 from './assets/icons8-erase-16.png';
import iconErase32 from './assets/icons8-erase-32.png';
export const MODULE_NAME = 'filters';
export const RESIZABLE = {
  style: {
    borderLeft: '1px solid #B3B0AD',
  },
  defaultSize: {
    width: 240,
    height: '100%',
  },
  maxWidth: 420,
  minWidth: 120,
};
export const RESIZABLE_CLOSED = {
  style: {
    borderLeft: '1px solid #B3B0AD',
  },
};

export const CUSTOM_ERASE_ICON = {
  small: iconErase12,
  large: iconErase32,
};

export const CLASS_NAME_PREFIX = 'generalFilters__';

export const FILTERS_SECTIONS = {
  COUNTRY: 'country',
  GBU: 'gbu',
  BL: 'bl',
  CC: 'cc',
};

export const FILTERS_TYPE = {
  country: [],
  gbu: [],
  bl: [],
  cc: [],
};

export const DEFAULT_FILTERS = [
  { id: null, name: 'Select all' },
  { id: null, name: '(Blank)' },
];

export const ROLES_LIST = [
  {
    value: 'EM',
    label: 'EM',
  },
  {
    value: 'HoEO',
    label: 'HoEO',
  },
  {
    value: 'KPIS',
    label: 'KPIS',
  },
  {
    value: 'HoD',
    label: 'HoD',
  },
  {
    value: 'HoED',
    label: 'HoED',
  },
  {
    value: 'EDM',
    label: 'EDM',
  },
  {
    value: 'WPM',
    label: 'WPM',
  },
  {
    value: 'DA',
    label: 'DA',
  },
  {
    value: 'PDA',
    label: 'PDA',
  },
  {
    value: 'DR',
    label: 'DR',
  },
  {
    value: 'CybSR',
    label: 'CybSR',
  },
  {
    value: 'EcoDR',
    label: 'EcoDR',
  },
  {
    value: 'UXDR',
    label: 'UXDR',
  },
  {
    value: 'DPR',
    label: 'DPR',
  },
  {
    value: 'MoBFR',
    label: 'MoBFR',
  },
  {
    value: 'EQL',
    label: 'EQL',
  },
  {
    value: 'CER',
    label: 'CER',
  },
  {
    value: 'G',
    label: 'G',
  },
  {
    value: 'PG',
    label: 'PG',
  },
  {
    value: 'EXCOM',
    label: 'EXCOM',
  },
  {
    value: 'SU',
    label: 'SU',
  },
  {
    value: 'QESCM',
    label: 'QESCM',
  },
  {
    value: 'Admin',
    label: 'Admin',
  },
  {
    value: 'Contributor',
    label: 'Contributor',
  },
  {
    value: 'HRM',
    label: 'HRM',
  },
];

export const SERVICES_LIST = [
  {
    value: 'Organization View',
    label: 'Organization View',
  },
  {
    value: 'Emw Access',
    label: 'Emw Access',
  },
  {
    value: 'Organization Update',
    label: 'Organization Update',
  },
  {
    value: 'Guest/PowerGuest View',
    label: 'Guest/PowerGuest View',
  },
  {
    value: 'Guest/PowerGuest Update',
    label: 'Guest/PowerGuest Update',
  },
  {
    value: 'Engineering Compliance View',
    label: 'Engineering Compliance View',
  },
  {
    value: 'WorkLoad/WorkForce View',
    label: 'WorkLoad/WorkForce View',
  },
  {
    value: 'Employee detail',
    label: 'Employee detail',
  },
  {
    value: 'Employee underneath hierarchy',
    label: 'Employee underneath hierarchy',
  },
  {
    value: 'Impersonate/Agregate User',
    label: 'Impersonate/Agregate User',
  },
  {
    value: 'Search User/Organization',
    label: 'Search User/Organization',
  },
  {
    value: 'Export organization',
    label: 'Export organization',
  },
  {
    value: 'Add/Remove organization from favorite',
    label: 'Add/Remove organization from favorite',
  },
  {
    value: 'Add/Remove employee from engineer activity',
    label: 'Add/Remove employee from engineer activity',
  },
  {
    value: 'Surveys(MCD) Downloaded',
    label: 'Surveys(MCD) Downloaded',
  },
  {
    value: 'Search BCC Configuration',
    label: 'Search BCC Configuration',
  },
  {
    value: 'Publish organization',
    label: 'Publish organization',
  },
  {
    value: 'WorkLoad/WorkForce Download',
    label: 'WorkLoad/WorkForce Download',
  },
];
