import React from 'react';
import { Grid } from '@mui/material';
import { TdsFormLabel, TdsTextInput, TdsTypography } from '@tds/react';
import { EmwButton } from '../../../lib/common';
import { StyledButtonItem, StyledTdsFormField } from './styles';
import { useSnackbar } from 'notistack';

import * as yup from 'yup';
import { useFormik } from 'formik';

const validationSchema = yup.object({
  username: yup.string('Enter your username').required('Username is required'),
  password: yup.string('Enter your password').required('Password is required'),
});

export default function LoginWithCredentials({
  postLogin,
  loginResult,
  setUsername,
  showErrorHeading,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema,
    onSubmit: values => postLogin(values),
  });

  const handleChange = event => {
    const { name, value } = event.target;
    formik.handleChange(event);

    if (name === 'username') {
      setUsername(value);
    }
  };

  const handleSubmit = event => {
    formik.handleSubmit();
    const host = window.location.host;

    // if (host.includes("thalesdigital.io")) {
    //   window.postMessage({
    //     name: "login",
    //     ...formik.values
    //   });
    // }

    event.preventDefault();
    validationSchema.validate(formik.values).catch(err => {
      if (formik.errors.username) {
        enqueueSnackbar('Username is required', { variant: 'error' });
      }

      if (formik.errors.password) {
        enqueueSnackbar('Password is required', { variant: 'error' });
      }
    });
  };

  return (
    <form>
      <Grid container alignItems="center" justifyContent="center">
        {showErrorHeading && (
          <Grid item xs={11} sm={10} md={8} mb={1} textAlign="center">
            <TdsTypography classes={['text-red-500']} component="body-2" fontWeight="thin">
              Wrong credentials
            </TdsTypography>

            <TdsTypography classes={['text-bluegrey-400']} component="caption-1" fontWeight="thin">
              Please use a correct Tgi and Password
            </TdsTypography>
          </Grid>
        )}

        <Grid item xs={11} sm={10} md={8}>
          <StyledTdsFormField>
            <TdsFormLabel required htmlFor="username">
              TGI
            </TdsFormLabel>
            <TdsTextInput
              id="username"
              name="username"
              classes={formik.touched.username && formik.errors.username && ['error']}
              leadingIcon="key"
              trailingIcon={formik.touched.username && formik.errors.username && 'error'}
              value={formik.values.username}
              onValueChanged={handleChange}
            />
          </StyledTdsFormField>
        </Grid>

        <Grid item xs={11} sm={10} md={8}>
          <StyledTdsFormField>
            <TdsFormLabel required htmlFor="password">
              Password
            </TdsFormLabel>
            <TdsTextInput
              id="password"
              name="password"
              classes={formik.touched.password && formik.errors.password && ['error']}
              leadingIcon="lock"
              trailingIcon={formik.touched.password && formik.errors.password && 'error'}
              type="password"
              value={formik.values.password}
              onValueChanged={handleChange}
            />
          </StyledTdsFormField>
        </Grid>

        <StyledButtonItem item xs={11} sm={10} md={8}>
          <EmwButton
            variant="filled"
            inputId="login-btn"
            disabled={loginResult.loading}
            type="submit"
            onClick={handleSubmit}
          >
            Login
          </EmwButton>
        </StyledButtonItem>
      </Grid>
    </form>
  );
}
