import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';

export const StyledToggleSwitch = styled(Switch)(({ theme }) => ({
  width: 26,
  height: 14,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {},
    '& .MuiSwitch-switchBase.Mui-checked': {},
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#a5aeff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    width: 10,
    height: 10,
    borderRadius: 6,
  },
  '& .MuiSwitch-track': {
    backgroundColor: '#9A9DB5!important',
  },
}));
