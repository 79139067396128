export const MODULE_NAME = 'userActivity';

export const USER_ACTIVITY_SERVICES = {
  MCD_TILE: 'Mood & Confidence',
  MCD_DRILL_THROGH: 'Mood & Confidence Drill Through',
  MCD_DRILL_TO: 'Mood & Confidence Drill to details',
  MCD_HZA: 'Mood & Confidence with Project Names',
  ENGINEERING_COCKPIT: 'Engineering Cockpit',
  TALENTS_AND_CULTURE: 'Talents & Culture',
  OPERATING_MODEL: 'Operating Model',
  ENG_ORG: 'Engineering Organization View',
  ENG_ORG_EDIT: 'Engineering Organization Edit',
  ENG_ORG_EXPORT: 'Engineering Organization Export',
  G_PG: 'Guest/PowerGuest View',
  G_PG_EDIT: 'Guest/PowerGuest Edit',
  MANAGE_KPI: 'Manage KPI data',
  SEARCH: 'Search User/Organization',
  ALLOCATE_CC: 'Allocate CC',
  EMW_ACCESS: 'Emw Access',
};
