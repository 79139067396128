import React, { useEffect, useState } from 'react';
import { Collapse, Grid, IconButton } from '@mui/material';
import { StyledTogglePanel } from '../../../../../../../../../modules/organization/components/organizationCard/components/OrganizationCardStyled';
import PlusIcon from '../../../../../../../../../lib/icons/PlusIcon';
import EmwTypography from '../../../../../../../../components/EmwTypography/EmwTypography';
import { TdsIcon } from '@tds/react';
import { ENGINEER_TYPE } from '../../../../../../constants';
import Wpm from './Wpm';
import SuitCaseIcon from '../../../../../../../../../lib/icons/SuitCaseIcon';
import { generateRandom } from '../../../../../../../../../util/generateRandom';

const newListItem = {
  btnAdd: { value: null, enabled: true, visible: true },
  btnEdit: { value: null, enabled: true, visible: true },
  id: -1,
  name: {
    value: null,
    enabled: false,
    visible: true,
  },
  comment: {
    value: null,
    enabled: true,
    visible: true,
  },
  type: {
    value: 0,
    enabled: false,
    visible: true,
  },
  startDate: {
    value: null,
    enabled: true,
    visible: true,
  },
  endDate: {
    value: null,
    enabled: true,
    visible: true,
  },
  visible: true,
  dateAdded: '',
};

export default function WpmPanel({ wpms, deliveryIndex, targetField, title }) {
  const [isOpen, setIsOpen] = useState(false);
  const [canAdd, setCanAdd] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    setCanAdd(wpms.btnAdd.visible);

    const filteredList = [...wpms.managers].filter(listItems => listItems);
    const sortedItems = [];
    //Sort WPM items - display new items first
    filteredList.forEach(item => {
      sortedItems.push(item);
    });
    setItems(
      sortedItems.map((item, index) => {
        return (
          <Wpm
            isNew={false}
            targetField={targetField}
            key={index}
            wpms={item}
            deliveryIndex={deliveryIndex}
            childrenIndex={index}
            showArrows={false}
            targetKey={targetField}
            propKey={'managers'}
            type={ENGINEER_TYPE.WPM.label}
          />
        );
      })
    );
  }, [wpms.managers]);

  const addItem = () => {
    setIsOpen(true);
    const _items = [...items];
    const _newItem = { ...newListItem, dateAdded: new Date().getTime() };
    _items.unshift(
      <Wpm
        isNew={true}
        targetField={targetField}
        key={generateRandom()}
        wpms={_newItem}
        childrenIndex={_items.length}
        deliveryIndex={deliveryIndex}
        showArrows={false}
        targetKey={targetField}
        propKey={'managers'}
        type={ENGINEER_TYPE.WPM.label}
      />
    );
    setItems(_items);
  };

  return (
    <>
      <StyledTogglePanel
        item
        sx={{
          flexGrow: 1,
          borderRadius: isOpen ? '5px 5px 0 0' : '5px',
          cursor: 'pointer',
        }}
      >
        <Grid container direction="row" alignItems="center">
          <Grid item mr={1}>
            <SuitCaseIcon />
          </Grid>
          <Grid item>
            <EmwTypography component="caption-2" fontWeight="bold">
              {title}
            </EmwTypography>
          </Grid>

          <Grid item ml="auto">
            <Grid container alignItems="center">
              {canAdd && (
                <IconButton
                  title="Add new"
                  sx={{ padding: 0 }}
                  disableRipple
                  onClick={addItem}
                  id="btnAddWpms"
                >
                  <PlusIcon />
                </IconButton>
              )}
              <IconButton
                title={`${isOpen ? 'Collapse section' : 'Expand section'}`}
                disableRipple
                size="small"
                onClick={() => setIsOpen(!isOpen)}
                id={`${isOpen ? 'btnCollapseWpms' : 'btnExpandWpms'}`}
              >
                {isOpen ? (
                  <TdsIcon icon="keyboard_arrow_up" size="small" style={{ color: '#4A4D65' }} />
                ) : (
                  <TdsIcon icon="keyboard_arrow_down" size="small" style={{ color: '#4A4D65' }} />
                )}
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </StyledTogglePanel>
      <Collapse in={isOpen} sx={{ width: '100%', display: 'flex' }}>
        {items}
      </Collapse>
    </>
  );
}
