import React from 'react';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import { CompatRouter, CompatRoute } from 'react-router-dom-v5-compat';
import app from './modules/app';
import authenticated from './modules/authenticated';
import history from './browserHistory';
import login from './modules/login';
import PublicLayout from './layout/PublicLayout/PublicLayout';
import Organization from './V2/features/organization/components/Organization';
import PowerBi from './V2/features/powerBi/components/PowerBi';
import Logout from './V2/Logout';
import Administration from './V2/features/administration/components/Administration';
import LandingPage from './V2/features/landingpage/components/LandingPage';

const createRoutes = () => {
  return (
    <>
      <Router>
        <CompatRouter>
          <app.bindings.AppBinding>
            <Switch>
              <CompatRoute exact path="/logout">
                <PublicLayout>
                  <Logout />
                </PublicLayout>
              </CompatRoute>
              <CompatRoute exact path="/login">
                <PublicLayout>
                  <login.bindings.LoginBinding />
                </PublicLayout>
              </CompatRoute>

              <Redirect exact from="/" to="/landingpage" />
              <authenticated.components.Authenticated>
                <CompatRoute path="/organization">
                  <Organization />
                </CompatRoute>
                <CompatRoute path="/administration">
                  <Administration />
                </CompatRoute>
                <CompatRoute path="/landingpage">
                  <LandingPage />
                </CompatRoute>
                <CompatRoute path="/moodandconfidence">
                  <div className="hidden" />
                </CompatRoute>
                <CompatRoute path="/engineeringcompetitivness">
                  <div className="hidden" />
                </CompatRoute>
                <CompatRoute path="/jobfamily">
                  <div className="hidden" />
                </CompatRoute>
                <>
                  <PowerBi />
                </>
              </authenticated.components.Authenticated>
            </Switch>
          </app.bindings.AppBinding>
        </CompatRouter>
      </Router>
    </>
  );
};

export default <Router history={history} children={createRoutes()} />;
