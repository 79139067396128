import React, { useContext, useEffect, useState } from 'react';
import { Collapse, Grid, IconButton } from '@mui/material';
import Discipline from './Discipline';
import { OrganizationCardContext } from '../../../../context/OrganizationCardContextProvider';
import { TdsIcon } from '@tds/react';
import PlusIcon from '../../../../../../../../../lib/icons/PlusIcon';
import EmwTypography from '../../../../../../../../components/EmwTypography/EmwTypography';
import { reverseAndUnshift, sortValidItems, splitList } from '../../utils';
import { useSelector } from 'react-redux';
import { userSelector } from '../../../../../../../../../modules/header/selectors';

export default function DisciplinesPanel({ disciplines, index, targetField }) {
  const context = useContext(OrganizationCardContext);
  const [isOpen, setIsOpen] = useState(false);
  const [openNewDiscipline, setOpenNewDiscipline] = useState(false);
  const [canAdd, setCanAdd] = useState(false);
  const user = useSelector(userSelector);
  const [incompleteList, setIncompleteList] = useState([]);
  const [completeList, setCompleteList] = useState([]);

  const isAdmin = () => {
    if (user.list && user.list.data) {
      if (user.list.data.additionalUser) {
        return user.list.data.additionalUser.admin;
      }

      return user.list.data.admin;
    }
    return false;
  };

  useEffect(() => {
    if (context.type === 'CREATE' && !isAdmin()) {
      return;
    }
    if (disciplines.length > 0) {
      setCanAdd(disciplines[0].btnAdd.visible);
    }
    setDisciplineItems();
  }, [disciplines]);

  const addItem = () => {
    setOpenNewDiscipline(true);
    setIsOpen(true);
    context.addNewDiscipline();
  };

  const setDisciplineItems = () => {
    if (!isOpen) {
      setIsOpen(context.shouldBeOpen(disciplines, targetField.key));
    }

    const regExp = /^Discipline [0-9]+$/;
    let { _incompleteItems, _completeItems } = splitList(disciplines, regExp);

    _incompleteItems = _incompleteItems.map(discipline => {
      return (
        <Discipline
          openNewDiscipline={openNewDiscipline}
          key={discipline.originalIndex}
          discipline={discipline}
          index={discipline.originalIndex}
          color="#E6F8FC"
          targetField={targetField}
        />
      );
    });

    _completeItems = _completeItems.map(discipline => {
      return (
        <Discipline
          openNewDiscipline={openNewDiscipline}
          key={discipline.originalIndex}
          discipline={discipline}
          index={discipline.originalIndex}
          color="#E6F8FC"
          targetField={targetField}
        />
      );
    });

    _incompleteItems.map((_item, index) => {
      if (!_item.props.discipline.name.value || regExp.test(_item.props.discipline.name.value)) {
        if (_item.props.discipline.originalIndex) {
          _item.props.discipline.name.value = `Discipline ${
            _item.props.discipline.originalIndex + 1
          }`;
        } else {
          _item.props.discipline.name.value = `Discipline ${index + 1}`;
        }
      }

      return _item;
    });

    setIncompleteList(_incompleteItems);
    setCompleteList(_completeItems);
  };

  return (
    <>
      <Grid
        item
        className="toggle-panel"
        sx={{ flexGrow: 1, borderRadius: isOpen ? '5px 5px 0 0' : '5px' }}
      >
        <Grid container direction="row" alignItems="center">
          <Grid item mr={1}>
            <TdsIcon icon="factory" variant="outlined" style={{ color: '#4A4D65' }} />
          </Grid>

          <Grid item>
            <EmwTypography component="caption-2" fontWeight="bold" classes="text-bluegrey-600">
              DISCIPLINES
            </EmwTypography>
          </Grid>

          <Grid item ml="auto">
            <Grid container alignItems="center">
              {canAdd && (
                <IconButton
                  title="Add new"
                  sx={{ padding: '5px', margin: '-5px' }}
                  disableRipple
                  onClick={addItem}
                  id="btnAddNewDisciplines"
                >
                  <PlusIcon />
                </IconButton>
              )}
              <IconButton
                title={`${isOpen ? 'Collapse section' : 'Expand section'}`}
                disableRipple
                size="small"
                onClick={() => setIsOpen(!isOpen)}
                id={`${isOpen ? 'btnCollapseDisciplines' : 'btnExpandDisciplines'}`}
              >
                {isOpen ? (
                  <TdsIcon icon="keyboard_arrow_up" size="small" style={{ color: '#4A4D65' }} />
                ) : (
                  <TdsIcon icon="keyboard_arrow_down" size="small" style={{ color: '#4A4D65' }} />
                )}
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Collapse className="toggle-collapse" in={isOpen}>
        {reverseAndUnshift(incompleteList, 'discipline')}
        {sortValidItems(completeList, 'discipline')}
      </Collapse>
    </>
  );
}
