import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { ROLES as roles, SERVICES as services } from '../../../constants';
import HorizontalBarChart from '../../../../../components/EmwCharts/components/BarChart/HorizontalBarChart';
import { StyledUsageMonitoringDrillThrough } from './styled';
import EmwButton from '../../../../../components/EmwButton/EmwButton';
import SortAlpha from '../../../../../../lib/icons/SortAlpha';
import SortAlphaReverse from '../../../../../../lib/icons/SortAlphaReverse';
import SortValue from '../../../../../../lib/icons/SortValue';
import SortValueReverse from '../../../../../../lib/icons/SortValueReverse';
import {
  getUsageMonitoringServicesCountApi,
  getUsageMonitoringServicesUserPercentageApi,
  getUsageMonitoringRolesApi,
} from '../../../redux/api';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../../../../components/EmwGeneralFilters/redux/actions';
import useAppSnackbar from '../../../../../hooks/useAppSnankbar';
import ServicesRolesFilters from './ServicesRolesFilters';
import {
  rolesFiltersSelector,
  servicesFiltersSelector,
} from '../../../../../components/EmwGeneralFilters/redux/selectors';

export default function UsageMonitoringDrillThrough({ payload, stateData }) {
  const [newStateData, setNewStateData] = useState(stateData);
  const [sortedClickData, setSortedClickData] = useState([]);
  const [sortedPercentageData, setSortedPercentageData] = useState([]);
  const [serviceCountData, setServiceCountData] = useState([]);
  const [rolesList, setRolesList] = useState([]);
  const [percentageData, setPercentageData] = useState([]);
  const [reversed, setReversed] = useState(false);
  const [sortBy, setSortBy] = useState('label');
  const dispatch = useDispatch();
  const snackbar = useAppSnackbar();
  const rolesFilters = useSelector(rolesFiltersSelector);
  const servicesFilters = useSelector(servicesFiltersSelector);

  useEffect(() => {
    (async () => {
      try {
        const response = await getUsageMonitoringRolesApi();
        if (response.data.length) {
          const rolesMap = response.data.map(item => ({ value: item.code, label: item.value }));
          rolesMap.unshift({
            value: 'all',
            label: 'Select All',
          });
          setRolesList(rolesMap);
        } else {
          setRolesList(roles);
        }
      } catch (err) {
        console.error('Error getting roles! ' + err);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      dispatch(setLoading(true));

      try {
        const response = await getUsageMonitoringServicesCountApi(newStateData);
        const percentageResponse = await getUsageMonitoringServicesUserPercentageApi(newStateData);
        setServiceCountData(response.data);
        setPercentageData(percentageResponse.data);
        setSortedClickData(sortData(response.data, 'value', 1));
        setSortedPercentageData(sortData(percentageResponse.data, 'value', 1));
      } catch (reason) {
        snackbar.show(reason.message || reason.statusText, 'warning');
      } finally {
        dispatch(setLoading(false));
      }
    })();
  }, [newStateData]);

  useEffect(() => {
    setNewStateData(currentNewStateData => {
      return {
        ...currentNewStateData,
        roles: rolesFilters,
        services: servicesFilters,
      };
    });
  }, [rolesFilters, servicesFilters]);

  useEffect(() => {
    setNewStateData(prevState => {
      return {
        ...prevState,
        organizationFilters: {
          ...prevState.organizationFilters,
          ...stateData.organizationFilters,
        },
        periodFilters: {
          ...prevState.periodFilters,
          ...stateData.periodFilters,
        },
        roles: rolesFilters,
        services: servicesFilters,
      };
    });
  }, [stateData]);

  const sortData = (data, sortBy, sortOrder) => {
    const compareFn = (a, b) => {
      const parseFunction = val => {
        if (!isNaN(parseFloat(val))) {
          return parseFloat(val);
        }
        return val;
      };

      const aValue = parseFunction(a[sortBy]);
      const bValue = parseFunction(b[sortBy]);

      if (sortBy === 'value') {
        return sortOrder * (aValue - bValue);
      } else {
        return sortOrder * aValue.toString().localeCompare(bValue);
      }
    };
    return data.slice().sort(compareFn);
  };

  const handleSortByClick = (sortType, sortOrder) => {
    setReversed(sortOrder === -1);
    setSortBy(sortType);
    setSortedClickData(sortData([...serviceCountData], sortType, sortOrder));
    setSortedPercentageData(sortData([...percentageData], sortType, sortOrder));
  };

  const handleSortByLabelDescendingClick = () => {
    handleSortByClick('label', 1);
  };

  const handleSortByLabelAscendingClick = () => {
    handleSortByClick('label', -1);
  };

  const handleSortByValueDescendingClick = () => {
    handleSortByClick('value', 1);
  };
  const handleSortByValueAscendingClick = () => {
    handleSortByClick('value', -1);
  };

  return (
    <StyledUsageMonitoringDrillThrough
      container
      className="flex-col h-full overflow-hidden flex-nowrap"
    >
      <div className="flex bg-white-100 h-6xl items-center justify-between mt-s mb-l shadow-s-light">
        <div className="pl-m">
          <ServicesRolesFilters stateData={stateData} />
        </div>
        <div className="flex flex-row inline-block mr-xs">
          <EmwButton
            id="btnSortAlpha"
            title="Sort Alphabetically"
            size="small"
            onClick={handleSortByLabelAscendingClick}
            disabled={false}
            variant="ghost"
          >
            <SortAlpha />
          </EmwButton>
          <EmwButton
            id="btnSortAlphaReverse"
            title="Sort Reverse Alphabetically"
            size="small"
            onClick={handleSortByLabelDescendingClick}
            disabled={false}
            variant="ghost"
          >
            <SortAlphaReverse />
          </EmwButton>
          <EmwButton
            id="btnSortAsc"
            title="Sort Ascending"
            size="small"
            onClick={handleSortByValueAscendingClick}
            disabled={false}
            variant="ghost"
          >
            <SortValue />
          </EmwButton>
          <EmwButton
            id="btnSortDesc"
            title="Sort Descending"
            size="small"
            onClick={handleSortByValueDescendingClick}
            disabled={false}
            variant="ghost"
          >
            <SortValueReverse />
          </EmwButton>
        </div>
      </div>

      <Grid spacing={3} className="flex flex-grow overflow-hidden justify-between ">
        <Grid
          item
          sm={6}
          xs={12}
          className="flex flex-grow overflow-auto overflow-x-hidden mr-l shadow-s-light mb-s rounded-sm"
        >
          <HorizontalBarChart
            unit=""
            data={sortedClickData}
            name="Number of clicks"
            tooltipInfo={<p className="mb-xxs">&#8226; Number of clicks</p>}
            info="Number of clicks"
          />
        </Grid>
        <Grid
          item
          sm={6}
          xs={12}
          className="flex flex-grow overflow-auto overflow-x-hidden shadow-s-light mb-s rounded-sm"
        >
          <HorizontalBarChart
            unit=""
            data={sortedPercentageData}
            infoColor="bg-lightblue-500"
            color="#00BBDD"
            name="Percentage of Users"
            tooltipInfo={<p className="mb-xxs">&#8226; Percentage of Users.</p>}
            info="% of Users"
            hasCustomTooltip={true}
          />
        </Grid>
      </Grid>
    </StyledUsageMonitoringDrillThrough>
  );
}
