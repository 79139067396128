import React, { useContext, useEffect, useState } from 'react';
import { Box, CircularProgress, Grid, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import {
  EmwAutocomplete,
  EmwButton,
  EmwDropDownList,
  EmwTextField,
} from '../../../../../../../../../lib/common';
import { StyledEmwDropDownList } from '../../../../../../../../../modules/organization/components/organizationCard/view/OrganizationCardStyledComponent';
import { WORKER_API_ENDPOINT } from '../../../../../../../../../constants';
import { calculateAutocompleteSugestionForEmployee } from '../../../../../../../../../modules/organization/components/OrganizationUtils';
import {
  DATE_TIME_FORMAT,
  ENGINEER_TYPE,
  NO_RESOURCE_ALLOCATED,
} from '../../../../../../constants';
import { OrganizationCardContext } from '../../../../context/OrganizationCardContextProvider';
import EmwDatePicker from '../../../../../../../../../lib/commonv2/EmwDatePicker';
import EmwTypography from '../../../../../../../../components/EmwTypography/EmwTypography';
import { getCountriesApi } from '../../../../../../redux/api';
import Autocomplete from '@mui/material/Autocomplete';
import { useSelector } from 'react-redux';
import { disciplineNamesSelector, orgDetailsSelector } from '../../../../../../redux/selectors';
import { TdsTextInput } from '@tds/react';
import EmwCustomAutocomplete from '../../../../../../../../components/EmwCustomAutocomplete/EmwCustomAutocomplete';

const Root = styled(Grid)(({ theme }) => ({
  padding: '8px',
  backgroundColor: '#F7F7F9',
  borderRadius: '10px',
  marginTop: '8px',
}));

const StyledButton = styled(Grid)(({ theme }) => ({
  flexGrow: 1,
  'tds-button': {
    width: '100%',
  },
  button: {
    width: '100%',
  },
}));

export default function FormEditItem({
  name,
  item,
  label,
  toggle,
  section,
  index,
  propKey,
  handleUpdate,
  isTextField,
  setEditMode,
  handleChangeHoD,
  isNew,
  text,
  id,
  beforeCancelClick,
  childrenIndex,
  isOtherReferent,
  categories,
}) {
  const context = useContext(OrganizationCardContext);
  const org = useSelector(orgDetailsSelector);
  const disciplineList = useSelector(disciplineNamesSelector);
  const [value, setValue] = useState('');
  const [type, setType] = useState(false);
  const [showEffectiveDropdown, setShowEffectiveDropdown] = useState(false);
  const [values, setValues] = useState(cloneDeep(item));
  const [countries, setCountries] = React.useState({ label: '', code: '' });
  const [defaultCountry, setDefaultCountry] = React.useState();
  const [defaultDisciplineName, setDefaultDisciplineName] = useState({ label: '', value: '' });
  const [open, setOpen] = React.useState(false);
  const loading = open && countries.length === 0;
  const [isNewCer, setIsNEwCer] = useState(false);
  const [disciplineNames, setDisciplinesNames] = useState({ label: '', value: '', indent: null });
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();
  const [hasError, setHasError] = useState(false);

  const categoriesList =
    categories &&
    categories.map(category => {
      return { value: category.value, label: category.value, indent: category.indent };
    });

  useEffect(() => {
    if (org.list.data.section3.disciplineNames.length > 0) {
      return setDisciplinesNames(() =>
        org.list.data.section3.disciplineNames.map(discipline => {
          return {
            value: discipline.value,
            label: discipline.value,
            indent: discipline.indent,
          };
        })
      );
    }
    if (disciplineList.list.hasOwnProperty('data')) {
      setDisciplinesNames(() => {
        return disciplineList.list.data.map(disciplineName => {
          return {
            label: disciplineName.value,
            value: disciplineName.value,
            indent: disciplineName.indent,
          };
        });
      });
    }
  }, [disciplineList.list]);

  useEffect(() => {
    if (item.baseName) {
      setDefaultDisciplineName(item.baseName);
    }
    if (item.hasOwnProperty('type') && item.type.visible) {
      setType(item.type.value);
      setShowEffectiveDropdown(true);
    } else {
      setShowEffectiveDropdown(false);
    }

    if (context.hasReservedName(values.name.value) || !values.name.value) {
      setHasError(true);
    }

    if (isOtherReferent && item && item.hasOwnProperty('category') && !item.category.value) {
      setHasError(true);
    }
  }, [item]);

  useEffect(() => {
    if (section !== 'sectionCer') {
      return;
    }

    if (!org.list.data.sectionCer.cers[index]) {
      setIsNEwCer(true);
    }

    (async () => {
      if (!countries.length) {
        const response = await getCountriesApi();
        const itemCountry = response.data.find(country => {
          return country.code === item.countryCode;
        });
        if (itemCountry) {
          setDefaultCountry({
            code: itemCountry.code,
            label: itemCountry.name,
          });
        }

        await setCountries(() =>
          response.data.map(country => {
            return {
              code: country.code,
              label: country.name,
            };
          })
        );
      }
    })();
  }, []);

  const handleTypeChange = event => {
    try {
      setType(event.detail.value);
      const updatedValues = { ...values };
      updatedValues.type.value = Number(event.detail.value);
      setValues(updatedValues);
    } catch (e) {
      // TODO: remove silent error after autocomplete selection bug is fixed
      console.warn('debug', e);
    }
  };

  const handleTextChange = valueText => {
    const updatedValues = { ...values };
    updatedValues.name.value = valueText;
    updatedValues.key = '';
    setValues(updatedValues);
    setValue(valueText);
    setHasError(false);
  };

  const handleSave = () => {
    if (handleUpdate) {
      handleUpdate(values, propKey);
    } else {
      if (isNew) {
        if (context.data.sectionCer[propKey]) {
          index = context.data.sectionCer[propKey].length;
        } else {
          index = context.data.section1[propKey].length;
        }
      }
      context.updateData(section, propKey, item, values, index, isNew);
    }
    if (typeof handleChangeHoD === 'function') {
      handleChangeHoD(values);
    }
    setEditMode(false);
  };

  const handleStartDateChange = date => {
    const updatedValues = { ...values };
    setDateFrom(date);
    if (updatedValues.hasOwnProperty('date')) {
      updatedValues.date.value = date ? moment(date).format(DATE_TIME_FORMAT) : null;
    }

    if (updatedValues.hasOwnProperty('startDate')) {
      updatedValues.startDate.value = date ? moment(date).format(DATE_TIME_FORMAT) : null;
    }
    setValues(updatedValues);
  };

  const handleEndDateChange = date => {
    setDateTo(date);
    const updatedValues = { ...values };
    updatedValues.endDate.value = date ? moment(date).format(DATE_TIME_FORMAT) : null;
    setValues(updatedValues);
  };

  const calculateLabelValue = selectedOption => {
    const updatedValues = { ...values };
    updatedValues.id = selectedOption.value;
    updatedValues.name.value =
      selectedOption.object.firstName + ' ' + selectedOption.object.lastName;
    setValues(updatedValues);
    return selectedOption && selectedOption.object
      ? selectedOption.object.firstName + ' ' + selectedOption.object.lastName
      : '';
  };

  const renderAutocomplete = () => {
    if (isTextField) {
      return null;
    }

    return (
      <>
        <Grid item sm={1.5}>
          <EmwTypography fontSize="9">
            {label === 'Other Referents' ? 'Referent' : label}:
          </EmwTypography>
        </Grid>
        <Grid item sm={7}>
          <EmwAutocomplete
            value={item.name.value}
            inputId={`${label}-${index}`}
            urlToFetch={WORKER_API_ENDPOINT}
            orgId={org.list.data.cardDetails.id}
            additionalCSS={{
              backgroundColor: 'white',
              width: '100%',
              '*': {
                padding: 0 + '!important',
              },
              input: {
                fontSize: '12px',
                height: '20px',
                padding: '5px!important',
              },
              '.MuiInputBase-sizeSmall': {
                borderRadius: '2px',
                padding: 0,
              },
            }}
            autoFocus={true}
            variant={'outlined'}
            size={'small'}
            disabled={!item.btnEdit.enabled}
            placeHolder={item.name.value}
            calculateAutocompleteSugestion={calculateAutocompleteSugestionForEmployee}
            calculateAutocompleteLabel={calculateLabelValue}
          />
        </Grid>
      </>
    );
  };

  const handleChange = (event, value, reason, details) => {
    const updatedValues = { ...values };
    if (!value) {
      return;
    }
    updatedValues.countryCode = value.code;
    updatedValues.countryName = value.label;
    setValues(updatedValues);
    setDefaultCountry({ label: value.label, code: value.code });
  };

  const handleCustomChange = (event, value) => {
    const updatedValues = { ...values };
    //new input value
    setHasError(false);
    if (!value) {
      updatedValues.name.value = event.target.value;
      setValues(updatedValues);
      return;
    }

    if (value.inputValue) {
      updatedValues.name.value = value.inputValue;
      updatedValues.baseName = value.inputValue;
      setValues(updatedValues);
    }

    if (value.value) {
      //avoid display comment null
      if (item.comment.value) {
        updatedValues.name.value = value.value + ' - ' + item.comment.value;
      } else {
        updatedValues.name.value = value.value;
      }
      updatedValues.baseName = value.value;
      setValues(updatedValues);
    }
  };

  const handleCategorySelect = (event, value) => {
    const updatedValues = { ...values };
    //new input value
    setHasError(false);
    if (!value) {
      updatedValues.category.value = event.target.value;
      setValues(updatedValues);
      return;
    }
    if (value.inputValue) {
      updatedValues.category.value = value.inputValue;
      setValues(updatedValues);
    }
    if (value.value) {
      updatedValues.category.value = value.value;
      setValues(updatedValues);
    }
  };

  const handleCommentChange = event => {
    const updatedValues = { ...values };

    updatedValues.comment.value = event.target.value;
    updatedValues.name.value = values.name.value + ' - ' + event.target.value;

    setValues(updatedValues);
  };

  const renderCountrySection = () => {
    if (section === 'sectionCer') {
      return (
        <Autocomplete
          fullWidth
          id="country-list"
          sx={{
            border: '1px solid #5D607E',
            padding: '3px',
            background: 'white',
            '.MuiInputBase-root': {
              padding: '2px 5px 1px',
            },
            input: {
              fontSize: '11px',
              '&::placeholder': {
                // color: "#e07903",
                opacity: '0.5',
                // fontWeight: "bold",
              },
            },
          }}
          open={open}
          size="small"
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          value={defaultCountry ? defaultCountry : ''}
          // disabled={!isNewCer}
          // isOptionEqualToValue={(option, value) => option.code === value.code}
          // getOptionLabel={(option) => option.label}
          // getOptionSelected={(option, value) => option.code === value.code}
          // defaultValue={defaultCountry}
          options={countries}
          loading={loading}
          onChange={handleChange}
          renderInput={params => (
            <TextField
              variant="standard"
              // placeholder={defaultCountry.length === 0 ? 'Please select at least one country' : ''}
              placeholder="Select Country"
              {...params}
              InputProps={{
                disableUnderline: true,
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      );
    }

    return null;
  };

  const renderTextField = () => {
    if (!isTextField) {
      return null;
    }

    return (
      <>
        <Grid item sm={1.5}>
          <EmwTypography fontSize="9">Name:</EmwTypography>
        </Grid>
        <Grid item sm={7}>
          <Box mr={1} sx={{ '.text-input': { fontSize: '12px' } }}>
            <EmwTextField
              inputId={id}
              value={value}
              defaultValue={name}
              disabled={!item.btnEdit.enabled}
              onTextChange={handleTextChange}
              size={'small'}
              textFieldStyle={{ borderRadius: '2px' }}
              atributes={{
                disabled: false,
                classes: hasError ? 'has-error' : '',
              }}
            />
          </Box>
        </Grid>
      </>
    );
  };

  const handleKPICommentChange = event => {
    const updatedValues = { ...values };
    updatedValues.comment.value = event.target.value;
    setValues(updatedValues);
  };

  const renderKPIComment = () => {
    if (values.comment) {
      return (
        <Grid item>
          <TdsTextInput
            id={`comment-kpi`}
            classes="ml-xs mr-s comment"
            placeholder="Comment"
            size="small"
            value={values.comment.value}
            onValueChanged={handleKPICommentChange}
          />
        </Grid>
      );
    }
  };

  return (
    <Root container className="edit-form-container my-xxs">
      <Grid item sm={12} md={10}>
        <Grid container>
          <Grid item sm={12} sx={{ marginBottom: '8px' }}>
            <Grid container alignItems="center">
              {renderAutocomplete()}
              {text === 'discipline' && disciplineNames.length > 0 ? (
                <>
                  <EmwTypography classes="discipline-input" fontSize="9">
                    <p>Discipline:</p>
                  </EmwTypography>
                  <div style={{ width: '300px' }}>
                    <EmwCustomAutocomplete
                      hasAutoFocus={true}
                      placeholder={name}
                      name="Discipline"
                      id={`discipline-name-${id}`}
                      options={disciplineNames}
                      handleChange={handleCustomChange}
                      defaultValue={defaultDisciplineName}
                    />
                  </div>
                </>
              ) : (
                renderTextField()
              )}
              <Grid item sm={3}>
                {showEffectiveDropdown && (
                  <StyledEmwDropDownList ml={1}>
                    <EmwDropDownList
                      placeHolder="Effective"
                      options={[
                        { value: 0, text: 'Effective' },
                        { value: 1, text: 'Acting' },
                        { value: 2, text: 'External' },
                      ]}
                      placement="bottom"
                      onDropDownListChanged={handleTypeChange}
                      size="small"
                      disabled={!item.btnEdit.enabled}
                      value={type}
                    />
                  </StyledEmwDropDownList>
                )}
                {isOtherReferent && (
                  <Box ml={1}>
                    <EmwCustomAutocomplete
                      hasAutoFocus={false}
                      placeholder={'Category'}
                      name="Category"
                      id={`discipline-name-${id}`}
                      options={categoriesList}
                      handleChange={handleCategorySelect}
                      defaultValue={values.category.value ? values.category.value : ''}
                      classes={hasError ? 'mandatory-category' : ''}
                    />
                  </Box>
                )}
                {text === 'discipline' && (
                  <Grid item>
                    <TdsTextInput
                      inputId={`comment-${id}`}
                      classes="ml-s mr-s comment"
                      placeholder="Comment"
                      size="small"
                      value={values.comment.value}
                      onValueChanged={handleCommentChange}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid container alignItems="center">
            <Grid item sm={1.5}>
              <EmwTypography fontSize="9">From:</EmwTypography>
            </Grid>
            <Grid item sm={6} md={6} lg={7}>
              <Grid container md={8} lg={12} alignItems="center" justifyContent="space-between">
                <EmwDatePicker
                  inputId={`${id}-start-date`}
                  date={
                    values.hasOwnProperty('startDate') ? values.startDate.value : values.date.value
                  }
                  disabled={!item.btnEdit.enabled}
                  handleChange={handleStartDateChange}
                  maxDate={dateTo}
                />
                <EmwTypography fontSize="9">To:</EmwTypography>
                <EmwDatePicker
                  inputId={`${id}-end-date`}
                  date={values.endDate.value}
                  disabled={!item.btnEdit.enabled}
                  handleChange={handleEndDateChange}
                  disablePast={false}
                  minDate={dateFrom}
                />
                {(section === 'sectionCer' || isNewCer) && (
                  <EmwTypography fontSize="9">Country:</EmwTypography>
                )}
              </Grid>
            </Grid>
            {label === ENGINEER_TYPE.KPI.label &&
              values.comment.visible &&
              values.comment.enabled && (
                <Grid item sm={3}>
                  {renderKPIComment()}
                </Grid>
              )}
            <Grid item sm={3} ml={1}>
              {renderCountrySection()}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item sm={12} md={2}>
        <Grid container>
          <StyledButton item sx={{ marginBottom: '2px' }}>
            <EmwButton
              variant="outline"
              size="small"
              id={`btnFormItemCancel-${label}-${index ? index : '0'}`}
              onClick={() => {
                setEditMode(false);
                if (beforeCancelClick) {
                  beforeCancelClick();
                }
              }}
            >
              Cancel
            </EmwButton>
          </StyledButton>

          <StyledButton item>
            <EmwButton
              variant="filled"
              title={
                (hasError || values.name.value === NO_RESOURCE_ALLOCATED) && 'Please fill the name'
              }
              size="small"
              id={`btnFormItemUpdate-${label}-${index ? index : '0'}`}
              sx={{ button: { width: '100%' } }}
              onClick={handleSave}
              disabled={hasError || values.name.value === NO_RESOURCE_ALLOCATED}
            >
              Update
            </EmwButton>
          </StyledButton>
        </Grid>
      </Grid>
    </Root>
  );
}
